/**
 * Handle GTM Events
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#ecommerce-data
 */
import { emitter } from './common'
import { type IProductSearchItem } from './types'
import { gtmEcommerceTracking, gtmEventTracking, type IProductTrackingItem } from './utilities/Gtm'
import { type IFormResponseInfo } from '@/components/GenericForm/types'
import { type ISearchAttributeMap } from '@/components/List/types'
import { type IInquiryProduct } from '@/pages/inquiries/types'
import { type IProjectProductItem } from '@/pages/projects/types'
import { isString, isArray } from 'radash'

export interface IListResponseInfo {
  items: any[]
  total: number
  baseUrl: string
  id: string
  params: any
}

export function mapInquiryItem(p: IInquiryProduct, data?: { [key: string]: any }) {
  return mapProductItem(p, { quantity: p.quantity, ...data })
}
export function mapProductItem(p: any, data?: { [key: string]: any }) {
  return {
    id: p.productId || p.id,
    name: p.title,
    brand: isString(p.brand) ? p.brand : p.brand.title,
    price: p.retailPriceNetString,
    quantity: 1,
    ...(data ? data : {}),
  }
}

const CHECKOUT_STEPS: { [k: string]: number } = {
  drafted: 0,
  inquired: 1,
  processing: 2,
  offered: 3,
  completed: 4,
}

let categoryMap: { [id: string]: { id: string; title: string } } = {}

let temporaryProductDump: IProductTrackingItem[] = []
emitter.on('loadProducts', (products?: IProductSearchItem[] | IProjectProductItem[]) => {
  temporaryProductDump = (products as any).map(mapProductItem)
})

emitter.on('submitSuccess', (args?: IFormResponseInfo) => {
  const { formId, formData, data, trackingInfo, action, endpoint } = args!
  switch (formId) {
    case 'FormRegistration':
      if (endpoint === '/_/Register/Register') {
        gtmEventTracking('event', 'registration', 'registrationSubmit', formData.Name)
      }
      break
    case 'FormProjectCreate':
      gtmEventTracking('event', 'project', 'createProject', formData.Name)
      break
    case 'FormAddOrEditExternalProjectItem':
      gtmEventTracking('event', 'project', 'addExternalProduct', formId + ' - ' + formData.name)
      break
    case 'FormAddProductToProject':
      if (action === 'AddProductsToInquiry') {
        // const productIds: string[] = formData.SelectedProducts
        gtmEcommerceTracking('addToCart', {
          add: {
            products: (isArray(formData.SelectedProducts)
              ? formData.SelectedProducts
              : [formData.SelectedProducts]
            ).map((id: string) => temporaryProductDump.find((p: any) => p.id === id)),
          },
        })
      }
      break
    case 'FormCreateInquiry':
      gtmEcommerceTracking('addToCart', {
        add: {
          products: formData.Items.map((id: string) => temporaryProductDump.find((p: any) => p.id === id)),
        },
      })
      break
    case 'FormInquiry':
      const isCheckout: boolean = action === 'Request'
      // @TODO make sure this works
      gtmEcommerceTracking('checkout', {
        checkout: {
          actionField: { step: isCheckout ? 1 : 0, id: data?.id },
          products: data?.items?.map(mapProductItem),
        },
      })
      gtmEventTracking('event', 'inquiry', 'requestInquiry', formData.InquiryIdentifier, data?.retailPriceTotalNet)
      break
    case 'FormUpdateInquiry':
      gtmEcommerceTracking('checkout', {
        checkout: {
          actionField: { step: CHECKOUT_STEPS[formData.Status as string], id: data.id },
        },
      })
      break
    case 'UpdateInquiryProducts':
      const addToCart: boolean = formData.Quantity > 0
      gtmEcommerceTracking(addToCart ? 'addToCart' : 'removeFromCart', {
        [addToCart ? 'add' : 'remove']: [mapInquiryItem(trackingInfo)],
      })
      break
  }
})

emitter.on('loadCategories', (categories?: ISearchAttributeMap) => {
  if (categories) {
    categoryMap = categories
  }
})

emitter.on('printInquiryPreview', () => {
  gtmEventTracking('event', 'inquiry', 'printInquiryPreview')
})

emitter.on('load', (args?: IListResponseInfo) => {
  const { id, items, params } = args!
  switch (id) {
    case 'products':
      temporaryProductDump = items
      gtmEcommerceTracking(null, {
        impressions: items.map((product: IProductSearchItem, index: number) => ({
          name: product.title,
          id: product.id,
          price: product.retailPriceNet,
          brand: product.brand.title,
          category:
            categoryMap &&
            product.categories.length > 0 &&
            `${categoryMap[product.categories[0]]?.title} (${categoryMap[product.categories[0]]?.id})`,
          list: 'Search Results',
          position: params.from + index + 1,
        })),
      })

      break
  }
})
