import { type InquiryStatus } from '@/pages/inquiries/types'
import { type IInquirySupplier, type IProjectSupplier } from '@/pages/manufacturer/components/types'
import { type IPaginatedResponse } from '@/types'
import { paramsToURIComponent } from '@/utilities'
import { formatPrice } from '@/utilities'
import axios from 'axios'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

export const inqTotalPrice = (inq: IInquirySupplier) => formatPrice(inq.totalAmountSupplier)

const parseInstantString = (dateAsString: string) => parseInt(dateAsString.slice(0, 10).split('-').join(''))
export const sortByDateString = (a: IInquirySupplier, b: IInquirySupplier) => {
  try {
    return parseInstantString(b.events!.instant) - parseInstantString(a.events!.instant)
  } catch (e) {
    return -1
  }
}

export const useQuery = () => {
  const router = useRouter()
  const query = computed<string>({
    get: () => (router.currentRoute.value?.query?.q as string) || '',
    set: (value: string) => router.replace({ query: { q: value } }),
  })
  return query
}

export const MANUFACTURER_STATUS_OFFER: InquiryStatus[] = ['drafted', 'inquired', 'processing', 'offered']
export const MANUFACTURER_STATUS_ORDER: InquiryStatus[] = ['accepted']
export const MANUFACTURER_STATUS_ARCHIVE: InquiryStatus[] = [
  'declined',
  'expired',
  'declinedWithChangeRequest',
  'rejectedByOperator',
]
export const useManufacturerStore = defineStore('manufacturerStore', () => {
  const STATUS_MAP: Record<string, InquiryStatus[]> = {
    a: MANUFACTURER_STATUS_OFFER,
    b: MANUFACTURER_STATUS_ORDER,
    c: MANUFACTURER_STATUS_ARCHIVE,
  }
  const loading = ref(false)

  const projects = ref<IProjectSupplier[]>([])
  const load = async (supplierId: string = '') => {
    loading.value = true
    try {
      const response = await axios.get<IPaginatedResponse<IProjectSupplier>>(
        (supplierId ? '/Suppliers/ProjectsForSupplierId' : '/Suppliers/Projects') +
          paramsToURIComponent({ supplierId, params: { sort: 'created.at_desc', from: 0, size: 1000 } }),
      )
      projects.value = response.data.hits
    } catch (e) {}
    loading.value = false
  }
  return {
    load,
    projects,
    loading,
    getProject: (id: string) => computed(() => projects.value.find((p) => p.projectId === id)),
    inquiriesInOfferStatus: computed(() =>
      projects.value.flatMap((p) => p.inquiries.filter((i) => STATUS_MAP.a.includes(i.status))).sort(sortByDateString),
    ),
    inquiriesInOrderStatus: computed(() =>
      projects.value.flatMap((p) => p.inquiries.filter((i) => STATUS_MAP.b.includes(i.status))).sort(sortByDateString),
    ),
    inquiriesInArchiveStatus: computed(() =>
      projects.value.flatMap((p) => p.inquiries.filter((i) => STATUS_MAP.c.includes(i.status))).sort(sortByDateString),
    ),
  }
})
