<template lang="pug">
btn(plain :loading='false' :class='{ "btn-select--selected": selected }' class='btn-select')
  div(v-if='hasSlot' class='btn-select__image')
    slot(name='image')
  div(class='btn-select__content')
    slot
  div(v-if='hoverText' class='btn-select__hover') {{ hoverText }}
  div(v-if='selected' class='btn-select__check')
    icon(name='check')
  div(v-if='arrow' class='btn-select__arrow')
    icon(name='arrow-right' strokeWidth='2px')
</template>

<script lang="ts">
import Btn from '@/components/Btn.vue'
import Icon from '@/components/Icon.vue'
import { hasSlotContent } from '@/utilities/Vue'
import { computed, defineComponent, type SetupContext } from 'vue'

const BtnSelect = defineComponent({
  components: { Btn, Icon },
  props: {
    arrow: Boolean,
    loading: Boolean,
    selected: Boolean,
    hoverText: String,
  },
  setup(props, context: SetupContext) {
    return {
      hasSlot: computed(() => hasSlotContent(context.slots.image)),
    }
  },
})

export default BtnSelect
</script>

<style lang="stylus">
@import '../styles/variables.styl'
.btn-select
  &&
    padding-top: 6px
    padding-bottom: 6px
    margin-bottom: 2px
    display: flex!important
    align-items: center
    transition: none
    max-width: 100%
  &--selected
    --btn-bg-color: var(--blue-400-10)!important
    &:active
      --btn-bg-color: var(--blue-400-20)!important
    &[truncate]
      padding-right: 60px!important
  &__image
    mix-blend-mode: multiply
    background: #fff
    padding-right: 20px
    margin: -10px 0
    flex-shrink: 0
    ~/[disabled] &
      opacity: 0.5
  &__content
    flex: 1
    min-width: 0
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    ~/--selected &
      color: var(--color-accent)
  &__arrow
    font-size: 18px
    opacity: 0.45
    display: flex
    align-items: center
    ~/:hover &
      opacity: 1
  &__check
    color: var(--color-accent)
    transform: scale(1.5) translateY(2px)
    position: absolute
    right: 20px
    height: auto
  &__hover
    @media (max-width: $breakpoint-sm-max)
      display: none
    padding-left: 10px
    opacity: 0
    ~/[truncate] &
      display: none
    ~/--selected &
      visibility: hidden
    transition: opacity .1s ease
    font-style: italic
    font-family: $font-default
    font-weight: 400
    ~/:not(.btn-select--selected):not([disabled]):hover &
      display: block
      opacity: 0.45
</style>
