<template lang="pug">
div(:class='{ "action-bar--wrap": wrap }' class='action-bar')
  div(v-if='$slots.left' class='action-bar__leftOuter')
    div(class='action-bar__left')
      slot(name='left')
  div(class='action-bar__rightOuter')
    div(class='action-bar__right')
      slot
</template>

<script lang="ts">
import { defineComponent } from 'vue'

/**
 * @TODO: merge with Toolbar
 */
const ActionBar = defineComponent({
  props: {
    wrap: {
      type: Boolean,
      default: true,
    },
  },
})

export default ActionBar
</script>
<style lang="stylus">
@import '../styles/variables.styl'

.action-bar
  display: flex
  flex-wrap: wrap
  gap: 8px
  overflow: auto
  @media (max-width: $breakpoint-xs-max)
    gap: 4px

  &__left,
  &__right
    display: flex
    flex-wrap: wrap
    gap: 8px
    align-items: center
  &__rightOuter,
  &__leftOuter
    flex-grow: 1
  &__right
    justify-content: flex-end
</style>
