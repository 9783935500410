import type { IClipboardItem } from '@/types'
import { objectToFormData } from '@/utilities/Form'
import axios from 'axios-observable'
import { map } from 'rxjs'

export default {
  loadItems: () => axios.get<{ items: IClipboardItem[] }>(`/_/Clipboard`).pipe(map((r) => r.data.items)),
  addProduct: (productId: string) =>
    axios.post<{ items: IClipboardItem[] }>(`/_/Clipboard/Update`, objectToFormData({ Product: productId })),
  removeProduct: (productId: string) =>
    axios.post<{ items: IClipboardItem[] }>(`/_/Clipboard/RemoveItem`, objectToFormData({ Product: productId })),
}
