<template lang="pug">
div(class='progress-bar')
  div(class='progress-bar__bar')
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

const ProgressBar = defineComponent({
  props: {
    progress: Number,
  },
  setup(props) {
    return { progressPercent: computed(() => `${props.progress}%`) }
  },
})
export default ProgressBar
</script>
<style lang="stylus">
@import '../styles/variables.styl'

:root
  --progress-bar-height: 8px
  --progress-bar-padding: 0px
.progress-bar
  width: 100%
  background: var(--grey-100)
  height: var(--progress-bar-height)
  border-radius: calc(var(--progress-bar-height) /  2)
  padding: var(--progress-bar-padding)
  position: relative
  overflow: hidden
  &__bar
    position: absolute
    transition: width .2s ease
    top: 0
    left: 0
    width: v-bind(progressPercent)
    height: 100%
    background: var(--color-accent)
</style>
