import { emitter } from '@/common'
import { useUtils } from '@/plugins/Utils'
import { type IListResponseInfo } from '@/tracking'
import { type IPaginatedResponse } from '@/types'
import { areObjectsDifferent } from '@/utilities'
import { paramsToURIComponent } from '@/utilities'
import { type Types, getType, parseJSONWithFallback } from '@/utilities'
import { useSessionStorage, useDebounceFn } from '@vueuse/core'
import { type AxiosResponse } from 'axios'
import Axios from 'axios-observable'
import { isArray, isEmpty, isObject, isString, omit, pick, shake, sleep } from 'radash'
import {
  inject,
  computed,
  type ComputedRef,
  type MaybeRef,
  type Ref,
  type SetupContext,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  toRaw,
  unref,
  watch,
  onActivated,
  onDeactivated,
} from 'vue'
import { useRoute } from 'vue-router'

const isBoolean = (val: any) => 'boolean' === typeof val

const sanitize = (value: boolean | string | string[], valueType: string) => {
  switch (valueType) {
    case 'array':
      return !isArray(value) && valueType === 'array' ? (value === '' ? [] : [value]) : value
    case 'boolean':
      return isBoolean(value) ? value : JSON.parse(value as string)
    default:
      return value
  }
}

export const useListFilter = (name: string, initialValue: any, valueType: Types = getType(initialValue)) => {
  inject<RegisterPropertyFunction | null>('registerProperty', null)?.(
    name,
    valueType === 'array' ? [...initialValue] : initialValue,
    'filter',
  )
  const setProperty = inject<SetPropertyFunction | null>('setProperty', null)!
  const properties = inject<any>('properties', null)!
  const value = computed(() =>
    properties && properties.value[name] !== undefined ? sanitize(properties.value[name], valueType) : initialValue,
  )
  return {
    change: (v: string) => setProperty!(name, v),
    setProperty,
    value,
    properties,
  }
}

export type PropertyValue = string | boolean | number | string[]
export interface IListProperties {
  page: number
  pageSize: number
  q?: string
  sort?: string
  [key: string]: any
}

export interface IListPropertyInfo {
  initialValue: any
  valueType: string
  propertyType: PropertyType
}
export interface IPaginatedQueryParams {
  from: number
  size: number
  q?: string
}

export interface IPaginatedQueryParamsWithFilter extends IPaginatedQueryParams {
  sort?: string
  filter: {
    [key: string]: string[]
  }
}

export interface IPaginatedQueryParamsWithAttributes extends IPaginatedQueryParams {
  active?: boolean
  sort?: string // @TODO: implement in backend
  irrelevant?: boolean
  attributes: QueryAttributes[]
}

export type PropertyType = 'default' | 'filter'
export type QueryAttributes = { name: string; values: string[] }
export type SetPropertyFunction = (key: string, value: any, replace?: boolean) => void
export type SetPropertiesFunction = (p: { [key: string]: any }, replace?: boolean) => void
export type RegisterPropertyFunction = (key: string, intitialValue: any, propertyType?: PropertyType) => void
export type OnLoadFunction<T> = (
  data: IPaginatedResponse<T>,
  properties: Record<string, string | PropertyValue>,
) => void
/**
 * Takes object and removes all key/value pairs with empty ('', [], {}) values.
 */
const removeEmpty = (obj: Record<string, any>) =>
  shake(obj, (v) => v === undefined || v === '' || v.length === 0 || (isObject(v) && isEmpty(v)))

const propertiesToParams = (p: Record<string, any>) => {
  const { page, pageSize, q, sort, ...otherProperties } = p
  return removeEmpty({
    from: (page - 1) * pageSize,
    size: pageSize,
    q,
    sort,
    filter: removeEmpty(otherProperties),
  }) as IPaginatedQueryParamsWithFilter
}

const convertStringToNumberOrBoolean = (value: string | any, type: string) => {
  if (!isString(value)) {
    return value
  }
  switch (type) {
    case 'number':
      return Number(value)
    case 'boolean':
      return parseJSONWithFallback<boolean>(value, false)
    default:
      return value
  }
}

const extractAndSanitzeProperties = (keys: string[], types: Record<string, string>, source: { [key: string]: any }) =>
  Object.fromEntries(
    Object.entries(source)
      .filter(([key, value]) => keys.includes(key) && value !== undefined)
      .map(([key, value]) => [key, convertStringToNumberOrBoolean(value, types[key])]),
  )

export interface IUseListConfig<T> {
  id: string
  endpoint: MaybeRef<string>
  pageSizes: number[]
  context: SetupContext
  additionalProperties?: Record<string, PropertyValue>
  termKey?: string
  transformParams?: TransformParamsFunction
  onLoad?: OnLoadFunction<T>
  transformItems?: TransformItemsFunction
  key?: string
  query?: MaybeRef<Record<string, PropertyValue>>
  persist?: boolean
  useRouter?: boolean
}

export const useListObject = <T>(c: IUseListConfig<T>) =>
  useList<T>(
    c.id,
    c.endpoint,
    c.pageSizes,
    c.additionalProperties,
    c.termKey,
    c.transformParams,
    c.onLoad,
    c.transformItems,
    c.key,
    c.query,
    c.persist,
    c.useRouter,
  )

export type TransformParamsFunction = (p: Record<string, any>) => any
export type TransformItemsFunction<T = any, F = any> = (i: T[]) => F[]

export const areRefValuesDifferent = (valueA: MaybeRef<any>, valueB: MaybeRef<any>) =>
  JSON.stringify(toRaw(valueA)) !== JSON.stringify(toRaw(valueB))

export const useRouterPropertiesStore = (
  propertyKeys: Ref<string[]>,
  propertyTypes: Ref<Record<string, string>>,
  initialProperties?: Record<string, any>,
) => {
  const $utils = useUtils()
  const $route = useRoute()
  const properties = computed<Record<string, any>>({
    set: (properties) => $utils.routeQueryAdd(properties, undefined, true),
    get: () => ({
      ...initialProperties,
      ...extractAndSanitzeProperties(propertyKeys.value, propertyTypes.value, $route.query),
    }),
  })

  return properties
}

export const useList = <T>(
  id: string,
  endpoint: MaybeRef<string>,
  pageSizes: number[],
  additionalProperties: Record<string, PropertyValue> = {},
  termKey: string = 'q',
  transformParams?: TransformParamsFunction,
  onLoad?: OnLoadFunction<T>,
  transformItems?: TransformItemsFunction,
  key: string = 'id',
  query?: MaybeRef<Record<string, any>>,
  persist: boolean = true,
  useRouter: boolean = true,
) => {
  const items: Ref<T[]> = ref([])
  const loading: Ref<boolean> = ref(false)
  const error: Ref<boolean> = ref(false)
  const total: Ref<number> = ref(0)
  const initialProperties: Ref<Record<string, PropertyValue>> = ref({
    page: 1,
    pageSize: pageSizes[0],
    [termKey]: '',
    sort: '',
    ...additionalProperties,
  })

  const propertiesKeys = computed(() => Object.keys(initialProperties.value))
  const propertyTypes = computed<Record<string, string>>(() =>
    Object.fromEntries(
      Object.entries(initialProperties.value).map(([propertyKey, value]) => [propertyKey, getType(toRaw(value))]),
    ),
  )
  const setProperties: SetPropertiesFunction = (p: { [key: string]: any }) =>
    (properties.value = { ...properties.value, ...p })

  const properties: Ref<Record<string, PropertyValue>> = useRouter
    ? useRouterPropertiesStore(propertiesKeys, propertyTypes, initialProperties.value)
    : persist
      ? useSessionStorage(id, initialProperties.value)
      : ref({ ...initialProperties.value })

  provide('properties', properties)

  const params: ComputedRef<any> = computed(
    () => transformParams?.(properties.value) || propertiesToParams(properties.value),
  )

  provide('setProperties', setProperties)

  const setProperty: SetPropertyFunction = (propertyKey: string, value: any, replace = false) =>
    setProperties({ [propertyKey]: value }, replace)
  provide('setProperty', setProperty)
  const registeredProperties = ref<Record<string, IListPropertyInfo>>({})
  const registerProperty: RegisterPropertyFunction = (
    propertyKey: string,
    initialValue: any,
    propertyType: PropertyType = 'default',
  ) =>
    initialProperties.value[propertyKey] === undefined &&
    ((initialProperties.value[propertyKey] = initialValue),
    (registeredProperties.value[propertyKey] = {
      initialValue: initialValue,
      propertyType,
      valueType: getType(toRaw(initialValue)),
    }),
    unref(properties.value[propertyKey]) === undefined &&
      (properties.value = { ...properties.value, [propertyKey]: initialValue }))
  provide('registerProperty', registerProperty)

  const clearProperties = (keys?: string[]) =>
    setProperties(pick(initialProperties.value, keys || Object.keys(initialProperties.value)))
  const replaceItem = (item: any) =>
    (items.value = items.value.map((i: T) => (i[key] === (item as any)[key] ? item : i)))
  const addItem = (item: any) => items.value.unshift(item)
  const removeItem = (item: any) => (items.value = items.value.filter((i) => i[key] !== item[key]))
  const emit = () => {
    emitter.emit('load', {
      items: items.value,
      total: total.value,
      baseUrl: unref(endpoint),
    } as IListResponseInfo)
  }
  const load = () => {
    loading.value = true
    error.value = false
    request(unref(params), unref(query)).subscribe({
      next: (response: AxiosResponse<IPaginatedResponse<T>>) => {
        items.value = transformItems
          ? transformItems(response.data?.hits || response.data || [])
          : response.data?.hits || []
        total.value = response.data?.total || items.value.length
        onLoad?.(response.data, properties.value)
        emit()
        loading.value = false
      },
      error: () => {
        items.value = []
        total.value = 0
        error.value = true
        loading.value = false
        clearProperties()
      },
    })
  }
  const active = ref(true)
  onActivated(() => (active.value = true))
  onDeactivated(() => (active.value = false))
  watch(() => unref(endpoint), load)
  watch(() => unref(query), load)

  const request = (params: Record<string, PropertyValue>, query: Record<string, any> = {}) =>
    Axios.get(unref(endpoint) + paramsToURIComponent({ params: params, ...(query ? query : {}) }))

  const debouncedLoad = useDebounceFn(load, 20)

  // call load when properties or params change change
  onMounted(() =>
    watch(
      () => params.value,
      (p1, p2) => active.value && areObjectsDifferent(p1, p2) && debouncedLoad(),
    ),
  )
  // set page to 1 when any properties other than page or sort have changed
  const mounted = ref(true)
  const filteredParams = computed(() => omit(params.value, ['from', 'sort']))
  onMounted(
    async () => {
      await sleep(300)
      const filteredParamsWatchStopHandle = watch(
        () => filteredParams.value,
        (v, v2) => mounted.value && areObjectsDifferent(v, v2) && setProperty('page', 1, true),
      )
      // prevent from changing property on page leave
      onBeforeUnmount(filteredParamsWatchStopHandle)
    },
    // timeout to make sure that all filters have been registered
    // @TODO: make sure this is no potential cause for flakiness in e2d tests
  )

  // load once initially when
  onMounted(debouncedLoad)

  const pageSize = computed<number>({
    get: () => (properties.value.pageSize as number) || 36,
    set: (pageSize: number) => setProperties({ page: 1, pageSize }),
  })
  const page = computed<number>({
    get: () => (properties.value.page as number) || 1,
    set: (page: number) => setProperty('page', page),
  })
  return {
    items,
    params,
    replaceItem,
    addItem,
    loading,
    emit,
    error,
    active,
    request,
    load,
    total,
    properties,
    clearProperties,
    registeredProperties,
    initialProperties,
    removeItem,
    registerProperty,
    setProperty,
    setProperties,
    clearFilters: () =>
      clearProperties(
        Object.entries(registeredProperties.value)
          .filter(([, info]) => info.propertyType === 'filter')
          .map(([key]) => key),
      ),
    selectedFilters: computed(() =>
      Object.entries(registeredProperties.value).filter(
        ([propertyName, info]) =>
          info.propertyType === 'filter' &&
          properties.value[propertyName] &&
          areRefValuesDifferent(properties.value[propertyName], info.initialValue),
      ),
    ),
    page,
    pageSize,
  }
}
