<template lang="pug">
modal(size='sm' data-test='modal-brand-reporting' @close='$emit("close", false)')
  modal-header {{ $t('Adresse & Fahrtdauer auswählen') }}
    template(#below)
      div(class='mb-1 text-sm font-normal text-text-light') Lieferadresse: {{ address }}
  div(class='bleed relative overflow-auto py-2 sm:max-h-[50vh]')
    div(class='unbleed')
      overlay(:visible='loading')
      control-radio(v-if='results.length' v-model='value' :options='options' class='flex flex-col')
        template(#default='{ option }')
          div(class='leading-snug')
            div(class='font-bold') {{ option.data.name }} !{ ' ' }
            div(class='text-sm font-normal') Fahrtdauer: {{ resultsMap[option.data.id].duration.text }}, (Distanz: {{ resultsMap[option.data.id].distance.text }})
            div(class='text-sm font-normal text-text-light') {{ option.data.street }}, {{ option.data.postalCode }} {{ option.data.city }}
      notification(v-if='error' type='error') {{ $t('Fahrtdauer konnte nicht ermittelt werden.') }}
  action-bar(class='mt-2')
    btn(tertiary @click='$emit("close", false)') {{ $t('close') }}
    btn(:disabled='!value' @click='submit') Übernehmen
</template>

<script setup lang="ts">
import { useMessages } from '@/components/Message'
import { type IOption } from '@/types'
import { useScriptTag, useSessionStorage } from '@vueuse/core'
import { computed, ref } from 'vue'

export interface ILogisticAddress {
  id: string
  name: string
  street: string
  postalCode: string
  company?: string
  city: string
}
const ADDRESSES: ILogisticAddress[] = [
  {
    id: 'manfred-krebs',
    name: 'Manfred Krebs Messebau MK-Messekonzepte',
    street: 'Langstr. 20',
    postalCode: '65779',
    city: 'Kelkheim',
  },
  {
    id: 'relogg-minden',
    name: 'Relogg Minden',
    company: 'Ahnefeld Möbel-Logistik GmbH',
    street: 'Wettinerallee 90',
    postalCode: '32429',
    city: 'Minden',
  },
  {
    id: 'relogg-stuttgart-mitte',
    name: 'Relogg Stuttgart-Mitte',
    company: 'Arnholdt & Sohn GmbH',
    street: 'Mittnachtstr. 21 - 25',
    postalCode: '70191',
    city: 'Stuttgart',
  },
  {
    id: 'relogg-rheine',
    name: 'Relogg Rheine',
    company: 'August Beckmann GmbH',
    street: 'Sandkampstrasse 77',
    postalCode: '48432',
    city: 'Rheine',
  },
  {
    id: 'relogg-düsseldorf',
    name: 'Relogg Düsseldorf',
    company: 'August Bertram GmbH & Co. KG',
    street: 'Westring 3',
    postalCode: '40721',
    city: 'Hilden',
  },
  {
    id: 'relogg-leipzig',
    name: 'Relogg Leipzig',
    company: 'F. Stamm GmbH',
    street: 'Freirodaer Weg 9-11',
    postalCode: '04435',
    city: 'Schkeuditz/Leipzig',
  },
  {
    id: 'relogg-köln',
    name: 'Relogg Köln',
    company: 'Gebr. Roggendorf GmbH | EUROMOVERS',
    street: 'Mathias-Brüggen-Str. 51',
    postalCode: '50827',
    city: 'Köln',
  },
  {
    id: 'relogg-münster',
    name: 'Relogg Münster',
    company: 'Geuer International GmbH',
    street: 'Im Südfeld 14',
    postalCode: '48308',
    city: 'Senden-Bösensell',
  },
  {
    id: 'relogg-stuttgart-nord',
    name: 'Relogg Stuttgart-Nord',
    company: 'INWORK GmbH',
    street: 'Holderäckerstraße 9',
    postalCode: '70499',
    city: 'Stuttgart',
  },
  {
    id: 'relogg-hamburg',
    name: 'Relogg Hamburg',
    company: 'Krügel Umzugslogistik GmbH',
    street: 'Wöhlerstraße 31',
    postalCode: '22113',
    city: 'Hamburg',
  },
  {
    id: 'relogg-nuernberg',
    name: 'Relogg Nürnberg',
    company: 'Lauer GmbH',
    street: 'Lenzstraße 7',
    postalCode: '90408',
    city: 'Nürnberg',
  },
  {
    id: 'relogg-muenchen',
    name: 'Relogg München',
    company: 'Paulus GmbH',
    street: 'Dieselstraße 25',
    postalCode: '85748',
    city: 'Garching',
  },
  {
    id: 'relogg-berlin',
    name: 'Relogg Berlin',
    company: 'Scholz Umzüge Möbelspedition GmbH',
    street: 'Buchholzer Str. 62-65',
    postalCode: '13156',
    city: 'Berlin',
  },
  {
    id: 'relogg-regensburg',
    name: 'Relogg Regensburg',
    company: 'Zitzelsberger Int. Möbelspedition+Logistik GmbH',
    street: 'Junkerstraße 11',
    postalCode: '93055',
    city: 'Regensburg',
  },
  {
    id: 'relogg-gera',
    name: 'Relogg Gera',
    company: 'STARKE Möbeltransporte GmbH',
    street: 'Leibnizstraße 74',
    postalCode: '07548',
    city: 'Gera',
  },
  {
    id: 'relogg-frankfurt',
    name: 'Relogg Frankfurt',
    company: 'Internationale Spedition H. & C. Fermont GmbH & Co. KG',
    street: 'Niedeckerstraße 1a',
    postalCode: '65795',
    city: 'Hattersheim',
  },
]

const toHours = (value: number) => Math.round((value! / 60 / 60) * 10) / 10
defineOptions({
  inheritAttrs: false,
})

const emit = defineEmits<{ close: [value: { duration: number; address: string } | boolean] }>()
const props = defineProps({
  address: { type: String, required: true },
})
const loading = ref(false)
const error = ref(false)
const results = useSessionStorage<google.maps.DistanceMatrixResponseElement[]>(
  `ModalLogisticCalculateDistance ${props.address}`,
  [],
)
const { $msg } = useMessages()

const addressToString = (a: ILogisticAddress) => `${a.street}, ${a.postalCode} ${a.city}, Germany`

if (results.value.length === 0) {
  loading.value = true
  useScriptTag('https://maps.googleapis.com/maps/api/js?key=AIzaSyD9Oa4fC6DR8eyL_82WbqDGqj59GPIZyuE', () => {
    const service = new (window as any).google.maps.DistanceMatrixService() as google.maps.DistanceMatrixService
    try {
      service.getDistanceMatrix(
        {
          origins: [props.address as string],
          destinations: ADDRESSES.map(addressToString),
          travelMode: 'DRIVING' as google.maps.TravelMode,
          // drivingOptions: {trafficModel: 'PESSIMISTIC' as google.maps.TrafficModel}
        },
        (result) => {
          if (result?.rows?.[0].elements.some((element) => element.status !== 'OK')) {
            $msg.error('Addresse nicht gefunden!')
            emit('close', false)
            error.value = true
          } else {
            results.value = result?.rows?.[0].elements
          }
        },
      )
    } catch (e) {
      console.log('error', e)
    } finally {
      loading.value = false
    }
  })
}

const value = ref('')
const addressIndexMap = Object.fromEntries(ADDRESSES.map((a, index) => [index, a.id]))
const addressMap = Object.fromEntries(ADDRESSES.map((a) => [a.id, a]))
const resultsMap = computed(() =>
  Object.fromEntries(results.value.map((result, index) => [addressIndexMap[index], result])),
)

const submit = () =>
  emit('close', {
    address: addressToString(addressMap[value.value]),
    duration: toHours(resultsMap.value[value.value].duration.value),
  })
const options = computed<IOption<ILogisticAddress>[]>(() =>
  ADDRESSES.map((a) => ({ label: a.name, data: a, value: a.id })).sort(
    (a, b) => resultsMap.value[a.value].duration.value - resultsMap.value[b.value].duration.value,
  ),
)
</script>
