<template lang="pug">
modal-container(:group='true')
  component(:is='modal.key' v-for="modal in modals" :key='modal.key' v-bind='modal.props' :data-test='"modal-" + modal.key' @close='(value: any) => close(modal.key, value)')
</template>

<script lang="ts">
import { useModals } from '@/plugins/Modals'
import { isString } from 'radash'
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'

const ModalProvider = defineComponent({
  setup() {
    const $route = useRoute()
    const $modals = useModals()
    return {
      close: (key: string, value?: any) => $modals.close(key, value),
      modals: computed(() =>
        $route.query
          ? Object.keys($route.query)
              .filter((key: string) => key.startsWith('modal-') || key.startsWith('form-'))
              .map(
                (key: string) =>
                  ({
                    key,
                    props: isString($route.query[key]) ? { id: $route.query[key] || '' } : $route.query[key] || {},
                  }) as { key: string; props: { id: string } },
              )
          : [],
      ),
    }
  },
})
export default ModalProvider
</script>
