import { openIntercomChat } from '@/utilities/ThirdParty'
import { type Directive, type DirectiveBinding } from 'vue'

const ContactLink: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding): void {
    el.setAttribute('href', `mailto:business@nuucon.com?subject${encodeURIComponent(binding.value.message)}`)
    el.addEventListener('click', (e: MouseEvent) => {
      if ((window as any).Intercom) {
        e.preventDefault()
        openIntercomChat(binding.value.message)
      }
    })
  },
}

export default ContactLink
