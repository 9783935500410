<template lang="pug">
div(class='mb-6 mt-3')
  toolbar(:wrap='false' :title='title')
    slot
    template(#after)
      slot(name='actions')
    template(#afterSticky)
      btn(v-if='showCloseButton' plain icon='x' :data-tooltip='$t("close")' data-target='form-header-close' class='!-mr-3' @click='close')
  slot(name='below')
</template>

<script lang="ts">
import Toolbar from '@/components/Toolbar.vue'
import { defineComponent, type PropType, inject } from 'vue'

export interface IFormActionItem {
  name: string
  icon?: string
  label: string
}

const FormHeader = defineComponent({
  components: { Toolbar },
  props: {
    title: String,
    /**
     * An array of form action items that will be displayed in a menu dropdown
     */
    actions: Array as PropType<IFormActionItem[]>,
    /**
     * If true, a close button will be displayed next to the title
     */
    showCloseButton: { type: Boolean, default: true },
  },
  setup() {
    return { close: inject<() => void>('closeModal') }
  },
})
export default FormHeader
</script>
