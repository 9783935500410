<template lang="pug">
div
  notification-list(:items='notifications' :showIcons='false')
  slot(:config='config')
</template>

<script lang="ts">
import { useFormItem } from './composables'
import { defineComponent, toRef } from 'vue'

const FormGroup = defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const name = toRef(props, 'name')
    const { config } = useFormItem(name)
    return {
      config,
      notifications: config?.value?.notifications || [],
    }
  },
})

export default FormGroup
</script>
