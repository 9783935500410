<template lang="pug">
div(class='flex max-w-full overflow-auto whitespace-nowrap')
  div(class='flex items-center')
    slot
  div(v-if='$slots.suffix')
    slot(name='suffix')
</template>

<script setup lang="ts">
import { provide } from 'vue'

const props = defineProps<{ highlightCurrent?: boolean }>()
provide('highlightCurrent', props.highlightCurrent)
</script>
