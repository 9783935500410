<template lang="pug">
div(
  class='aspect_square inline-flex items-center justify-center [&_>img]:h-full [&_>img]:w-full [&_>img]:object-contain [&_>img]:mix-blend-multiply'
  :class='{ "p-2 rounded bg-grey-75": grey }'
  :style='`width: var(--project-product-image-size, ${size || 60}px)`'
)
  img(v-if='url && !imageError' v-image-lazy='lazy' :data-src='url' :data-src-zoom='url' :data-cloudinary='!url.includes("http") && cloudinaryPreset' @error='imageError = true')
  img(v-else src='/images/nuucon-image-placeholder.svg')
</template>

<script setup lang="ts">
import { ref } from 'vue'

defineProps({
  url: { type: String, required: true },
  cloudinaryPreset: { type: String, default: 't_xs' },
  grey: Boolean,
  size: [Number, String],
  lazy: { type: Boolean, default: true },
})

const imageError = ref(false)
</script>
