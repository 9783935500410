import type { IAttachment, IProjectData, ITagList, ProjectSettingsRaw } from './types'
import { thorFormPost, thorFormPostRequest } from '@/composables/'
import { type IInquiry } from '@/pages/inquiries/types'
import { type IPaginatedResponse, type IPconDTO } from '@/types'
import { paramsToURIComponent } from '@/utilities'
import Axios from 'axios'
import axios from 'axios-observable'
import { map } from 'rxjs'

export type ProjectOrInquiryData = { project?: IProjectData; inquiry?: IInquiry }

export default {
  loadProjects: () =>
    axios
      .get<IPaginatedResponse<IProjectData>>(
        `/ProjectSearch` +
          paramsToURIComponent({
            params: { q: '', size: 100, sort: 'created.at_desc' },
          }),
      ) // @TODO: add proper pagination
      .pipe(map((r) => r.data.hits)),
  loadProject: (projectId: string) =>
    axios.get<IProjectData>(`/_/Projects/${projectId}/Details`).pipe(map((r) => r.data)),
  loadInquiry: (inquiryId: string) => axios.get<IInquiry>(`/_/Inquiry/${inquiryId}/Show`).pipe(map((r) => r.data)),
  deleteProject: (projectId: string) => thorFormPost<IProjectData>(`/_/Projects/${projectId}/Delete`, {}),
  restoreProject: (projectId: string) => thorFormPost<IProjectData>(`/_/Projects/${projectId}/Restore`, {}),
  deleteInquiry: (inquiryId: string) => thorFormPost<IInquiry>(`/_/Inquiry/${inquiryId}/Delete`, {}),
  requestInquiry: (inquiryId: string) => thorFormPost<IInquiry>(`/_/Inquiry/${inquiryId}/Request`, {}),
  revokeInquiry: (inquiryId: string) => thorFormPost<IInquiry>(`/_/Inquiry/${inquiryId}/Revoke`, {}),
  declineInquiry: (inquiryId: string, declineReason: string) =>
    thorFormPost<IInquiry>(`/_/DeclineOfferForm/SendDecline`, { Id: inquiryId, DeclineReason: declineReason }),
  requestChangesInquiry: (inquiryId: string, declineReason?: string) =>
    thorFormPost<IInquiry>(`/_/RequestChangesForm/SendChangeRequest`, {
      Id: inquiryId,
      DeclineReason: declineReason || '',
    }),
  acceptInquiry: (inquiryId: string) => thorFormPost<IInquiry>(`/_/Inquiry/${inquiryId}/AcceptOffer`, {}),
  copyInquiry: (inquiryId: string) => thorFormPost<IInquiry>(`/_/Inquiry/${inquiryId}/CopyInquiry`, {}),
  restoreInquiry: (inquiryId: string) => thorFormPost<IInquiry>(`/_/Inquiry/${inquiryId}/Restore`, {}),
  createInquiry: (projectId: string, selectedProductIds: string[]) =>
    thorFormPost<IInquiry>('/_/Inquiry/CreateInquiry/Create', {
      Items: selectedProductIds.join(','),
      Project: projectId,
    }),
  updateSettings: (projectId: string, settingKey: string, settingValue: string) =>
    thorFormPostRequest<ProjectSettingsRaw>('/_/ProjectSettingsForm/Update', {
      SelectedProject: projectId,
      SettingKey: settingKey,
      SettingValue: settingValue,
    }),
  addAlternatives: (projectId: string, alternativeIds: string[], parentProductId: string) =>
    thorFormPost<IProjectData>(`/_/Projects/${projectId}/AddAlternative`, {
      AddAlternativeIds: alternativeIds,
      ParentProductId: parentProductId,
    }),
  removeAlternative: (projectId: string, alternativeId: string, parentProductId: string) =>
    thorFormPost<IProjectData>(`/_/Projects/${projectId}/RemoveAlternative`, {
      AlternativeId: alternativeId,
      ParentProductId: parentProductId,
    }),
  setAlternativeAsFavorite: (projectId: string, alternativeId: string, parentProductId: string) =>
    thorFormPost<IProjectData>(`/_/Projects/${projectId}/AlternativeToFavorite`, {
      AlternativeIds: alternativeId,
      ParentProductId: parentProductId,
    }),
  moveAlternativeToToplevel: (projectId: string, alternativeId: string, parentProductId: string, index: number) =>
    thorFormPost<IProjectData>(`/_/Projects/${projectId}/ProjectItemByAlternative`, {
      AlternativeId: alternativeId,
      ParentProductId: parentProductId,
      Index: index,
    }),
  copyExternalProduct: (projectId: string, productId: string, parentProductId?: string) =>
    thorFormPost<IProjectData>(`/_/Projects/${projectId}/CopyExternalProduct`, {
      SelectedProduct: productId,
      MaybeParentProductId: parentProductId,
    }),
  updateProductOrder: (projectId: string, productItems: string[]) =>
    thorFormPost<IProjectData>(`/_/Projects/${projectId}/ProductOrder/UpdateProductOrder`, {
      ProductItems: productItems.join(','),
    }),
  updateAlternativeProductOrder: (
    projectId: string,
    alternativeId: string,
    parentProductId: string,
    index: number,
    targetParentId?: string,
  ) =>
    thorFormPost<IProjectData>(`/_/Projects/${projectId}/UpdateAlternativeProductOrder`, {
      AlternativeId: alternativeId,
      ParentProductId: parentProductId,
      TargetId: targetParentId || parentProductId,
      Index: index,
    }),
  removeProducts: (projectId: string, productItems: string[] | string) =>
    // @TODO: check response
    thorFormPost<IProjectData>(`/_/Projects/${projectId}/UpdateItem/RemoveProduct`, {
      SelectedProduct: productItems,
    }),
  removeMember: (projectId: string, userId: string) =>
    thorFormPost<IProjectData>(`/_/Projects/${projectId}/ProjectMembers/${userId}/DeleteMember`, {}),
  removeAttachment: (projectId: string, assetKey: string) =>
    thorFormPost<IAttachment[]>(`/_/Projects/${projectId}/DeleteAttachment`, { AssetKey: assetKey }),
  updateTagOrder: (projectId: string, tags: string[]) =>
    thorFormPost<ITagList>(`/_/Projects/${projectId}/UpdateTagOrder`, { Tags: tags }),
  updateProductQuantity: (projectId: string, productId: string, quantity: number) =>
    thorFormPost<IProjectData>(`/_/Projects/${projectId}/UpdateQuantity`, {
      SelectedProduct: productId,
      Product: productId,
      Quantity: quantity,
    }),
  removeTags: (projectId: string, tags: string[]) =>
    thorFormPost<IProjectData>(`/_/Projects/${projectId}/ProjectTags/DeleteForm/DeleteTags`, { Tags: tags }),
  addProducts: (
    projectId: string,
    sourceProjectId: string,
    productIds: string[],
    tag: string,
    index = 0,
    parentProductId?: string,
  ) =>
    thorFormPost<IProjectData>('/_/AddProductToProject/AddProductsToProject', {
      SelectedProducts: productIds,
      SelectedProject: projectId,
      SourceProjectId: sourceProjectId,
      ParentProductId: parentProductId,
      SelectedTag: [tag],
      Index: index,
    }),
  addComment: (projectId: string, productId: string, comment: string, maybeParentProductId: string) =>
    thorFormPost<IProjectData>(
      `/_/Projects/${projectId}/AddNoteForm/AddForm`,
      {
        SelectedProduct: productId,
        MaybeParentProductId: maybeParentProductId,
        Comment: comment,
      },
      'addComment',
    ),

  addProductsToInquiry: (
    projectId: string,
    inquiryId: string,
    sourceProjectId: string = '',
    productIds: string[],
    index = 0,
  ) =>
    thorFormPost<{ inquiry: IInquiry; project: IProjectData }>('/_/AddProductToProject/AddProductsToInquiry', {
      SelectedProducts: productIds,
      SelectedProject: projectId,
      SelectedInquiry: inquiryId,
      SourceProjectId: sourceProjectId,
      Index: index,
    }),

  addPConProducts: (projectId: string, products: IPconDTO[], inquiryId?: string) =>
    Axios.post<{ inquiry?: IInquiry; project: IProjectData }>(
      '/_/AddPConFromOBX',
      { projectId, inquiryId, products },
      { headers: { 'Content-Type': 'application/json' } },
    ),

  addOrEditPconProduct: (
    projectId: string,
    baseProductId: string = '',
    inquiryId: string = '',
    productId: string = '*',
    tag: string = '',
    name: string,
    data: IPconDTO,
    parentProductId?: string,
  ) =>
    thorFormPost<ProjectOrInquiryData>(
      `/_/AddOrEditPconProduct/${productId}/Save?projectId=${projectId}${
        productId === '*' ? '&baseProductId=' + baseProductId : ''
      }${inquiryId ? '&inquiryId=' + inquiryId : ''}${parentProductId ? '&parentProductId=' + parentProductId : ''}`,
      {
        Name: name,
        ImageUrl: data.image || 'https://app.nuucon.com/images/placeholder-image.png',
        Description: data.description,
        PriceNet: data.priceNet,
        PurchasingPriceNet: data.priceNet,
        ManufacturerId: data.pConInformation.manufacturerId,
        Tag: tag,
        PconBrandName: data.pConBrandName,
        ObxUrl: data.obxUrl,
      },
    ),
}
