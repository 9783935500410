<template lang="pug">
div(class='mb-3 mt-3')
  div(class='flex')
    div(class='flex-1' :class='{ flex: compact }') 
      slot(name='icon')
        icon(v-if='icon' :name='icon' class='mr-3 mt-0.5 text-2xl' :class='{ "mb-1 text-3xl": !compact }')
      h4
        slot {{ title }}
    div
      div(class='-mt-2 flex items-center gap-2')
        slot(name='actions')
        btn(plain icon='x' :data-tooltip='$t("close")' data-test='btn-modal-close' data-target='form-header-close' class='!-mr-3' @click='close')
  slot(name='below')
</template>

<script setup lang="ts">
import Btn from './Btn.vue'
import Icon from './Icon.vue'
import { inject } from 'vue'

defineProps({
  title: String,
  icon: String,
  compact: Boolean,
})
const emit = defineEmits(['close'])
const closeModal = inject<() => void>('closeModal')
const close = () => {
  closeModal?.()
  emit('close')
}
</script>
