<template lang="pug">
modal(size='2xl' mode='drawer' data-test='modal-project-pdf-preview' @close='$emit("close")')
  modal-header(v-if='project')
    div(class='flex items-center gap-2')
      span(class='') {{ $t('PDF Preview') }}: !{ ' ' }
      span(class='mr-2 font-light') {{ project.title || project.id }}
      filter-group(:showClearButton='!!currentTags.length || !!currentBrands.length' @clear='clearFilters')
        dropdown-filter(v-if='currentTags.length' :label='$t("Tags")' :value='currentTags.length')
          control-select-list-multi(v-model='currentTags' :options='allTags')
        dropdown-filter(v-if='allBrands.length > 0' class='!mx-2' :label='$t("Brands")' :value='currentBrands.length')
          control-select-list-multi(v-model='currentBrands' :options='allBrands')

    template(#actions)
      btn(icon='download' secondary='' @click='download(project.title || project.id)') {{ $t('download') }}
      btn(icon='printer' secondary='' @click='print') {{ $t('print') }}
      dropdown(:ignoreContentClick='true')
        template(#default='{ toggle, visible }')
          badge(bordered :visible='hideFieldsNum > 0' :count='hideFieldsNum' :offset='[-3, -3]' secondary)
            btn(:active='visible' icon='sliders' secondary iconOnly @click='toggle')

        template(#content)
          dropdown-group(:title='$t("Show or hide fields")')
            dropdown-item(v-for='(_, key) in hideFields' :key='key' class='!p-0')
              control-switch(v-model='hideFields[key]' size='tiny' class='w-full !p-3')
                span {{ $t(key) }}
  div(class='bleed relative flex-1')
    overlay(:visible='loading')
    iframe(ref='iframe' data-test='iframe-project-pdf-preview' class='h-full w-full flex-1')
</template>

<script setup lang="ts">
import { type IProjectPriceCalculation } from '@/pages/projects/components/ProjectProductFooter.vue'
import { useProjectsStore } from '@/pages/projects/store'
import { type IOption } from '@/types'
import { stringArrayToBooleanMap } from '@/utilities'
import { usePdfGenerator } from '@/utilities/Pdf'
import { createProjectPdfDefinition } from '@/utilities/PdfProject'
import { useSessionStorage, watchDebounced } from '@vueuse/core'
import axios from 'axios'
import { intersects, unique } from 'radash'
import { computed, ref } from 'vue'
import { onMounted } from 'vue'
import { type Ref } from 'vue'

const DEFAULT_PROJECT_PDF_SETTINGS = [
  'alternatives',
  'totalRrpNet',
  'quantity',
  'comment',
  'tags',
  'retailPriceNetString',
  'retailPriceTotalNetString',
  'projectTitle',
]

const props = defineProps<{ projectId: string; tag?: string; productIds?: string[] }>()

const iframe = ref<HTMLIFrameElement | null>(null)
const { init, download, print } = usePdfGenerator(iframe as Ref<HTMLIFrameElement>)
const loading = ref(true)

const store = useProjectsStore()
const project = computed(() => store.project(props.projectId))
const currentBrands = ref<string[]>([])
const propFilteredItems = computed(() =>
  props.productIds
    ? project.value.items.filter((item) => props.productIds?.includes(item.productId))
    : project.value.items,
)
const uniqueBrands = computed(() =>
  project.value ? unique(propFilteredItems.value.map((product) => product.brand.trim().toLowerCase())) : [],
)
const allBrands = computed(() =>
  uniqueBrands.value.length > 1 ? uniqueBrands.value.map((brand) => ({ value: brand, label: brand })) : [],
)
const currentTags = ref<string[]>(props.tag ? [props.tag as string] : [])
const allTags = computed(() =>
  project.value.tagList?.tags.length > 0
    ? project.value.tagList.tags.map((tag) => ({ value: tag.name, label: tag.name }) as IOption)
    : [],
)

const tagFilteredItems = computed(() =>
  currentTags.value.length
    ? propFilteredItems.value.filter((item) =>
        intersects(
          currentTags.value,
          item.tags.map((tag) => tag.name),
        ),
      )
    : propFilteredItems.value,
)
const filteredItems = computed(() =>
  currentBrands.value.length > 0
    ? tagFilteredItems.value.filter((item) => currentBrands.value.includes(item.brand.trim().toLowerCase()))
    : tagFilteredItems.value,
)

const getPricingData = async () => {
  const result = await axios.post<IProjectPriceCalculation>(`/_/Projects/${props.projectId}/CalculateItems`, {
    ids: filteredItems.value.map((item) => item.productId),
  })
  return result.data
}

const hideFields = useSessionStorage<Record<string, boolean>>(
  'pdf-project-settings',
  stringArrayToBooleanMap(DEFAULT_PROJECT_PDF_SETTINGS, true),
)

const hideFieldsMapped = computed(() =>
  Object.entries(hideFields.value)
    .filter(([, value]) => !value)
    .map(([key]) => key),
)
const hideFieldsNum = computed(() => Object.values(hideFields.value).filter((v) => !v).length)

const update = async () => {
  loading.value = true
  const pricingData = await getPricingData()
  const definitions = await createProjectPdfDefinition(
    project.value.title,
    currentTags.value.length ? currentTags.value.join(', ') : '',
    filteredItems.value,
    pricingData,
    hideFieldsMapped.value,
  )
  init(definitions)
  loading.value = false
}
onMounted(async () => {
  await store.loadProject(props.projectId)
  update()
  watchDebounced(() => [project.value, currentTags.value, currentBrands.value, hideFields.value], update, {
    debounce: 100,
    deep: true,
  })
})

const clearFilters = () => {
  currentTags.value = []
  currentBrands.value = []
}
</script>
