<template lang="pug">
modal(size='xl' @close='$emit("close")')
  modal-header(icon='calculator')
    span Lieferkosten Kalkulation: !{ ' ' }
    span(class='ml-1 font-thin') {{ inquiryId }}
    template(#actions)
      notification(type='warning' class='mr-2') <b>Hinweis:</b> Feature noch in Testphase.
    template(#below)
      div(class='flex flex-wrap [&>*:not(:last-child):after]:mx-1 [&>*:not(:last-child):after]:content-["·"]')
        div(class='inline-flex flex-wrap items-center gap-1 text-sm text-text-light')
          span {{ $t('deliverySetupType') }}:
          span(v-if='deliverySetupType') {{ $t(deliverySetupType) }}
          span(v-else) {{ $t('noch nicht ausgewählt') }}
        div(v-if='serviceLevel' class='text-sm text-text-light')
          span {{ $t('Servicelevel') }}: !{ ' ' }
          span {{ $t(serviceLevel) }}
        div(class='text-sm text-text-light')
          span {{ $t('Produkte') }}: !{ ' ' }
          span {{ productsQuantity }}
        div(class='text-sm text-text-light')
          span {{ $t('totalRrpNet') }}: !{ ' ' }
          span {{ formatPrice(vars.totalRrpNet) }}
  form(v-if='inquiry' class='flex-1 gap-8 sm:flex' novalidate @submit.stop.prevent='calculate()')
    div(class='flex flex-1 flex-col text-base leading-snug text-text antialiased')
      tabs(v-model='tabsState' class='[&_.control-input]:w-[110px] [&_td:first-child]:w-full [&_td:first-child]:pr-2 [&_td:not(:first-child)]:align-top [&_td]:py-[2px]')
        tab-pane#a(:title='$t("Projektdaten")')
          table(class='w-full [&_table]:mt-0' style='--tr-bg: transparent')
            tr
              td
                div Anzahl Zulieferpartner
                notification(v-if='vars.supplierQuantity !== parseFloat(supplierQuantity)' type='warning' size='small') {{ $t('Anzahl Zulieferpartner abweichend.') }}
              td
                control-input(v-model.number='vars.supplierQuantity' size='small' type='number' :clearable='false')
              td
                btn(icon='info' class='ml-1' faded small :data-tooltip='suppliers.map((s, i) => `${i + 1}. ${s}`).join("<br>")')
            tr
              td
                div
                  span Fahrtdauer (eine Richtung in Stunden)
                div(class='mb-2 mt-0 text-sm text-text-light')
                  div(v-if='storageAddress') Lageradresse: {{ storageAddress }}
                  div(v-if='customAddress || deliveryAddress') Lieferadresse: {{ customAddress || deliveryAddress }}
                  notification(v-else size='small') {{ $t('Keine Lieferadresse ausgewählt') }}
              td
                control-input(v-model.number='vars.trip_duration' size='small' type='number' :clearable='false')
              td
                btn(plain small icon='calculator' class='ml-1' data-tooltip='Fahrtdauer berechnen' @click='calculateTripDuration()') 
            tr
              td Summe Volumen (in m<sup>3</sup>)
              td
                control-input(v-model.number='vars.productsVolume' size='small' type='number' :clearable='false')
              td
                btn(v-if='inquiry' plain small icon='calculator' class='ml-1' data-tooltip='Volumen berechnen' @click='calculateVolume()') 
            tr
              td Dauer Zwischenlagerung in Wochen
              td
                control-input(v-model.number='vars.interim_delivery_weeks' size='small' type='number' :clearable='false')
            tr
              td(:class='{ "line-through text-text-light": !serviceLevel }') Anzahl Montage-Personen
              td
                control-input(v-model.number='vars.construction_personel_quantity' size='small' :disabled='!serviceLevel' type='number' :clearable='false')
              td
                btn(v-if='!serviceLevel' icon='alert-triangle' faded small :data-tooltip='`Wird bei Standardlieferung nicht berücksichtigt`')
            tr
              td(:class='{ "line-through text-text-light": !serviceLevel }') Montageaufwände in Stunden (pro Person)
              td
                control-input(v-model.number='vars.construction_time_hours_per_person' size='small' :disabled='!serviceLevel' type='number' :clearable='false')
              td
                btn(v-if='!serviceLevel' icon='alert-triangle' faded small :data-tooltip='`Wird bei Standardlieferung nicht berücksichtigt`')
            tr
              td Entladen- & Vertragen-Aufwände in Stunden (pro Team)
              td
                control-input(v-model.number='vars.unloading_and_contracting_time_hours' size='small' type='number' :clearable='false')
            tr
              td Anzahl Logistik Teams
                div(class='text-sm text-text-light') (2 Mann Team + LKW bis 7,49T)
              td
                control-input(v-model.number='vars.logistics_personal_quantity' size='small' type='number' :clearable='false')
            tr
              td Anzahl Ausliefertage vor Ort (Nuucon Projektleitung)
              td
                control-input(v-model.number='vars.delivery_time_days' size='small' type='number' :clearable='false')
            tr
              td HVZ-Pauschale
              td
                control-input-money(v-model.number='vars.hvz_flat_price' size='small' type='number' :clearable='false')
            tr
              td Sonstige Posten
              td
                control-input-money(v-model.number='vars.other' size='small' type='number' :clearable='false')
        tab-pane#b(:title='$t("Fixkosten")')
          table
            tr
              td Zwischenliefer/Lagerkosten (m<sup>3</sup> / Woche)
              td
                control-input-money(v-model.number='vars.interim_delivery_costs_per_week_price' size='small' type='number' :clearable='false' step='0.1')
            tr
              td Stundenlohn Logistiker (2 Personen Team)
              td
                control-input-money(v-model.number='vars.logistics_hourly_rate_price' size='small' type='number' :clearable='false' step='5')
            tr
              td Stundenlohn Montage-Person (Relogger Medium)
              td
                control-input-money(v-model.number='vars.construction_hourly_rate_price' size='small' type='number' :clearable='false' step='0.1')
              td
                btn(v-if='!serviceLevel' icon='alert-triangle' faded small :data-tooltip='`Wird bei Standardlieferung nicht berücksichtigt`')
            tr
              td Projektmanagement vor Ort (Tagessatz)
              td
                control-input-money(v-model.number='vars.project_management_daily_rate_price' size='small' type='number' :clearable='false' step='0.1')
            tr
              td Entsorgungskostenkosten (pro m<sup>3</sup>)
              td
                control-input-money(v-model.number='vars.disposal_cost' size='small' type='number' :clearable='false')
            tr
              td Übernahmelagerpauschale (pro Lieferant)
              td
                control-input-money(v-model.number='vars.storage_price' size='small' type='number' :clearable='false' step='0.1')
        tab-pane#c(:title='$t("Variablen")')
          table
            tr
              td Montage Aufwands Multiplikator
                div(class='mb-3 mt-1 !text-sm text-text-light') Multiplikator zur Berechung des "Montage"-Postens (Produktanzahl * <b>Multiplikator</b> * Montage-Stundenlohn)
              td
                control-input(v-model.number='vars.assembly_multiplier' size='small' type='number' :clearable='false' step='0.1')
            tr
              td
                div Kommission Kubikmeter/Stunden Faktor
                div(class='mb-3 mt-1 !text-sm text-text-light') Faktor zur Berechung des "Kommissionierung und Verladung"-Postens (Produktvolumen * <b>Faktor</b> * Montage-Stundenlohn)
              td
                control-input(v-model.number='vars.comission_volume_per_hour_factor' size='small' type='number' :clearable='false' step='0.1')
            tr
              td Fahrtdauer-LKW-Multiplikator
                |
                div(class='mb-3 mt-1 !text-sm text-text-light') Faktor zur Berechung des "An- / Abfahrt"-Postens (Fahrtdauer × <b>Faktor</b> × (Logistik-Team-Stundenlohn × Anzahl Logistik-Teams))
              td
                control-input(v-model.number='vars.delivery_time_multiplier' size='small' type='number' :clearable='false' step='0.1')
            tr
              td
                div(class='font-normal') Verpackungsmaterial-Faktor (in %)
                div(class='mb-3 mt-1 !text-sm text-text-light') Faktor zur Berechnung der Kosten für die Entsorgung des Verpackungsmaterial (Produktanzahl × <b>Faktor</b> × Entsorgungskostenkosten pro m3)
              td
                control-input(v-model.number='vars.packaging_proportion_factor_percent' size='small' type='number' :clearable='false' step='0.1')
            tr
              td(colspan='2')

      btn(type='submit' class='!hidden')
    div(class='sm:w-[50%]') 
      div(class='-mr-4 mb-2 rounded-lg bg-grey-50 p-6 pr-4 text-text') 
        table(class='w-full text-base leading-snug [&_table]:mt-0 [&_td:first-child]:pr-2 [&_td]:py-[4px]' style='--tr-bg: transparent')
          tr(v-for='{ id, label, description, disabled } in CALCULATION_ITEMS' :key='id')
            td
              span(class='mr-2' :class='{ "line-through text-text-light": disabled }') {{ label }}
              btn(v-if='description' icon='help-circle' faded tiny :data-tooltip='description')
            td(class='text-right font-bold' :class='{ "text-text-light": disabled }') {{ formatPrice(calculation[id]) }}
            td
              btn(faded tiny icon='custom-edit' class='ml-3' data-tooltip='überschreiben' @click='override(id, label)') 
          tr
            td(colspan='2' class='h-5')
              hr(class='my-2')
          tr
            td(class='font-normal') Summe Lieferkosten
            td(class='text-right font-bold') {{ formatPrice(sum) }}
            td
          tr
            td
              span(class='font-bold') Inkl. Pauschalaufschlag !{ ' ' }
              span(class='font-normal') ({{ vars.surcharge_margin_percent }}%)
            td(class='text-right !text-lg font-bold') {{ formatPrice(plus_margin) }}
            td
              btn(icon='custom-edit' tiny faded class='ml-3' @click='editSurchargeMarginPercent') 
          tr(class='text-text-light')
            td
              | Verhältnis zu Gesamt netto UVP ({{ formatPrice(vars.totalRrpNet) }})
            td(class='whitespace-nowrap text-right')
              span {{ round(total_rrp_ratio) }}%
          tr(class='text-text-light')
            td Verhältnis zu Lieferkostensummen-Differenz ({{ vars.control_margin_percent }}%)
            td(class='text-right')
              span(v-if='delivery_cost_difference_ratio > 0' class='text-red-600') + {{ round(delivery_cost_difference_ratio) }}%
              span(v-else class='text-green-600') {{ round(delivery_cost_difference_ratio) }}%
            td
              btn(icon='custom-edit' tiny faded class='ml-3' @click='editControlMarginPercent') 

  action-bar
    template(#left)
      btn(:disabled='!hasChanges' secondary icon='x' @click='reset()') Zurücksetzen
    btn(link-dark data-test-btn='form-footer-cancel' data-test='btn-form-footer-cancel' @click='$emit("close")') {{ $t('Cancel') }}
    btn(@click='submit') Übernehmen
</template>

<script setup lang="ts">
import { useInquiriesStore } from '@/pages/inquiries/store'
import { useConfirm } from '@/plugins/Confirm'
import { useModals } from '@/plugins/Modals'
import { shallowEqual } from '@/utilities'
import { formatPrice } from '@/utilities'
import { toReactive, useSessionStorage } from '@vueuse/core'
import { unique } from 'radash'
import { computed, ref, reactive, watch, onMounted } from 'vue'

type LogisticCalculationVariables =
  | 'supplierQuantity'
  | 'trip_duration'
  | 'productsVolume'
  | 'productsQuantity'
  | 'totalRrpNet'
  | 'interim_delivery_weeks'
  // | 'delivery_multiplier'
  | 'assembly_multiplier'
  | 'comission_volume_per_hour_factor'
  | 'delivery_time_multiplier'
  | 'packaging_proportion_factor_percent'
  | 'interim_delivery_costs_per_week_price'
  | 'storage_price'
  | 'logistics_personal_quantity'
  | 'surcharge_margin_percent'
  | 'logistics_hourly_rate_price'
  | 'construction_time_hours_per_person'
  | 'unloading_and_contracting_time_hours'
  | 'construction_personel_quantity'
  | 'construction_hourly_rate_price'
  | 'hvz_flat_price'
  | 'control_margin_percent'
  | 'disposal_cost'
  | 'project_management_daily_rate_price'
  | 'other'
  | 'delivery_time_days'
type LogisticCalculationItem =
  | 'project_management'
  | 'takeover_fee'
  | 'storage_rent_price_per_m3'
  | 'picking_and_loading'
  | 'arrival_and_departure'
  | 'unloading_and_contracting'
  | 'assembly'
  | 'other'
  | 'special_expense_hvz'
  | 'packaging_disposal'

export interface ILogisticCalculationItem {
  id: LogisticCalculationItem
  label: string
  description?: string
  disabled?: boolean
}
const props = defineProps({
  totalRrpNet: { type: String, required: true },
  inquiryId: { type: String },
  productsVolume: { type: String, required: true },
  addressString: { type: String, required: true },
  supplierQuantity: { type: String, required: true },
  productsQuantity: { type: String, required: true },
  deliveryAddress: { type: String },
  serviceLevel: { type: String },
  deliverySetupType: { type: String },
})

const defaultVars: Record<LogisticCalculationVariables, number> = {
  supplierQuantity: parseFloat(props.supplierQuantity),
  trip_duration: 3,
  productsVolume: 0,
  productsQuantity: parseFloat(props.productsQuantity),
  totalRrpNet: parseFloat(props.totalRrpNet),
  interim_delivery_weeks: 2,
  // delivery_multiplier: 0.25,
  assembly_multiplier: 0.25,
  comission_volume_per_hour_factor: 0.2,
  delivery_time_multiplier: 1.8,
  packaging_proportion_factor_percent: 1.5,
  interim_delivery_costs_per_week_price: 3.75,
  storage_price: 25,
  logistics_personal_quantity: 1,
  surcharge_margin_percent: 20,
  logistics_hourly_rate_price: 108,
  unloading_and_contracting_time_hours: 0,
  construction_time_hours_per_person: 0,
  construction_personel_quantity: 1,
  construction_hourly_rate_price: 45,
  hvz_flat_price: 130,
  control_margin_percent: 6,
  disposal_cost: 10,
  project_management_daily_rate_price: 650,
  other: 0,
  delivery_time_days: 0,
}

const varsStored = useSessionStorage(`logistic_calc_vars_${props.inquiryId}`, { ...defaultVars })

const vars = toReactive(varsStored)

const CALCULATION_ITEMS = computed<ILogisticCalculationItem[]>(() => [
  {
    id: 'takeover_fee',
    label: 'Übernahmelagerpauschale',
    description:
      'Warenannahme und Vollständigkeitskontrolle bei der Anlieferung der Waren. Pauschale pro (Hersteller-) Anlieferung',
  },
  {
    id: 'storage_rent_price_per_m3',
    label: ' Lagermiete pro qbm pro Monat',
    description: 'Wie groß ist das zu lagernde Volumen pro qbm? Wie lange lagern die Waren voraus. bis Auslieferung?',
  },
  {
    id: 'picking_and_loading',
    label: 'Kommissionierung und Verladung',
    description:
      'Auslieferung, Palettieren und Einstretchen der einzelnen Komponenten auf Europaletten. (Summe Produktvolumen * Kommissionierungs-Volumen-Stunden-Faktor * Stundenlohn Montage-Person',
  },
  {
    id: 'arrival_and_departure',
    label: 'An- / Abfahrt',
    description: 'Anzahl Monteure pro Stunde inkl. Kosten LKW',
  },
  {
    id: 'unloading_and_contracting',
    label: 'Entladen & Vertragen',
    description: 'Auspacken, Platzierung und Montage der einzelnen Komponenten Anzahl Monteure pro Std.',
  },
  {
    id: 'assembly',
    label: 'Montage',
    description: 'Auspacken, Platzierung und Montage der einzelnen Komponenten Anzahl Monteure pro Std.',
    disabled: props.serviceLevel! !== 'combinedDeliveryWithInstallation',
  },
  {
    id: 'other',
    label: 'Sonstige Posten',
  },
  {
    id: 'special_expense_hvz',
    label: 'Sonderaufwand wie HVZ, etc.',
  },
  {
    id: 'packaging_disposal',
    label: 'Entsorgung Verpackungsmaterial',
    disabled: props.serviceLevel! !== 'combinedDeliveryWithInstallation',
  },
  {
    id: 'project_management',
    label: 'On-Site Management durch Projektleitung vor Ort',
    description: `2 Wochen von Mo-Fr (Tagessatz à ${vars.project_management_daily_rate_price},- €) 1 Pers. durchgängig (ges. 10 Tage)`,
  },
])

defineOptions({ inheritAttrs: false })

const emit = defineEmits(['close'])

const store = useInquiriesStore()
const modals = useModals()
const confirm = useConfirm()

const tabsState = ref('a')
const customAddress = ref('')
const inquiry = computed(() => (props.inquiryId ? store.allInquiriesAsMap[props.inquiryId] : undefined))
const suppliers = computed(() =>
  inquiry.value ? unique(inquiry.value?.items.map((item) => item.supplierInfo.title)) : [],
)

const calculation = reactive<Record<LogisticCalculationItem, number>>({
  project_management: 0,
  takeover_fee: 0,
  storage_rent_price_per_m3: 0,
  picking_and_loading: 0,
  arrival_and_departure: 0,
  unloading_and_contracting: 0,
  assembly: 0,
  other: 0,
  special_expense_hvz: 0,
  packaging_disposal: 0,
})
const sum = computed(() => Object.values(calculation).reduce((sum, current) => sum + current || 0, 0))
const plus_margin = computed(() => sum.value + sum.value / (100 / vars.surcharge_margin_percent))
const total_rrp_ratio = computed(() => sum.value / (vars.totalRrpNet / 100))
const delivery_cost_difference_ratio = computed(() => total_rrp_ratio.value - vars.control_margin_percent)

const calculate = () => {
  calculation.takeover_fee = vars.supplierQuantity * vars.storage_price
  calculation.storage_rent_price_per_m3 =
    vars.interim_delivery_weeks * vars.productsVolume * vars.interim_delivery_costs_per_week_price
  calculation.picking_and_loading =
    vars.productsVolume * vars.comission_volume_per_hour_factor * vars.construction_hourly_rate_price
  // @TODO: check assembly_multiplier
  calculation.arrival_and_departure =
    vars.trip_duration *
    2 *
    vars.delivery_time_multiplier *
    (vars.logistics_hourly_rate_price * vars.logistics_personal_quantity)
  calculation.unloading_and_contracting =
    vars.unloading_and_contracting_time_hours * vars.logistics_personal_quantity * vars.construction_hourly_rate_price

  calculation.assembly =
    vars.construction_time_hours_per_person * vars.construction_personel_quantity * vars.construction_hourly_rate_price
  calculation.other = vars.other
  calculation.special_expense_hvz = vars.hvz_flat_price || 0

  calculation.packaging_disposal =
    props.serviceLevel! === 'combinedDeliveryWithInstallation'
      ? Math.min(vars.productsQuantity * vars.packaging_proportion_factor_percent * vars.disposal_cost, 600)
      : 0
  calculation.project_management = vars.delivery_time_days * vars.project_management_daily_rate_price
}
onMounted(calculate)
watch(() => vars, calculate, { deep: true })

const hasChanges = computed(() => !shallowEqual(vars, defaultVars))
const storageAddress = ref('')
const calculateTripDuration = async (overrideAddress?: boolean) => {
  if (overrideAddress || (!props.deliveryAddress && !customAddress.value)) {
    customAddress.value =
      ((await confirm.prompt(
        'Ziel-Adresse angeben',
        'Straße, PLZ Stadt, Land',
        customAddress.value || props.deliveryAddress || '',
      )) as string) || ''
    if (!customAddress.value) {
      return
    }
  }

  if (props.deliveryAddress || customAddress.value) {
    const result = await modals.open('modal-logistic-calculate-distance', {
      address: customAddress.value || props.deliveryAddress,
    })
    storageAddress.value = result.address
    console.log('result', result)
    if (result) {
      vars.trip_duration = result.duration
    } else {
      await calculateTripDuration(true)
    }
  }
}

const reset = () => (varsStored.value = { ...defaultVars })

const calculateVolume = async () => {
  const volume = await modals.open('modal-logistic-calculate-volume', {
    inquiryId: props.inquiryId,
  })
  if (volume) {
    vars.productsVolume = volume
  }
}

const override = async (id: LogisticCalculationItem, label: string) => {
  const value = await confirm.prompt(
    `"${label}" überschreiben:`,
    'Wert in Euro',
    calculation[id as LogisticCalculationItem],
    {
      type: 'number',
    },
  )
  if (value !== undefined) {
    calculation[id as LogisticCalculationItem] = value as number
  }
}
const editSurchargeMarginPercent = async () => {
  const value = await confirm.prompt('Pauschal-Aufschlag', 'Wert in Prozent', vars.surcharge_margin_percent, {
    type: 'number',
  })
  if (value !== undefined) {
    vars.surcharge_margin_percent = value as number
  }
}
const editControlMarginPercent = async () => {
  const value = await confirm.prompt(
    'Kontrollziffer Verhältnis Logistkkosten Gesamt (ohne Projektmanagement) zu Gesamt-UVP',
    'Wert in Prozent',
    vars.control_margin_percent,
    {
      type: 'number',
    },
  )
  if (value !== undefined) {
    vars.control_margin_percent = value as number
  }
}
const round = (value: number) => value.toFixed(2)
const submit = () => emit('close', plus_margin.value)
</script>
