<template lang="pug">
div
  div(class='flex gap-2')
    btn(data-test='btn-add-product-to-project' medium block :loading='loadingAddBtn' class='flex-1' icon='plus' @click='$emit("submit")')
      | {{ $t('Add to Project or Inquiry') }}
    btn(
      secondary
      medium
      :data-tooltip='isInClipboard(id) ? $t("Remove from clipboard") : $t("Add to clipboard")'
      :loading='isLoading(id)'
      :active='isInClipboard(id)'
      :icon='isInClipboard(id) ? "check" : "custom-clipboard"'
      @click='toggle(id)'
    )
</template>

<script lang="ts">
import { useClipboardStore } from './Clipboard/store'
import { defineComponent } from 'vue'

const ProductActions = defineComponent({
  props: {
    id: { type: String, required: true },
    title: String,
    pcon: Boolean,
    loadingAddBtn: Boolean,
  },
  emits: ['submit'],
  setup() {
    return {
      ...useClipboardStore(),
    }
  },
})

export default ProductActions
</script>
