<template lang="pug">
div(:style='style' aria-hidden='true' class='avatar')
  img(v-if='isImage' style='display: none' :src='src' @error='onImgError')
  span(v-show='!isImage') {{ userInitials }}
  icon(v-if='icon' :name='icon' class='avatar__icon')
</template>

<script lang="ts">
/* tslint:disable */
import { defineComponent, computed, ref } from 'vue'

export const getInitials = (username: string) => {
  const parts = username.split(/[ -]/)
  let initials = ''
  for (let i = 0; i < parts.length; i++) {
    initials += parts[i].charAt(0)
  }
  if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
    initials = initials.replace(/[a-z]+/g, '')
  }
  initials = initials.substr(0, 3).toUpperCase()
  return initials
}

// From https://css-tricks.com/snippets/javascript/lighten-darken-color/
export const lightenDarkenColor = (hex: string, amt: number) => {
  let usePound = false
  if (hex[0] === '#') {
    hex = hex.slice(1)
    usePound = true
  }
  const num = parseInt(hex, 16)
  let r = (num >> 16) + amt
  if (r > 255) r = 255
  else if (r < 0) r = 0
  let b = ((num >> 8) & 0x00ff) + amt
  if (b > 255) b = 255
  else if (b < 0) b = 0
  let g = (num & 0x0000ff) + amt
  if (g > 255) g = 255
  else if (g < 0) g = 0
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

const Avatar = defineComponent({
  props: {
    username: { type: String, default: '' },
    icon: String,
    src: String,
    rounded: { type: Boolean, default: true },
    color: String,
    backgroundColor: String,
    size: { type: Number, default: 42 },
  },
  setup(props) {
    const backgroundColors = [
      '#F44336',
      '#FF4081',
      '#9C27B0',
      '#673AB7',
      '#3F51B5',
      '#2196F3',
      '#03A9F4',
      '#00BCD4',
      '#009688',
      '#4CAF50',
      '#8BC34A',
      '#CDDC39',
      '#FFC107',
      '#FF9800',
      '#FF5722',
      '#795548',
      '#9E9E9E',
      '#607D8B',
    ]

    const imgError = ref(false)
    const isImage = computed<boolean>(() => imgError.value && Boolean(props.src))
    const background = computed(() =>
      !isImage.value ? randomBackgroundColor(props.username.length, backgroundColors) : '',
    )
    const fontColor = computed(() => (!isImage.value ? lightenDarkenColor(background.value, 80) : 'white'))
    const iconColor = computed(() => (props.icon ? background.value : 'black'))
    const randomBackgroundColor = (seed: number, colors: string[]) => colors[seed % colors.length]
    const userInitials = computed(() => (!isImage.value ? getInitials(props.username) : ''))

    return {
      userInitials,
      isImage,
      onImgError: () => (imgError.value = true),
      style: computed(() => ({
        width: `${props.size}px`,
        height: `${props.size}px`,
        lineHeight: `${props.size + Math.floor(props.size / 20)}px`,
        backgroundColor: props.backgroundColor || background.value,
        color: props.color || fontColor.value,
        borderRadius: props.rounded ? '50%' : '0',
        '--icon-color': iconColor.value,
        fontSize: `${Math.floor(props.size / 2.5)}px`,
      })),
      background,
    }
  },
})

export default Avatar
</script>

<style lang="stylus">
@import '../styles/variables.styl'

:root
  --icon-bg: $color-white
.avatar
  position: relative
  display: inline-flex
  align-items: center
  justify-content: center
  text-align: center
  user-select: none
  text-decoration: none!important
  span
    font-weight: bold
  &__icon
    border-radius: 50%
    position: absolute
    border: 3px solid var(--icon-bg)
    width: 50%
    height: 50%
    color: var(--icon-color)!important
    bottom: -4px
    right: -4px
    background: var(--icon-bg)
</style>
