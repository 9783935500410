<template lang="pug">
component(
  :is='isLink ? "a" : "span"'
  :href='isLink && linkHref'
  class='group flex items-center text-[15px] font-normal text-text'
  :class='{ "text-xl font-lato": lg, "last:font-black": highlightCurrent }'
  data-test='breadcrumb'
)
  slot {{ label }}
  icon(name='chevron-right' class='mx-1 text-text-extralight group-last:!hidden')
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue'
import { computed, inject } from 'vue'
import { type RouteLocationRaw, useRouter } from 'vue-router'

const props = defineProps<{
  href?: string
  location?: RouteLocationRaw
  label?: string
  link?: boolean
  lg?: boolean
}>()
const router = useRouter()
const isLink = computed(() => props.link || props.href || props.location)
const linkHref = computed(
  () => props.href || (props.location && router.resolve(props.location as any).href) || 'javascript:void(0);',
)
const highlightCurrent = inject<boolean>('highlightCurrent')
</script>
