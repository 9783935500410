<template lang="pug">
div(:id='id' :class='classes' class='control-radio')
  control-radio-item(v-for='option in options' :id='id + "_" + option.value' :key='option.value' :value='option.value' :disabled='disabled' :bordered='bordered') 
    slot(:option='option') {{ option.label }}
</template>

<script lang="ts">
import ControlRadioItem from './ControlRadioItem.vue'
import { Input, useControl } from './composables'
import type { IOption } from '@/types'
import { computed, defineComponent, provide, type PropType, type SetupContext } from 'vue'

const ControlRadio = defineComponent({
  components: { ControlRadioItem },
  props: {
    ...Input.props,
    modelValue: [String, Number, Boolean],
    options: Array as PropType<IOption[]>,
    bordered: Boolean,
  },
  setup(props, context: SetupContext) {
    const { value: val, classes, onChange } = useControl<string>(props, context, '')
    provide('value', val)
    provide('onChange', (value: string) => ((val.value = value), onChange()))
    provide(
      'disabled',
      computed(() => props.disabled),
    )
    return {
      isEmpty: computed(() => !val.value || !props.options?.map((i) => i.value).includes(val.value)),
      onChange,
      focus,
      val,
      classes,
    }
  },
})

export default ControlRadio
</script>
