import { type AxiosResponse } from 'axios'
import Axios from 'axios-observable'
import { type App, type Plugin, inject, reactive } from 'vue'

export interface IPermissionData {
  canAccessAdminMenu?: boolean
  canImpersonate?: boolean
  canAccessProductImport?: boolean
  canAccessProductImportSettings?: boolean
  canAccessAdvancedAdminTools?: boolean
  canSeePurchasingPrices?: boolean
  canAccessCustomerProfile?: boolean
  canAccessCustomerAdminFunctions?: boolean
}

export type PermissionsData = IPermissionData

export const copyValues = (source: Record<string, any>, target: Record<string, any>) =>
  Object.entries(source).forEach(([key, value]) => target[key] !== undefined && (target[key] = value))

const PermissionsPlugin: Plugin = {
  install(app: App) {
    const permissions = reactive<IPermissionData>({
      canAccessAdminMenu: false,
      canImpersonate: false,
      canAccessProductImport: false,
      canAccessProductImportSettings: false,
      canAccessAdvancedAdminTools: false,
      canSeePurchasingPrices: false,
      canAccessCustomerProfile: false,
      canAccessCustomerAdminFunctions: false,
    })
    Axios.get('/_/Permissions').subscribe((response: AxiosResponse<IPermissionData>) =>
      copyValues(response.data, permissions),
    )
    app.config.globalProperties.$permissions = permissions
    app.provide<PermissionsData>('$permissions', permissions)
  },
}
export default PermissionsPlugin

export const usePermissions = () => {
  return inject<PermissionsData>('$permissions')!
}
