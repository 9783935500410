<template lang="pug">
div(:class='classes' class='notification')
  icon(v-if='showIcon' :name='actualIcon' class='notification__icon')
  div(class='notification__content')
    slot
      div(v-html='message')
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { type UI_STATE } from '@/types'
import { defineComponent, computed } from 'vue'
import { type PropType } from 'vue'

export const ICON_MAP: Record<UI_STATE, string> = {
  info: 'info',
  success: 'custom-check-circle',
  error: 'alert-triangle',
  warning: 'alert-circle',
}

const Notification = defineComponent({
  components: { Icon },
  props: {
    type: { type: String as PropType<'info' | 'success' | 'error' | 'warning'>, default: 'info' },
    icon: String,
    message: String,
    size: { type: String, default: 'default' },
    showIcon: { type: Boolean, default: true },
  },
  setup(props) {
    return {
      actualIcon: computed(() => props.icon || ICON_MAP[props.type] || props.type),
      classes: computed(() => ({
        [`notification--${props.type}`]: true,
        [`notification--${props.size}`]: true,
        'notification--withIcon': props.showIcon,
      })),
    }
  },
})

export default Notification
</script>
<style lang="stylus">
@import '../../styles/variables.styl'

:root
  --notification-font-size: 15px
  --notification-color: var(--grey-500)
.notification
  display: flex
  position: relative
  padding: 1px 2px 2px
  &, &_content, &__content *
    color: var(--notification-color)
    font-size: var(--notification-font-size)
    line-height: 1.5!important
    margin: 0
  &__content
    p
      margin: 0
      &:not(:last-child)
        margin-bottom: 0.40rem
    li
      padding: 0
  &:first-child
    padding-top: 6px
  &__icon.icon
    font-size: 19px
    margin-right: 10px
    display: inline-block
    position: relative
    top: 2px
    align-items: flex-start
    ~/--small &
      margin-right: 5px
      font-size: 16px
  &--medium
    --notification-font-size: 16px
  &--small
    --notification-font-size: 14px
  &--withIcon
    padding: 5px 0
  &--success
    --notification-color: var(--green-500)
  &--error
    --notification-color: var(--red-600)
  &--warning
    --notification-color: var(--yellow-500)
</style>
