import { onMountedOrActivated } from './Vue'
import { useFeatureFlags } from '@/plugins/FeatureFlags'
import { useTranslation } from '@/plugins/translation'
import createDragDropObservable, {
  type DragDropOptions,
  autoScroll,
  addClasses,
  indicator,
  dragImage,
  type DragDropPayload,
} from 'dndrxjs'
import { filter } from 'rxjs'
import { type Ref, onDeactivated } from 'vue'

export const fromHTML = (html: string) => {
  const template = document.createElement('template')
  template.innerHTML = html.trim()
  return template.content.children[0] as HTMLElement
}

export const useDragDrop = (
  container: Ref<HTMLElement | null>,
  options: Partial<DragDropOptions>,
  onDrop: (payload: DragDropPayload) => any,
) => {
  const $flags = useFeatureFlags()
  const { $tc } = useTranslation()
  let subscription: any
  onMountedOrActivated(() => {
    subscription = createDragDropObservable({
      ...options,
      container: container.value!,
      dragOverThrottle: $flags.DISABLE_TRANSITIONS ? 0 : undefined,
    })
      .pipe(
        addClasses(),
        indicator(),
        autoScroll(),
        dragImage({
          minElements: 1,
          updateElement: (selectedElements: HTMLElement[]) =>
            fromHTML(
              `<div style="background: var(--blue-950); padding: 2px 8px; color: white; font-size: 14px; margin: 10px; font-weight: bold; border-radius: 10px;">${$tc('{0} Produkt | {0} Produkte', selectedElements.length)} </div>`,
            ),
        }),
        filter(({ type }) => type === 'DragEnd'),
      )
      .subscribe(onDrop)
  })
  onDeactivated(() => subscription?.unsubscribe())
}
