<template lang="pug">
dropdown(ref='dropdown')
  template(#content)
    slot(:value='value')
</template>

<script setup lang="ts" generic="T">
import Dropdown from './Dropdown.vue'
import { useDelegatedClickEventListener } from '@/composables/'
import { useEventListener } from '@vueuse/core'
import { ref, type UnwrapRef } from 'vue'

const props = defineProps<{
  selector: string
  getValue: (el: HTMLElement) => T
}>()
const value = ref<T | null>(null)

const dropdown = ref<InstanceType<typeof Dropdown> | null>(null)

// @TODO: find better solution
useEventListener(document.body, 'dragend', () => dropdown.value?.close())

useDelegatedClickEventListener(
  document.body as HTMLElement,
  props.selector,
  (el, event) => (
    console.log('clicked'),
    (value.value = props.getValue(el) as UnwrapRef<T>),
    dropdown.value!.toggle(event as PointerEvent)
  ),
  true,
)
</script>
