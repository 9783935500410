import { useProjectsStore } from './store'
import { type IProjectData, type IProjectProductItem } from './types'
import { emitter } from '@/common'
import { type IFormResponseInfo } from '@/components/GenericForm/types'
import { useConfirm } from '@/plugins/Confirm'
import { useModals } from '@/plugins/Modals'
import { useUtils } from '@/plugins/Utils'
import { useTranslation } from '@/plugins/translation'
import { arrayMoveMultiple, parsePrice, toDataURL } from '@/utilities'
import Env from '@/utilities/Env'
import { useDebounceFn } from '@vueuse/core'
import { type MaybeRef } from '@vueuse/shared'
// @ts-expect-error
import * as csvjson from 'csvjson'
import { type DragDropPayload, type DropPositionFn } from 'dndrxjs'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { isArray, pick, unique } from 'radash'
import { Observable, Subscription } from 'rxjs'
import { computed, ref, type Ref, unref } from 'vue'
import { useRouter } from 'vue-router'

export interface IProjectsState {
  loading: { [id: string]: boolean }
  projects: IProjectData[]
}

export const arrayToFormData = (key: string, array: string[]) => {
  const formData = new FormData()
  array.forEach((v) => formData.append(key, v))
  return formData
}

export const useEditPconProduct = () => {
  const $modals = useModals()
  return {
    editPconProduct: (
      { pConInformation, productId, title }: IProjectProductItem,
      projectId: string,
      inquiryId?: string,
      parentProductId?: string,
      disabled?: boolean,
    ) =>
      $modals.open('form-edit-pcon-configuration', {
        productId,
        inquiryId,
        parentProductId,
        projectId,
        pconInformation: pConInformation,
        name: title,
        disabled,
      }),
  }
}

export const hasAnyChildren = (selected: string[], products: IProjectProductItem[]) =>
  products
    .filter((product: IProjectProductItem) => selected.includes(product.productId))
    .some((product: IProjectProductItem) => product.alternatives?.length > 0)

export const useProject = (projectId: Ref<string>, selected: Ref<string[]>, tag: Ref<string>) => {
  const { $t } = useTranslation()
  const store = useProjectsStore()
  const { info } = useConfirm()
  const $modals = useModals()
  const project = computed(() => store.project(projectId.value))
  const products = computed(() => project.value?.items)
  const hasAnySelectedElementChildren = computed(() => hasAnyChildren(selected.value, products.value))
  const productsAsMap = computed(() => store.projectProductsAsMap[projectId.value])
  const hasSelectedItems = computed(() => selected.value.length > 0)
  const filteredProducts = computed(() =>
    !tag.value ? products.value : products.value?.filter((p) => p.tags.map((t) => t.name).includes(tag.value!)) || [],
  )

  const createInquiryRequest = () => {
    $modals.open('form-inquiry', { isNewInquiry: true, inquiryId: '*' })
    store
      .createInquiry(
        unref(projectId),
        selected.value.length > 0 ? selected.value : filteredProducts.value.map((item) => item.productId),
      )
      .then((response) => {
        emitter.emit('submitSuccess', {
          formId: 'FormCreateInquiry',
          endpoint: '',
          response: {} as any, // @TODO: make this right
          data: {},
          formData: { Items: selected.value } as any,
          formAction: 'Create',
        } as IFormResponseInfo)

        setTimeout(() => {
          $modals.open('form-inquiry', { inquiryId: response.data.id, isNewInquiry: true })
        }, 300)
      })
      .catch(() => {
        store.loadProject(projectId.value)
      })
  }

  return {
    hasSelectedItems,
    hasAnySelectedElementChildren,
    project,
    store,
    productsAsMap,
    products,
    actions: useProjectActions(),
    filteredProducts,
    loadingCreateInquiry: computed(() => store.loading['createInquiry']),
    createInquiry: () => {
      const hasAnyUnavailableOrInactiveProducts = selected.value.some(
        (id) => !productsAsMap.value[id].isAvailable || !productsAsMap.value[id].isActive,
      )
      const hasAnyProductZeroAsQuantity = selected.value.some((id) => productsAsMap.value[id].quantity === 0)
      if (hasAnyUnavailableOrInactiveProducts) {
        info({
          title: $t('Some products are not available any more.'),
          okText: $t('Ok'),
          cancelText: $t('Abort'),
          content: $t('Please remove or deselect all unavailable products.'),
        })
      } else if (hasAnyProductZeroAsQuantity) {
        info({
          onOk: () => createInquiryRequest(),
          title: $t('There are products with quantity 0.'),
          okText: $t('Create inquiry'),
          cancelText: $t('Abort'),
          content: $t('The quantity of these products will be set to 1.'),
        })
      } else {
        // @TODO: temporarily
        info({
          onOk: () => createInquiryRequest(),
          title: $t('Hinweis zur aktuellen Geschäftslage'),
          okText: $t('Projektrabatt trotzdem anfragen'),
          content:
            'Leider müssen wir Euch mitteilen, dass nach unserem Antrag am 27.03.2024 ein vorläufiges Insolvenzverfahren vom Amtsgericht Hamburg angeordnet wurde. Herr Dr. Tobias Brinkmann aus der Kanzlei Brinkmann & Partner, Sechslingspforte 2, 22087 Hamburg, wurde zum vorläufigen Insolvenzverwalter bestellt.<br/><br/>Ziel des Verfahrens ist es, einen Investor zu finden und die unternehmerische Basis der nuucon GmbH zu sichern. In Abstimmung mit dem vorläufigen Insolvenzverwalter können wir den Geschäftsbetrieb zunächst in vollem Umfang aufrechterhalten.<br/><br/><b>Dies ermöglicht es uns auch in der aktuellen Phase neue Projekte und Aufträge  rechtssicher umzusetzen und Euch und Euren Bauherren Sicherheit bei Bestellungen zu geben.</b><br/><br/>Wir würden uns sehr über eine Fortsetzung der Zusammenarbeit mit Euch freuen. Eure Ansprechpartner bei uns im Hause bleiben unverändert und wir stehen Euch jederzeit für Rückfragen und Anmerkungen zur Verfügung.<br/><br/>Herzlichen Dank für Euer Vertrauen und Eure Unterstützung.<br/><i>Philipp & Pierre und das gesamte nuucon-Team</i><br/>',
          cancelText: $t('Abort'),
        })
      }
    },
    moveProductsToIndex: (
      dropElementIndex: number,
      selectedIds: string[],
      currentProductList: string[] = products.value.map((p: IProjectProductItem) => p.productId),
    ) => {
      store.updateProductOrder(
        unref(projectId),
        arrayMoveMultiple<string>(currentProductList, selectedIds, dropElementIndex),
      )
    },
  }
}

const EXPORT_KEY_TRANSLATION_MAP: Record<string, string> = {
  image: 'Bild',
  title: 'Produktname',
  brand: 'Hersteller',
  productNumber: 'Artikelnummer',
  description: 'Produktbeschreibung',
  comment: 'Notiz',
  tags: 'Listen',
  quantity: 'Anzahl',
  retailPriceNetString: 'UVP',
  retailPriceTotalNetString: 'Gesamt UVP',
  externalProductUrl: 'Produkt-URL (extern)',
  baseArticleNumber: 'baseArticleNumber (pCon)',
  manufacturerId: 'manufacturerId (pCon)',
  seriesId: 'seriesId (pCon)',
  ofmlVariantCode: 'ofmlVariantCode (pCon)',
  variantCode: 'variantCode (pCon)',
}

export const useQuantityHandling = (
  projectId: MaybeRef<string>,
  apiCall: (projectId: string, productId: string, quanity: number) => Observable<any>,
) => {
  const loadingQuantityMap = ref<Record<string, boolean>>({})
  const quantityMap = ref<Record<string, number>>({})
  let subscription: Subscription | null = null
  const postUpdateQuantityDebounced = useDebounceFn(
    (quantity: number, productId: string) =>
      (subscription = apiCall(unref(projectId), productId, quantity).subscribe({
        complete: () => (loadingQuantityMap.value[productId] = false),
      })),
    300,
  )
  const updateQuantity = (quantity: number, productId: string) => {
    subscription?.unsubscribe() // cancel request to prevent race conditions
    quantityMap.value[productId] = quantity
    loadingQuantityMap.value[productId] = true
    postUpdateQuantityDebounced(quantity || 0, productId)
  }
  return {
    updateQuantity,
    quantityMap,
    loadingQuantity: computed(() => Object.values(loadingQuantityMap.value).some((v) => v)),
    loadingQuantityMap,
  }
}
// const linkStyle = {
//   underline: true,
//   color: { argb: 'FF0000FF' },
// }

export const useExport = () => {
  const toExportProjectItem = ({
    title,
    brand,
    description,
    comment,
    tags,
    productId,
    retailPriceNetString,
    retailPriceTotalNetString,
    quantity,
    pConInformation,
    isExternal,
    image,
    productNumber,
    externalProductUrl,
  }: IProjectProductItem) => ({
    image: image.includes('http') ? image : `${Env.cloudinaryPath}${Env.assetPathPrefix}${image}`,
    title,
    brand,
    productNumber: isExternal ? productNumber : pConInformation ? '-' : productId,
    description,
    comment,
    tags: tags?.map((tag) => tag.name.trim()).join(',') || '',
    externalProductUrl: { text: externalProductUrl, hyperlink: externalProductUrl },
    quantity,
    retailPriceNetString: parsePrice(retailPriceNetString),
    retailPriceTotalNetString: parsePrice(retailPriceTotalNetString),
    ...(pConInformation
      ? pick(pConInformation, ['seriesId', 'manufacturerId', 'baseArticleNumber', 'ofmlVariantCode'])
      : { seriesId: '', manufacturerId: '', baseArticleNumber: '', ofmlVariantCode: '' }),
  })
  return {
    exportAsXls: async (fileName: string, projectItems: IProjectProductItem[]) => {
      const items = projectItems.map(toExportProjectItem)
      const workbook = new ExcelJS.Workbook()
      const sheet = workbook.addWorksheet('My Sheet')
      const widths: Partial<Record<keyof IProjectProductItem, number>> = {
        quantity: 10,
        description: 40,
        comment: 10,
        image: 18,
        retailPriceNetString: 22,
        retailPriceTotalNetString: 22,
      }

      sheet.columns = Object.keys(items[0]).map((key) => ({
        header: EXPORT_KEY_TRANSLATION_MAP[key as string] || (key as string),
        key: key as string,
        width: widths[key as keyof IProjectProductItem] || 20,
        alignment: { wrapText: true },
      }))

      await sheet.addRows(items)

      // sheet.getColumn(7).eachCell((cell, rowNumber) => rowNumber > 1 && (cell.font = linkStyle))
      sheet.getRow(1).height = 40
      sheet.getRow(1).eachCell((c) => {
        c.fill = {
          pattern: 'solid',
          type: 'pattern',
          fgColor: { argb: 'F0EEEE' },
        }
        c.font = { bold: true }
        c.alignment = { wrapText: true }
      })

      sheet
        .getRows(2, sheet.rowCount)
        ?.forEach((r) => r.eachCell((c) => (c.alignment = { wrapText: true, vertical: 'top' })))
      sheet.getRows(2, sheet.rowCount)?.forEach((r) => (r.height = 80))

      for (let index = 1; index < sheet.rowCount; index++) {
        const c = sheet.getCell(index, 1)
        if (c.text && c.text.includes('http')) {
          const myBase64Image = await toDataURL(c.text)
          const image = workbook.addImage({
            base64: myBase64Image,
            extension: 'png',
          })
          c.value = null

          sheet.addImage(image, {
            tl: { col: 0, row: index - 1, colWidth: 22 },
            ext: { width: 80, height: 80 },
            editAs: undefined,
            hyperlinks: {
              hyperlink: c.text,
            },
          })
        }
      }
      const buffer = await workbook.xlsx.writeBuffer()
      saveAs(
        new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        fileName + '.xlsx',
      )
    },

    exportAsCsv: (fileName: string, projectItems: IProjectProductItem[]) => {
      const options = {
        delimiter: ';',
        quote: '"',
        wrap: true,
        headers: 'key',
      }

      const hiddenElement = document.createElement('a')
      hiddenElement.href =
        'data:text/csv;charset=utf-8,' +
        encodeURI(csvjson.toCSV(projectItems.map(toExportProjectItem), options).replaceAll('\t', '\n'))
      hiddenElement.target = '_blank'
      hiddenElement.download = fileName + '.csv'
      hiddenElement.click()
    },
  }
}

export const useProjectActions = () => {
  const $utils = useUtils()
  const { $t } = useTranslation()
  const { danger, confirm } = useConfirm()
  const $router = useRouter()
  const store = useProjectsStore()
  const $modals = useModals()
  let copyProductDialogHasBeenOpened = false

  return {
    openEditTagsForm: (projectId: string) => $modals.open('form-project-tags-edit', { id: projectId }),
    openAddTagForm: (projectId: string) => $modals.open('form-project-tag-create', { id: projectId }),
    openSettingsForm: (projectId: string) => $modals.open('form-project-edit', { projectId }),
    openAddProductsForm: (projectId: string, tag: string = '') =>
      $utils.routeQueryAdd(
        { 'form-project-add-products': { projectId, tag, size: 'lg', mode: 'drawer' } },
        false,
        false,
      ),
    openAddExternalProductForm: (projectId: string, tag: string = '') =>
      $modals.open('form-add-or-edit-external-project-item', { projectId, tag }),
    openPconCatalog: (projectId: string, tag: string = '') => $modals.open('modal-pcon-catalog', { projectId, tag }),
    openPdfPreview: (projectId: string, tag: string = '', productIds?: string[]) =>
      $modals.open('modal-project-pdf-preview', { tag, projectId, productIds }),
    openExportDialog: (projectId: string) => $modals.open('form-export', { projectId }),
    upadeProjectOwner: (projectId: string) => $modals.open('form-project-update-owner-admin', { projectId }),
    restoreProject: (projectId: string) => store.restoreProject(projectId),
    removeProject: (projectId: string) =>
      danger({
        title: $t('Delete Project {0}?', '', [projectId]),
        content: $t('Das Projekt darf keine aktiven Anfragen haben.'),
        onOk: () =>
          store
            .deleteProject(projectId)
            .then((value: boolean | string) => value && setTimeout(() => $router.push('/projects'), 900)),
      }),
    showPrintPreview: (projectId: string, tag: string) => $modals.open('modal-project-pdf-preview', { projectId, tag }),
    editMembers: (projectId: string) => $modals.open('form-project-members', { id: projectId }),
    addMember: (projectId: string) => $modals.open('form-project-member-add', { id: projectId }),
    editTitle: (projectId: string) =>
      $utils.routeQueryAdd({
        'form-generic': {
          data: {
            baseUrl: `/_/Projects/${projectId}`,
            action: [{ name: 'UpdateSettings', label: $t('Save'), primary: true }],
            id: 'FormEditTitle',
            label: $t('Edit Title'),
            size: 'sm',
            fields: [
              {
                name: 'Name',
                bindings: { autofocus: true },
                type: 'form-input',
              },
            ],
          },
        },
      }),
    removeAlternative: (projectId: string, id: string, parentProductId: string) =>
      confirm({
        title: $t('Are you sure you want to remove this product?'),
        onOk: () => store.removeAlternative(projectId, id, parentProductId),
      }),
    editExternalProduct: (projectId: string, productId: string, parentProductId: string = '') =>
      $utils.routeQueryAdd({
        'form-add-or-edit-external-project-item': { projectId, parentProductId, productId, size: 'xl' },
      }),
    editTags: (projectId: string, productId: string) =>
      $utils.routeQueryAdd({ 'form-project-tags-add': { projectId, productId, size: 'sm' } }),
    setAsAlternative: (projectId: string, productId: string) =>
      $utils.routeQueryAdd({
        'form-add-product-alternative': { projectId, productId, size: 'sm' },
      }),
    copyProducts: (projectId: string, productId: string | string[]) => {
      $utils.routeQueryAdd({
        'form-project-add-product': {
          productIds: productId,
          projectId: copyProductDialogHasBeenOpened ? undefined : projectId,
          sourceProjectId: projectId,
        },
      })
      copyProductDialogHasBeenOpened = true
    },
    removeProducts: (projectId: string, productId: string | string[]) => {
      const hasAnySelectedElementChildren = hasAnyChildren(
        isArray(productId) ? productId : [productId],
        store.project(projectId).items,
      )
      const hasProductAlternatives =
        !isArray(productId) && store.projectProductsAsMap[projectId][productId as string].alternatives.length > 0
      confirm({
        onOk: () => store.removeProducts(projectId, productId),
        title: isArray(productId)
          ? $t('Are you sure you want to remove these products?')
          : $t('Are you sure you want to remove this product?'),
        content: isArray(productId)
          ? hasAnySelectedElementChildren
            ? $t('Some products have alternatives which will be removed as well.')
            : undefined
          : hasProductAlternatives
            ? $t('This product has alternatives which will be removed as well.')
            : undefined,
      })
    },
  }
}

export const extractRowData = (e: Element) => ({
  id: e.getAttribute('data-id'),
  index: Number(e.getAttribute('data-index')),
  hasChildren: e.getAttribute('data-has-children') === 'true',
  level: Number(e.getAttribute('data-level')),
  expanded: e.getAttribute('data-expanded') === 'true',
})

export const useProjectProductDragDrop = (
  id: Ref<string>,
  selected: Ref<string[]>,
  tag: Ref<string>,
  onFavoriteUpdate: (dropElementId: string) => any,
) => {
  const { products, moveProductsToIndex, hasAnySelectedElementChildren } = useProject(id, selected, tag)
  const store = useProjectsStore()
  const dropPositionFn: DropPositionFn = ({ dropElement, dragElement }) => {
    if (!dragElement || !dropElement) {
      return 'none'
    }
    const { hasChildren } = extractRowData(dragElement)
    const hasActuallyChildren = hasChildren || hasAnySelectedElementChildren.value
    const { level: dropElementLevel, expanded: dropElementExpanded } = extractRowData(dropElement)
    return dropElementLevel === 0
      ? hasActuallyChildren
        ? dropElementExpanded
          ? 'before'
          : 'around'
        : dropElementExpanded
          ? 'notAfter'
          : 'all'
      : hasActuallyChildren
        ? 'none'
        : 'around'
  }
  return {
    dropPositionFn,
    onDragDropEnd: (t: DragDropPayload) => {
      const currentId = t.dragElements[0]?.getAttribute?.('data-id')
      const dropElementId = t.dropElement?.getAttribute?.('data-id')
      if (!currentId || !dropElementId) {
        return
      }
      const dropElementIndex = Number(t.dropElement?.getAttribute?.('data-index') || '0')
      const dropElementParentId = t.dropElement?.getAttribute?.('data-parent-id')
      const currentParentId = t.dragElements[0]?.getAttribute?.('data-parent-id')
      const selectedIds = unique([currentId, ...t.dragElements.map((e) => e.getAttribute('data-id')!)])
      if (!currentParentId && (t.position === 'in' || dropElementParentId)) {
        // 1. Set products as alternative product
        store
          .addAlternatives(
            unref(id),
            selectedIds.filter((id) => id !== dropElementParentId && id !== dropElementId),
            dropElementParentId || dropElementId,
          )
          .then(() => onFavoriteUpdate(dropElementParentId || dropElementId))
      } else if (dropElementId === currentParentId && t.position === 'in') {
        // 2. Set alternative as favorite (partentId === dropElementId)
        store.setAlternativeAsFavorite(unref(id), currentId, dropElementId).then(() => onFavoriteUpdate(dropElementId))
      } else if (!dropElementParentId && currentParentId && t.position !== 'in') {
        // 3. Move alternative to top level () T
        store.moveAlternativeToToplevel(
          unref(id),
          currentId,
          currentParentId,
          dropElementIndex + (t.position === 'after' ? 1 : 0),
        )
      } else if (!dropElementParentId && !currentParentId) {
        // 4. Reorder products on top level
        const currentProductList = products.value.map((p: IProjectProductItem) => p.productId)
        const actualTargetIndex = currentProductList.indexOf(dropElementId!)
        moveProductsToIndex(
          actualTargetIndex + (t.position === 'after' ? 1 : 0),
          selectedIds,
          products.value.map((p: IProjectProductItem) => p.productId),
        )
      } else if (currentParentId) {
        // 5. Sort alternatives or move alternative as alternative to different parent
        store
          .updateAlternativeProductOrder(
            unref(id),
            currentId,
            currentParentId,
            dropElementIndex + (t.position === 'after' ? 1 : 0),
            dropElementParentId || dropElementId,
          )
          .then(() => onFavoriteUpdate(dropElementParentId || dropElementId))
      }
    },
  }
}
