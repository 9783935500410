import { type TranslateFn } from './translation'
import { type ModalSizes } from '@/components/Modal.vue'
import type { IOption } from '@/types'
import { type App, type Plugin, inject, ref } from 'vue'

export type IPromptInputOptions = {
  component?: string
  label: string
  suffix?: string
  inputBindings?: object
  multiline?: boolean
  initialValue?: string | number
  options?: IOption[]
}
export interface IConfirmData {
  id?: string
  layout?: 'vertical' | 'horizontal'
  title?: string
  okText?: string
  content?: string
  hideCancelButton?: boolean
  size?: ModalSizes
  icon?: string
  displayIcon?: boolean
  closeable?: boolean
  inputOptions?: IPromptInputOptions
  okButtonBindings?: Record<string, any>
  abortButtonBindings?: Record<string, any>
  iconBindings?: Record<string, any>
  cancelButtonBindings?: Record<string, any>
  cancelText?: string
  abortText?: string
  onOk?: (value?: any) => void
  onCancel?: (value?: any) => void
  onAbort?: (value?: any) => void
}

export type IInfoData = Pick<
  IConfirmData,
  'title' | 'okText' | 'icon' | 'onOk' | 'onCancel' | 'cancelText' | 'content' | 'displayIcon' | 'size'
>

export type PromptFn = (
  title: string,
  label: string,
  initialValue?: number | string,
  inputBindings?: any,
  multiline?: boolean,
  c?: IInfoData,
) => Promise<string | number>
export type PromptSelectFn = (
  title: string,
  label: string,
  options: IOption[],
  initialValue?: number | string,
  inputBindings?: any,
  c?: IInfoData,
) => Promise<string | number>
export interface IConfirmFunctions {
  confirm: (c: IConfirmData) => void
  danger: (c: IConfirmData) => void
  success: (c: IInfoData) => void
  info: (c: IInfoData) => void
  prompt: PromptFn
  promptSelect: PromptSelectFn
}
export type CloseConfirmFn = (key: string, value?: string | number) => void
export interface IConfirmOptionsItem {
  key: string
  resolvePromise: (value: any) => void
  options: IConfirmData
}

const modalsGlobal = ref<IConfirmOptionsItem[]>([])

const removeModal = (key: string) => (modalsGlobal.value = modalsGlobal.value.filter((item) => item.key !== key))

const addModal = async (options: IConfirmData) => {
  let resolvePromise = null
  const promise = new Promise((resolve) => (resolvePromise = resolve))
  const key = `m_${Math.random()}`
  modalsGlobal.value.push({
    resolvePromise: resolvePromise!,
    key,
    options,
  })
  const value = await promise
  removeModal(key)
  return value
}

// @TODO: simplify ModalConfirmManager and FormModalManager?
const ConfirmPlugin: Plugin = {
  install(app: App, $t: TranslateFn) {
    const confirm = (c: IConfirmData) => addModal({ icon: 'info', ...c })
    const danger = (c: IConfirmData) => addModal({ icon: 'info', okButtonBindings: { danger: true }, ...c })
    const info = (c: IConfirmData) =>
      addModal({
        size: 'sm',
        okText: $t('Ok'),
        icon: 'info',
        okButtonBindings: { medium: true },
        cancelButtonBindings: { link: true },
        closeable: true,
        hideCancelButton: c.onCancel ? false : true,
        ...c,
      })
    const success = (c: IConfirmData) =>
      addModal({
        size: 'sm',
        icon: 'custom-check-circle',
        okText: 'Ok',
        iconBindings: { class: 'text-green-400 text-5xl stroke-[1.5px] animated' },
        closeable: true,
        okButtonBindings: { medium: true, class: 'mb-4' },
        hideCancelButton: c.onCancel ? false : true,
        ...c,
      })
    const prompt: PromptFn = async (
      title: string,
      label: string,
      initialValue?: number | string,
      inputBindings?: any,
      multiline?: boolean,
      c?: IInfoData,
    ) =>
      addModal({
        okText: $t('Ok'),
        cancelText: $t('Abort'),
        closeable: true,
        layout: 'horizontal',
        cancelButtonBindings: { tertiary: true },
        inputOptions: { initialValue, label, inputBindings, multiline },
        title,
        ...c,
      }) as Promise<string | number>
    const promptSelect: PromptSelectFn = async (
      title: string,
      label: string,
      options: IOption[],
      initialValue?: number | string,
      inputBindings?: any,
      c?: IInfoData,
    ) =>
      addModal({
        okText: $t('Ok'),
        cancelText: $t('Abort'),
        closeable: true,
        layout: 'horizontal',
        cancelButtonBindings: { tertiary: true },
        inputOptions: { initialValue, label, inputBindings, options },
        title,
        ...c,
      }) as Promise<string | number>

    const close: CloseConfirmFn = (key: string, value?: string | number) =>
      modalsGlobal.value.find((item) => item.key === key)?.resolvePromise(value)

    app.provide('closeConfirmModal', close)
    app.provide('confirm', { confirm, info, success, danger, prompt, promptSelect })
    app.provide('modals', modalsGlobal)
  },
}
export const useConfirm = () => inject<IConfirmFunctions>('confirm')!

export default ConfirmPlugin
