<template lang="pug">
empty-state(v-if='!hasTag' :centered='true' style='height: clamp(300px, 40vh, 750px)')
  template(#message)
    span {{ $t('No products in project yet.') }}
  template(#buttons)
    btn(:to='{ name: "/products/" }' secondary icon='arrow-left') {{ $t('Go to products') }}
empty-state(v-else :centered='true' style='height: clamp(300px, 40vh, 750px)')
  template(#message)
    span {{ $t('No products with this tag yet.') }}
  template(#buttons)
    btn(:to='{ name: "/projects/[id]/", params: { id: projectId } }' secondary icon='arrow-left') {{ $t('Go to product collection') }}
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const ProjectItemsEmptyState = defineComponent({
  props: { hasTag: { type: Boolean, required: true }, projectId: { type: String, required: true } },
})

export default ProjectItemsEmptyState
</script>
