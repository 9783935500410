<template lang="pug">
div(class='control-select-list-multi')
  sticky-container(v-if='showFilterInput && options.length > 10' class='w-full p-2' :enabled='breakpoints.md')
    control-input(v-model='term' :placeholder='$t("filter values")' grey)
  empty(v-if='results.length === 0' class='!px-2') 
  template(v-for='option in results' :key='option.value')
    slot(:selected='isSelected(option.item.value)' :item='option.item' :option='option' :highlightFn='highlight' :toggle='toggleValue' :isSelected='isSelected')
      div(:data-id='option.item.value' class='flex w-full !flex-col')
        control-select-list-item(:selected='isSelected(option.item.value)' data-test='control-select-list-item' :data-id='option.item.value' @click.stop.prevent='toggleValue(option.item.value)')
          template(#default='{ selected }')
            slot(name='before' :selected='selected' :option='option.item' :toggle='toggleValue' :isSelected='isSelected')
            div(class='mr-4 flex-1' v-html='option.matches ? highlight(option.item.label, option.matches[0].indices) : option.item.label')
          template(#right='{ selected }')
            slot(name='checkbox' :selected='selected' :option='option.item' :toggle='toggleValue' :isSelected='isSelected')
        slot(name='after' :selected='isSelected(option.item.value)' :option='option.item' :toggle='toggleValue' :isSelected='isSelected')
</template>

<script setup lang="ts" generic="T = string">
import ControlInput from './ControlInput.vue'
import ControlSelectListItem from './ControlSelectListItem.vue'
import { highlight, useCustomBreakpoints } from '@/composables/'
import { type IOption } from '@/types'
import { type InterceptToggleFn } from '@/types'
import { useFuse } from '@vueuse/integrations/useFuse'
import { toggle } from 'radash'
import { computed, ref, type PropType } from 'vue'

const props = defineProps({
  showFilterInput: Boolean,
  options: { type: Array as PropType<IOption<T>[]>, default: () => [] },
  modelValue: { type: Array as PropType<string[]>, required: true },
  interceptToggle: Function as PropType<InterceptToggleFn<T>>,
})
const emit = defineEmits<{ 'update:modelValue': [value: string[]] }>()
const term = ref('')
const { results } = useFuse(
  term,
  computed(() => props.options),
  {
    fuseOptions: {
      keys: ['label'],
      includeMatches: true,
      minMatchCharLength: 1,
      ignoreFieldNorm: true,
      ignoreLocation: true,
      threshold: 0.2,
    },
    matchAllWhenSearchEmpty: true,
  },
)
const breakpoints = useCustomBreakpoints()
const isSelected = (id: string | string[]) => props.modelValue.includes(id as string)
const toggleValue = (id: string | string[], data?: any) => {
  emit(
    'update:modelValue',
    props.interceptToggle
      ? props.interceptToggle(id as string, props.modelValue, props.options, data)
      : (toggle(props.modelValue, id) as string[]),
  )
}
</script>
