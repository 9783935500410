<template lang="pug">
control-input-wrapper(:disabled='disabled' :size='size' :icon='icon' :showClear='clearable && showClear' @clear='clear')
  input(
    :id='id'
    ref='el'
    v-model='value'
    :placeholder='placeholder'
    :type='type'
    :data-empty='isEmpty'
    :max='max'
    :min='min'
    :step='step'
    :disabled='disabled'
    @input='onChange'
    @keydown.enter='onSubmit'
    @focus='onFocus'
    @blur='onBlur'
    @change='$emit("change:modelValue", value)'
  )
  template(#after)
    slot
</template>

<script lang="ts">
import ControlInputWrapper from './ControlInputWrapper.vue'
import { useControl, Input } from './composables'
import { defineComponent, type SetupContext, type PropType } from 'vue'

export const INPUT_TYPES = ['text', 'number', 'tel', 'date', 'password'] as const
export type InputTypes = (typeof INPUT_TYPES)[number]

export const inputEmits = <T = string | number,>() => ({
  'change:modelValue': (value: T) => value,
  'update:modelValue': (value: T) => value,
  clear: () => null,
  'update:submit': (value: T) => value,
})

const ControlInput = defineComponent({
  components: { ControlInputWrapper },
  props: {
    ...Input.props,
    modelValue: [String, Number],
    // prevents submit event on enter
    noSubmit: Boolean,
    icon: String,
    size: String,
    type: {
      type: String as PropType<InputTypes>,
      default: 'text',
    },
    step: [Number, String],
    max: {
      type: String,
    },
    min: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  emits: inputEmits(),
  setup(props, context: SetupContext) {
    const control = useControl<string>(props, context, '')
    return {
      ...control,
      onSubmit: (e: Event) =>
        props.noSubmit ? (context.emit('change:modelValue', control.value), e.preventDefault()) : control.onSubmit(),
      focus,
    }
  },
})

export default ControlInput
</script>
