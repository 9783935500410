<template lang="pug">
modal(size='sm' data-test='modal-pcon-obx-export' @close='$emit("close")')
  modal-header {{ $t('Export OBX Files') }}
  p {{ $t('Create zip with obx files for each brand.') }}
  p(class='mb-4')
    b {{ $t('Available pcon products:') }}
    b {{ products.length }}
  // for pcon application! do not remove
  div#content 
  btn(block :disabled='!products.length || loading' :loading='loading' @click='downloadObx') {{ $t('Generate and Download') }}
</template>

<script lang="ts">
import { usePconApplication } from '@/composables/pcon'
import { type IPconInformation } from '@/types'
import Axios from 'axios'
import saveAs from 'file-saver'
import JSZip from 'jszip'
import { type PropType, ref } from 'vue'
import { defineComponent } from 'vue'

const ModalExportPconObx = defineComponent({
  inheritAttrs: false,
  props: {
    id: { required: true, type: String },
    products: { required: true, type: Array as PropType<IPconInformation[]> },
  },
  setup(props, { emit }) {
    const { addArticle, articleManager, removeAllElements, init } = usePconApplication()
    init()
    const loading = ref(false)
    return {
      downloadObx: async () => {
        loading.value = true
        const brandMap = new Map<string, IPconInformation[]>()
        props.products?.forEach((product: IPconInformation) => {
          const manufacturerId = product.manufacturerId
          if (!brandMap.has(manufacturerId)) {
            brandMap.set(manufacturerId, [])
          }
          brandMap.get(manufacturerId)?.push(product)
        })

        const zip = new JSZip()
        const obxBrandMap = new Map<string, string>()

        for (const [key, inqProducts] of brandMap) {
          for (const product of inqProducts) {
            await addArticle(product)
          }
          const maybeObxUrl = await articleManager.value!.exportObx(articleManager.value!.getAllMainArticles())
          const res = await Axios.get(maybeObxUrl)
          obxBrandMap.set(key, res.data)
          await removeAllElements()
        }

        for (const [brandId, obxXmlString] of obxBrandMap) {
          zip.file(brandId + '.obx', obxXmlString)
        }

        const file = await zip.generateAsync({ type: 'blob' })

        saveAs(file, props.id + '.zip')
        emit('close')
        loading.value = false
      },
      loading,
    }
  },
})

export default ModalExportPconObx
</script>

<style>
.modal-product-detail .product-detail__info {
  grid-area: 2/1/3/3;
}
</style>
