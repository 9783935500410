<template lang="pug">
modal(size='base' data-test='modal-supplier-reporting' mode='drawer' admin @close='$emit("close")')
  modal-header
    span(v-html='$t("Supplier Reporting: <em>{0}</em>", "", [title])')
  tabs(v-model='tabsState')
    tab-pane#products(:title='$t("Products")')
      custom-data-table(size='small' :items='items' :config='config' class='mb-3')
        template(#image='{ value }')
          img(v-image-lazy :data-src='value' class='aspect-square w-[60px] object-contain' data-cloudinary='t_xs')
        template(#title='{ value }')
          b {{ value }}
        template(#id='{ value }')
          | {{ value }}
        template(#retailPriceNet='{ value }')
          span {{ value }}
      pagination(v-model='page' v-model:pageSize='pageSize' :total='total' class='mb-3')

    tab-pane#suppliers(:title='$t("Brands")')
      list(v-slot='{ items: supplierItems }' :endpoint='`/_/ProductsReporting/Supplier/${supplierId}/Brands`')
        custom-data-table(size='small' :items='supplierItems' :config='configSuppliers')
          template(#title='{ value }')
            b {{ value }}
          template(#id='{ value }')
            | {{ value }}
</template>

<script setup lang="ts">
import { type ITableConfig } from '@/components/Table/CustomDataTable.vue'
import { useProductSearch } from '@/pages/products/composables'
import { useTranslation } from '@/plugins/translation'
import { computed, ref } from 'vue'

const props = defineProps<{ supplierId: string; title?: string }>()

const { $t } = useTranslation()
const tabsState = ref('')
// used composable since product search differs from other filterable lists
const { list } = useProductSearch(false, 'productsSupplierReporting')
const { page, pageSize, items, total } = list
list.setProperty('suppliers', [props.supplierId])

const config = computed<ITableConfig>(() => ({
  image: { label: '', sortable: false, class: 'image w-0' },
  title: { label: $t('Title'), sortable: false },
  retailPriceNet: { label: $t('retailPriceNet'), sortable: false },
}))
const configSuppliers = computed<ITableConfig>(() => ({
  title: { label: $t('Name'), sortable: false },
  id: { label: $t('ID'), sortable: false },
}))
</script>
