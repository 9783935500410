import { useProjectsStore } from '../projects/store'
import { loadAllInquiries, loadInquiriesForProject } from '@/pages/inquiries/composables'
import { type IInquiry, type IExtendedInquiry } from '@/pages/inquiries/types'
import api from '@/pages/projects/api'
import { userDataReactive } from '@/plugins/User'
import { type IPconDTO } from '@/types'
import { defineStore } from 'pinia'
import { group, objectify, replaceOrAppend } from 'radash'
import { lastValueFrom } from 'rxjs'
import { computed, ref } from 'vue'

export const useInquiriesStore = defineStore('inquiries-store', () => {
  const inquiries = ref<IExtendedInquiry[]>([])
  const loading = ref<Record<string, boolean>>({})
  const activeInquiries = computed(() =>
    inquiries.value.filter((inquiry) => !inquiry.deleted && inquiry.status !== 'declinedWithChangeRequest'),
  )
  const activeInquiriesByProject = computed(() =>
    group(activeInquiries.value, (inquiry: IExtendedInquiry) => inquiry.projectId),
  )
  const allInquiriesAsMap = computed(() => objectify(inquiries.value, (inquiry: IExtendedInquiry) => inquiry.id))
  const projectsStore = useProjectsStore()
  return {
    inquiries,
    loading,
    activeInquiries,
    allInquiriesAsMap,
    sharedInquiries: computed(() =>
      activeInquiries.value.filter((p) => p.createdByUser.userId !== userDataReactive.id),
    ),
    ownInquiries: computed(() => activeInquiries.value.filter((p) => p.createdByUser.userId === userDataReactive.id)),
    deletedInquiries: computed(() => inquiries.value.filter((p) => p.deleted)),
    activeInquiriesByProject,
    setInquiry(inquiry: IInquiry) {
      inquiries.value = replaceOrAppend(inquiries.value, inquiry, (item) => inquiry.id === item.id)
      return inquiry
    },
    async loadInquiry(id: string) {
      loading.value[id] = true
      const inquiry = await lastValueFrom(api.loadInquiry(id))
      loading.value[id] = false
      return this.setInquiry(inquiry!)
    },
    async createInquiry(projectId: string, selectedProductIds: string[]) {
      loading.value['createInquiry'] = true
      const inquiry = lastValueFrom(api.createInquiry(projectId, selectedProductIds))
      loading.value['createInquiry'] = false
      return inquiry
    },
    async loadInquiriesForProject(id: string) {
      loading.value[id] = true
      const data = await lastValueFrom(loadInquiriesForProject(id))
      loading.value[id] = false
      data.forEach((inq) => this.setInquiry(inq))
      return data
    },
    async loadAllInquiries() {
      loading.value['all'] = true
      const data = await lastValueFrom(loadAllInquiries())
      loading.value['all'] = false
      inquiries.value = data
      return data
    },
    async deleteInquiry(inquiryId: string) {
      const { data } = await lastValueFrom(api.deleteInquiry(inquiryId))
      return this.setInquiry(data)
    },
    async revokeInquiry(inquiryId: string) {
      const { data } = await lastValueFrom(api.revokeInquiry(inquiryId))
      return this.setInquiry(data)
    },
    async declineInquiry(inquiryId: string, declineReason: string) {
      const { data } = await lastValueFrom(api.declineInquiry(inquiryId, declineReason))
      return this.setInquiry(data)
    },
    async requestInquiry(inquiryId: string) {
      const { data } = await lastValueFrom(api.requestInquiry(inquiryId))
      return this.setInquiry(data)
    },
    async acceptInquiry(inquiryId: string) {
      const { data } = await lastValueFrom(api.acceptInquiry(inquiryId))
      return this.setInquiry(data)
    },
    async requestChangesInquiry(inquiryId: string, declineReason?: string) {
      const { data } = await lastValueFrom(api.requestChangesInquiry(inquiryId, declineReason))
      return this.setInquiry(data)
    },
    async copyInquiry(inquiryId: string) {
      const { data } = await lastValueFrom(api.copyInquiry(inquiryId))
      return this.setInquiry(data)
    },
    async restoreInquiry(inquiryId: string) {
      const { data } = await lastValueFrom(api.restoreInquiry(inquiryId))
      return this.setInquiry(data)
    },

    async addProductsToInquiry(
      projectId: string,
      inquiryId: string,
      sourceProjectId: string | undefined,
      productIds: string[],
      index?: number,
    ) {
      loading.value['addProductsToInquiry'] = true
      const response = await lastValueFrom(
        api.addProductsToInquiry(projectId, inquiryId, sourceProjectId, productIds, index),
      )
      loading.value['addProductsToInquiry'] = false
      return response
    },
    async addPConProducts(projectId: string, inquiryId: string, pConData: IPconDTO[]) {
      const response = await api.addPConProducts(projectId, pConData, inquiryId)
      if (response.data.project) {
        projectsStore.setProject(response.data.project)
      }
      if (response.data.inquiry) {
        this.setInquiry(response.data.inquiry!)
      }
      return response.data
    },
    async addOrEditPconProduct(
      projectId: string,
      inquiryId: string = '',
      baseProductId: string = '',
      productId: string = '*',
      pconDTO: IPconDTO,
      name?: string,
    ) {
      loading.value['addOrEditPconProduct'] = true
      const { data, response } = await lastValueFrom(
        api.addOrEditPconProduct(
          projectId,
          baseProductId,
          inquiryId,
          productId,
          undefined,
          name || pconDTO.name!,
          pconDTO,
          undefined,
        ),
      ).finally(() => {
        loading.value['addOrEditPconProduct'] = false
      })
      if (response.valid) {
        if (data?.inquiry) {
          this.setInquiry(data.inquiry!)
        }
        if (data?.project) {
          projectsStore.setProject(data.project)
        }
        return data
      } else {
        return false
      }
    },
  }
})
