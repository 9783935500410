<template lang="pug">
div(class='relative flex flex-1 flex-col')
  overlay(:visible='loading')
  div(v-if='error')
    empty-state(type='warning' icon='custom-sofa-not-found' :centered='true' :title='$t("Product not found")' :message='$t("Product id wrong or product no longer available.")')
  component(:is='dynamicComponent' v-else)
</template>

<script lang="ts">
import { useOnSuccess } from '@/composables/'
import Axios from 'axios'
import { defineComponent, ref, onMounted, compile, watch } from 'vue'
import { type RenderFunction } from 'vue'

const ProductDetailContainer = defineComponent({
  props: {
    id: { type: String, required: true },
    hideSimilarProducts: Boolean,
    hideActions: Boolean,
  },
  setup(props) {
    const loading = ref(false)
    const dynamicComponent = ref<null | RenderFunction>(null)
    const error = ref(false)
    watch(
      () => props.id,
      (id: string) => update(id),
    )

    onMounted(() => update(props.id))
    useOnSuccess(['/Administration/Brands', '/Administration/Models', '/Administration/Products', 'AssetOrder'], () =>
      update(props.id),
    )

    const update = async (id: string) => {
      loading.value = true
      error.value = false
      try {
        const { data } = await Axios.get('/Products/' + id)
        dynamicComponent.value = compile(
          props.hideSimilarProducts || props.hideActions
            ? data.replace(
                '<product-detail-content',
                `<product-detail-content ${props.hideSimilarProducts ? 'hide-similar-products' : ''} ${
                  props.hideActions ? 'hide-actions' : ''
                }`,
              )
            : data,
        )
      } catch (e) {
        error.value = true
      } finally {
        loading.value = false
      }
    }
    return {
      loading,
      error,
      dynamicComponent,
    }
  },
})
export default ProductDetailContainer
</script>
