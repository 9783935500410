<template lang="pug">
teleport(to='#modal')
  transition-group(v-if='group' name='modals' @before-enter='onBeforeEnter' @after-leave='onAfterLeave')
    slot
  transition(v-else name='modals' @before-enter='onBeforeEnter' @after-leave='onAfterLeave')
    slot
</template>

<script setup lang="ts">
import { useModals } from '@/plugins/Modals'

const props = defineProps<{
  group?: boolean
  /**
   * If true body scroll will not be locked
   */
  enableBodyScroll?: boolean
}>()

const $modals = useModals()
const onBeforeEnter = (el: Element) => {
  $modals.register(el.querySelector('.modal')?.getAttribute('id') || '', !props.enableBodyScroll)
}
const onAfterLeave = (el: Element) => {
  $modals.unregister(el.querySelector('.modal')?.getAttribute('id') || '')
}
</script>
<style lang="stylus">
:root
  --modal-animation-duration: .2s

.DISABLE_TRANSITIONS
  --modal-animation-duration: 0

.modals-enter-active, .modals-leave-active
  transition: opacity var(--modal-animation-duration) ease!important

.modal
  transition: transform var(--modal-animation-duration) ease!important

.modals-enter-from, .modals-leave-to
  opacity: 0
  .modal--default
    transform: translateY(30px)
  .modal--drawer
    transform: translateX(30px)
</style>
