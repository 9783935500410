import { type App, type Plugin, type Ref, shallowRef, defineComponent, type PropType, inject } from 'vue'

export interface ITranslations {
  [key: string]: string
}
export type TranslateFn = (text: string, hint?: string, placeholders?: any[]) => string
export type AddTranslationsFn = (translations: ITranslations) => any
export type TranslateCountFn = (text: string, count: number) => string
export type Translations = Ref<ITranslations>

export const translations = shallowRef<ITranslations>({})
export const translationsReady = shallowRef(false)
export const addTranslationsFromModule = async (file: string) => {
  const translationsFromModule = await import(`../../locales/${file}.json`)
  translations.value = { ...translations.value, ...translationsFromModule.default }
  translationsReady.value = true
}
export const addTranslations: AddTranslationsFn = (newTranslations: ITranslations) => {
  translations.value = { ...translations.value, ...newTranslations }
}

const replacePlaceholders = (text: string, placeholders: string[]) => {
  let i: number = 0
  return text.replace(/\{(.*?)\}/g, () => placeholders[i++])
}

export const Translation = defineComponent({
  props: {
    translationKey: { type: String, required: true },
    placeholders: Array as PropType<string[]>,
  },
  template: `<div v-html="$t(translationKey, placeholders)></div>`,
})

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $t: TranslateFn
    $tc: TranslateCountFn
    $translationsReady: Ref<boolean>
    add: AddTranslationsFn
  }
}

export const useTranslation = () => {
  return {
    $t: inject<TranslateFn>('$t')!,
    $tc: inject<TranslateCountFn>('$tc')!,
    $translationsReady: translationsReady,
    add: addTranslations,
  }
}

export const translateCount = (key: string, count: number) => {
  const options = (translations.value[key] || key).split(' | ')
  return (options[count] || options[options.length - 1]).replace('{0}', count.toString())
}
export const translate: TranslateFn = (key: string, hint?: string, placeholders?: string[]) => {
  return placeholders
    ? replacePlaceholders(translations.value[key] || key, placeholders)
    : translations.value[key] || key
}

const TranslationPlugin: Plugin = {
  install(app: App) {
    addTranslationsFromModule('de-DE')
    app.config.globalProperties.$t = translate
    app.config.globalProperties.$tc = translateCount
    app.config.globalProperties.$translationsReady = translationsReady
    app.provide<TranslateFn>('$t', translate)
    app.provide<TranslateCountFn>('$tc', translateCount)
  },
}

export default TranslationPlugin
