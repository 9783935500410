import { useList } from '@/components/List'
import { type ISearchAttribute, type ISearchAttributesGroups, type ISearchAttributeMap } from '@/components/List/types'
import { useSearchAttributes } from '@/composables/'
import { type IOption, type IProductSearchItem } from '@/types'
import { parseJSONWithFallback } from '@/utilities'
import { flattenTree, type IFlatTreeItem, type IFlatTreeItemMap } from '@/utilities/TreeUtils'
import { useLocalStorage } from '@vueuse/core'
import { pick, objectify, mapEntries } from 'radash'
import { isArray, isEmpty } from 'radash'
import { computed, type ComputedRef, ref } from 'vue'

export const attributesToOptions = (attributes: ISearchAttribute[]) => attributes.map(attributeToOption)
export const attributeToOption = (a: ISearchAttribute) =>
  ({ value: a.id, label: a.title, data: a }) as IOption<ISearchAttribute>

const toNestedSearchAttributeMap = (map: ISearchAttributesGroups) =>
  Object.fromEntries(
    Object.entries(map).map(([attributeKey, item]) => [attributeKey, objectify(item.values, (item) => item.id)]),
  ) as { [attributeKey: string]: ISearchAttributeMap }

export const transformProductSearchParams = (filterableProperties: string[]) => (properties: Record<string, any>) => ({
  q: properties.term as string, // set term to q
  from: (properties.page - 1) * properties.pageSize,
  sort: properties.sort,
  size: parseInt(properties.pageSize),
  active: parseJSONWithFallback(properties.active, 'true'),
  attributes: Object.entries(pick(properties, filterableProperties))
    .filter(([, values]) => values && values.length > 0)
    .map(([name, values]) => ({
      name,
      values: (isArray(values) ? values : [values]) as string[],
    })),
})

export type SearchAttributeCountMap = Record<string, Record<string, number>>

export const useProductSearch = (useRouteForProperties = true, id = 'Products') => {
  const pageSizes = [36, 72, 144, 288]

  const filterableProperties = [
    'brands',
    'suppliers',
    'materials',
    'categories',
    'colors',
    'width',
    'height',
    'depth',
    'price',
  ]
  const list = useList<IProductSearchItem>(
    id,
    '/Products',
    pageSizes,
    { active: 'true', irrelevant: false, sort: '_score' },
    'term',
    // transform params for endpoint/elastic search
    transformProductSearchParams(filterableProperties),
    // transform attributes response to key/value schema
    (data, properties) => {
      const hasTermAndNoResults = !!properties.term && data.hits.length === 0
      searchResponseAttributes.value = hasTermAndNoResults
        ? mapEntries(initialAttributes.value, (attributeName, searchAttribute) => [attributeName, searchAttribute])
        : objectify(data.attributes!, (attributes) => attributes.name)
    },
    undefined,
    undefined,
    undefined,
    true,
    useRouteForProperties,
  )

  const searchTerm = computed({
    get: () => list.properties.value.term as string,
    set: (value: string) => list.setProperty('term', value),
  })
  const { initialAttributes, attributesReady, loadAttributes } = useSearchAttributes('/Products/Attributes')
  const searchResponseAttributes = ref<ISearchAttributesGroups>({})
  const searchAttributesAsMaps = computed<{ [attributeKey: string]: ISearchAttributeMap }>(() =>
    toNestedSearchAttributeMap(searchResponseAttributes.value),
  )

  const attributes = computed<ISearchAttributesGroups>(() =>
    !attributesReady.value || isEmpty(searchResponseAttributes.value)
      ? initialAttributes.value
      : mapEntries(initialAttributes.value, (attributeKey, { title, values }) => [
          attributeKey,
          {
            name: attributeKey as string,
            title: title as string,
            values: values
              .filter((attribute) => (searchAttributesAsMaps.value[attributeKey]?.[attribute.id]?.count || 0) > 0)
              .map((attribute: ISearchAttribute) => ({
                ...attribute,
                ...(searchAttributesAsMaps.value[attributeKey]?.[attribute.id] || {}),
              })),
          },
        ]),
  )

  const categoriesAsMap = computed<IFlatTreeItemMap<ISearchAttribute>>(() =>
    initialAttributes.value?.categories && initialAttributes.value.categories.values.length > 0
      ? flattenTree<ISearchAttribute>(initialAttributes.value.categories.values)
      : {},
  )

  // load attributes if attributes have not already been loaded from session storage
  loadAttributes()
  if (isEmpty(initialAttributes.value)) {
    loadAttributes()
  } else {
    attributesReady.value = true
  }

  const currentCategoryId: ComputedRef<string> = computed<string>(() => list.properties.value.categories as string)
  const currentCategory = computed<IFlatTreeItem<any> | undefined>(() =>
    currentCategoryId.value ? categoriesAsMap.value[currentCategoryId.value] : undefined,
  )

  const viewSettings = useLocalStorage<'default' | 'large'>('product-search-view-settings', 'default')
  return {
    list,
    viewSettings,
    searchTerm,
    attributesReady,
    attributes,
    initialAttributes,
    searchResponseAttributes,
    categoriesAsMap,
    currentCategory,
    currentCategoryId,
    counts: computed<SearchAttributeCountMap>(() =>
      mapEntries(searchResponseAttributes.value, (key, group) => [
        key,
        objectify(
          group.values,
          (item) => item.id,
          (item) => item.count!,
        ),
      ]),
    ),
    availables: computed<SearchAttributeCountMap>(() =>
      mapEntries(searchResponseAttributes.value, (key, group) => [
        key,
        objectify(
          group.values,
          (item) => item.id,
          (item) => item.available!,
        ),
      ]),
    ),
    pageSizes,
    hasActiveFilterableProperties: computed(
      () =>
        Object.entries(list.properties.value).filter(
          ([key, value]) => filterableProperties.includes(key) && !!value && (value as string[]).length > 0,
        ).length > 0,
    ),
    clearAllActiveProperties: () => list.clearProperties(filterableProperties),
    hasResults: computed(() => list.items.value && list.items.value.length > 0),
  }
}
