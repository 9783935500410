import { type Directive, type DirectiveBinding } from 'vue'

export interface IInnerElementConfig {
  [selector: string]: {
    [attribute: string]: string
  }
}

const updateAttribute = (container: HTMLElement, config: IInnerElementConfig) => {
  Object.entries(config).forEach(([selector, attributeAndValue]) => {
    const element: HTMLElement | null = container.querySelector(selector)
    if (element) {
      Object.entries(attributeAndValue).forEach(([attribute, value]) => element.setAttribute(attribute, value))
    }
  })
}

const AddElementDirective: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding): void {
    updateAttribute(el, binding.value as IInnerElementConfig)
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    updateAttribute(el, binding.value as IInnerElementConfig)
  },
}

export default AddElementDirective
