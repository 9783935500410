import { useListObject } from '@/components/List'
import { useOnSuccess, useSearchAttributes } from '@/composables/'
import { transformInquiryGroups } from '@/pages/inquiries/composables'
import { type IInquiry } from '@/pages/inquiries/types'
import { type SetupContext } from 'vue'

export const useInquirySearch = (
  context: SetupContext,
  query: Record<string, string> = {},
  pageSizes = [50],
  additionalProperties: Record<string, any> = {},
) => {
  const list = useListObject<IInquiry>({
    id: 'inquiries-user',
    context,
    pageSizes,
    query,
    endpoint: '/InquirySearch',
    additionalProperties: { deleted: ['false'], sort: 'recentStateChangeDateInstant_desc', ...additionalProperties },
    transformItems: transformInquiryGroups,
  })

  // @TODO: move this to store
  useOnSuccess(['UpdateTitle', 'Revoke', 'Request', 'SendChangeRequest', 'SendDecline'], (response) =>
    list.replaceItem(response.data),
  )
  useOnSuccess(['Delete', 'Restore'], (response) => list.removeItem(response.data))
  useOnSuccess(['CopyInquiry', 'CreateInquiry'], (response) => list.addItem(response.data))

  const searchAttributes = useSearchAttributes('/_/Inquiry/Attributes')
  searchAttributes.loadAttributes()

  return {
    ...list,
    ...searchAttributes,
  }
}
