<template lang="pug">
modal(size='base' data-test='modal-brand-reporting' mode='drawer' @close='$emit("close")')
  modal-header {{ $t('Brand Reporting: {0}', '', [title]) }}
  tabs(v-model='tabsState')
    tab-pane#products(:title='$t("Products")')
      custom-data-table(size='small' :items='items' :config='config')
        template(#image='{ value }')
          img(v-image-lazy :data-src='value' class='aspect-square w-[60px] object-contain' data-cloudinary='t_xs')
        template(#title='{ value }')
          b {{ value }}
        template(#id='{ value }')
          | {{ value }}
        template(#retailPriceNet='{ value }')
          span {{ value }}

    tab-pane#suppliers(:title='$t("Suppliers")')
      list#BrandSuppliersList(v-slot='{ items }' :endpoint='`/_/ProductsReporting/Brand/${brandId}/Suppliers`')
        custom-data-table(size='small' :items='items' :config='configSuppliers')
          template(#name='{ value }')
            b {{ value }}
          template(#id='{ value }')
            | {{ value }}
          template(#xentralId='{ value }')
            span {{ value }}
</template>

<script lang="ts">
import { type ITableConfig } from '@/components/Table/CustomDataTable.vue'
import { useProductSearch } from '@/pages/products/composables'
import { useTranslation } from '@/plugins/translation'
import { computed, defineComponent, ref } from 'vue'

const ModalBrandReporting = defineComponent({
  inheritAttrs: false,
  props: {
    brandId: { type: String, required: true },
    title: String,
  },
  setup(props) {
    const { $t } = useTranslation()
    const tabsState = ref('')
    // used composable since product search differs from other filterable lists
    const { list } = useProductSearch(false, 'productsBrandReporting')
    list.setProperty('brands', [props.brandId])
    return {
      config: computed<ITableConfig>(() => ({
        image: { label: '', sortable: false, class: 'image w-0' },
        title: { label: $t('Title'), sortable: false },
        retailPriceNet: { label: $t('retailPriceNet'), sortable: false },
      })),
      configSuppliers: computed<ITableConfig>(() => ({
        name: { label: $t('Name'), sortable: false },
        id: { label: $t('ID'), sortable: false },
        xentralId: { label: $t('Xentral ID'), sortable: false },
      })),
      ...list,
      // @TODO brauchen wir den endpoint überhaupt
      endpoint: computed(() => `/_/ProductsReporting/Brand/${props.brandId}/Products`),
      tabsState,
    }
  },
})

export default ModalBrandReporting
</script>
