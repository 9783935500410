<template lang="pug">
div
  div(class='slider-container')
    div(:id='id' class='splide')
      div(class='splide__arrows')
        btn(v-show='totalSlides > 1' plain icon='chevron-left' class='splide__arrow splide__arrow--prev slider-prev')
        btn(v-show='totalSlides > 1' plain icon='chevron-right' class='splide__arrow splide__arrow--next slider-next')
      div(class='splide__track')
        ul(class='splide__list')
          slot
    slot(name='below' :slideTo='slideTo' :index='index' :totalSlides='totalSlides')
</template>

<script lang="ts">
import Btn from '@/components/Btn.vue'
import Splide, { type Options } from '@splidejs/splide'
import '@splidejs/splide/css/core'
import { defineComponent, ref, onMounted, nextTick, onUnmounted, type PropType } from 'vue'

export const defaultOptions: Options = {
  type: 'loop',
  gap: 20,
  arrows: true,
  autoHeight: true,
  lazyLoad: 'nearby',
  pagination: true,
  reducedMotion: false as any,
}

export const Slide = { template: '<div class="splide__slide">awfwaf<slot></slot></div>' }

const Slider = defineComponent({
  components: { Btn },
  props: {
    options: Object as PropType<Options>,
    navigation: Boolean,
  },
  setup(props) {
    let slider: Splide | null = null
    const index = ref(0)
    const totalSlides = ref(0)
    const disabled = ref(false)
    const id = `slider-${Math.round(Math.random() * 10000)}`

    onMounted(() =>
      nextTick(() => {
        slider = new Splide(`#${id}`, { ...defaultOptions, ...props.options }).mount()
        totalSlides.value = (slider?.Components?.Elements as any)?.slides.length || 0
        slider.on('move', (i: number) => (index.value = i))
      }),
    )

    onUnmounted(() => slider?.destroy())
    return {
      id,
      index,
      totalSlides,
      disabled,
      slideTo: (i: number) => slider?.go(i),
    }
  },
})
export default Slider
</script>
<style lang="stylus">
@import '../styles/variables.styl'
:root
  --slider-dot-size: 8px
  --slider-arrow-offset: 0px
  @media (min-width: $breakpoint-md)
    --slider-arrow-offset: 10px
.slider-container
  position: relative
  overflow: hidden

.slider-next, .slider-prev
  position: absolute!important
  top: 50%
  transform: translateY(-50%)
  z-index: 1
  cursor: pointer
  font-size: 24px!important

.slider-prev
  left: var(--slider-arrow-offset)
.slider-next
  right: var(--slider-arrow-offset)

.splide__track
  margin-bottom: 5px

.splide__pagination
  position: relative
  display: flex
  justify-content: center
  align-items: center
  transform: none
  left: initial
  bottom: initial
  width: 100%
  margin-top: 0!important
  padding: 0 0 10px 0
  li
    margin-top: 0!important
  &__page
    background: var(--color-text-extralight)
    position: relative
    margin: 0 8px
    width: var(--slider-dot-size)
    height: var(--slider-dot-size)
    border-radius: 5px
    &:before
      content: ''
      display: block
      position: absolute
      width: 30px
      height: 30px
      top: -12px
      left: -12px
      opacity: 0.5
    &.is-active
      background: var(--color-text)
</style>
