<template lang="pug">
modal-container(group)
  modal-confirm(v-for='modal in modals' :id='modal.key' :key='modal.key' :ref='modal.key' :options='modal.options' :modelValue='true' @close='close')
</template>

<script setup lang="ts">
import ModalContainer from './ModalContainer.vue'
import type { IConfirmOptionsItem, CloseConfirmFn } from '@/plugins/Confirm'
import { inject } from 'vue'

const modals = inject<IConfirmOptionsItem[]>('modals')
const close = inject<CloseConfirmFn>('closeConfirmModal')!
</script>
