<template lang="pug">
div(class='btn-group')
  slot
</template>

<script lang="ts">
export default {}
</script>
<style lang="stylus">
.btn-group
  display: inline-grid
  border-radius: 20px
  grid-auto-flow: column
  grid-gap: 0px
  position: relative
  > *:not(:first-child) .btn
    border-top-left-radius: 0
    border-bottom-left-radius: 0
  mix-blend-mode: multiply
  .btn
    mix-blend-mode: unset
    &:hover
      z-index: 1
  &[vertical]
    width: 100%
    display: flex
    flex-flow: column
    .btn
      width: 100%
      justify-content: flex-start
      --btn-border-radius: 8px
      &:not(:first-child)
        border-top-left-radius: 0
        border-top-right-radius: 0
        margin-top: -1px
      &:not(:last-child)
        border-bottom-left-radius: 0
        border-bottom-right-radius: 0
  &:not([vertical]) .btn
    &:not(:first-child)
      border-top-left-radius: 0
      border-bottom-left-radius: 0
      margin-left: -1px
    &:not(:last-child)
      border-top-right-radius: 0
      border-bottom-right-radius: 0
</style>
