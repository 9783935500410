<template lang="pug">
slider(:navigation='images.length > 1' :options='sliderConfig')
  div(v-for='item in images' :key='item' class='splide__slide')
    slot(name='item' :item='item')
  template(#below='{ slideTo, index }')
    div(v-if='images.length > 1' class='grid grid-cols-[repeat(auto-fill,minmax(60px,0.5fr))] gap-2')
      btn-image(v-for='(srcOrId, i) in images' :key='i' :src='srcOrId' :active='index === i' @click='() => slideTo(i)')
</template>

<script lang="ts">
import BtnImage from '@/components/BtnImage.vue'
import Slider from '@/components/Slider.vue'
import { type Options } from '@splidejs/splide'
import { defineComponent, type PropType } from 'vue'

const SliderImages = defineComponent({
  components: { BtnImage, Slider },
  props: {
    value: Number,
    images: { type: Array as PropType<string[]>, required: true },
  },
  setup() {
    return {
      sliderConfig: {
        gap: 30,
        rewind: true,
        pagination: false,
        perPage: 1,
      } as Options,
    }
  },
})
export default SliderImages
</script>
