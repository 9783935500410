<template lang="pug">
div(v-bind='attributes' class='radio-icon')
  div(class='radio-icon__inner')
</template>

<script lang="ts">
import { shakeFalsy } from '@/utilities'
import { defineComponent, computed } from 'vue'

const RadioIcon = defineComponent({
  props: {
    value: Boolean,
    disabled: Boolean,
  },
  setup(props) {
    return {
      attributes: computed(() =>
        shakeFalsy({
          'data-disabled': props.disabled,
          'data-checked': props.value,
        }),
      ),
    }
  },
})

export default RadioIcon
</script>

<style lang="stylus">
@import '../../styles/variables.styl'
:root
  --radio-border-color: #ddd
  --radio-bg-color: #fff
  --radio-focus-shadow: none
  --radio-bg-image: none
  --radio-border-width: 2px

.radio-icon
  display: inline-flex
  justify-content: center
  align-items: center
  &__inner
    flex: 1
    width: 20px
    height: 20px
    border: var(--radio-border-width) solid var(--radio-border-color)
    background-color: var(--radio-bg-color)
    background-image: var(--radio-bg-image)
    border-radius: 20px
    background-size: 14px 14px
    background-position: center center
    box-shadow: var(--radio-focus-shadow)
    transition: border .1s ease
    display: inline-flex
    justify-content: center
    align-items: center
    &:after
      content: ''
      display: block
      width: calc(100%)
      height: calc(100%)
      border: 3px solid #fff
      border-radius: 20px

  &[data-indeterminate],
  &[data-checked]
    --radio-border-color: var(--color-accent)
    --radio-bg-color: var(--color-accent)
  &:focus-within, &:hover, :focus + label &, button:hover &, label:hover &
    --radio-focus-shadow: 0 0 0 6px rgba($color-accent, .1)
    --radio-border-color: var(--color-accent)
  // &[data-checked]
  //   --radio-border-width: 6px
  &[data-disabled]
    --radio-cursor: unset!importnant
    --radio-label-color: var(--color-text-light)!important
    --radio-border-color: var(--color-text-extralight)!important
    --radio-bg-color: var(--color-text-extralight)!important
    --radio-focus-shadow: none!important
</style>
