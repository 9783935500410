<template lang="pug">
.control-textarea(:data-disabled='disabled' :data-autoresize="autoresize")
  textarea(
    :id='id'
    v-model='val'
    :placeholder='placeholder'
    :disabled='disabled'
    :required='required'
    @input='onChange'
    @keyup.enter='onSubmit'
    @focus='onFocus'
    @blur='onBlur'
    )
  .control-textarea__placeholder(v-if='autoresize') {{ val }}

  </template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { Input, useControl } from './composables'

const ControlTextarea = defineComponent({
  props: {
    ...Input.props,
    color: {
      type: String,
      default: 'white',
    },
    placeholder: String,
    minHeight: { type: Number, default: 80 },
    autoresize: {
      type: Boolean,
      default: true,
    },
  },
  setup(props: any, context: any) {
    const {
      value: val,
      classes,
      focus,
      hasFocus,
      onFocus,
      onChange,
      onSubmit,
      onBlur,
      isEmpty,
    } = useControl<string>(props, context, '')

    return {
      isEmpty,
      onFocus,
      onBlur,
      onChange,
      onSubmit,
      focus,
      hasFocus,
      val,
      classes: computed(() => ({ ...classes.value, [`control--${props.color}`]: true })),
    }
  },
})

export default ControlTextarea
</script>

<style lang="stylus">
@import '../../styles/variables.styl'

:root 
  --control-bg-color: #fff
  --control-textarea-min-height: 80px
  
.control-textarea
  display: inline-flex
  width: 100%
  align-items: center
  position: relative
  margin-bottom: 3px
  background: var(--control-bg-color)
  border: var(--control-border-width) solid var(--control-border-color)
  border-radius: var(--control-border-radius)
  &[grey]
    --control-bg-color: var(--grey-75)
    --control-border-color: var(--grey-75)
    &:hover:not([data-disabled])
      --control-bg-color: var(--grey-100)
      --control-border-color: var(--grey-100)
    &:focus,
    &:focus-within
      --control-bg-color: white
  &[data-disabled="true"]
    --control-bg-color: var(--grey-75)
    --control-text-color: var(--color-text-light)
    --control-border-color: var(--grey-75)
  &:hover:not([data-disabled])
    --control-border-color: var(--grey-200)
  [error] &, &[error]
    &, &:hover
      --control-border-color: var(--color-danger)
  &:focus,
  &:focus-within
    --control-border-color: var(--color-accent)!important
  &__placeholder
    pointer-events: none
    z-index: -1
    &:after
      content: ' '
  textarea,
  &__placeholder
    min-height: var(--control-textarea-min-height)
    height: auto
    border: none
    padding-top: calc(var(--control-padding-t) + 10px)
    padding-left: var(--control-padding-x)
    padding-right: var(--control-padding-x)
    padding-bottom: 5px
    line-height: 1.325
    letter-spacing: 0
    white-space: pre-wrap
    word-break: break-word
    font-size: var(--control-font-size)
    width: 100%
    font-family: $font-default

  textarea
    ~/[data-autoresize="true"]&
      position: absolute
      top: 0
      left: 0
      height: 100%
      resize: none
    background: var(--control-bg-color)
    color: var(--control-text-color)
    flex: 1
</style>
