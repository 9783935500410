<template lang="pug">
div(class='pill' :class='classes')
  icon(v-if='icon' :name='icon' class='pill__icon')
  span
    slot {{ message }}
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue'
import { computed } from 'vue'

export type PillColorOptions =
  | 'blue'
  | 'success'
  | 'error'
  | 'warning'
  | 'secondary'
  | 'accent'
  | 'fuchsia'
  | 'purple'
  | 'faded'
  | 'orange'
  | 'outlined'

const props = withDefaults(
  defineProps<{
    type?: PillColorOptions
    icon?: string
    message?: string
    outlined?: boolean
    iconRight?: boolean
    block?: boolean
  }>(),
  { type: 'faded' },
)

const classes = computed(() => ({
  [`pill--${props.type}`]: true,
  'pill--iconRight': props.iconRight,
  'pill--block': props.block,
  'pill--outlined': props.outlined,
}))
</script>
<style lang="stylus">
@import '../styles/variables.styl'

:root
  --pill-color-bg: $color-manatee
  --pill-color-text: $color-white
  --pill-icon-margin: -1px 4px -2px -2px
.pill
  border-radius: 20px
  background: var(--pill-color-bg)
  padding: 4px 6px
  font-size: 13px
  font-weight: 400
  line-height: 1
  font-size: 13px
  min-width: 20px
  letter-spacing: -0.0025em
  display: inline-flex
  -webkit-font-smoothing: auto
  align-items: center
  justify-content: center
  font-family: $font-default
  b
    font-weight: 800
  > span
    ~/[ellipsis]&
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
  &, > *
    color: var(--pill-color-text)
  &__icon
    font-size: 14px
    stroke-width: 2.5px
    margin: var(--pill-icon-margin)
  &--iconRight
    flex-direction: row-reverse
    --pill-icon-margin: -2px -4px -4px 2px
  &--block
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    --pill-icon-margin: -2px 4px -2px 0
  &--faded
    --pill-color-bg: var(--grey-100)
    --pill-color-text: var(--grey-500)
    ~/--outlined&
      --pill-color-bg: var(--grey-300)
      --pill-color-text: var(--grey-400)
  &--blue
    --pill-color-bg: var(--blue-300)
  &--success
    --pill-color-bg: var(--green-400)
  &--error
    --pill-color-bg: var(--red-400)
  &--warning
    --pill-color-bg: var(--yellow-300)
    --pill-color-text: var(--yellow-900)
  &--secondary
    --pill-color-bg: var(--grey-700)
  &--accent
    --pill-color-bg: var(--color-accent)
  &--fuchsia
    --pill-color-bg: #dd87d5
  &--purple
    --pill-color-bg: var(--purple-400)
  &--orange
    --pill-color-bg: var(--yellow-400)
  &--outlined
    background: transparent!important
    border: 1px solid var(--pill-color-bg)
    --pill-color-text: var(--pill-color-bg)
</style>
