<template lang="pug">
component(:is='htmlTag' ref='element' :class='classes' v-bind='attributes' :type='htmlType' :href='href' class='btn')
  spinner(v-if='loading' class='btn__icon')
  icon(v-else-if='icon' :name='currentIcon' class='btn__icon')
  slot
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue'
import Spinner from '@/components/Spinner.vue'
import { hasSlotContent } from '@/utilities/Vue'
import { shake } from 'radash'
import { computed, type PropType, defineSlots } from 'vue'
import { ref } from 'vue'
import { onMounted } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

// FOR DEBUGGING
// import { onUpdated, onRenderTriggered } from 'vue'
// onUpdated(() => console.log('update Btn'))
// onRenderTriggered(() => console.log('onRenderTriggered: Btn'))

const props = defineProps({
  htmlType: { type: String as PropType<'button' | 'submit'>, default: 'button' },
  disabled: { type: Boolean, default: false },
  to: [Object, String] as PropType<RouteLocationRaw>,
  active: Boolean,
  loading: { type: Boolean, default: false },
  iconOnly: Boolean, // @TODO: get rid of this
  icon: String,
  href: String,
  htmlTag: String,
  autofocus: Boolean,
})

const slots = defineSlots<{
  default(): any
}>()

const element = ref<HTMLButtonElement | null>(null)
if (props.autofocus) {
  onMounted(() => setTimeout(() => (document.body.classList.remove('using-mouse'), element.value?.focus()), 100))
}

const htmlTag = computed(() => props.htmlTag || (props.href ? 'a' : props.to ? 'router-link' : 'button'))
const currentIcon = computed<string>(() => (props.loading ? 'loading' : props.icon || ''))
const attributes = computed(() =>
  shake(
    {
      disabled: props.disabled,
      to: props.to,
    },
    (v) => !v,
  ),
)
const classes = computed(() => ({
  active: props.active,
  'btn--iconOnly': props.iconOnly || !hasSlotContent(slots.default),
  'btn--withIcon': props.loading || props.icon,
}))
</script>
<style lang="stylus">
@import '../styles/variables.styl'
@import '../styles/btn.styl'
</style>
