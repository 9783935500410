import { useFeatureFlags } from '@/plugins/FeatureFlags'
import { useTranslation } from '@/plugins/translation'
import { type IPconDTO, type IPconInformation } from '@/types'
import { Color4, Vector3 } from '@babylonjs/core'
import * as cf from '@easterngraphics/wcf/modules/cf'
import { ArticleManager } from '@easterngraphics/wcf/modules/cf'
import * as core from '@easterngraphics/wcf/modules/core'
import { CameraControl } from '@easterngraphics/wcf/modules/core/view/CameraControl'
import * as eaiws from '@easterngraphics/wcf/modules/eaiws'
import * as basket from '@easterngraphics/wcf/modules/eaiws/basket'
import { CatalogItem, ArticleCatalogItem } from '@easterngraphics/wcf/modules/eaiws/catalog'
import { wcfConfig } from '@easterngraphics/wcf/modules/utils'
import { createGlobalState } from '@vueuse/core'
import Axios, { type AxiosResponse } from 'axios'
import { computed, ref } from 'vue'

export const DEFAULT_PRICING_PROCEDURE = 'STDB2B_WBK'
export const session: eaiws.EaiwsSession = new eaiws.EaiwsSession()

let sessionId = ''

/**
 * Provides a reusable pCon-session.
 * @returns {session, loading, init()}
 */
export const usePconSession = () => {
  const loading = ref(false)
  const gatekeeperId = '63ca3e30dca56_wcf'
  return {
    session,
    loading,
    init: async () => {
      // abort if sessionId already exists
      if (sessionId) {
        return sessionId
      }
      loading.value = true
      const { data: gatekeeperResponse } = await Axios.post<{
        keepAliveInterval: number
        server: string
        sessionId: string
      }>('https://eaiws-server.pcon-solutions.com/v3/session/' + gatekeeperId)
      session.connect(
        gatekeeperResponse.server,
        gatekeeperResponse.sessionId,
        gatekeeperResponse.keepAliveInterval * 1000, // keep alive is given in seconds
      )
      // 'en' is required because not all manufacturers provide 'de'-translations
      await session.catalog.setLanguages(['de', 'en'])
      await session.basket.setLanguages(['de', 'en'])
      loading.value = false
      sessionId = gatekeeperResponse.sessionId
      return gatekeeperResponse.sessionId
    },
  }
}

/**
 * Initializes all relevant pCon-components such as the (core.)Application, ArticleManager, BasketView.
 * @returns
 */
export const usePconApplication = () => {
  const { session, init: initSession } = usePconSession()
  const loading = ref(false)
  const coreApp = new core.Application()
  const { $t } = useTranslation()
  coreApp.applicationName = 'NUUCON'
  coreApp.applicationVersion = '1.0.0'
  wcfConfig.dataPath = '/w-cf/data/'
  const articleManager = ref<ArticleManager | null>(null)
  const priceNet = ref(0)
  const data = computed<IPconDTO | null>(() => null)
  let mainArticleElements: cf.MainArticleElement[] = []
  const basketViewConfig = ref<basket.BasketViewConfig>()

  /**
   * Creates a new BasketViewConfig and adds it to the session basket.
   * @returns basketViewConfigConfig
   */
  const InitBasketViewConfig = async () => {
    const basketViewMergedConfig = new basket.BasketViewConfig()
    basketViewMergedConfig.name = 'merged main and subarticles'
    basketViewMergedConfig.displayMode = 'Sorted'
    basketViewMergedConfig.mergeMode = 'ArticlesCompact'
    return await session.basket.addBasketView(basketViewMergedConfig)!
  }

  const init = async () => {
    loading.value = true
    await initSession()
    basketViewConfig.value = await InitBasketViewConfig()

    try {
      // try catch to prevent error when calculation was already added
      await session.basket.addPriceCalculation(DEFAULT_PRICING_PROCEDURE)
    } catch (e) {}

    coreApp.initialize(document.getElementById('viewer') as HTMLDivElement)
    if (coreApp.viewer) {
      coreApp.viewer.backgroundColor = new Color4(0, 0, 0, 0)
    }
    articleManager.value = new ArticleManager(coreApp, session)
    await articleManager.value.initializeSession({ oapPlanningMode: false })
    articleManager.value.setGfjBasketIdsEnabled(true)
  }

  const clearBasket = async () => {
    const allItems = await session.basket.getAllItems()
    await session.basket.deleteItems(allItems.map((item) => item.itemId))
    return true
  }

  const removeAllElements = async () => {
    while (coreApp.model.elements.length > 0) {
      coreApp.model.removeElement(coreApp.model.elements[0])
    }
    await articleManager.value!.synchronizeSession(false) // we need to tell the server, that we deleted items
  }
  /**
   * Imports articles from Obx file to ArticleManager and ModelManager.
   * @param pObxData
   */
  const importFromObx = async (pObxData: ArrayBuffer | Blob) => {
    await removeAllElements()
    await clearBasket()
    const elements = await articleManager.value!.importObx(pObxData, {
      shouldUpdateCallback: () => true,
      importArticlesWithoutPosition: true,
    })
    elements.forEach((element) => {
      coreApp.model.addElement(element)
    })
    coreApp.model.setSelection(elements)
  }
  /**
   * Imports article from either obxHash or OFML-params to ArticleManager and ModelManager.
   * @returns
   */
  const addArticle = async ({
    baseArticleNumber,
    manufacturerId,
    seriesId,
    variantCode,
    ofmlVariantCode = '',
    obxHash,
  }: IPconInformation) => {
    loading.value = true
    if (obxHash) {
      const blob = await getObxFile(obxHash)
      // import obxfile into articelManager
      const elements = await articleManager.value!.importObx(blob)
      console.log('usePconApplication.addArticle.elements', elements)
      if (!elements.length) {
        // create element if not provided
        // this is the case with products that have been exported from the pcon basket
        const basketItems = await session.basket.getAllItems()
        // make sure folder item is not added, otherwise multiple scene elements will be added
        const firstNonFolderBasketItem = basketItems.filter((item) => item.itemType !== 'Folder')[0]
        mainArticleElements = (await articleManager.value?.createArticlesById(
          [firstNonFolderBasketItem.itemId],
          undefined,
          basketItems,
        )) as cf.MainArticleElement[]
      } else {
        mainArticleElements = elements as cf.MainArticleElement[]
      }
    } else {
      const insertInfo = new basket.InsertInfo()
      insertInfo.baseArticleNumber = baseArticleNumber
      insertInfo.variantCode = variantCode
      insertInfo.manufacturerId = manufacturerId
      insertInfo.seriesId = seriesId
      insertInfo.ofmlVariantCode = ofmlVariantCode
      const element = await articleManager.value!.insertArticle(insertInfo)
      mainArticleElements = [element]
    }
    if (mainArticleElements.length) {
      // we need to add it also to the model manager, or we wont see the new article
      mainArticleElements.forEach((e) => coreApp.model.addElement(e))
      coreApp.model.setSelection(mainArticleElements)
    } else {
      console.warn('element not found for ', baseArticleNumber)
    }
    loading.value = false
    return mainArticleElements
  }

  /**
   * Returns PriceCalculationSheet with price and quantity information.
   * @param itemIds - BasketItemIds
   * @param viewId - BasketViewId
   * @returns
   */
  const getPriceCalculationSheet = async (itemIds?: string[], viewId?: string) => {
    const pricingOptions = new basket.GetPriceCalculationSheetOptions()
    pricingOptions.viewId = viewId
    if (!itemIds) {
      const options = new basket.GetAllItemsOptions()
      options.viewId = viewId
      itemIds = (await session.basket.getAllItems(undefined, options)).map((item) => item.itemId)
    }
    const pricingSheet = await session.basket.getPriceCalculationSheet(
      itemIds!,
      DEFAULT_PRICING_PROCEDURE,
      pricingOptions,
    )
    return pricingSheet
  }

  const updatePriceNet = async () => {
    const pricingSheet = await getPriceCalculationSheet()
    priceNet.value = pricingSheet.netValue?.value || 0
  }

  const getGeneratedImage = async () =>
    await session.basket.getGeneratedImage(articleManager.value!.getAllMainArticles()[0].basketId!, [])

  /**
   * Fetches obx files from backend.
   * @param hash
   * @returns Obx file as blob
   */
  const getObxFile = (hash: string) =>
    Axios.get(`/_/PCon/Obx/${hash}`).then(
      (response: AxiosResponse<any>) => new Blob([response.data], { type: 'application/octet-stream' }),
    )

  /**
   * @param viewId - BasketViewId
   * @returns All current BasketItems.
   */
  const getBasketItemsForView = async (viewId: string) => {
    const options = new basket.GetAllItemsOptions()
    options.positionNumbers = true
    options.subItems = true
    options.wholeComposite = false
    options.setArticleIds = true
    options.geometryIds = true
    options.basketItems = true
    options.parentItems = false
    options.viewId = viewId
    const basketItems = await session.basket.getAllItems(undefined, options)
    return basketItems
  }

  /**
   * @param basketItems
   * @param viewId - Current basketViewIdConfig
   * @returns Aggregated properties for basketItems
   */
  const getPropertiesForBasketItem = async (basketItems: basket.BasketItem[], viewId: string) => {
    const optionsProperties = new basket.GetItemPropertiesOptions()
    optionsProperties.subItems = true
    optionsProperties.wholeComposite = true
    optionsProperties.viewId = viewId
    optionsProperties.parentItems = false
    const properties = await session.basket.getItemProperties(
      basketItems.map((item) => item.itemId),
      optionsProperties,
    )
    return properties
  }

  const getObxUrlForBasketItem = async (basketItems: basket.BasketItem[]) => {
    const obxUrl = await session.basket.copy(
      basketItems.flatMap?.((item) => item.basketItems?.map((item) => item.itemId) || []) || [basketItems[0].itemId],
      undefined,
      undefined,
    )
    return obxUrl
  }

  const getImageUrlForBasketItem = async (basketItem: basket.BasketItem, viewId: string) => {
    const optionsGetImages = new basket.GetImagesOptions()
    optionsGetImages.wholeComposite = true
    optionsGetImages.useCache = true
    optionsGetImages.attributes = true
    optionsGetImages.generate = true
    optionsGetImages.viewId = viewId // add view id
    const image = (await session.basket.getImages(basketItem.itemId, optionsGetImages))[0]?.url || ''
    return image
  }

  const getCenterOfSceneElement = () =>
    coreApp.model.elements.length > 0 && coreApp.model.elements[0].boundingBox.isValid()
      ? coreApp.model.elements[0].boundingBox.getCenter()
      : Vector3.Zero()

  const getRadiusOfSceneElement = () =>
    coreApp.model.elements.length > 0 && coreApp.model.elements[0].boundingBox.isValid()
      ? coreApp.model.elements[0].boundingBox.getRadius()
      : 20 // 20 meter, if nothing is in the scene

  const resetCameraPosition = (limitCameraDistanceByElementRadius: boolean = false) => {
    coreApp.viewer.view.cameraControl.setFixedTarget(getCenterOfSceneElement())
    if (limitCameraDistanceByElementRadius) {
      const radiusMultiplier = 3 // should be changed to value you want
      const maxZoomRadius: number = getRadiusOfSceneElement() * radiusMultiplier // maxZoomRadius can also be a fixed value, but we take size of element into count
      coreApp.viewer.view.cameraControl.setNavigationArea(maxZoomRadius, getCenterOfSceneElement())
    }
    coreApp.viewer.view.cameraControl.setPosition(CameraControl.DEFAULT_CAMERA_POSITION)
    coreApp.viewer.view.cameraControl.zoomToFitElements(null)
    coreApp.viewer.requestRenderFrame()
  }

  const getPconDataForCurrentBasketItem = async (name?: string) => {
    const basketItems = await getBasketItemsForView(basketViewConfig.value!.viewId)
    console.log('usePconApplication.getPconDataForCurrentBasketItem', basketItems)
    return await getAggregatedPconDTOForBasketItem(
      basketItems.filter((item) => item.itemType !== 'Folder'),
      basketViewConfig.value!.viewId,
      true,
      name,
    )
  }

  const flags = useFeatureFlags()

  /**
   * Compiles basketItems to IPconDTO object.
   * @param basketItems
   * @param viewId
   * @param createScreenshot
   * @param name
   * @returns Aggregated IPconDTO object
   */
  const getAggregatedPconDTOForBasketItem = async (
    basketItems: basket.BasketItem[],
    viewId: string,
    createScreenshot: boolean = false,
    name?: string,
  ) => {
    const obxUrl = await getObxUrlForBasketItem(basketItems)
    const properties = await getPropertiesForBasketItem(basketItems, viewId)
    console.log('usePconApplication.getAggregatedPconDTOForBasketItem', basketItems, properties)

    const pricingSheet = await getPriceCalculationSheet(
      basketItems.map((item) => item.itemId),
      viewId,
    )
    // @TODO: settle for permanent solution
    const image =
      flags.ENABLE_PCON_IMAGES_FROM_CANVAS && createScreenshot
        ? (resetCameraPosition(), await coreApp.viewer.createScreenshot({ width: 800, height: 800 }, false))
        : await getImageUrlForBasketItem(basketItems[0], viewId)

    const { shortText, manufacturerName, manufacturerId, seriesId, seriesName, finalArticleNumber, ofmlVariantCode } =
      properties.find((p) => p.itemType !== 'Folder')!.article!
    const articlePropertiesToString = (article: basket.ArticleProperties) =>
      `${article.longText}${article.featureText ? '\n' + article.featureText : ''}\n${$t('Quantity')}: ${article.quantity}\n${$t('Article Number')}:  ${article.baseArticleNumber} (${article.finalArticleNumber})`
    const generateDescriptionFromProperties = (properties: basket.ItemProperties[]) => {
      const articlesAndSubarticles = properties.flatMap((p) => [p.article, ...(p.article?.subArticles || [])])
      return articlesAndSubarticles.length > 1
        ? articlesAndSubarticles
            .map((article, index) => `${index + 1}. ${articlePropertiesToString(article!)}`)
            .join('\n\n')
        : articlePropertiesToString(articlesAndSubarticles[0]!)
    }
    return {
      obxUrl,
      name: name || seriesName || shortText || finalArticleNumber,
      image,
      quantity: pricingSheet.quantity?.value || 1,
      description: generateDescriptionFromProperties(properties.filter((p) => p.itemType !== 'Folder')),
      priceNet: `${pricingSheet.netValue?.value}`,
      pConBrandName: manufacturerName!,
      pConInformation: {
        manufacturerId: manufacturerId!,
        seriesId: seriesId!,
        ofmlVariantCode: ofmlVariantCode!,
        obxHash: '',
      },
    } as IPconDTO
  }

  return {
    getPconDataForCurrentBasketItem,
    getAggregatedPconDTOForBasketItem,
    getGeneratedImage,
    getBasketItemsForView,
    getPropertiesForBasketItem,
    getObxUrlForBasketItem,
    getImageUrlForBasketItem,
    updatePriceNet,
    getPriceCalculationSheet,
    getObxFile,
    importFromObx,
    priceNet,
    clearBasket,
    data,
    resetCameraPosition,
    session,
    coreApp,
    articleManager,
    addArticle,
    removeAllElements,
    init,
    mainArticleElements,
    basketViewConfig,
  }
}

/**
 * Global store to share pCon-catalogItems between ModalPconCatalog and PconConfigurator
 */
export const useGlobalPconItemStore = createGlobalState(() => {
  const catalogItem = ref<CatalogItem | ArticleCatalogItem | null>(null)
  return { catalogItem }
})
