<template lang="pug">
modal(size='base' admin @close='$emit("close")')
  generic-form(:endpoint='`/_/SelectProductSupplier?inquiryId=${inquiryId}&productId=${productId}`' :allowShortcuts='true' @submitSuccess='$emit("close")' @onReady='onFormReady')
    template(#default)
      form-header
        | {{ $t('Choose supplier and purchasing price') }}
        template(#below)
          notification(v-if='isInquiryStatusAfterOffered' type='warning' :message='$t("Inquiry has already been offered.")')
          p(class='text-sm text-text-light') {{ $t('Wähle einen Lieferanten und einen Einkaufspreis für das Produkt aus. In der Liste werden nur Lieferanten angezeigt, deren Einkaufspreis wir bereits importiert haben. In der Select-Box darunter kannst du einen alternativen Lieferanten auswählen, für den für diese Marke keine Standardkondition hinterlegt ist.') }}
      div
        custom-table
          thead
            tr
              th
              th {{ $t('Default Supplier') }}
              th {{ $t('Purchasing Price') }}
              th {{ $t('Discount') }}
              th {{ $t('Source') }}
              th {{ $t('Last Modified') }}
          tbody
            template(v-for='brandSupplier in brandSupplierOptions.filter((o) => o.data)' :key='brandSupplier.value')
              tr(class='cursor-pointer' @click='updateCheckbox(brandSupplier)')
                td
                  control-checkbox(:modelValue='selectedSupplier === brandSupplier.value' :value='brandSupplier.data?.supplierId' class='pointer-events-none')
                td(class='!text-sm font-bold') {{ brandSupplier.label }}
                td(class='!text-sm') {{ brandSupplier.data?.purchasingPrice ? formatPrice(brandSupplier.data?.purchasingPrice) : formatPrice(0) }}
                td(class='!text-sm') {{ brandSupplier.data?.discount ? formatPercents(brandSupplier.data?.discount / 100) : formatPercents(0) }}
                td(class='!text-sm') {{ brandSupplier.data?.source }}
                td(class='!text-sm') {{ brandSupplier.data?.lastModified.split('-')[0] }}
                  icon(name='info' size='small' :data-tooltip='brandSupplier.data?.lastModified.split("-").slice(1).join("-")')
              tr(v-if='selectedSupplier === brandSupplier.value')
                td(class='!border-t-0')
                td(colspan='5' class='!border-t-0')
                  div(class='columns-2')
                    form-input-money(
                      name='PurchasingPrice'
                      :default='priceNetRef'
                      noSubmit
                      :modelValue='purchasingPrice'
                      @update:modelValue.once='changePurchasingPrice'
                      @change:modelValue='changePurchasingPrice'
                    )
                      | {{ $t('€') }}
                    control-label(
                      :label='$t("Discount on rrp net")'
                      :floating='true'
                      :error='discountOutOfRange'
                      :notifications='discountOutOfRange ? [{ type: "error", message: $t("Invalid discount") }] : []'
                    )
                      control-input(type='number' noSubmit :modelValue='discount' max='100' min='0' @update:modelValue.once='changeDiscount' @change:modelValue='changeDiscount')
                        | %
                  notification(v-if='isCustomDiscountSet && isCustomSupplierSelected' class='-mt-3' :type='"warning"')
                    span {{ $t('Einkaufspreis wurde überschrieben!') }}
                    btn(link link-inline class='ml-2' @click='updateCheckbox(brandSupplier)') {{ $t('reset') }}

            tr
              td
                control-checkbox(:modelValue='!isCustomSupplierSelected' :disabled='isCustomSupplierSelected' @update:modelValue='updateCheckbox')
              td(colspan='5')
                control-label(class='!mb-0' for='selectBrandsupplierWithoutData' :label='$t("Alternative Supplier")' :floating='true')
                  control-select(name='selectBrandsupplierWithoutData' :modelValue='selectedSupplier' :options='brandSupplierOptionsWithoutData' @update:modelValue='updateBrandSupplier')
            tr(v-if='!isCustomSupplierSelected')
              td(class='!border-t-0')
              td(colspan='5' class='!border-t-0')
                div(v-if='!isCustomSupplierSelected' class='columns-2')
                  form-input-money(
                    name='PurchasingPrice'
                    :default='priceNetRef'
                    noSubmit
                    :modelValue='purchasingPrice'
                    @update:modelValue.once='changePurchasingPrice'
                    @change:modelValue='changePurchasingPrice'
                  )
                    | {{ $t('€') }}
                  control-label(
                    class='!mb-0'
                    :label='$t("Discount on rrp net")'
                    :floating='true'
                    :error='discountOutOfRange'
                    :notifications='discountOutOfRange ? [{ type: "error", message: $t("Invalid discount") }] : []'
                  )
                    control-input(type='number' noSubmit :modelValue='discount' max='100' min='0' @update:modelValue.once='changeDiscount' @change:modelValue='changeDiscount')
                      | %
      notification(v-if='isCustomDiscountSet && isCustomSupplierSelected' class='-mt-3' :type='"warning"' :message='$t("Einkaufspreis wurde überschrieben!")')
      form-hidden(:modelValue='selectedSupplier' name='BrandSupplier')
      form-footer
        form-action(name='SetSupplierForProduct' formActionName='SelectProductSupplierForm/SetSupplierForProduct')
          template(#inner) {{ $t('Save') }}
</template>

<script lang="ts">
import { type IGenericFormConfigMap } from '@/components/GenericForm/types'
import { useInquiryStatus } from '@/pages/inquiries/composables'
import type { InquiryStatus } from '@/pages/inquiries/types'
import { type IOption } from '@/types'
import { formatPercents, formatPrice } from '@/utilities'
import { type PropType, computed, defineComponent, nextTick, ref, watch } from 'vue'

export type IBrandSupplierOptionData = any //@TODO
const FormSelectProductSupplier = defineComponent({
  inheritAttrs: false,
  props: {
    inquiryId: { type: String, required: true },
    productId: { type: String, required: true },
    priceNet: { type: Number, required: true },
    preSelectedSupplierId: { type: String },
    inquiryStatus: { type: String as PropType<InquiryStatus>, required: true },
  },
  setup(props) {
    const selectedSupplier = ref<string>(props.preSelectedSupplierId ? props.preSelectedSupplierId : '')
    const brandSupplierOptions = ref<IOption<IBrandSupplierOptionData>[]>([])
    const brandSupplierOptionsWithoutData = ref<IOption<IBrandSupplierOptionData>[]>([])
    const discount = ref<string | undefined>('')
    const purchasingPrice = ref<string | undefined>(undefined)
    const priceNetRef = ref<number>(props.priceNet)
    const calcDiscount = (purchasingPriceValue: number, priceNetValue: number) =>
      purchasingPriceValue !== 0 && priceNetValue !== 0
        ? (100 - (purchasingPriceValue / priceNetValue) * 100).toFixed(2)
        : 0

    const calcPurchasingPrice = (priceNetValue: number, discountValue: number) =>
      (priceNetValue - (priceNetValue / 100) * discountValue).toFixed(2)

    const changePurchasingPrice = (purchasingPriceValue: number) => (
      (discount.value = `${calcDiscount(Number(purchasingPriceValue)!, priceNetRef.value)}`),
      (purchasingPrice.value = purchasingPriceValue.toString())
    )

    const isCustomSupplierSelected = computed(
      () => brandSupplierOptionsWithoutData.value.findIndex((o) => o.value === selectedSupplier.value) === -1,
    )

    const isCustomDiscountSet = computed(
      () =>
        brandSupplierOptions.value.find((o) => o.value === selectedSupplier.value)?.data?.purchasingPrice.toFixed(2) !==
        parseFloat(purchasingPrice.value || '0').toFixed(2),
    )

    return {
      ...useInquiryStatus(props.inquiryStatus),
      updateCheckbox: (option: IOption<IBrandSupplierOptionData> | undefined) => {
        if (!option) {
          return
        }
        selectedSupplier.value = option.value as string
        const purchasingPrice = option.data?.purchasingPrice
        if (purchasingPrice) {
          changePurchasingPrice(purchasingPrice)
        }
      },
      updateBrandSupplier: (newSupplier: string) => {
        if (!newSupplier) {
          return
        }
        selectedSupplier.value = newSupplier
        const selectedSupplierOption = brandSupplierOptionsWithoutData.value.find((b) => b.value === newSupplier)
        const purchasingPrice = selectedSupplierOption?.data?.purchasingPrice
        if (purchasingPrice) {
          changePurchasingPrice(purchasingPrice)
        }
        return newSupplier
      },
      purchasingPrice,
      discount,
      priceNetRef,
      onFormReady: ({ configMap }: { configMap: IGenericFormConfigMap }) => {
        brandSupplierOptions.value = configMap.BrandSupplier.options?.filter((o) => o.data) as IOption<any>[] // TODO IOption<IBrandSupplierOptionData>[]
        brandSupplierOptionsWithoutData.value = configMap.BrandSupplier.options?.filter(
          (o) => !o.data,
        ) as IOption<any>[] // TODO IOption<IBrandSupplierOptionData>[]
      },
      brandSupplierOptions,
      brandSupplierOptionsWithoutData,
      selectedSupplier,
      isCustomSupplierSelected,
      isCustomDiscountSet,
      formatPrice,
      formatPercents,
      discountOutOfRange: computed(() => {
        const discountValue = Number(discount.value || 0)
        return discountValue < 0 && discountValue >= 100
      }),
      changePurchasingPrice,
      changeDiscount: (discountValue: string) => (
        (purchasingPrice.value = `${calcPurchasingPrice(priceNetRef.value!, Number(discountValue))}`),
        (discount.value = discountValue)
      ),
      onReady: () =>
        nextTick(() => {
          if (priceNetRef.value && purchasingPrice.value) {
            discount.value = `${calcDiscount(Number(purchasingPrice.value)!, priceNetRef.value!)}`
          }
          watch(
            () => priceNetRef.value,
            (priceNetValue: number | undefined) =>
              !!priceNetValue &&
              (purchasingPrice.value = `${calcPurchasingPrice(priceNetValue!, Number(discount.value || 0))}`),
          )
        }),
    }
  },
})
export default FormSelectProductSupplier
</script>
