<template lang="pug">
dropdown-options(maxWidth='min(400px, calc(100vw - 40px))' ignoreContentClick)
  template(#button='{ toggle, active }')
    div(class='relative')
      badge(:count='attachments.length' :visible='!!attachments.length' :offset='[-2, -2]' grey bordered)
        btn(:active='active' icon='custom-attachments' data-test-btn='attachments' secondary :data-tooltip='$t("Attachments")' v-bind='$attrs' @click='toggle')
  dropdown-item(v-for='attachment in attachments' :key='attachment.key' :href='`/Uploads/Attachments/${projectId}-${attachment.key}.${attachment.ext}`' v-bind='linkBindings(attachment)' tabindex='1')
    file-item(:icon='iconMap[attachment.ext]' :filename='attachment.orgFileName' tag='a' :info='attachment.ext.toUpperCase()' @click.stop='false')
      template(#actions)
        btn(
          icon='download'
          small
          faded
          :href='`/Uploads/Attachments/${projectId}-${attachment.key}.${attachment.ext}`'
          :download='attachment.orgFileName'
          :data-tooltip='$t("Download Attachment")'
          tabindex='1'
        )
        btn(icon='trash' data-test-btn='attachment-remove' small faded :data-tooltip='$t("Remove Attachment")' tabindex='1' @click.stop.prevent='removeAttachment(attachment.key)')
  dropdown-item(v-if='loading')
    file-item(:progress='progress' :loading='loading')
  div(@click.stop='() => false')
    control-file-upload#Attachments(
      hideFiles
      :endpoint='`/_/Projects/${projectId}/UploadAttachment`'
      accept='image/*, application/pdf, application/vnd., application/vnd.ms-excel, application/mspowerpoint, application/msword, application/excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      @uploadEnd='onUploadEnd'
      @uploadError='onUploadError'
      @uploadStart='onUploadStart'
      @progressUpdate='onProgressUpdate'
    )
</template>
<script setup lang="ts">
import { useProjectsStore } from '../store'
import { type IAttachment } from '../types'
import { useConfirm } from '@/plugins/Confirm'
import { useTranslation } from '@/plugins/translation'
import { type PropType, ref } from 'vue'

const ICON_MAP: Record<string, string[]> = {
  'custom-file-doc': ['doc', 'docx'],
  'custom-file-image': ['png', 'gif', 'jpeg', 'jpg'],
  'custom-file-excel': ['xlsx'],
  'custom-file-pdf': ['pdf'],
}

defineOptions({ inheritAttrs: false })
const props = defineProps({
  projectId: { type: String, required: true },
  attachments: { type: Array as PropType<IAttachment[]>, default: () => [] },
})
const emit = defineEmits<{ update: [attachments: IAttachment[]] }>()
const { confirm } = useConfirm()
const { $t } = useTranslation()
const loading = ref(false)
const progress = ref(0)
const store = useProjectsStore()

const canBeOpenendInBrowser = (ext: string) => ['png', 'gif', 'jpeg', 'jpg', 'pdf'].includes(ext.toLowerCase())
const linkBindings = (attachment: IAttachment) =>
  canBeOpenendInBrowser(attachment.ext) ? { target: '_blank' } : { download: attachment.orgFileName }
const iconMap = Object.fromEntries(
  Object.entries(ICON_MAP).flatMap(([iconName, fileEndings]) =>
    fileEndings.map((ending: string) => [ending, iconName]),
  ),
)
const removeAttachment = (key: string) =>
  confirm({
    title: $t('Do you really want to remove the file <b>{0}</b>?', '', [
      props.attachments.find((attachment) => attachment.key === key)!.orgFileName,
    ]),
    onOk: () => store.removeAttachment(props.projectId, key).then((data) => emit('update', data as IAttachment[])),
  })
const onProgressUpdate = (progressValue: number) => (progress.value = progressValue)
const onUploadStart = () => ((progress.value = 0), (loading.value = true))
const onUploadError = () => (loading.value = false)
const onUploadEnd = (attachments: IAttachment[]) => (
  (progress.value = 100),
  setTimeout(() => (emit('update', [...props.attachments, ...attachments]), (loading.value = false)), 400)
)
</script>
