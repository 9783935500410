import { type Directive, type DirectiveBinding } from 'vue'

export interface IInnerElementConfig {
  [selector: string]: () => void
}

const update = (container: HTMLElement, config: IInnerElementConfig) => {
  Object.entries(config).forEach(([selector, handler]) => {
    const element: HTMLElement | null = container.querySelector(selector)
    element?.addEventListener('click', handler)
  })
}
const AddClickHandlers: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding): void {
    update(el, binding.value as IInnerElementConfig)
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    update(el, binding.value as IInnerElementConfig)
  },
}
export default AddClickHandlers
