<template lang="pug">
div(class='')
  div(v-if='!breakpoints.sm || compact' class='flex items-center gap-2')
    div(:data-tooltip='$t("Previous Page")')
      btn(:disabled='disabled || current === 1' plain small icon='arrow-left' @click='() => onChange(current - 1)')
    div(:data-tooltip='$t("Next Page")')
      btn(:disabled='disabled || current === lastPage' plain small icon='arrow-right' @click='() => onChange(current + 1)')
    control-select#pagination-select(v-if='!breakpoints.sm || compact' v-model='current' plain inline small :options='pagesAsOptions' size='small' :disabled='total < pageSizeOptions[0]' class='')

  div(v-else class='-mx-1 flex flex-wrap items-center gap-2')
    btn(:disabled='disabled || current === 1' plain icon='arrow-left' small @click='() => onChange(current - 1)')
    btn(
      v-for='page in displayedPages'
      :key='page.value'
      iconOnly
      small
      plain
      :disabled='disabled'
      :active='current === page.value'
      :class='{ "underline-current font-bold": current === page.value }'
      :data-test-btn-page='page.label'
      @click='() => onChange(page.value)'
    )
      | {{ page.label }}
    btn(:disabled='disabled || current === lastPage' plain icon='arrow-right' small @click='() => onChange(current + 1)')
    div(v-if='pageSizeOptions.length > 1' class='w-auto')
      control-select(v-if='pageSizeOptions.length > 1' v-model='pageSize' plain inline small :disabled='total < pageSizeOptions[0]' :options='options' size='small')
</template>

<script setup lang="ts">
import Btn from './Btn.vue'
import ControlSelect from './Control/ControlSelect.vue'
import { useCustomBreakpoints } from '@/composables/'
import { useTranslation } from '@/plugins/translation'
import { computed, onBeforeMount, watch } from 'vue'

interface IPaginationItem {
  label: string | number
  value: number
}

const props = withDefaults(
  defineProps<{
    total: number
    compact?: boolean
    range?: number
    pageSizeOptions?: number[]
  }>(),
  { pageSizeOptions: () => [36, 72, 144, 288], range: 2 },
)

const current = defineModel<number>({ required: true })
const pageSize = defineModel<number>('pageSize', { default: () => 36 })
const updatePageSize = () =>
  !props.pageSizeOptions.includes(pageSize.value) && (pageSize.value = props.pageSizeOptions[0])
onBeforeMount(updatePageSize)
watch(() => props.pageSizeOptions, updatePageSize)

const { $t } = useTranslation()
const disabled = computed(() => !pages.value || pages.value.length < 2)
const options = computed(() =>
  props.pageSizeOptions.map((value: number) => ({ value, label: `${value} / ${$t('Page')}` })),
)
const pages = computed(() =>
  Array.from(Array(Math.ceil((props.total === 0 ? 1 : props.total) / pageSize.value)).keys()).map((i: number) => ++i),
)
const displayedPages = computed(() =>
  pages.value.length > 8
    ? [
        1,
        ...pages.value.filter(
          (i: number) => Math.abs(current.value - i) <= props.range && i !== pages.value.length && i !== 1,
        ),
        pages.value.length,
      ].reduce(
        (prev: IPaginationItem[], p: number, index: number, a: number[]) => [
          ...prev,
          { value: p, label: p },
          ...(index < a.length && Math.abs(p - a[index + 1]) > 1
            ? [{ label: `...`, value: p === 1 ? a[index + 1] - 1 : p + 1 }]
            : []),
        ],
        [],
      )
    : pages.value.map((i: number) => ({ value: i, label: i })),
)

const pagesAsOptions = computed(() =>
  pages.value.map((value: number) => ({ value, label: $t('{0} / {1}', '', [value, pages.value.length]) })),
)
const lastPage = computed(() => pages.value[pages.value.length - 1])
const breakpoints = useCustomBreakpoints()

const onChange = (page: number) => {
  current.value = page
  // window.scrollTo({ top: 0 })
}
</script>
