<template lang="pug">
div(:class='{ "tab--isActive": active }' class='tab')
  button(:data-test-tab-button='id' type='button' class='tab__button')
    slot
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export type ITabRegisterFn = (tab: ITab) => void
export interface ITab {
  title: string
  id: string
}

/**
 * Wrapper component for TabPane components
 * @displayName Tabs
 */
const TabButton = defineComponent({
  props: {
    active: Boolean,
    id: String,
  },
  setup() {},
})

export default TabButton
</script>
<style lang="stylus">
@import '../../styles/variables.styl'
:root
  --tab-padding-x: 6px
  --tab-padding-y: 6px
  --tab-font-weight: normal
  --tab-text-color: var(--color-text-light)
  --tab-border-color: transparent
.tab
  display: inline-block
  padding-bottom: 4px
  border-bottom: 2px solid var(--tab-border-color)
  margin-bottom: -2px
  &--isActive
    --tab-border-color: var(--color-text)
    --tab-font-weight: 900
    --tab-text-color: var(--color-text)

  &__button
    font-weight: var(--tab-font-weight)
    background-color: transparent
    border: 0
    padding: var(--tab-padding-y) var(--tab-padding-x)
    margin: 0 calc(var(--tab-padding-x) * -1)
    color: var(--tab-text-color)
    font-weight: var(--tab-font-weight)
    font-size: 15px
    font-family: $font-lato
    cursor: pointer
    border-radius: 3px
    &:active,
    &:hover
      background: var(--grey-75)
    &:active
      background: var(--grey-100)
    &:focus
      body.using-mouse &
        outline: 0

  &:not(:last-child)
    margin-right: calc(var(--tab-padding-x) + 10px)
</style>
