<template lang="pug">
component(:is='tag' :to='to' plain :loading='false' :class='{ "btn-image--active": active }' class='btn-image')
  slot
    img(v-image-lazy :data-src='src' :data-cloudinary='src && src.includes("http") ? "false" : "t_xs"')
</template>

<script lang="ts">
import { hasSlotContent } from '@/utilities/Vue'
import { computed, defineComponent, type SetupContext, type PropType } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

const BtnImage = defineComponent({
  props: {
    src: { type: String, required: false },
    to: [String, Object] as PropType<RouteLocationRaw>,
    active: Boolean,
  },
  setup(props, context: SetupContext) {
    return {
      tag: computed(() => (props.to ? 'router-link' : 'button')),
      hasSlot: computed(() => hasSlotContent(context.slots.image)),
    }
  },
})

export default BtnImage
</script>

<style lang="stylus">
@import '../styles/variables.styl'
.btn-image
  padding: 2px
  border: 2px solid transparent
  min-width: 30px
  margin: 0
  border-radius: 5px
  display: flex
  align-items: center
  justify-content: center
  aspect-ratio: 1
  border-color: var(--grey-100)
  img
    width: 100%
    border-radius: 5px
  &:hover
    border-color: var(--grey-200)
    img
      opacity: 0.9
  &:active
    border-color: var(--grey-300)
    img
      opacity: 0.7
  &--active
    border-color: var(--color-text)!important
</style>
