<template lang="pug">
section
  div(v-if='!hideActions' class='relative mr-8 flex gap-4')
    breadcrumbs(class='mb-4 flex-1')
      breadcrumbs-item(location='/products/') {{ $t('Products') }}
      breadcrumbs-item(v-for='c in categories' :key='c.id' :location='{ name: "/products/", query: { categories: [c.id] } }') {{ c.texts.de.title }}
    div#ProductAdminHeader
      product-edit-button(:productId='product.id' :modelId='product.modelId' :brandId='brand.id')
div(v-if='pcon' class='relative min-h-[50vh]')
  pcon-product-configurator(
    ref='pconProductConfigurator'
    :images='assets'
    :autoCaptureImage='assets.length === 0'
    showCreateAndUploadAssetImageButton
    :productId='product.id'
    :pconInfo='pconDataFromRoute'
    class='sm:min-h-[80vh]'
  )
    template(#default='{ loading }')
      product-actions(:id='product.id' :title='product.title' class='mt-4' @submit='submit()' @loadingAddBtn='pconLoading')
      notification(v-show='!loading' type='info') {{ $t('Prices may differ according to the volume within a project') }}
    template(#belowViewer='{ articleProperties }')
      div(class='py-5')
        collapse
          collapse-item#product-detail-properties(title='Zusammenfassung' isOpenByDefault)
            div(v-for='article in articleProperties' :key='article.baseArticleNumber' class='flex flex-wrap gap-y-1')
              template(v-if='article.featureText')
                div(v-for='(property, index) in split(article.featureText)' :key='index' class='flex w-full text-sm md:w-[50%]')
                  div(class='w-[200px] font-bold') {{ property[0] }}:
                  div(class='flex-1 text-text md:pr-2') {{ property[1] }}

    template(#title)
      h4(class='mb-2 text-xl' v-html='product.title')
    template(#technical)
      product-detail-technical-information(class='flex-1 pt-5' :product='product' :hideDescription='hasShortDescription')
section(v-else v-gtm-product-detail='{ id: product.id, name: product.title, price: product.rrpNet, brand: brand.name }' class='product-detail')
  div(class='product-detail__media')
    slider-images(:images='assets' class='mx-3 sm:ml-6 md:mx-0')
      template(#item='{ item }')
        picture(v-image-lazy class='')
          source(:data-srcset='item' data-cloudinary='f_auto/t_md' media='(max-width: 600px)')
          img(:data-src='item' data-cloudinary='f_auto/t_xl' class='w-full object-contain sm:h-[70vh] xl:max-h-[950px]')
  div(class='product-detail__content')
    div(class='product-detail__contentInner')
      router-link(v-if='!hideActions' :to='createBrandLocation(brand.id)' class='text-text-light hover:text-text-light')
        img(
          v-if='!isPlaceholderBrandLogo'
          v-image-lazy
          :data-tooltip='brand.name'
          :data-src='brand.logoPath'
          data-cloudinary='f_auto/if_!negative!_nin_tags,e_make_transparent/if_!negative!_nin_tags,e_trim:10:transparent/w_300,h_200,c_fit'
          class='mb-4 max-h-[60px] max-w-[100px] mix-blend-multiply'
        )
        span(v-else) {{ brand.name }}
      div(v-else) {{ brand.name }}
      h4(class='mb-2 text-xl' v-html='product.title')
      p(v-if='product.description && hasShortDescription' v-html='product.description')
      div(class='')
        table(class='mt-4 text-sm')
          tbody
            tr
              td(width='1')
                span {{ $t('Dimensions') }}:
              td {{ $t('W:') }} {{ product.width }}, {{ $t('H:') }} {{ product.height }}, {{ $t('D:') }} {{ product.depth }}
            tr
              td(width='1')
                span {{ $t('Weight') }}:
              td {{ product.weight }}
            tr(v-if='product.materials')
              td(width='1')
                span {{ $t('Materials') }}:
              td {{ product.materials }}
      div(v-if='!hideActions' class='mt-10')
        product-variants(:data='variants' class='mt-4')
      div(class='mt-5')
        div(class='flex text-xl font-bold') {{ product.rrpNet }}
        span(class='text-sm') {{ $t('UVP net') }} ({{ $t('excl. {0}% VAT', '', [$env.vat]) }})
      product-actions(v-if='!hideActions' :id='product.id' :loadingAddBtn='loadingSubmit' :title='product.title' class='mt-4' @submit='submit()')

      notification(class='mt-4' type='info')
        | {{ $t('Prices may differ according to the volume within a project') }}
  div(class='product-detail__info')
    product-detail-technical-information(:product='product' :hideDescription='hasShortDescription')

section(v-if='!hideSimilarProducts' class='bleed mt-10 bg-white py-8')
  div(class='unbleed')
    h5(class='mb-2') {{ $t('Similar products') }}
  slider(:options='config')
    slot
</template>

<script setup lang="ts">
import PconProductConfigurator from '@/PconProductConfigurator.vue'
import { type IProductVariant } from '@/components/ProductVariants.vue'
import { usePageTitle } from '@/composables/'
import { useModals } from '@/plugins/Modals'
import type { IPconInformation, IProductItem } from '@/types'
import { shakeFalsy } from '@/utilities'
import { type Options } from '@splidejs/splide'
import { crush } from 'radash'
import { computed, ref, type PropType } from 'vue'
import { type RouteLocationRaw, useRoute } from 'vue-router'

const objectToOfmlVariantCode = (object: Record<string, string>) =>
  Object.entries(crush(object))
    .map(([key, value]) => `${key}=${value}`)
    .join(';')

const queryObjectToPconEvent = ({
  baseArticleNumber,
  manufacturerId,
  seriesId,
  ofmlVariantCode,
  variantCode,
  obxHash,
}: Record<string, string>) => {
  try {
    return shakeFalsy({
      manufacturerId,
      baseArticleNumber,
      seriesId,
      variantCode,
      obxHash,
      ofmlVariantCode: ofmlVariantCode && objectToOfmlVariantCode(JSON.parse(window.atob(ofmlVariantCode))),
    })
  } catch (error) {
    return {}
  }
}

interface IBrandDetail {
  name: string
  id: string
  logoPath?: string
}
interface ICategory {
  id: string
  texts: { de: { title: string } }
}
interface IProductDetailItem extends IProductItem {
  modelId: string
  rrpNet: string
  width: string
  height: string
  depth: string
  weight: string
  ean: string
  logoPath: string
  materials: string
  colors: string
  additionalInformation: string
  colorDescription: string
  materialDescription: string
  measurementsDescription: string
  technicalInformation: string
}

const props = defineProps({
  categories: { type: Array as PropType<ICategory[]>, required: true },
  product: { type: Object as PropType<IProductDetailItem>, required: true },
  brand: { type: Object as PropType<IBrandDetail>, required: true },
  pcon: { type: Object as PropType<IPconInformation>, required: false },
  variants: { type: Array as PropType<IProductVariant[]>, required: true },
  assets: { type: Array as PropType<string[]>, required: true },
  hideSimilarProducts: Boolean,
  hideActions: Boolean,
})

usePageTitle(props.product.title)
window.scrollTo({ top: 0 })
const route = useRoute()
const $modals = useModals()
const pconLoading = ref(true)
const pconProductConfigurator = ref<InstanceType<typeof PconProductConfigurator> | null>(null)
const loadingSubmit = ref(false)
const pconDataFromRoute = ref<IPconInformation | undefined>(
  props.pcon ? { ...props.pcon, ...queryObjectToPconEvent(route.query as any) } : undefined,
)

const split = (featureText: string) =>
  featureText
    .split('\n')
    .map((s) => s.split(': '))
    .reduce<string[][]>(
      (p, c, currentIndex) =>
        c.length === 2
          ? [...p, c]
          : p.map((v: string[], index) => (index !== currentIndex - 1 ? v : [v[0], `${v[1]} ${c[0]}`])),
      [],
    )
const createBrandLocation = (brandId: string) =>
  ({ name: '/products/', query: { brands: [brandId] } }) as RouteLocationRaw
const submit = async () => {
  // add imageUrl
  if (props.pcon) {
    loadingSubmit.value = true
    const pconDTO = await pconProductConfigurator.value?.getPconDataForCurrentBasketItem()
    $modals.open('form-project-add-product', { productIds: [props.product.id], pcon: pconDTO }, true)
    loadingSubmit.value = false
  } else {
    $modals.open('form-project-add-product', { productIds: [props.product.id] }, true)
  }
}

const hasShortDescription = computed(() => (props.product?.description?.length || 0) < 350)
const isPlaceholderBrandLogo = computed(() => props.brand?.logoPath?.includes?.('placeholder'))
const config = {
  gap: 30,
  padding: { left: 30, right: 30 },
  arrows: false,
  perMove: 2,
  perPage: 6,
  pagination: false,
  rewind: true,
  width: '100%',
  breakpoints: {
    480: {
      padding: { left: 15, right: 15 },
      perPage: 2,
      gap: 15,
      perMove: 1,
    },
    980: {
      perPage: 2,
    },
    1280: {
      perPage: 4,
    },
    1800: {
      perPage: 5,
    },
  },
} as Options
</script>
<style lang="stylus">
@import '../../../styles/variables.styl'
:root
  --bg-product-detail-media: var(--color-body)
  --product-detail-info-grid-area:  2 / 1 / 3 / 2
.product-detail
  z-index: 1
  @media (min-width: $breakpoint-md)
    display: grid
    grid-template-columns: auto 450px
    grid-template-columns: auto max(50%, 470px)
    position: relative
    grid-template-rows: repeat(auto, auto)
    grid-column-gap: 60px
    grid-row-gap: 40px
  &__logo img
    max-width: 170px
    max-height: 80px

  &__media
    grid-area: 1 / 1 / 2 / 2
    background: var(--bg-product-detail-media)
    margin-bottom: 10px
    border-radius: 10px
    > *
      mix-blend-mode: multiply
  &__content
    grid-area: 1 / 2 / 2 / 2
    @media (min-width: $breakpoint-md)
      top: 20px
      display: flex
      // position: sticky
  &__contentInner
    padding: 20px 0 40px
    flex: 1
    display: flex
    flex-flow: column
    padding-bottom: 10px
    padding-top: 0px
  &__info
    grid-area: var(--product-detail-info-grid-area)
</style>
