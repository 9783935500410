<template lang="pug">
checkbox(:id='id' v-model='val' :class='classes' :disabled='disabled' @update:modelValue='onChange')
  slot {{ label }}
</template>

<script lang="ts">
import Checkbox from './Checkbox.vue'
import { useControl, Input } from './composables'
import { defineComponent, type SetupContext } from 'vue'

/**
 * Generic Checkbox Component
 */
const ControlCheckbox = defineComponent({
  components: { Checkbox },
  props: {
    ...Input.props,
    modelValue: Boolean,
    label: String,
  },
  setup(props: any, context: SetupContext) {
    const {
      value: val,
      classes,
      focus,
      onChange,
      onFocus,
      onBlur,
      isEmpty,
    } = useControl<boolean>(props, context, false)
    return {
      val,
      isEmpty,
      onFocus,
      classes,
      onBlur,
      onChange,
      focus,
    }
  },
})

export default ControlCheckbox
</script>
