export const hasChildren = (node: any) => {
  return typeof node === 'object' && typeof node.children !== 'undefined' && node.children.length > 0
}

export interface IFlatTreeItemMap<T> {
  [id: string]: IFlatTreeItem<T>
}

export interface IFlatTreeItem<T> {
  parents: string[]
  hasChildren: boolean
  option: ITreeItem<T>
}

export interface ITreeItem<T> {
  children?: ITreeItem<T>[]
  [prop: string]: any
}

export const flattenTree: <T>(opts: ITreeItem<T>[], parents?: string[]) => IFlatTreeItemMap<T> = <T>(
  opts: ITreeItem<T>[],
  parents: string[] = [],
) => ({
  ...opts.reduce(
    (flatOpts: IFlatTreeItemMap<T>, opt: ITreeItem<T>) =>
      hasChildren(opt)
        ? {
            ...flatOpts,
            [opt.id]: { parents, hasChildren: true, option: opt },
            ...flattenTree(opt.children!, [...parents, opt.id]),
          }
        : { ...flatOpts, [opt.id]: { parents, option: opt, hasChildren: false } },
    {},
  ),
})

export const flattenTreeArray: <T>(opts: ITreeItem<T>[], parents?: string[]) => IFlatTreeItem<T>[] = <T>(
  opts: ITreeItem<T>[],
  parents: string[] = [],
) => [
  ...opts.reduce(
    (flatOpts: IFlatTreeItem<T>[], opt: ITreeItem<T>) =>
      opt.children
        ? [
            ...flatOpts,
            { parents, hasChildren: hasChildren(opt), option: opt },
            ...flattenTreeArray(opt.children, [...parents, opt.id]),
          ]
        : [...flatOpts, { parents, option: opt, hasChildren: hasChildren(opt) }],
    [],
  ),
]
