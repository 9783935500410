<template lang="pug">
component(
  :is='component'
  :id='id'
  :href='href'
  :to='to'
  :data-test-menu-item='id'
  :data-test='"btn-" + id'
  :class='{ "dropdown-item--withDescription": !!description }'
  v-bind='attributes'
  class='dropdown-item'
)
  icon(v-if='icon' :name='icon' class='dropdown-item__icon')
  div(class='dropdown-item__content')
    div(class='dropdown-item__text')
      slot {{ text }}
    div(v-if='description' class='dropdown-item__description' v-html='description')
</template>

<script lang="ts">
import Icon from './Icon.vue'
import { shakeFalsy } from '@/utilities'
import { computed, defineComponent, type PropType } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

const DropdownItem = defineComponent({
  components: { Icon },
  props: {
    icon: String,
    id: String,
    text: String,
    description: String,
    href: String,
    active: Boolean,
    disabled: Boolean,
    to: Object as PropType<RouteLocationRaw>,
  },
  setup(props) {
    return {
      component: computed(() => (props.href ? 'a' : props.to ? 'router-link' : 'button')),
      attributes: computed(() =>
        shakeFalsy({
          disabled: props.disabled,
        }),
      ),
    }
  },
})

export default DropdownItem
</script>

<style lang="stylus">
@import '../styles/variables.styl'

:root
  --menu-item-border-radius: 5px
  --dropdown-item-padding-y: 12px

.dropdown-item
  display: flex
  text-align: left
  width: 100%
  position: relative
  justify-content: left
  padding: var(--dropdown-item-padding-y) var(--dropdown-padding-x)
  color: var(--grey-900)
  background-color: transparent
  border: 1px solid transparent
  line-height: 1.25
  font-size: 16px
  transition: all .2s ease
  cursor: pointer
  &, &:focus, &:hover
    color: var(--grey-900)
  &:focus,
  &:active,
  &:hover
    &:not([disabled])
      background: var(--grey-50)
      text-decoration: none
  &[lg]
    --dropdown-item-padding-y: 16px
    font-size: 17px
  &[danger]
    color: var(--color-danger)
  &[disabled]
    color: var(--grey-400)!important
    cursor: not-allowed
  &__content
    flex: 1
    min-width: 0
  &__description
    color: var(--grey-500)
    font-size: 14.5px
  &__text
    display: flex
    flex: 1
  &__icon
    font-size: 18px
    margin-right: 8px!important
    stroke-width: 2px
    ~/--withDescription &
      position: relative
      align-items: flex-start
      top: 1px
  &:not(:last-child)
    border-bottom: 1px solid var(--grey-100)
  &:last-child &
    border-radius: 0px 0px var(--menu-item-border-radius) var(--menu-item-border-radius)
  &:first-child &
    border-radius: var(--menu-item-border-radius) var(--menu-item-border-radius) 0 0
</style>
