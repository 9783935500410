<template lang="pug">
control-input-wrapper(:disabled='disabled' :size='size' :icon='icon' :showClear='clearable && showClear' @clear='clear')
  currency-input(
    :id='id'
    ref='el'
    v-model='numberValue'
    :data-empty='isEmpty'
    :disabled='disabled'
    @input='onChange'
    @keydown.enter='onSubmit'
    @change='$emit("change:modelValue", val)'
    @focus='onFocus'
    @blur='onBlur'
  )
</template>

<script lang="ts">
import ControlInputWrapper from './ControlInputWrapper.vue'
import { Input, useControl } from './composables'
import { defineComponent, type SetupContext, watch, type Ref, type WritableComputedRef, computed } from 'vue'
import { type CurrencyInputOptions, useCurrencyInput } from 'vue-currency-input'

const defaultOptions: CurrencyInputOptions = {
  currency: 'EUR',
  hideCurrencySymbolOnFocus: true,
  hideGroupingSeparatorOnFocus: true,
  hideNegligibleDecimalDigitsOnFocus: true,
  autoDecimalDigits: false,
  useGrouping: true,
  precision: 2,
  accountingSign: false,
}

const CurrencyInput = defineComponent({
  props: {
    modelValue: Number,
    options: { type: Object, default: () => defaultOptions },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { inputRef, setValue } = useCurrencyInput(props.options as CurrencyInputOptions)
    watch(
      () => props.modelValue,
      (v: any) => (setValue(v), emit('update:modelValue', v)),
    )
    return { inputRef }
  },
  template: '<input ref="inputRef" type="text" />',
})

const ControlInputMoney = defineComponent({
  components: { CurrencyInput, ControlInputWrapper },
  props: {
    ...Input.props,
    modelValue: [String, Number],
    noSubmit: Boolean,
    icon: String,
    size: String,
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context: SetupContext) {
    const {
      value: val,
      onFocus,
      onChange,
      onSubmit,
      onBlur,
      isEmpty,
      provideState,
      el,
      showClear,
      clear,
    } = useControl<string>(props, context, '')

    const isEmptyish: Ref<boolean> = computed(() => val.value === '')
    const numberValue: WritableComputedRef<number | null> = computed({
      get: () => (val.value ? Number(val.value) : null),
      set: (v: number | null) => ((val.value = v === null ? '' : `${v}`), onChange()),
    })
    provideState?.('isEmpty', isEmptyish)

    return {
      el,
      showClear,
      isEmpty,
      onFocus,
      onBlur,
      onChange,
      onSubmit: (e: Event) =>
        props.noSubmit ? (context.emit('change:modelValue', val.value), e.preventDefault()) : onSubmit(),
      clear,
      numberValue,
      val,
    }
  },
})

export default ControlInputMoney
</script>
