<template lang="pug">
div(class='control-file-upload')
  overlay(:visible='loading' color='dark')
  control-file(
    :id='id'
    :clearAfterSelect='true'
    :placeholder='placeholder'
    :icon='icon'
    :accept='accept'
    :centered='centered'
    :disabled='disabled'
    class='control-file-upload__container'
    @update:modelValue='onChangeInput'
  )
    template(#inner)
      slot(name='inner')
    div(v-if='!hideFiles && localValue.length' class='file-list' @click.prevent='() => false')
      div(v-for='(file, index) in localValue' :key='file.path' class='file-list-item')
        div(class='file-list-item__text') {{ file.orgFileName + ' ' }}
          span(v-if='file.size' class='faded') ({{ file.size }})
        div(class='file-list-item__actions')
          btn(faded small icon='trash' :data-tooltip='$t("remove")' @click.prevent='remove(index)')
</template>
<script lang="ts">
import ControlFile from './ControlFile.vue'
import { Input, useControl } from './composables'
import Overlay from '@/components/Overlay.vue'
import { type AxiosResponse } from 'axios'
import Axios from 'axios-observable'
import { isArray } from 'radash'
import { computed, defineComponent, onMounted, ref, type Ref, type SetupContext, watch } from 'vue'

export const filesArrayToFormData = (fieldName: string, files: File[]) => {
  const formData = new FormData()
  files.forEach((f: File) => formData.append(fieldName, f))
  return formData
}

export interface IProgresEvent {
  total: number
  loaded: number
}
export const submitFiles = (
  endpoint: string,
  fieldName: string,
  files: File[],
  onUploadProgress?: (progressEvent: IProgresEvent) => void,
) =>
  Axios.post(endpoint, filesArrayToFormData(fieldName, files), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  })

export const valueToArray = (value: string) => (value !== '' ? value.split(',') : [])

export const stringToUploadAssetInfo = (value: string) => {
  const path = value.split(',')[0]
  return { path, orgFileName: value.slice(path.length + 1, value.length) } as IUploadAssetInfo
}

export interface IUploadAssetInfo {
  ext?: string
  mediaType?: string
  orgFileName: string
  path: string
  key?: string
  size?: string
}

const ControlFileUpload = defineComponent({
  components: { ControlFile, Overlay },
  props: {
    ...Input.props,
    endpoint: {
      type: String,
      required: true,
    },
    modelValue: Array,
    multiple: Boolean,
    hideFiles: Boolean,
    placeholder: String,
    centered: Boolean,
    icon: String,
    accept: {
      type: String,
      default: 'image/*',
    },
  },
  setup(props, context: SetupContext) {
    const {
      value: val,
      classes,
      focus,
      hasFocus,
      onFocus,
      onSubmit,
      onChange,
      onBlur,
      isEmpty,
    } = useControl<string[]>(props, context, [])
    const dragState: Ref<string> = ref('default')
    const localValue: Ref<IUploadAssetInfo[]> = ref([])
    const { endpoint, id } = props
    const loading = ref(false)
    onMounted(() => {
      if (val.value) {
        localValue.value = val.value.map((v) => stringToUploadAssetInfo(v))
      }
      watch(
        () => localValue.value,
        (value: IUploadAssetInfo[]) => (
          (val.value = value.map((uploadInfo) => uploadInfo.path + ',' + uploadInfo.orgFileName)), onChange()
        ),
      )
    })
    return {
      isEmpty,
      localValue,
      onFocus,
      onBlur,
      onChange,
      onChangeInput: (files: File[]) => {
        loading.value = true
        context.emit('uploadStart', files)
        submitFiles(endpoint, id as string, files, ({ total, loaded }) =>
          context.emit('progressUpdate', Math.round((total / loaded) * 100)),
        ).subscribe({
          next: ({ data }: AxiosResponse<IUploadAssetInfo[]>) => (
            isArray(data) && (localValue.value = [...localValue.value, ...data]),
            (loading.value = false),
            context.emit('uploadEnd', data)
          ),
          error: () => ((loading.value = false), context.emit('uploadError')),
        })
      },
      clear: () => (localValue.value = []),
      hasFiles: computed(() => localValue.value?.length > 0),
      remove: (index: number) => localValue.value.splice(index, 1),
      onSubmit,
      focus,
      loading,
      hasFocus,
      val,
      classes: computed(() => ({ ...classes.value, [`control-file--${dragState.value}`]: true })),
    }
  },
})

export default ControlFileUpload
</script>
<style lang="stylus">
@import '../../styles/variables.styl'

.control-file-upload
  position: relative
  display: flex
  flex-flow: column
  &__container
    flex-grow: 1
.file-list
  padding: 4px 0
.file-list-item
  display: flex
  align-items: center
  border-top: 1px solid #eee
  padding: 4px 0
  &:last-child:first-child
    padding: 16px 0 4px
  &__text
    color: var(--color-text)
    flex-grow: 1
</style>
