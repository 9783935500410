<template lang="pug">
modal(
  :id='id'
  :visible='true'
  :closeable='closeable'
  :size='size || "xs"'
  :data-test-confirm='id'
  :class='{ [`modal-confirm--${layout || "vertical"}`]: true }'
  class='modal-confirm'
  @close='onCancelCallback'
)
  modal-header
    span(v-html='title || $t("Are you sure?")')
    template(#icon)
      icon(v-if='icon' :name='icon' v-bind='iconBindings' class='mb-4 text-3xl')
    template(#below)
      div(v-if='content' class='modal-confirm__content' v-html='content')
  form(@submit.prevent.stop='onOkCallback')
    control-label(v-if='inputOptions' ifta :label='inputOptions.label' class='modal-confirm__inputLabel') 
      control-textarea(v-if='inputOptions.multiline' v-model='localValue' autofocus v-bind='inputOptions.inputBindings || {}')
      control-input(v-else-if='!inputOptions.options' v-model='localValue' autofocus v-bind='inputOptions.inputBindings || {}')
      control-select(v-else v-model='localValue' :options='inputOptions.options' autofocus v-bind='inputOptions.inputBindings || {}')
    div(class='modal-confirm__footer')
      btn(htmlType='submit' v-bind='okButtonBindings' data-test='btn-confirm-ok')
        | {{ okText || $t('Yes') }}
      btn(v-if='!hideCancelButton' v-bind='cancelButtonBindings || { secondary: true }' data-test='btn-confirm-cancel' @click='onCancelCallback')
        | {{ cancelText || $t('No') }}
      template(v-if='options.onAbort')
        btn(v-bind='abortButtonBindings || { secondary: true }' data-test='btn-confirm-abort' @click='onAbortCallback')
          | {{ abortText || $t('Abort') }}
</template>

<script setup lang="ts">
import Btn from './Btn.vue'
import Modal from './Modal.vue'
import { type IConfirmData } from '@/plugins/Confirm'
import { type PropType } from 'vue'
import { ref } from 'vue'

const props = defineProps({
  id: { type: String, required: true },
  options: { type: Object as PropType<IConfirmData>, required: true },
})
const emit = defineEmits<{ close: [id: string, value: any] }>()
const localValue = ref(props.options.inputOptions?.initialValue || '')
const close = (id: string, value?: string | number) => emit('close', id, value)
const {
  okText,
  cancelText,
  cancelButtonBindings,
  hideCancelButton,
  inputOptions,
  abortButtonBindings,
  content,
  icon,
  iconBindings,
  size,
  closeable,
  okButtonBindings,
  abortText,
  title,
  layout,
} = props.options
const onOkCallback = () => (props.options.onOk?.(localValue.value), close(props.id, localValue.value))
const onCancelCallback = () => (props.options.onCancel?.(undefined), close(props.id))
const onAbortCallback = () => (props.options.onAbort?.(undefined), close(props.id))
</script>
<style lang="stylus">
@import '../styles/variables.styl'

.modal-confirm
  --modal-offset-top: @css{max(30px, calc(50vh - 260px))}
  --modal-confirm-flow: column
  &--horizontal
    --modal-confirm-flow: row-reverse
  &--vertical
    button
      width: 100%
      justify-content: center
  &__icon
    font-size: 30px
    position: relative
    align-items: flex-start!important
    margin-top: 5px
    stroke-width: 2px
  &__footer
    display: flex
    // justify-content: flex-end
    flex-flow: var(--modal-confirm-flow)
    gap: 12px
  &__title
    padding-top: 10px
    align-content: center
    margin-bottom: 10px
    font-size: 19px
    line-height: 1.35
    font-weight: 800
    font-family: $font-lato
    b
      font-weight: 800
    span
      font-weight: 400
  &__content
    font-size: 16px
    margin-bottom: 10px
</style>
