import { pick } from 'radash'
import { type App, type Plugin, computed, inject } from 'vue'

export interface IFeatureFlags {
  [name: string]: {
    description: string
  }
}
export const Flags: IFeatureFlags = {
  ENABLE_HELPCENTER: { description: 'Enable new helpcenter feature.' },
  ENABLE_NEW_DASHBOARD: { description: 'New Dashboard.' },
  ENABLE_HELPCENTER_LOCAL: { description: 'Helpcenter Local.' },
  DISABLE_DISCARDABLES: { description: 'Disable discardable popover messages.' },
  DISABLE_TRANSITIONS: { description: 'Disable transitions for e2e tests.' },
  DISABLE_FOCUS_LOCK: { description: 'Disable focus lock feature on modals etc' },
  DISABLE_PRODUCT_TOUR: { description: 'Disable automatic product tour launch' },
  ENABLE_GTM_EVENTS: { description: 'Enable Google Tag Manager Events for nuucon users' },
  ENABLE_INTERCOM_FOR_NUUCON_USERS: { description: 'Enable intercom for nuucon users' },
  DISABLE_INTERCOM: { description: 'Disable Intercom entirely' },
  ENABLE_CLAIMS_FEATURE: { description: 'Enable Claims Feature' },
  SHOW_ALL_PLATFORM_UPDATES: { description: '' },
  ENABLE_PCON_IMAGES_FROM_CANVAS: { description: '' },
}

export type FlagKeys = keyof typeof Flags
export type FeatureFlags = { [key: FlagKeys]: boolean }
export const featureFlags: FeatureFlags = {}

export const useFeatureFlags = () => {
  const $flags = inject<FeatureFlags>('$flags')!
  return $flags
}
export const useFeatureFlagsManipulation = (keys?: Array<keyof typeof Flags>) => {
  const flags = computed(() =>
    Object.entries(keys ? pick(Flags, keys) : Flags).map(([flag, { description }]) => ({ flag, description })),
  )
  const update = (feature: string, value: boolean) => (localStorage.setItem(feature, `${value}`), location.reload())
  return { flags, update }
}

Object.keys(Flags)
  .map((feature: string) => ({
    feature,
    activatedByUrl: document.location.href.includes(`${feature}=true`),
    deactivatedByUrl: document.location.href.includes(`${feature}=false`),
    activatedByLocalStorage: localStorage.getItem(feature) === 'true',
  }))
  .forEach(({ feature, activatedByUrl, deactivatedByUrl, activatedByLocalStorage }) => {
    const activated: boolean = activatedByUrl || activatedByLocalStorage
    featureFlags[feature] = !deactivatedByUrl && activated
    localStorage.setItem(feature, `${activated}`)
    if (activated) {
      document.head.insertAdjacentHTML(
        'beforeend',
        `<style type="text/css">[data-feature-flag="${feature}"] {display: block!important}</style>`,
      )
      document.body.classList.add(feature)
    }
  })
const FeatureFlagsPlugin: Plugin = {
  install(app: App) {
    app.config.globalProperties.$flags = featureFlags
    app.provide('$flags', featureFlags)
  },
}
export default FeatureFlagsPlugin

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $flags: FeatureFlags
  }
}
