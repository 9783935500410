<template lang="pug">
pill(:type='STATUS_MAP[props.status] || "drafted"' :data-status='status' :block='block' class='') {{ $t(status) }}
</template>

<script setup lang="ts">
import { type InquiryStatus } from '../types'
import Pill, { type PillColorOptions } from '@/components/Pill.vue'

const STATUS_MAP: Record<InquiryStatus, PillColorOptions> = {
  drafted: 'faded',
  inquired: 'purple',
  processing: 'blue',
  offered: 'fuchsia',
  accepted: 'success',
  declined: 'error',
  expired: 'orange',
  declinedWithChangeRequest: 'error',
  rejectedByOperator: 'warning',
}

const props = defineProps<{ status: InquiryStatus; block?: boolean }>()
</script>
