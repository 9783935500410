import { emitter } from '@/common'
import type {
  IFormResponse,
  IFormResponseError,
  IFormResponseInfo,
  IGenericFormConfig,
  INotification,
} from '@/components/GenericForm/types'
import { type ISearchAttributeMap, type ISearchAttributesGroups } from '@/components/List/types'
import { useMessages } from '@/components/Message'
import { type IMessage } from '@/components/Message/index'
import { useEscapeKey } from '@/plugins/EscapeKey'
import { type IUserDataExtended, useUser } from '@/plugins/User'
import { type IUtils } from '@/plugins/Utils'
import { useTranslation } from '@/plugins/translation'
import { type IOption, type ResultMatch } from '@/types'
import { objectToFormData } from '@/utilities/Form'
import {
  type MaybeRef,
  type UseTimeAgoMessages,
  type UseTimeAgoUnitNamesDefault,
  reactivePick,
  useEventListener,
  useKeyModifier,
  useSessionStorage,
  useTimeAgo,
  useTitle,
} from '@vueuse/core'
import { type Breakpoints, useBreakpoints } from '@vueuse/core'
import { createGlobalState } from '@vueuse/core'
import { useFuse } from '@vueuse/integrations/useFuse'
import { AxiosError, type AxiosResponse } from 'axios'
import Axios from 'axios-observable'
import { type Handler } from 'mitt'
import { defineStore } from 'pinia'
import { isArray, mapValues, objectify, toggle, unique } from 'radash'
import { lastValueFrom, of, throwError } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { computed, inject, onMounted, onUnmounted, ref, type Ref, watch, type WritableComputedRef } from 'vue'
import { useRoute } from 'vue-router'

export const useOnSuccess = <T>(endpointPartials: string | string[], callback: (data: IFormResponseInfo) => any) => {
  const onSubmitSuccess = (data: IFormResponseInfo) =>
    (isArray(endpointPartials) ? (endpointPartials as string[]) : [endpointPartials as string]).some((s: string) =>
      data!.endpoint?.includes(s),
    ) && callback(data)

  onMounted(() => emitter.on('submitSuccess', onSubmitSuccess as Handler<IFormResponseInfo<T>>))
  onUnmounted(() => emitter.off('submitSuccess', onSubmitSuccess as Handler<IFormResponseInfo<T>>))
}

export const useOnError = (endpointPartials: string | string[], callback: (data: IFormResponseError) => any) => {
  const onSubmitError = (data: IFormResponseError) =>
    (isArray(endpointPartials) ? (endpointPartials as string[]) : [endpointPartials as string]).some((s: string) =>
      data!.endpoint?.includes(s),
    ) && callback(data)

  onMounted(() => emitter.on('submitError', onSubmitError as Handler<IFormResponseError>))
  onUnmounted(() => emitter.off('submitError', onSubmitError as Handler<IFormResponseError>))
}

export const useFetchData = <T>(endpoint: string, initialValue?: T) => {
  const loading = ref(false)
  const error = ref(false)
  const data = initialValue ? ref<T>(initialValue) : ref<T>()
  return {
    loading,
    data,
    fetchData(callback?: (data: T) => any) {
      loading.value = true
      error.value = false
      Axios.get(endpoint).subscribe({
        next: (r: AxiosResponse<T>) => {
          loading.value = false
          error.value = false
          data.value = r.data as T
          if (callback) {
            callback(r.data)
          }
        },
        error: () => {
          loading.value = false
          error.value = true
        },
      })
    },
  }
}

export const useNotificationMessages = () => {
  const { $msg } = useMessages()
  return {
    showNotificationMessages: (config?: IGenericFormConfig) => {
      const { notifications, items } = config || {
        notifications: [] as INotification[],
        items: {} as IGenericFormConfig,
      }
      const allNotifications: INotification[] = [
        ...notifications!,
        ...Object.entries(items!).flatMap?.(([key, item]) =>
          (item.notifications || []).map((n: INotification) => ({ ...n, message: `${n.message} (${key})` })),
        ),
      ]
      unique(allNotifications, (n) => n.message).forEach(({ message, type }) => $msg[type as keyof IMessage](message))
    },
  }
}

export const useRequest = () => {
  const loading = ref(false)
  const error = ref(false)
  const request = <T, D>(method: 'POST' | 'GET', url: string, data?: D) => (
    (loading.value = true),
    Axios.request<T>({ url, method, data }).pipe(
      tap({
        next: () => {
          error.value = false
          setTimeout(() => (loading.value = false), 30)
        },
        error: () => {
          error.value = true
          loading.value = false
        },
      }),
    )
  )
  return {
    loading,
    error,
    post: <T, D = any>(url: string, data: D) => request<T, D>('POST', url, data),
    get: <T, D = any>(url: string) => request<T, D>('GET', url),
  }
}

export interface IFormData {
  [key: string]: string | boolean | number | string[]
}

export const thorFormPostPromise = <T>(endpoint: string, data: Record<string, any>, id?: string) =>
  lastValueFrom(thorFormPost<T>(endpoint, data, id))

export const thorFormPostRequest = <T>(endpoint: string, data: Record<string, any>) =>
  Axios.post<T>(endpoint, objectToFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' },
  })

export const isIFormResponse = (arg: any): arg is IFormResponse => {
  return arg.endpoint !== undefined
}

export const thorFormPost = <T>(endpoint: string, formData: Record<string, any>, id?: string) =>
  thorFormPostRequest<IFormResponse<T>>(endpoint, formData).pipe(
    map(
      ({ data }) =>
        ({
          formId: id || endpoint,
          endpoint,
          formData,
          response: data,
          data: data.data,
          action: endpoint?.split('?')[0].split('/').pop(),
        }) as IFormResponseInfo<T>,
    ),
    catchError((error) =>
      throwError(
        () =>
          ({
            endpoint,
            formData,
            error,
          }) as IFormResponseError,
      ),
    ),
    tap({
      next: (responseInfo) => responseInfo.response.valid === true && emitter.emit('submitSuccess', responseInfo),
      error: (responseError) => emitter.emit('submitError', responseError),
    }),
  )

export const useThorForm = () => {
  const loading = ref(false)
  const loadingMap = ref<Record<string, boolean>>({})
  return {
    loading,
    loadingMap,
    get: <T>(endpoint: string) => {
      loading.value = true
      loadingMap.value[endpoint] = true
      return Axios.get<T>(endpoint).pipe(
        map((response) => response.data),
        tap({
          complete: () => {
            loading.value = false
            loadingMap.value[endpoint] = false
          },
        }),
      )
    },
    post: <T>(endpoint: string, data: Record<string, any>, id?: string) => {
      loading.value = true
      loadingMap.value[id || endpoint] = true
      return thorFormPost<IFormResponse<T>>(endpoint, data).pipe(
        tap({
          next: () => {
            loading.value = false
            loadingMap.value[id || endpoint] = false
          },
          error: () => {
            loading.value = false
            loadingMap.value[id || endpoint] = false
          },
        }),
      )
    },
  }
}

export const useInitByRouteQuery = (key: string, callback: () => any) => {
  const $route = useRoute()
  const $utils = inject<IUtils>('$utils')!
  const reset = () => $utils.routeQueryRemove(key)
  watch(
    () => $route.query,
    (value) => !!value[key] && (reset(), callback()),
  )
  onMounted(() => $route.query[key] && (reset(), callback()))
}

export const useFetchForm = () => {
  const loading = ref(false)
  const error = ref(false)
  return {
    loading,
    error,
    fetch(endpoint: string) {
      loading.value = true
      error.value = false
      return Axios.get<IGenericFormConfig>(endpoint).pipe(
        // @TODO: make sure this is always the case
        mergeMap((r) => (r.data.id !== undefined ? of(r) : throwError(() => r))),
        tap({
          next: (r) => {
            error.value = false
            loading.value = false
            return r.data
          },
          error: (e: AxiosError) => {
            error.value = true
            loading.value = false
            return e
          },
        }),
        map((r: AxiosResponse) => r.data),
      )
    },
  }
}

export interface IGenericKeyValueStore {
  values: {
    [key: string]: any
  }
}

export const useGenericKeyValueStore = defineStore({
  id: 'genericKeyValue',
  state: () => ({ values: {} }) as IGenericKeyValueStore,
  getters: {
    getValues: (state: any) => state.values,
    getValue: (state: any) => (id: string) => state.values[id],
  },
  actions: {
    setValue(id: string, value: any) {
      this.values[id] = value
    },
  },
})

export const store: Record<string, any> = ref({})
export const useShallowStore = <T = any>(id: string, initialValue: T) => {
  const store = useSessionStorage<T>(id, initialValue, { shallow: true, writeDefaults: false })
  return computed({ get: () => store.value, set: (value: T) => (store.value = value) })
}

export const parseValues = (object: { [key: string]: string }) =>
  Object.fromEntries(Object.entries(object).map(([key, value]) => [key, JSON.parse(value as string)]))

export type IUserSettings<T> = { [key: string]: T }
export const useUserSettings = <T>() => {
  const $user = inject<IUserDataExtended>('$user')!
  const update = async (key: string, value: T) => {
    const response = await thorFormPostPromise(`/_/UserSettingsForm/Update`, {
      SettingKey: key,
      SettingValue: JSON.stringify(value),
    })
    $user.settings = parseValues(response.response as any) as T
  }

  return {
    update,
    $user,
  }
}
export const useUserSettingsValue = <T>(key: string, defaultValue: T) => {
  const { update, $user } = useUserSettings()
  return computed({
    get: () => ($user.settings?.[key] as T) ?? defaultValue,
    set: (value: T) => update(key, value),
  })
}

export const useEmitterOn = <T = any>(key: string, callback: Handler<T>) => {
  onMounted(() => emitter.on(key, callback))
  onUnmounted(() => emitter.off(key, callback))
}
export const highlight = (text: string, regions: ReadonlyArray<[number, number]>) => {
  if (!regions) return text
  let content = '',
    nextUnhighlightedRegionStartingIndex = 0
  regions.forEach((region: [number, number]) => {
    content +=
      '' +
      text.substring(nextUnhighlightedRegionStartingIndex, region[0]) +
      '<mark>' +
      text.substring(region[0], region[1] + 1) +
      '</mark>' +
      ''
    nextUnhighlightedRegionStartingIndex = region[1] + 1
  })
  content += text.substring(nextUnhighlightedRegionStartingIndex)
  return content
}

export const useMatches = (obj: Ref<Record<string, string>>, matches: Ref<ResultMatch[]>) => {
  const groupedMatches = computed(() => objectify(matches.value, (m) => m.key!))
  return computed(() =>
    mapValues(obj.value, (value, key) =>
      groupedMatches.value[key] ? highlight(value, groupedMatches.value[key]!.indices) : value,
    ),
  )
}

const toNestedSearchAttributeMap = (map: ISearchAttributesGroups) =>
  Object.fromEntries(
    Object.entries(map).map(([attributeKey, item]) => [attributeKey, objectify(item.values, (item) => item.id)]),
  ) as { [attributeKey: string]: ISearchAttributeMap }

export const useSearchAttributes = (endpoint: string, onAttributesReady?: () => any) => {
  const initialAttributes = useSessionStorage<ISearchAttributesGroups>(endpoint, {})
  const attributesReady = ref(false)
  const attributesError = ref(false)

  const loadAttributes = () =>
    Axios.get(endpoint).subscribe({
      next: (response: AxiosResponse<ISearchAttributesGroups>) => {
        initialAttributes.value = response.data
        attributesReady.value = true
        if (onAttributesReady) {
          onAttributesReady()
        }
      },
      error: () => (attributesError.value = true),
    })

  const initialAttributesAsMaps = computed<{ [attributeKey: string]: ISearchAttributeMap }>(() =>
    toNestedSearchAttributeMap(initialAttributes.value),
  )

  return {
    initialAttributesAsMaps,
    initialAttributes,
    attributesValuesOptions: computed<Record<string, IOption<string, string>[]>>(() =>
      mapValues(initialAttributes.value, (group) =>
        group.values.map((item) => ({
          label: item.title,
          value: item.id,
        })),
      ),
    ),
    attributesReady,
    loadAttributes,
  }
}

export const useDelegatedClickEventListener = (
  element: MaybeRef<HTMLElement | null>,
  selector: string,
  callback: (clickedElement: HTMLElement, event: Event) => void,
  stopPropagation: boolean = false,
  ignoreSelectors?: string,
) => {
  useEventListener(element, 'keydown', (e: KeyboardEvent) => {
    if (['Space', 'Enter'].includes(e.code) && document.activeElement?.matches(selector)) {
      e.preventDefault()
      callback(document.activeElement as HTMLElement, e)
    }
  })
  useEventListener(element, 'click', (e) => {
    if (ignoreSelectors && (e.target as HTMLElement)?.matches(ignoreSelectors)) {
      return false
    }
    const target: HTMLElement | null = (e.target as HTMLElement).closest(selector)
    if (target) {
      if (stopPropagation) {
        e.stopPropagation()
        e.stopImmediatePropagation()
      }
      e.preventDefault()
      callback(target!, e)
    }
  })
}

export const useTranslatedTimeAgo = (date: Date) => {
  const { $t, $tc } = useTranslation()
  return useTimeAgo(date, {
    messages: {
      justNow: $t('just now'),
      invalid: 'invalid',
      past: (n) => (n.match(/\d|einer|einem/) ? $t('{0} ago', '', [n]) : n),
      future: (n) => (n.match(/\d|einer|einem/) ? $t('in {0}', '', [n]) : n),
      month: (n, past) => (n === 1 ? (past ? $t('last month') : $t('next month')) : $tc('{0} month | {0} months', n)),
      year: (n, past) => (n === 1 ? (past ? $t('last year') : $t('next year')) : $tc('{0} year | {0} years', n)),
      day: (n, past) => (n === 1 ? (past ? $t('yesterday') : $t('tomorrow')) : $tc('{0} day | {0} days', n)),
      week: (n, past) => (n === 1 ? (past ? $t('last week') : $t('next week')) : $tc('{0} week | {0} weeks', n)),
      hour: (n) => $tc('{0} hour | {0} hours', n),
      minute: (n) => $tc('{0} minute | {0} minutes', n),
      second: (n) => $tc('{0} second | {0} seconds', n),
    } as UseTimeAgoMessages<UseTimeAgoUnitNamesDefault>,
  })
}

export const usePageTitle = (title: MaybeRef<string>) => useTitle(title, { titleTemplate: 'nuucon - %s' })

export const useSelectItems = (
  selected: WritableComputedRef<string[]> | Ref<string[]>,
  container: Ref<HTMLElement | null>,
  selector: string = '[data-id]',
  attribute: string = 'data-id',
) => {
  const getAll = () =>
    Array.from(container.value?.querySelectorAll(`${selector}`) || []).map((e) => e.getAttribute(attribute)) as string[]
  const selectedMap = computed(() => Object.fromEntries(selected.value.map((id) => [id, true])))
  const shiftState = useKeyModifier('Shift')
  let lastSelectedId: string | undefined = undefined
  const toggleAllInbetween = (selected: string[], all: string[], startId: string, endId: string) => {
    const isStartValueSelected = selected.includes(startId)
    const startIndex = all.indexOf(startId)
    const endIndex = all.indexOf(endId)
    const range = startIndex < endIndex ? all.slice(startIndex, endIndex + 1) : all.slice(endIndex, startIndex)
    return !isStartValueSelected ? selected.filter((id) => !range.includes(id)) : unique([...selected, ...range])
  }
  const toggleSelect = (id: string) => {
    selected.value =
      shiftState.value && lastSelectedId
        ? toggleAllInbetween(selected.value, getAll(), lastSelectedId, id)
        : toggle(selected.value, id)
    lastSelectedId = id
  }
  const allSelected = computed(() => selected.value.length > 0 && selected.value.length === getAll().length)
  const toggleSelectAll = () => (selected.value = allSelected.value ? [] : getAll())
  const selectAll = () => (selected.value = getAll())
  const hasSelected = computed(() => selected.value.length > 0)
  const clearSelected = () => (selected.value = [])
  const selectState = computed(() => (selected.value.length === 0 ? 'none' : allSelected.value ? 'all' : 'partial'))
  useEscapeKey(() => (hasSelected.value ? (clearSelected(), true) : false))

  watch(
    () => container.value,
    (containerElement) =>
      containerElement &&
      useDelegatedClickEventListener(
        containerElement,
        selector,
        (target) => target.getAttribute(attribute) && toggleSelect(target.getAttribute(attribute)!),
        undefined,
        'button *, button, a, a *, input, td.editable, td.editable *',
      ),
  )

  const allModel = computed<boolean>({
    set: (value: boolean) => (value ? clearSelected() : selectAll()),
    get: () => allSelected.value,
  })

  return { toggleSelect, toggleSelectAll, selectState, selectedMap, allSelected, hasSelected, clearSelected, allModel }
}

export const customBreakpoints: Breakpoints = {
  xs: 450,
  sm: 768,
  md: 1024,
  lg: 1441,
  xl: 1536,
  '2xl': 2000,
}

export const useCustomBreakpoints = () =>
  reactivePick(useBreakpoints(customBreakpoints), 'sm', 'lg', 'xs', 'md', 'xl', '2xl')
export const useCurrentBreakpoint = () =>
  computed<string>(() => useBreakpoints(customBreakpoints).current().value.pop() as string)

export const useLogin = () => {
  const redirectToLogin = () => window.location.assign('/login.html?from=manufacturer')
  const $user = useUser()
  const updateLoggedInState = (loggedIn: boolean) => !loggedIn && redirectToLogin()
  watch(() => $user.isLoggedIn, updateLoggedInState)
  updateLoggedInState($user.isLoggedIn)

  return {
    redirectToLogin,
    logout: async () => {
      await fetch('/_/Logout')
      redirectToLogin()
    },
  }
}

export const useCollapsePersisted = (storageKey: string = 'collapse', keys?: Ref<string[]>) => {
  const collapsed = useSessionStorage<Record<string, boolean>>(storageKey, {})
  const set = (ids: string[], value: boolean) =>
    (collapsed.value = { ...collapsed.value, ...Object.fromEntries(ids.map((id) => [id, value])) })
  return {
    collapsed,
    collapseAll: () => keys && set(keys!.value, true),
    expandAll: () => keys && set(keys!.value, false),
    areAllItemsCollapsed: computed(() => !!keys && keys.value.every((key) => !!collapsed.value[key])),
    areAllItemsExpanded: computed(() => !!keys && keys.value.every((key) => !collapsed.value[key])),
    toggle: (id: string) => (collapsed.value[id] = !collapsed.value[id]),
  }
}

export const useGlobalFileState = createGlobalState(() => {
  const file = ref<File | null>(null)
  return { file }
})

export const useGlobalCsvOrXlsImportState = createGlobalState((initialFieldMap: Record<string, string>) => {
  const data = ref<Record<string, any>[]>([])
  const fieldMap = ref<Record<string, string>>(initialFieldMap)
  const keys = ref<string[]>([])
  return { data, fieldMap, keys }
})

export const splitStringForExtendedSearch = (value: string | undefined) =>
  value ? "'" + value?.split(' ').join(" | '") : ''

export const useFuzzySearch = <T>(query: Ref<string | undefined>, items: Ref<T[]>, keys: string[]) =>
  useFuse(
    computed(() => splitStringForExtendedSearch(query.value)),
    items,
    {
      matchAllWhenSearchEmpty: true,
      fuseOptions: {
        keys,
        useExtendedSearch: true,
        minMatchCharLength: 2,
        threshold: 0.1,
        ignoreLocation: true,
        includeMatches: true,
      },
    },
  )

export const usePaginatedResults = <T>(items: Ref<Array<T>>) => {
  const page = ref(1)
  const pageSize = ref(36)
  const total = computed(() => items.value.length)
  return {
    pageSize,
    total,
    totalPages: computed(() => Math.ceil(total.value / pageSize.value)),
    page,
    paginatesResults: computed(() =>
      items.value.slice(page.value * pageSize.value - pageSize.value, page.value * pageSize.value),
    ),
  }
}
