<template lang="pug">
teleport(v-if='visible || loading' to='#content')
  div(ref='container' v-focus-trap='visible' :data-content='value' :style='styles' data-test='editable-cell-text-overlay' class='relative z-[99]')
    control-textarea(ref='textarea' v-model='value' class='text-sm' style='--control-font-size: 14px; --control-padding-x: 4px; --control-padding-y: 6px')
    div(class='absolute right-0 top-[100%] flex gap-2')
      btn(icon='check' :loading='loading' data-test-btn='submit' data-test='btn-submit-comment' class='shadow-xl' @click='submit')
      btn(icon='x' :disabled='loading' secondary data-test-btn='cancel' data-test='btn-cancel-comment' class='!bg-white shadow-xl' @click='() => cancel(true)')
</template>

<script setup lang="ts">
import ControlTextarea from '@/components/Control/ControlTextarea.vue'
import { useDelegatedClickEventListener } from '@/composables/'
import { autoUpdate, useFloating, offset } from '@floating-ui/vue'
import { onKeyStroke } from '@vueuse/core'
import { nextTick, ref, computed, type PropType } from 'vue'

const props = defineProps({
  loading: Boolean,
  selector: { type: String, required: true },
  getIdAndContentFn: {
    type: Function as PropType<(el: HTMLElement) => { id: string; content: string }>,
    required: true,
  },
})

const emit = defineEmits<{ cancel: [id: string]; submit: [id: string, value: string] }>()
const value = ref<string>('')
const visible = ref<boolean>(false)
const valueStored = ref<Record<string, string>>({})
const textarea = ref<InstanceType<typeof ControlTextarea> | null>(null)

const width = ref('100px')
const minHeight = ref('80px')
const currentId = ref('')

const currentOffset = ref(-60)
const middleware = computed(() => [offset(currentOffset.value)])
const target = ref<HTMLElement | null>()
const container = ref<HTMLElement | null>()
const { floatingStyles } = useFloating(target, container, {
  whileElementsMounted: autoUpdate,
  placement: 'bottom-start',
  middleware,
})
const styles = computed(() => ({
  ...floatingStyles.value,
  width: width.value,
  '--control-textarea-min-height': minHeight.value,
}))

const cancel = (reset: boolean = true) => {
  if (!reset) {
    valueStored.value[currentId.value] = value.value
  } else {
    valueStored.value[currentId.value] = ''
  }

  emit('cancel', currentId.value)
  visible.value = false
}

useDelegatedClickEventListener(
  document.body,
  props.selector,
  (targetElement) => {
    if (visible.value && currentId.value && value.value) {
      valueStored.value[currentId.value] = value.value
    }

    visible.value = true
    const rect = targetElement.getBoundingClientRect()
    currentOffset.value = rect.height * -1 - 1
    width.value = `${rect.width + 2}px`
    minHeight.value = `${rect.height + 2}px`
    const { id, content } = props.getIdAndContentFn(targetElement)
    currentId.value = id
    value.value = valueStored.value[id] || content
    nextTick(() => {
      console.log(targetElement, container.value)
      target.value = targetElement
    })
    nextTick(() => textarea.value?.focus())
  },
  true,
)

onKeyStroke('Escape', () => cancel(false))

const submit = () => {
  valueStored.value[currentId.value] = ''
  emit('submit', currentId.value, value.value)
  visible.value = false
}
</script>
