<template lang="pug">
div(class='control-file-container')
  div(ref='container' :class='classes' class='control-file')
    overlay(icon='download' :visible='dragState === "over"' class='control-file__overlay')
    input(:id='id' ref='file' :name='id' type='file' :multiple='multiple ? "multiple" : ""' :accept='accept' :disabled='disabled' class='control-file__input' @input='onChangeInput')
    label(:for='id' tabindex='1' class='control-file__label')
      div(class='control-file__body')
        div(class='control-file__icon')
          icon(:name='icon')
        div(class='control-file__text') 
          div(class='control-file__placeholder' v-html='placeholder || $t("Drop files here or <a>browse</a>.")')
          div(v-if='info' class='control-file__info' v-html='info')

    div(class='align-items flex')
      slot(name='inner')
    div(v-if='$slots.default' class='control-file__footer')
      slot
</template>

<script lang="ts">
import { type IMessage } from '../Message'
import { Input, useControl } from './composables'
import Icon from '@/components/Icon.vue'
import { useTranslation } from '@/plugins/translation'
import { fromEvent, merge } from 'rxjs'
import { debounceTime, skipWhile, tap, throttleTime } from 'rxjs/operators'
import { computed, defineComponent, inject, onMounted, ref, type Ref, type SetupContext } from 'vue'

const initFileDragDrop = (
  el: HTMLElement,
  onDragEnter: (e: MouseEvent) => any,
  onDragLeave: (e: MouseEvent) => any,
  onDrop: (e: MouseEvent) => any,
) => {
  const dragenter$ = merge(
    fromEvent<MouseEvent>(el, 'dragover'),
    fromEvent<MouseEvent>(el, 'dragenter'),
    fromEvent<MouseEvent>(el, 'dragstart'),
  )
  const dragleave$ = merge(fromEvent<MouseEvent>(el, 'dragend'), fromEvent<MouseEvent>(el, 'dragleave'))
  const drop$ = fromEvent<MouseEvent>(el, 'drop')
  const stopEvent = (e: MouseEvent) => (e.stopImmediatePropagation(), e.preventDefault())
  let dragging: boolean = false
  dragenter$
    .pipe(
      tap(stopEvent),
      throttleTime(100),
      tap(() => (dragging = true)),
    )
    .subscribe(onDragEnter)
  dragleave$
    .pipe(
      tap(stopEvent),
      debounceTime(100),
      skipWhile((e: MouseEvent) => el.contains(e.target as Node) || !dragging),
    )
    .subscribe(onDragLeave)
  drop$
    .pipe(
      tap(stopEvent),
      tap(() => (dragging = false)),
    )
    .subscribe(onDrop)
}

const validateMimeTypes = (e: MouseEvent | any, accept: string) => {
  const files: FileList = e.target.files || e.dataTransfer.files
  if (!files.length) {
    return false
  }
  const acceptAsArray = accept.split(',').map((a) => a.trim().replaceAll('*', ''))
  return Array.from(files).every((f: File) => acceptAsArray.some((a) => f.type.includes(a) || f.name.includes(a)))
}

const ControlFile = defineComponent({
  components: {
    Icon,
  },
  props: {
    ...Input.props,
    modelValue: Array,
    placeholder: String,
    info: String,
    multiple: Boolean,
    icon: { type: String, default: 'upload' },
    centered: Boolean,
    clearAfterSelect: Boolean,
    accept: {
      type: String,
      default: 'image/*',
    },
  },
  emits: ['change', 'update:modelValue'],
  setup(props: any, context: SetupContext) {
    const $msg = inject<IMessage>('$msg')!
    const { $t } = useTranslation()
    const { value: val, classes, onSubmit, onChange, isEmpty } = useControl<File[]>(props, context, [])
    const { clearAfterSelect, accept } = props
    const dragState: Ref<string> = ref('default')
    const hasFiles = computed(() => !!(val.value?.length > 0))
    const error = ref(false)
    const container = ref<HTMLElement | null>(null)
    const mask = ref<HTMLElement | null>(null)

    onMounted(() =>
      initFileDragDrop(
        container.value!,
        () => ((error.value = false), (dragState.value = 'over')),
        () => ((error.value = false), console.log('ondDragLEave'), (dragState.value = 'default')),
        (e: MouseEvent) => (
          validateMimeTypes(e, accept) ? onChangeInput(e) : $msg.error($t('File type not supported.')),
          (dragState.value = 'default')
        ),
      ),
    )

    const onChangeInput = (e: any) => {
      const files: FileList = e.target.files || e.dataTransfer.files
      val.value = Array.from(files)
      error.value = false
      onChange()
      if (clearAfterSelect) {
        val.value = []
      }
    }

    return {
      isEmpty,
      dragState,
      container,
      mask,
      error,
      onChangeInput,
      clear: () => {
        error.value = false
        val.value = []
      },
      hasFiles,
      onSubmit,
      val,
      classes: computed(() => ({
        ...classes.value,
        'control-file--disabled': props.disabled,
        'control-file--centered': props.centered,
        [`control-file--${dragState.value}`]: true,
      })),
    }
  },
})

export default ControlFile
</script>
<style lang="stylus">
@import '../../styles/variables.styl'

.control-file
  &, &-container, &__label
    display: flex
    flex-flow: column
    justify-content: center
    flex-grow: 1

:root
    --control-file-borer-radius:  4px
    --control-file-icon-size:  30px
    --control-file-flex-flow: row
    --control-file-align-items: flex-start
.control-file
  position: relative
  color: var(--grey-500)
  border-radius: var(--control-file-borer-radius)
  background: var(--grey-50)
  border: 1px solid var(--grey-200)
  flex-grow: 1
  &--disabled
    &, *
      pointer-events: none!important
    a
      color: var(--color-text-light)
  &--centered
    --control-file-icon-size: 60px
    --control-file-flex-flow: column
    --control-file-align-items: center
  &--over
    background: var(--grey-200)
  &__text
    display: flex
    flex-flow: column
    align-items: var(--control-file-align-items)
  &__info
    font-weight: 100
    font-size: 13px
  &__overlay
    border-radius: var(--control-file-borer-radius)
    color: var(--grey-600)
  &__label
    padding: 20px
    display: flex
    flex-wrap: wrap
    align-items: center
    cursor: pointer
    font-size: 16px
    border-radius: 5px
    transition: all .2s ease
    a
      pointer-events: all
      text-decoration: underline
      transition: all .2s ease
    &:hover
      &, a
        color: var(--grey-800)
  ~/ &__icon
    font-size: var(--control-file-icon-size)
    margin-right: 10px
    opacity: 0.5
  &__body
    display: flex
    align-items: center
    gap: 10px
    flex-flow: var(--control-file-flex-flow)
    min-height: 100px
    ~/__label:not(:last-child) &
      min-height: 30px
  &__footer
    padding: 0 20px 0
  &__input
    height: 0
    overflow: hidden
    width: 0
    position: absolute
  .control-label--hasError &
    border: 1px solid var(--red-600)
</style>
