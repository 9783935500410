<template lang="pug">
div(class='empty-state-small')
  icon(name='info' class='empty-state-small__icon')
  slot
    | {{ $t('no results...') }}
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const Empty = defineComponent({})
export default Empty
</script>

<style lang="stylus">
@import '../styles/variables.styl'
.empty-state-small
  faded()
  margin: 10px 0
  position: relative
  line-height: 1.4
  padding: 3px 0
  display: flex
  &:first-child
    padding-top: 6px
  &__icon.icon
    font-size: 20px
    margin-right: 5px
    display: inline-block
    position: relative
</style>
