<template lang="pug">
div
  p(v-if='product.description && !hideDescription' class='text-lg mb-12 leading-relaxed' v-html='product.description')
  collapse
    collapse-item(:title='$t("Technical information")' isOpenByDefault)
      div(v-if='product.additionalInformation')
        h5(class='font-light') {{ $t('Additional information') }}
        p(class='text-base mb-8' v-html='product.additionalInformation')
      div(v-if='product.colorDescription')
        h5(class='font-light') {{ $t('Color description') }}
        p(class='text-base mb-8' v-html='product.colorDescription')
      div(v-if='product.materialDescription')
        h5(class='font-light') {{ $t('Material description') }}
        p(class='text-base mb-8' v-html='product.materialDescription')
      div(v-if='product.measurementsDescription')
        h5(class='font-light') {{ $t('Measurements description') }}
        p(class='text-base mb-8' v-html='product.measurementsDescription')
      div(v-if='product.technicalInformation')
        h5(class='font-light') {{ $t('Technical information') }}
        p(class='text-base mb-8' v-html='product.technicalInformation')
      div(class='flex flex-wrap gap-y-1')
        div(v-if='product.ean' class='w-full md:w-[50%] flex text-sm')
          div(class='font-bold w-[200px]') {{ $t("EAN") }}:
          div(class='text-text flex-1 md:pr-2') {{ product.ean }}
        div(v-if='product.materials' class='w-full md:w-[50%] flex text-sm')
          div(class='font-bold w-[200px]') {{ $t("Materials") }}:
          div(class='text-text flex-1 md:pr-2') {{ product.materials }}
        div(v-if='product.colors' class='w-full md:w-[50%] flex text-sm')
          div(class='font-bold w-[200px]') {{ $t("Colors") }}:
          div(class='text-text flex-1 md:pr-2') {{ product.colors }}
        div(v-if='product.width || product.height || product.depth' class='w-full md:w-[50%] flex text-sm')
          div(class='font-bold w-[200px]') {{ $t("Dimensions") }}:
          div(class='text-text flex-1 md:pr-2') {{ $t('W:') }} {{ product.width }}, {{ $t('H:') }} {{ product.height }}, {{ $t('D:') }} {{ product.depth }}
        div(v-if='product.weight' class='w-full md:w-[50%] flex text-sm')
          div(class='font-bold w-[200px]') {{ $t("Weight") }}:
          div(class='text-text flex-1 md:pr-2') {{ product.weight }}
        div(v-if='product.categories' class='w-full md:w-[50%] flex text-sm')
          div(class='font-bold w-[200px]') {{ $t("Categories") }}:
          div(class='text-text flex-1 md:pr-2') {{ product.categories }}
        div(class='w-full md:w-[50%] flex text-sm')
          div(class='font-bold w-[200px]') {{ $t("Questions about the product?") }}
          div(class='text-text flex-1 md:pr-2')
            a(v-contact-link='{ message: $t("Question about {0} ({1}).", "", [product.title, product.id]) }')
              | {{ $t('Contact us') }}

</template>

<script lang="ts">
import { defineComponent } from 'vue'

const ProductDetailTechnicalInformation = defineComponent({
  props: {
    product: { type: Object, required: true },
    hideDescription: Boolean,
  },
})
export default ProductDetailTechnicalInformation
</script>
<style lang="stylus">
@import '../../../styles/variables.styl'

</style>
