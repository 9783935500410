<template lang="pug">
div(v-bind='attributes' class='control-label')
  label(v-if='label && !hiddenLabel' v-html='computedLabel')
  slot
  notification-list(:items='notifications' :showIcons='false')
</template>

<script lang="ts">
import { type INotification } from '@/components/GenericForm/types'
import NotificationList from '@/components/Notification/NotificationList.vue'
import { shakeFalsy } from '@/utilities'
import { defineComponent, ref, type Ref, reactive, computed, provide, type PropType } from 'vue'

const ControlLabel = defineComponent({
  components: { NotificationList },
  props: {
    label: String,
    error: Boolean,
    hiddenLabel: Boolean, // @TODO: rename to hideLabel
    floatOnFocus: { type: Boolean, default: false },
    notifications: Array as PropType<INotification[]>,
    disabled: { type: Boolean, default: undefined },
    optional: { type: Boolean, default: undefined },
    floating: { type: Boolean, default: undefined },
    ifta: { type: Boolean, default: undefined },
  },
  setup(props) {
    const controlState = reactive<any>({
      isEmpty: ref(false),
      hasFocus: ref(false),
      isDirty: ref(false),
    })

    provide('setState', (key: string, reference: Ref<boolean>) => (controlState[key] = reference))
    return {
      controlState,
      computedLabel: computed(
        () => props.label + (props.optional ? ` <span class='faded'>(${'Optional'})</span>` : ''),
      ),
      attributes: computed(() =>
        shakeFalsy({
          floating: props.floating,
          ifta: props.ifta,
          error: props.error,
          disabled: props.disabled,
          dirty: controlState.isDirty,
          'float-on-focus': props.floatOnFocus,
          empty: controlState.isEmpty,
        }),
      ),
    }
  },
})
export default ControlLabel
</script>

<style lang="stylus">
@import '../../styles/variables.styl'
:root
  --control-label-color: var(--color-text)
  --margin-bottom: 1rem

.control-label
  position: relative
  width: 100%
  text-align: left
  margin-bottom: var(--margin-bottom)
  > label
    font-weight: bold
    margin-bottom: 4px
    display: block
    color: var(--control-label-color)
    font-size: 15px
    -webkit-font-smoothing: antialiased
    > span
      font-weight: 200
    sup
      position: relative
      left: 0.125em
      top: -0.25em
  &[disabled]
    --control-label-color: var(--color-text-light)!important
  &[error]
    --control-label-color: var(--color-danger)
  &[floating]
    --control-padding-t: 2px
    --control-height: 60px
    --control-padding-x: 14px
    --control-label-color: var(--color-text-semi-light)
    &[small]
      --control-height: 50px
    > label
      position: absolute
      transform: translate(0px, 23px)
      left: 0
      display: block
      width: 100%
      margin-bottom: 0
      line-height: 1
      font-size: 16px
      font-weight: normal
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      border: 1px solid transparent
      z-index: 2
      border-radius: 0.25rem
      transition: all 0.075s ease-in-out
      pointer-events: none
      padding: 0px var(--control-padding-x)
    &:not([empty]), &[float-on-focus]:focus-within
      --control-label-color: var(--color-text)
      --control-padding-t: 20px
      ~/[small] &
        --control-padding-t: 20px
      > label
        transform: translate(0, 6px)
        font-size: 13px
        top: 4px

  &[ifta]
    --control-height: 60px
    --control-padding-x: 14px
    --control-padding-t: 16px
    > label
      position: absolute
      left: 0
      width: 100%
      margin-bottom: 0
      line-height: 1
      border: 1px solid transparent
      z-index: 2
      border-radius: 0.25rem
      transition: all 0.075s ease-in-out
      pointer-events: none
      padding: 0px var(--control-padding-x)
      font-size: 14px
      top: 8px
      font-weight: 500
      font-family: $font-system
    &:focus-within
      --control-label-color: var(--blue-500)
</style>
