<template lang="pug">
collapse-group-item(v-model='collapsed' :force='force')
  icon(name='file-05' class='text-xl')
  div(class='font-lato text-base') {{ item.inquiryId }}

  pill(:type='item.listItems.length ? "secondary" : "faded"' :data-tooltip='$t("Anzahl Produkte von aktuellem Hersteller in Anfrage")' class='mr-2') {{ item.listItems.length }}
  inquiry-status-pill(:status='item.status' :data-tooltip='$t("Anfrage Status")')
  date-tooltip(v-if='item.events' v-slot='{ text }' :dateAsString='item.events.instant' class='link-tooltip flex items-center gap-1 text-sm')
    icon(name='clock')
    span {{ $t('{0} aktualisiert', '', [text]) }}

  div(v-if='item.responsibleOperator && item.responsibleOperator !== "no operator currently"' class='link-tooltip flex items-center gap-1' :data-tooltip='$t("Responsible Operator")')
    avatar(:username='item.responsibleOperator' :size='22')
    span(class='text-sm') {{ item.responsibleOperator }}
  btn(
    v-if='$permissions.canSeePurchasingPrices'
    class='ml-2'
    icon='external-link'
    :data-tooltip='$t("Anfrage anzeigen")'
    admin
    tiny
    secondary
    :to='$modals.getLocation("form-inquiry-admin", { id: item.inquiryId })'
  )
  div(class='flex-1')
  pill(:data-tooltip='$t("Summe der Produkte ohne Produkte anderer Hersteller (gerundet)")' ellipsis style='--pill-color-bg: var(--green-100); --pill-color-text: var(--green-500)')
    span(v-html='$t("Summe:  <b>~ {0}</b> ({1} v. Gesamtanfrage)", "", [inqTotalPrice(item), item.supplierPercentOfTotalAmountString])')
  template(#content)
    SupplierInquiryItemTable(:items='item.listItems' :matches='matches' class='!mb-4')
</template>

<script setup lang="ts">
import { inqTotalPrice } from '../'
import { type IInquirySupplier } from './types'

defineProps<{
  item: IInquirySupplier
  matches: any
  force?: boolean
}>()

const collapsed = defineModel<boolean>({ default: false })
</script>
