import { isArray, mapValues, omit, isEmpty } from 'radash'
import { type App, type Plugin, inject } from 'vue'
import { type LocationQueryRaw, type RouteLocationRaw } from 'vue-router'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $utils: IUtils
  }
}

export type RouteQueryAddFn = (
  obj: any,
  resolveOnly?: boolean,
  replace?: boolean,
  customParams?: any,
) => RouteLocationRaw
export type RouteQueryRemoveFn = (
  key: string | string[],
  resolveOnly?: boolean,
  replace?: boolean,
  customParams?: any,
) => RouteLocationRaw
export type RouteQueryRemoveValueFn = (key: string, value: string, resolveOnly?: boolean) => RouteLocationRaw
export interface IUtils {
  routeQueryAdd: RouteQueryAddFn
  routeQueryRemove: RouteQueryRemoveFn
  routeQueryRemoveValue: RouteQueryRemoveValueFn
}

const UtilsPlugin: Plugin = {
  install(app: App) {
    const router = app.config.globalProperties.$router
    const utils: IUtils = {
      routeQueryAdd: (obj: any, resolveOnly: boolean = false, replace: boolean = true) => {
        const { name, query } = router.currentRoute.value
        const location: RouteLocationRaw = {
          name: name as string | undefined,
          query: {
            ...query,
            ...(typeof obj === 'string' ? { [obj]: '' } : mapValues(obj, (v) => (isEmpty(v) ? '' : v))),
          } as LocationQueryRaw,
        }
        if (!resolveOnly) {
          router[replace ? 'replace' : 'push'](location).catch((err: any) => {
            console.log('route error', err)
          })
        }
        return location
      },
      routeQueryRemove: (
        key: string | string[],
        resolveOnly: boolean = false,
        replace: boolean = true,
        customParams: any,
      ) => {
        const { name, query, params } = router.currentRoute.value
        const location: RouteLocationRaw = {
          ...router.currentRoute.value,
          name: name as string | undefined,
          query: omit(query, isArray(key) ? (key as string[]) : [key as string]),
          ...(params ? { params: { ...params, ...customParams } } : {}),
        }
        if (!resolveOnly) {
          router[replace ? 'replace' : 'push'](location).catch(() => {})
        }
        return location
      },
      routeQueryRemoveValue: (key: string, value: string, resolveOnly: boolean = false) => {
        const { name, query } = router.currentRoute.value
        const newQuery: Record<string, any> = {
          ...query,
          [key]: (query[key] as string)
            .split(',')
            .filter((v: string) => v !== value)
            .join(','),
        }
        const location: RouteLocationRaw = {
          ...router.currentRoute,
          name: name as string | undefined,
          query: !newQuery[key] ? omit(newQuery, [key]) : newQuery,
        }
        if (!resolveOnly) {
          router.push(location).catch(() => {})
        }
        return location
      },
    }
    app.config.globalProperties.$utils = utils
    app.provide('$utils', utils)
  },
}

export const useUtils = () => {
  return inject<IUtils>('$utils')!
}

export default UtilsPlugin
