<template lang="pug">
div(ref='container')
  slot
</template>

<script setup lang="ts">
import { useCustomBreakpoints } from '@/composables/'
import { useDragDrop } from '@/utilities/DragDrop'
import type { DragDropPayload, DropPositionFn } from 'dndrxjs'
import 'dndrxjs/dist/styles.css'
import { shake } from 'radash'
import { ref, type PropType } from 'vue'

const props = defineProps({
  dropPositionFn: { type: Function as PropType<DropPositionFn>, default: undefined },
  vertical: { type: Boolean, default: true },
  handleSelector: { type: String },
  targetSelector: { type: String, default: '[data-id]' },
  dropElementSelector: { type: String, default: '[data-id]' },
  active: { type: Boolean, default: true },
  customContainer: Object as PropType<HTMLElement>,
})
const emit = defineEmits<{ dragDropEnd: [value: DragDropPayload]; dragDropStart: [] }>()
const container = ref<HTMLElement | null>(null)
const breakpoints = useCustomBreakpoints()
if (props.active && breakpoints.md) {
  useDragDrop(
    props.customContainer ? ref(props.customContainer!) : container,
    shake(
      {
        handleSelector: props.handleSelector!,
        dragElementSelector: props.targetSelector!,
        dropElementSelector: props.dropElementSelector!,
        dropPositionFn: props.dropPositionFn!,
        vertical: props.vertical,
        getSelectedElements: () => Array.from(container.value!.querySelectorAll(`[data-selected="true"]`)),
      },
      (value) => value === undefined,
    ),
    (t: DragDropPayload) => emit('dragDropEnd', t),
  )
}
</script>
