<template lang="pug">
modal(class='modal-project-supplier' size='xl' mode='drawer' @close='$emit("close")')
  template(v-if='project')
    modal-header(icon='folder')
      span(class='text-2xl')
        span {{ $t('Projekt') }}: !{ '  ' }
        span(class='font-thin') {{ project?.projectId }}
      template(#actions)
        btn(
          v-if='$permissions.canSeePurchasingPrices'
          class='ml-2'
          icon='external-link'
          :data-tooltip='$t("Projekt anzeigen")'
          admin
          tiny
          secondary
          :to='$modals.getLocation("form-project-admin", { id: project.projectId })'
        )
          | {{ project.name }}
    div(class='mb-4 flex flex-wrap items-center gap-4')
      date-tooltip(v-slot='{ text }' :dateAsString='project.createdDate.split(" - ")[0]' class='link-tooltip flex items-center gap-1 text-sm')
        icon(name='clock')
        span {{ $t('created {0}', '', [text]) }}
      //- div(class='flex items-center gap-1 text-sm text-text-light')
      //-   icon(name='shopping-cart-01')
      //-   span {{ $t('Gesamtes Projektvolumen') }}:
      //-   span(class='font-bold') {{ project.totalAmountString }}
      div(class='flex items-center gap-1 text-sm text-text-light')
        icon(name='sale-01')
        span {{ $t('Summe (gerundet)') }}:
        span(class='font-bold') ~ {{ project.totalAmountSupplierString }}
    div(class='tabs-tw')
      button(class='tab-tw' :class='{ "tab-tw-active": selectedTab === "products" }' @click='selectedTab = "products"')
        span {{ $t('Alle Produkte') }}
        pill(type='secondary') {{ project.listItems.length }}
      button(class='tab-tw' :class='{ "tab-tw-active": selectedTab === "offers" }' @click='selectedTab = "offers"')
        span {{ $t('Alle Angebote') }}
        pill(type='secondary') {{ project.inquiries.length }}
    template(v-if='selectedTab === "products"')
      popover-image-zoom-provider
      SupplierInquiryItemTable(:items='project.listItems')
    SuppliersInquiriesList#modal-project-supplier(v-else :items='project.inquiries')
</template>

<script setup lang="ts">
import { useManufacturerStore } from '@/pages/manufacturer'
import { computed, ref } from 'vue'

type ModalProjectSupplierTabs = 'products' | 'offers'
const props = defineProps<{
  projectId: string
  tab?: ModalProjectSupplierTabs
}>()
const store = useManufacturerStore()
const project = computed(() => store.projects.find((p) => p.projectId === props.projectId))
const selectedTab = ref<ModalProjectSupplierTabs>(props.tab || 'products')
</script>
