import { useInquiriesStore } from './store'
import { type InquiryStatus, type IInquiry, type IInquiryProduct } from './types'
import { emitter } from '@/common'
import { type TransformItemsFunction } from '@/components/List'
import { useThorForm } from '@/composables/'
import api from '@/pages/projects/api'
import { type IProjectData } from '@/pages/projects/types'
import { useConfirm } from '@/plugins/Confirm'
import { useModals } from '@/plugins/Modals'
import { useTranslation } from '@/plugins/translation'
import { type IGroupedItem, type IPaginatedAndGroupedResponse } from '@/types'
import { paramsToURIComponent } from '@/utilities'
import axios from 'axios-observable'
import { sleep } from 'radash'
import { map } from 'rxjs'
import { type MaybeRef, computed, unref, type Ref, ref, onMounted, watch } from 'vue'

export const transformInquiryGroups: TransformItemsFunction = (inquiries: IGroupedItem<IInquiry>[]) =>
  inquiries.map((i: IGroupedItem<IInquiry>) => ({
    ...i.items[i.items.length - 1],
    ...(i.items.length > 1 ? { previousVersions: i.items.slice(0, i.items.length - 1).reverse() } : {}),
  }))

export const loadAllInquiries = () =>
  axios
    .get<
      IPaginatedAndGroupedResponse<IInquiry>
    >('/InquirySearch' + paramsToURIComponent({ params: { q: '', sort: 'created.at_desc', size: 999, filter: { deleted: ['true', 'false'] } } }))
    .pipe(map((r) => transformInquiryGroups(r.data.hits)))

export const loadInquiriesForProject = (projectId: string) =>
  axios
    .get<
      IPaginatedAndGroupedResponse<IInquiry>
    >('/InquirySearch' + paramsToURIComponent({ params: { q: '', sort: 'created.at_desc', size: 999 }, projectId }))
    .pipe(map((r) => transformInquiryGroups(r.data.hits)))

export const isInquiryStatusAfterOfferedCheck = (state: string) =>
  [
    'offered',
    'completed',
    'accepted',
    'declined',
    'rejectedByOperator',
    'declinedWithChangeRequest',
    'expired',
  ].includes(state)

export const isInquiryDeletable = (state: string) =>
  ['drafted', 'declined', 'declinedWithChangeRequest', 'expired', 'rejectedByOperator'].includes(state)

export const useInquiryStatus = (status: MaybeRef<InquiryStatus>) => ({
  isInquiryStatusDrafted: computed(() => unref(status) === 'drafted'),
  isInquiryStatusExpired: computed(() => unref(status) === 'expired'),
  isInquiryStatusAfterOffered: computed(() => isInquiryStatusAfterOfferedCheck(unref(status))),
  isInquiryStatusOffered: computed(() => ['offered'].includes(unref(status))),
})

export const useInquiry = (id: Ref<string>) => {
  const store = useInquiriesStore()
  const loading = ref<boolean>(false)
  const error = ref<boolean>(false)
  const inquiry = computed<IInquiry | null>(() => store.allInquiriesAsMap[id.value] || null)
  const load = () => {
    loading.value = true
    api.loadInquiry(id.value).subscribe({
      next: (inquiry: IInquiry) => {
        store.setInquiry(inquiry)
        // for tracking purposes
        emitter.emit('loadProducts', inquiry.items)
      },
      error: () => ((error.value = true), (loading.value = true)),
      complete: () => (loading.value = false),
    })
  }

  onMounted(load)
  watch(() => id.value, load)

  const items = computed<IInquiryProduct[]>(() => inquiry.value?.items || [])
  const hasExternalProducts = computed<boolean>(() => items.value.some((item: IInquiryProduct) => item.isExternal))

  return {
    items,
    load,
    store,
    setInquiry: (inquiry: IInquiry) => store.setInquiry(inquiry),
    ...useInquiryStatus(computed(() => inquiry.value?.status || 'drafted')),
    hasExternalProducts,
    inquiry,
    loading,
    error,
  }
}

export const useInquiriesApi = () => {
  const { post, loading, loadingMap } = useThorForm()
  return {
    loading,
    loadingMap,
    updateProductQuantity: (inquiryId: string, productId: string, quantity: number) =>
      post<IProjectData>(`/_/Inquiry/${inquiryId}/UpdateInquiry`, {
        SelectedProduct: productId,
        Product: productId,
        Quantity: quantity,
      }),
    updateProductOrder: (inquiryId: string, selectedItem: string, newIndex: number) =>
      post<IProjectData>(`/_/Inquiry/${inquiryId}/UpdateProductOrder`, {
        Index: newIndex,
        SelectedItem: selectedItem || '',
      }),
  }
}
export const useInquiryActions = () => {
  const { $t } = useTranslation()
  const { confirm, danger } = useConfirm()
  const $modals = useModals()
  const store = useInquiriesStore()

  const copyInquiry = (inquiryId: string, onCopy?: (inquiry: IInquiry) => any) =>
    confirm({
      title: $t('Duplicate Inquiry'),
      content: $t('This will create a copy of this inquiry in draft mode.'),
      okText: $t('Ok'),
      cancelText: $t('Cancel'),
      onOk: async () => {
        const inquiry = await store.copyInquiry(inquiryId)
        sleep(100)
        onCopy?.(inquiry)
      },
    })

  const deleteInquiry = (inquiryId: string, onDelete?: () => void, onCancel?: () => void) =>
    danger({
      title: $t('Do you really want to delete the inquiry?'),
      onOk: async () => {
        await store.deleteInquiry(inquiryId)
        onDelete?.()
      },
      onCancel,
    })

  const cancelCreateInquiry = (inquiryId: string, onCancelCreate?: () => void, onSave?: () => void) =>
    confirm({
      title: $t('Do you want to save the inquiry?'),
      okText: $t('Yes'),
      cancelText: $t('No'),
      onOk: async () => onCancelCreate?.(),
      onCancel: async () => {
        await store.deleteInquiry(inquiryId)
        onSave?.()
      },
      onAbort: async () => true,
    })

  const restoreInquiry = async (inquiryId: string, onRestore?: () => void) => {
    await store.restoreInquiry(inquiryId)
    onRestore?.()
  }

  const showPrintPreview = (inquiryId: string) => {
    $modals.open('modal-inquiry-pdf-preview', { inquiryId })
    emitter.emit('printInquiryPreview')
  }

  const editTitle = (inquiryId: string) =>
    $modals.open('form-generic', {
      data: {
        baseUrl: `/_/Inquiry/${inquiryId}`,
        action: 'UpdateTitle',
        id: 'FormEditTitle',
        label: $t('Edit Title'),
        size: 'sm',
        fields: [
          {
            name: 'InqTitle',
            type: 'form-input',
            bindings: { modelValue: inquiryId, autofocus: true },
          },
        ],
      },
    })

  const openClaimsForm = (
    inquiryId: string,
    productName: string = '',
    productId: string = '',
    quantity: string | number = '',
  ) =>
    window.open(
      `https://nuucon.com/claims?inquiryid=${inquiryId}&product=${productName}&product_id=${productId}&quantity=${quantity}`,
    )

  return {
    editTitle,
    showPrintPreview,
    copyInquiry,
    cancelCreateInquiry,
    deleteInquiry,
    restoreInquiry,
    openClaimsForm,
  }
}
