<template lang="pug">
div(class='flex flex-col')
  slot(name='header' :load='load' :total='total' :pageSize='pageSize' :page='page' :pageSizeOptions='pageSizes')
  div(v-if='items.length' class='flex-1')
    slot(:items='items' :loading='loading' :load='load')
    slot(name='abovePagination')
  div(v-if='!items.length')
    slot(name='emptyState')
      empty-state(icon='custom-sofa-not-found' :centered='true' :message='$t("There are no items yet..")')
  pagination(v-else-if='!hidePagination' v-model='page' v-model:pageSize='pageSize' :total='total' :pageSizeOptions='pageSizes' class='my-4')
  slot(name='footer' :total='total' :pageSizeOptions='pageSizes' :page='page' :pageSize='pageSize')
  overlay(:visible='loading')
</template>

<script setup lang="ts">
import { type PropertyValue, type TransformParamsFunction, type TransformItemsFunction, useList } from '.'
import { useOnSuccess } from '@/composables/'
import { type PropType } from 'vue'

const props = defineProps({
  endpoint: { type: String, required: true },
  pageSizes: { type: Array as PropType<number[]>, default: () => [36, 72, 144, 288] },
  id: { type: String, required: true },
  hidePagination: Boolean,
  additionalProperties: { type: Object as PropType<Record<string, PropertyValue>> },
  updateOnSubmitSuccess: String,
  useRouter: { type: Boolean, default: true },
  persist: { type: Boolean, default: true },
  transformParams: Function as PropType<TransformParamsFunction>,
  transformItems: Function as PropType<TransformItemsFunction>,
})

const { id, endpoint, pageSizes, additionalProperties, transformItems, transformParams, useRouter, persist } = props
const emit = defineEmits(['load'])

const { items, total, loading, load, page, pageSize } = useList(
  id,
  endpoint,
  pageSizes,
  additionalProperties,
  undefined,
  transformParams,
  (items) => emit('load', items),
  transformItems,
  undefined,
  undefined,
  persist,
  useRouter,
)
// @TODO: handle differently
if (props.updateOnSubmitSuccess) {
  useOnSuccess(props.updateOnSubmitSuccess.split(','), () => setTimeout(() => load(), 900))
}
</script>
