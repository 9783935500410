<template lang="pug">
div(:class='{ "badge--dot": dot }' class='badge')
  slot
  div(v-if='visible' :style='`top: ${offset[0]}px; right: ${offset[1]}px;`' class='badge__number')
    span(v-if='!dot') {{ count }}
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const Badge = defineComponent({
  props: {
    count: [String, Number],
    visible: { type: Boolean, default: true },
    offset: { type: Array, default: () => [0, 0] },
    dot: Boolean,
  },
})

export default Badge
</script>
<style lang="stylus">
@import '../styles/variables.styl'
:root
  --badge-bg-color: var(--color-text)
  --badge-color: var(--color-white)
  --badge-size: 16px
  --badge-font-size: 10px
.badge
  position: relative
  display: inline-block
  min-width: 7px
  min-height: 7px
  @media (min-width: $breakpoint-sm)
    --badge-size: 19px
    --badge-font-size: 11px
  &[accent]
    --badge-bg-color: var(--color-accent)
  &[success]
    --badge-bg-color: var(--green-400)
  &[warning]
    --badge-bg-color: var(--yellow-300)
    --badge-color: var(--yellow-800)
  &[purple]
    --badge-bg-color: var(--purple-400)
  &[grey]
    --badge-bg-color: var(--grey-300)
  &[danger]
    --badge-bg-color: var(--red-400)
  &[admin]
    --badge-bg-color: var(--color-nuucon)
  &__number
    pointer-events: none
    box-sizing: content-box
    position: absolute
    min-width: var(--badge-size)
    min-height: var(--badge-size)
    color: var(--badge-color)
    background: var(--badge-bg-color)
    font-size: var(--badge-font-size)
    font-family: var(--font-default)
    font-weight: 500
    line-height: 1
    border-radius: var(--badge-size)
    display: flex
    align-items: center
    justify-content: center

    > span
      display: inline-flex
      padding: 0 3px
    ~/[bordered] &
      border: 1px solid var(--color-body)
      margin-right: -2px
      margin-top: -2px
      --badge-font-size: 9px
      // box-sizing: border-box
      @media (min-width: $breakpoint-sm)
        border-width: 2px
    ~/--dot &
      --badge-size: 6px
      width: 6px!important
      height: 6px!important
      display: block
      min-width: 0
      padding: 0
</style>
