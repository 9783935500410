<template lang="pug">
table(class='table-meta')
  tbody
    slot
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const MetaTable = defineComponent({})
export default MetaTable
</script>

<style lang="stylus">
table.table-meta
  border-collapse: separate
  margin: 0
  border-radius: 8px
  width: 100%
  --table-td-border: var(--grey-100)
</style>
