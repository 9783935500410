<template lang="pug">
div(v-if='inquiry' :id='inquiry.id' icon='truck' :collapsible='false') 
  toolbar(:title='$t("Delivery Information")' titleClass='!text-base')
    template(#after)
      btn(v-if='showEditButton' secondary small class='-ml-2' data-test-btn='open-delivery-setup' data-test='btn-delivery-setup' @click='$emit("openDeliverySetupForm")')
        | {{ $t(inquiry.deliverySetup || inquiry.invoiceAddress || inquiry.deliveryAddress ? 'edit' : 'add') }}
  meta-table
    template(v-if='areObjectsDifferent(inquiry.deliveryAddress, inquiry.invoiceAddress)')
      meta-table-row#deliveryAddress(v-if='inquiry.deliveryAddress' icon='map-pin') {{ addressToString(inquiry.deliveryAddress) }}
      meta-table-row#invoiceAddress(v-if='inquiry.invoiceAddress' icon='map-pin') {{ addressToString(inquiry.invoiceAddress) }}
    meta-table-row#invoiceAddressAndDeliveryAddress(v-else-if='inquiry.invoiceAddress && inquiry.deliveryAddress' icon='map-pin') {{ addressToString(inquiry.invoiceAddress) }}
    meta-table-row#desiredDeliveryDate(icon='calendar' :value='inquiry.desiredDeliveryDate')
    template(v-if='inquiry.deliverySetup')
      meta-table-row#deliverySetupType(icon='truck-option')
        span {{ $t(inquiry.deliverySetup.deliverySetupType.name) }} !{ ' ' }
      meta-table-row#contactPerson(icon='user')
        span {{ inquiry.deliverySetup.contactPerson }} !{ ' ' }
        span(data-test-meta-row='contactPersonPhone') !{ ' ' }({{ inquiry.deliverySetup.contactPersonPhone }})
      template(v-if='inquiry.deliverySetup.serviceLevel')
        meta-table-row#serviceLevel(icon='star' :value='inquiry.deliverySetup.serviceLevel.name' :transformValue='$t')
      template(v-if='inquiry.deliverySetup.floor')
        meta-table-row(:label='$t("Object information")' icon='building-01' :value='inquiry.deliverySetup.floor')
          span(data-test-meta-row='floor') {{ $t('Floor') }}: {{ inquiry.deliverySetup.floor }}
          span(data-test-meta-row='elevator') , {{ $t(inquiry.deliverySetup.elevator ? 'elevator available' : 'elevator not available') }}
          span(v-if='inquiry.deliverySetup.elevator && inquiry.deliverySetup.elevatorDimensions' data-test-meta-row='elevatorDimensions') !{ ' ' } ({{ inquiry.deliverySetup.elevatorDimensions }})
      template(v-if='(!inquiry.deliverySetup.truckParkingPossible && inquiry.deliverySetup.truckParkingInfo) || inquiry.deliverySetup.noStoppingZone')
        meta-table-row(:label='$t("Delivery information")' icon='info' :value='"true"')
          span(class='[&>*:not(:last-child):after]:content-[","]')
            span(v-if='!inquiry.deliverySetup.truckParkingPossible && inquiry.deliverySetup.truckParkingInfo' data-test-meta-row='truckParkingInfo') {{ inquiry.deliverySetup.truckParkingInfo }}
            span(v-if='inquiry.deliverySetup.noStoppingZone' data-test-meta-row='noStoppingZone') {{ $t('No stopping zone required') }}
      template(v-if='inquiry.description')
        meta-table-row#inquiryDescription(:label='$t("description.label")' icon='message-text-square' :value='inquiry.description' :transformValue='$t')

div(v-if='inquiry && isInquiryStatusAfterOffered' :id='inquiry.id' icon='custom-receipt' class='mt-4' :collapsible='false')
  toolbar(:title='$t("Offer Information")' titleClass='!text-base')
  meta-table
    meta-table-row#paymentCondition(icon='credit-card-check' :value='inquiry.paymentCondition' :transformValue='$t')
    meta-table-row#offerDate(icon='calendar-plus-02' :value='inquiry.offerDate')
    meta-table-row#acceptDate(icon='calendar-check-02' :value='inquiry.acceptDate')
    meta-table-row#dateOfExpiry(icon='clock-stop-watch' :value='inquiry.dateOfExpiry')
    meta-table-row#offerTexts(icon='printer' singleColumn :value='inquiry.offerTexts')
</template>

<script lang="ts">
import { type IInquiry } from '../types'
import { useTranslation } from '@/plugins/translation'
import { areObjectsDifferent } from '@/utilities'
import { addressToString } from '@/utilities'
import { defineComponent, type PropType } from 'vue'

const InquiryMetaTable = defineComponent({
  props: {
    isInquiryStatusAfterOffered: Boolean,
    showEditButton: Boolean,
    inquiry: { required: true, type: Object as PropType<IInquiry> },
  },
  setup() {
    const { $t } = useTranslation()
    return {
      areObjectsDifferent,
      addressToString,
      transformBoolean: (value: boolean) => $t(value ? 'yes' : 'no'),
    }
  },
})
export default InquiryMetaTable
</script>
