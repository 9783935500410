<template lang="pug">
div(ref='container' :class='classes' class='custom-table')
  table
    slot
</template>

<script setup lang="ts">
import { useDelegatedClickEventListener } from '@/composables/'
import { type PropType, computed, ref } from 'vue'

const props = defineProps({
  size: { type: String as PropType<'default' | 'small'>, default: 'default' },
  linked: Boolean,
})

const emit = defineEmits<{ clickRow: [el: HTMLElement] }>()

const container = ref<HTMLElement | null>(null)
useDelegatedClickEventListener(container, 'tr', (el) => emit('clickRow', el), false, 'button, a, a *, button *')
const classes = computed(() => ({
  'custom-table--linked': props.linked,
  [`custom-table--${props.size}`]: true,
}))
</script>

<style lang="stylus">
@import '../../styles/variables.styl'

:root
  --td-padding-x: 6px
  --td-padding-r: var(--td-padding-x)
  --td-padding-l: var(--td-padding-x)
  --td-padding-y: 10px
  --td-padding-t: var(--td-padding-y)
  --td-padding-b: var(--td-padding-y)
  --tr-bg: var(--color-white)
  --tr-border-color: var(--grey-75)

.custom-table
  position: relative
  font-family: $font-default
  display: block
  @media (max-width: $breakpoint-sm-max)
    overflow-x: auto
  table
    width: 100%
    line-height: 1.25
  th
    font-size: 13.5px
    font-weight: 500
    font-family: $font-system
  td
    font-size: 15.75px
    letter-spacing: -0.0125em
    border-top: 1px solid var(--tr-border-color)
    // makes sure dragover event wont be triggered by children
    .is-dragging & *
      pointer-events: none!important
  td, th
    padding: var(--td-padding-t) var(--td-padding-r) var(--td-padding-b) var(--td-padding-l)
  &--small
    --td-padding-r: 7px
    --td-padding-l: 7px
    --td-padding-t: 2px
    --td-padding-b: 2px
    td
      font-size: 15px
  &--linked
    tr
      cursor: pointer
  td, th
    .bleed &
      &:last-child
        padding-right: var(--bleed-right)
      &:first-child
        padding-left: var(--bleed-left)
  tr
    background-color: var(--tr-bg)
    &:active
      --tr-bg: var(--color-body)
    &[data-selected="true"]
      --tr-bg: var(--blue-50)
      &:active
        --tr-bg: var(--blue-100)

th.index,
td.index
  width: 0%
  --td-padding-r: 12px
  color: var(--color-text-extralight)
  tr:hover  &
    color: var(--color-text)
td.index
  font-size: 13px
  font-family: monospace
th.title
  width: 30%

th.collapse,
td.collapse,
th.image,
td.image
  width: 1%
  text-align: center
td.image
  --td-padding-t: 2px
  --td-padding-b: 2px
  --td-padding-r: 0px
  --td-padding-l: 0
  padding-bottom: 0!important
  mix-blend-mode: multiply

.td-after
  width: 1%

.td-before
  width: 1%
  vertical-align: middle
  @media print
    display: none

.tr-link-title
  tr:hover &
    text-decoration: underline

td.editable
  font-size: 14px
  line-height: 1.5
  white-space: pre-wrap
  min-width: 130px
  position: relative
  background: transparent
  tr:not([draggable='true']) > &
    cursor: pointer
    position: relative
    &:empty
      padding-right: 25px
    &:after, &:before
      content: ''
      position: absolute
      right: 0px
      top: 6px
      width: calc(100%)
      height: calc(100% - 12px)
      pointer-events: none
      display: flex
      @media print
        display: none!important
      @media (min-width: $breakpoint-sm)
        display: none
      ../:hover^[2..-1],
      ../:focus^[2..-1]
        display: flex
        opacity: 1
      ../:active^[2..-1],
      ../:focus^[2..-1]
        opacity: 1
    &:before
      @media (min-width: $breakpoint-sm)
        background-image: url(../../assets/icons/custom-edit.svg)
        background-repeat: no-repeat
        background-position: center center
        background-size: 18px
        width: 30px
        z-index: 1
    &:after
      mix-blend-mode: multiply
      z-index: 1
      opacity: 1
      @media (min-width: $breakpoint-sm)
        background: var(--color-body)
        opacity: 0
      border-radius: 8px
      pointer-events: none
      ../:empty:after
        content: attr(data-col-edit-empty-message)
        line-height: 1.2
        font-style: italic
        display: flex
        font-size: 14px
        padding: 4px 23px 4px 14px
        align-items: center

  @media (max-width: $breakpoint-sm-max)
    tbody tr,
    thead,
      > td.sticky
        background: var(--tr-bg)!important
        .table--isIntersecting &
          background: #fff
          mix-blend-mode: normal
          &:after
            content: ''
            display: block
            width: 10px
            height: calc(100% + 1px)
            box-shadow: inset 5px 0px 10px -5px rgba(0,0,0,0.2)
            position: absolute
            left: 100%
            top: -1px

      > th,
      > td
        &:last-child
          padding-right: 15px
        &:first-child
          padding-left: 15px
        &.sticky
          position: sticky
          left: -1px
          z-index: 99
        &.title
          min-width: 220px

.tr-nested td
  border-top: 0!important
</style>
