import type { TCreatedPdf } from 'pdfmake/build/pdfmake'
import type {
  Alignment,
  Content,
  ContentImage,
  ContentStack,
  ContentTable,
  CustomTableLayout,
  Size,
  StyleReference,
  TDocumentDefinitions,
  TableCell,
  TDocumentInformation,
  TFontDictionary,
  Style,
} from 'pdfmake/interfaces'
import { isNumber, isString } from 'radash'

export interface InvoicePDFInfoItemCol {
  content: Content
  style?: StyleReference | undefined
}

export interface InvoicePDFColConfig {
  alignment?: Alignment | undefined
  id: string
  label: string | Content
  style?: StyleReference | undefined
  width?: Size
}

export interface InvoicePDFInfoItemRow {
  style?: StyleReference | undefined
  border?: boolean
  cols: Record<string, InvoicePDFInfoItemCol>
}
export interface InvoicePDFInfoRowGroup {
  rows: InvoicePDFInfoItemRow[]
  groupTitle: string | Content
  groupFooter: string | Content
}

export const isRowGroup = (
  rowOrGroup: InvoicePDFInfoItemRow | InvoicePDFInfoRowGroup,
): rowOrGroup is InvoicePDFInfoRowGroup => !!(rowOrGroup as InvoicePDFInfoRowGroup).groupTitle

export const isContentImage = (content: Content): content is ContentImage => !!(content as ContentImage).image

export interface InvoicePDFTableConfig {
  rowsOrGroups: InvoicePDFInfoItemRow[] | InvoicePDFInfoRowGroup[]
  colConfigs: InvoicePDFColConfig[]
}
export type InvoicePDFKeyValue = Record<string, string | number | Content>

export interface InvoicePDFInfo {
  info: TDocumentInformation
  titleContent: Content
  logo: Content
  headInfoContent: Content
  addressContent: Content
  itemTableConfig: InvoicePDFTableConfig
  belowTableContent: Content
  tableFooter?: TableCell[][]
  pageFooter: Content
}
const SPACER: (margin?: number) => Content = (margin: number = 5) => ({
  text: '',
  margin: [0, 0, 0, margin],
})

export const DEFAULTS: Partial<TDocumentDefinitions> = {
  pageMargins: [40, 40, 40, 70],
  styles: {
    documentFooter: {
      fontSize: 6.5,
      margin: [40, 10, 40, 10],
      alignment: 'left',
      color: '#777',
    },

    tableHead: {
      fontSize: 7,
      bold: true,
    },
    tableCell: {
      fontSize: 8,
      margin: [0, 9, 0, 6],
    },
    noMargin: {
      margin: [0, 0, 0, 0],
    },
    light: {
      color: '#777',
    },
    textXS: {
      fontSize: 6.5,
    },
    textSM: {
      fontSize: 7,
    },
    bold: {
      bold: true,
    },
    tableCellIndex: {
      color: '#777',
      fontSize: 7,
      margin: [0, 12, 0, 6],
    },
    tableCellShallow: {
      color: '#777',
      fontSize: 7,
    },
    tableCellBold: {
      fontSize: 8,
      bold: true,
    },
    headInfoContent: {
      fontSize: 7,
      lineHeight: 1.3,
    },
    titleContent: {
      fontSize: 12,
      margin: [0, 10, 0, 10],
    },
  },
  defaultStyle: {
    columnGap: 3,
    lineHeight: 1.2,
    fontSize: 8,
    font: 'Helvetica',
  },
}

export const createRowGroupContent = (group: InvoicePDFInfoRowGroup, colConfigs: InvoicePDFColConfig[]) => [
  { text: group.groupTitle, style: 'rowGroupTitle' },
  ...group.rows.map((row) => createRowContent(row, colConfigs)),
  { text: group.groupFooter, style: 'rowGroupFooter' },
]

export const createRowContent = (row: InvoicePDFInfoItemRow, colConfigs: InvoicePDFColConfig[]) =>
  colConfigs.map((colConf) => createColContent(row.cols[colConf.id], colConf, row))

export const isValidImage = (url: string) =>
  new Promise((resolve) => {
    const image = new Image()
    image.onload = () => resolve(true)
    image.onerror = () => resolve(false)
    image.src = url
  })

const createColContent = (
  col: InvoicePDFInfoItemCol,
  colConfig: InvoicePDFColConfig,
  rowConfig: InvoicePDFInfoItemRow,
) => {
  const style: StyleReference = [
    'tableCell',
    (rowConfig.style as Style) || undefined,
    (colConfig.style as Style) || undefined,
    (col.style as Style) || undefined,
  ].filter((e) => !!e)
  const border = rowConfig.border === false ? [false, false, false, false] : [false, true, false, false]
  return isString(col.content) || isNumber(col.content)
    ? {
        text: col.content,
        style,
        alignment: colConfig.alignment,
        border,
      }
    : {
        ...col.content,
        style,
        border,
      }
}

export const createDocumentDefinition = async (
  {
    titleContent,
    info,
    logo,
    headInfoContent,
    addressContent,
    itemTableConfig,
    belowTableContent,
    tableFooter,
    pageFooter,
  }: InvoicePDFInfo,
  fallbackImage: string = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC',
) => {
  const images: Record<string, string> = Object.fromEntries(
    itemTableConfig.rowsOrGroups
      .flatMap((rowOrGroup) => (isRowGroup(rowOrGroup) ? rowOrGroup.rows : [rowOrGroup]))
      .flatMap((row) => Object.values(row.cols))
      .map((col) => col.content)
      .filter((content) => isContentImage(content))
      .map((content) => [(content as ContentImage).image, (content as ContentImage).image]),
  )
  // replace with placeholder if not valid
  await Promise.all(
    Object.values(images).map(async (url) => {
      const isValid = await isValidImage(url)
      console.log('isValid', isValid)
      if (!isValid) images[url] = fallbackImage
    }),
  )
  const createTable: () => ContentTable = () => ({
    layout: customTableLayout,
    table: {
      headerRows: 1,
      dontBreakRows: true,
      widths: itemTableConfig.colConfigs.map((item) => item.width || '*'),
      body: [
        // Table Header
        itemTableConfig.colConfigs.map((item) => ({
          text: item.label,
          style: 'tableHead',
          alignment: item.alignment,
        })),
        // Table Content
        ...itemTableConfig.rowsOrGroups.map((rowOrGroup) =>
          isRowGroup(rowOrGroup)
            ? createRowGroupContent(rowOrGroup, itemTableConfig.colConfigs)
            : createRowContent(rowOrGroup, itemTableConfig.colConfigs),
        ),
        [{ text: '', border: [false, true, false, false], colSpan: itemTableConfig.colConfigs.length }],
      ],
    },
  })

  const definition: TDocumentDefinitions = {
    ...DEFAULTS,
    images,
    info,
    content: [
      logo,
      {
        margin: [0, 0, 0, 25],
        columns: [
          addressContent || [],
          {
            width: '35%',
            style: 'headInfoContent',
            stack: headInfoContent || [],
          } as ContentStack,
        ],
      },
      {
        text: titleContent,
        style: 'titleContent',
      },
      createTable(),
      SPACER(),
      ...(tableFooter
        ? [
            {
              layout: 'noBorders',
              lineHeight: 1,
              table: {
                headerRows: 0,
                widths: [300, 'auto', '*'],
                body: tableFooter,
              },
            },
          ]
        : []),
      SPACER(10),
      belowTableContent ? belowTableContent : '',
    ],
    footer: (currentPage, pageCount) => ({
      style: 'documentFooter',
      columns: [pageFooter, { width: 10, alignment: 'right', text: `${currentPage}/${pageCount} ` }],
    }),
  }
  return definition
}

export const customTableLayout: CustomTableLayout = {
  hLineWidth: (i, node) => (i === 0 ? 0 : i === node.table.headerRows ? 0.25 : 0.25),
  vLineWidth: () => 0,
  paddingTop: () => 0,
  paddingBottom: () => 1,
  hLineColor: '#ccc',
  paddingLeft: (i) => (i === 0 ? 0 : 4),
  paddingRight: (i, node) => (i === node.table.widths!.length - 1 ? 0 : 4),
}

export const customFonts: TFontDictionary = {
  Helvetica: {
    normal: window.location.origin + '/fonts/Helvetica.ttf',
    bold: window.location.origin + '/fonts/Helvetica-Bold.ttf',
    italics: window.location.origin + '/fonts/Helvetica-Oblique.ttf',
    bolditalics: window.location.origin + '/fonts/Helvetica-BoldOblique.ttf',
  },
}

export const createAndOpenPdf = (definition: TDocumentDefinitions, iframe?: HTMLIFrameElement) => {
  const pdfMake = (window as any).pdfMake
  pdfMake.fonts = customFonts
  const pdfDocGenerator: TCreatedPdf = pdfMake.createPdf(definition)
  if (!iframe) {
    // pdfDocGenerator.open({}, window)
  } else {
    pdfDocGenerator.getBlob((blob: any) => {
      iframe!.src = URL.createObjectURL(blob)
    })
  }
  return pdfDocGenerator as TCreatedPdf
}

export const create = async (info: InvoicePDFInfo, iframe?: HTMLIFrameElement) => {
  const definition = await createDocumentDefinition(info)
  createAndOpenPdf(definition, iframe)
}
