<template lang="pug">
div(class='notification-list')
  div(v-if='hasItems && ready')
    notification(v-for='(item, index) in items' v-bind='item' :key='index' :showIcon='showIcons')
</template>

<script lang="ts">
import Notification from './Notification.vue'
import { type INotification } from '@/components/GenericForm/types'
import { defineComponent, type PropType, computed, ref, onMounted, nextTick } from 'vue'

const NotificationList = defineComponent({
  components: { Notification },
  props: {
    items: { type: Array as PropType<INotification[]> },
    showIcons: Boolean,
  },
  setup(props) {
    const ready = ref(false)

    onMounted(() => nextTick(() => (ready.value = true)))
    return {
      ready,
      hasItems: computed(() => props.items && props.items.length > 0),
    }
  },
})
export default NotificationList
</script>
