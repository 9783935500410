<template lang="pug">
div(v-bind='attributes' class='control-select')
  icon(v-if='icon' :name='icon' class='control-select__icon')
  select(:id='id' ref='el' v-model='val' :disabled='disabled' @focus='onFocus' @blur='onBlur' @change.stop.prevent='onChange')
    slot(name='options')
      option(v-if='placeholder' disabled value='' selected) {{ $t(placeholder) }}
      option(v-for='option in options' :key='option.value' :value='option.value') {{ option.label }}
  div(class='control-select__placeholder')
    span {{ currentTitle }}
  icon(name='chevron-down' class='control-select__arrow')
</template>

<script lang="ts">
import { useControl, Input } from './composables'
import Icon from '@/components/Icon.vue'
import { useTranslation } from '@/plugins/translation'
import type { IOption } from '@/types'
import { shakeFalsy } from '@/utilities'
import { computed, defineComponent, type PropType, type SetupContext, nextTick, onMounted, toRaw } from 'vue'

const ControlSelect = defineComponent({
  components: { Icon },
  props: {
    ...Input.props,
    modelValue: [String, Number],
    inline: Boolean,
    icon: String,
    placeholder: String,
    options: Array as PropType<IOption[]>,
  },
  setup(props, context: SetupContext) {
    const { $t } = useTranslation()
    const {
      value: val,
      focus,
      hasFocus,
      onChange,
      onFocus,
      onBlur,
      cachedInitialValue,
    } = useControl(props, context, props.modelValue)
    // prevents iOs safari from preselecting first option initially without propagating it to the field value
    if (!val.value === undefined && props?.options && props.options.length > 0) {
      nextTick(() => {
        val.value = undefined
      })
    }
    // reset value if not in options
    onMounted(() => !currentOption.value && (val.value = cachedInitialValue))

    const currentOption = computed(() =>
      props.options?.find(({ value }) => JSON.stringify(value) === JSON.stringify(toRaw(val.value))),
    )
    const isEmpty = computed(() => !val.value || !props.options?.map((i) => i.value).includes(val.value))
    return {
      isEmpty,
      onFocus,
      onChange,
      onBlur,
      currentOption,
      focus,
      hasFocus,
      val,
      currentTitle: computed(
        () =>
          currentOption.value?.label ||
          currentOption.value?.value ||
          (props.placeholder && $t(props.placeholder)) ||
          '',
      ),
      attributes: computed(() =>
        shakeFalsy({
          'data-disabled': props.disabled,
          'data-empty': isEmpty.value,
          'data-placeholder-visible': props.placeholder && isEmpty.value,
          'data-inline': props.inline,
          'data-icon': !!props.icon,
        }),
      ),
    }
  },
})

export default ControlSelect
</script>

<style lang="stylus">
@import '../../styles/variables.styl'
:root
  --control-icon-width: 0px
  --control-font-size: 15px

.control-select
  display: inline-flex
  width: 100%
  align-items: center
  position: relative
  background: var(--control-bg-color)
  border: var(--control-border-width) solid var(--control-border-color)
  border-radius: var(--control-border-radius)
  cursor: pointer
  &[small]
    --control-height: var(--control-height-small)
  &[large]
    --control-height: var(--control-height-large)
  &[tiny]
    --control-height: var(--control-height-tiny)
  &[medium]
    --control-height: var(--control-height-medium)
  &[data-inline]
    width: auto
  &[data-icon]
    --control-icon-width: 24px
  &[data-disabled]
    --control-bg-color: var(--grey-75)
    --control-border-color: var(--grey-75)
    --control-text-color: var(--color-text-light)
    cursor: unset
  &[plain]
    --control-bg-color: transparent
    --control-padding-x: 3px
    --control-border-color: transparent
    &:hover:not([data-disabled])
      --control-border-color: transparent
    &:hover:not([data-disabled]),
    &:focus-within:not([data-disabled])
      --control-bg-color: var(--grey-75)
  &[grey]
    mix-blend-mode: multiply
    --control-bg-color: var(--grey-75)
    --control-border-color: var(--grey-75)
    &:hover:not([data-disabled]):not(:focus):not(:focus-within)
      --control-bg-color: var(--grey-100)
      --control-border-color: var(--grey-100)
    &:focus,
    &:focus-within
      --control-bg-color: white
  &__arrow, &__icon
    position: absolute
    pointer-events: none
    stroke-width: 2px
  &__arrow
    right: 5px
    color: var(--color-text-light)
    ~/:hover:not([data-disabled]) &,
    ~/:focus-within:not([data-disabled]) &
      color: var(--color-text)
  &__icon
    left: 6px
    color: var(--color-text-light)
    ~/:focus-within:not([data-disabled]) &
      color: var(--color-text)
  [error] &
    --control-border-color: var(--color-danger)
    --control-bg-color: white!important
    &:hover
      --control-border-color: var(--red-600)
  &:hover:not([data-disabled]) &
    --control-border-color: var(--grey-200)
  &&:focus-within:not([data-disabled])

      --control-border-color: var(--color-accent)
  &__placeholder
    pointer-events: none
    align-items: center
    display: flex
    text-indent: 0
    min-width: 0
    [floating] [data-empty="true"] &
      color: transparent!important
    ~/[data-disabled] &,
    ~/[data-placeholder-visible]:not(:hover) &
      color: var(--color-text-light)
    span
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
  select
    background: transparent
    position: absolute
    width: 100%
    left: 0
    opacity: 0
    appearance: none
    border: none
    flex-grow: 1
    height: var(--control-height)
  &__placeholder, select
    text-align: left
    width: 100%
    font-size: var(--control-font-size)
    option
      font-size: 16px!important
    letter-spacing: 0
    font-weight: 400
    font-family: $font-default
    height: var(--control-height)
    padding-top: var(--control-padding-t)
    padding-right: calc(var(--control-padding-x) + 24px)
    padding-left: calc(var(--control-padding-x) + var(--control-icon-width))
    ~/[bold] &
      font-weight: bold!important
</style>
