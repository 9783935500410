<template lang="pug">
backdrop(ref='backdrop' v-focus-trap='!disableFocusTrap && ready && $flags && !$flags.DISABLE_FOCUS_LOCK' :visible='true' :withPadding='mode === "default"')
  div(v-bind='$attrs' :id='id' ref='element' :class='{ [`modal--${size}`]: true, [`modal--${mode}`]: true }' tabindex='0' class='modal')
    div(class='modal__content')
      slot(:close='close')
</template>

<script setup lang="ts">
import { useEscapeKey } from '@/plugins/EscapeKey'
import { useModals } from '@/plugins/Modals'
import { type Sizes } from '@/types'
import { onClickOutside } from '@vueuse/core'
import { provide, ref, onUnmounted, nextTick, type PropType } from 'vue'
import { onMounted } from 'vue'

export type ModalSizes = Sizes | 'fullwidth'

export type ModalTypes = 'default' | 'drawer'

const props = defineProps({
  id: { type: String, default: () => `modal_${Math.random()}` },
  /**
   * If true, outside click will close the modal
   */
  maskCloseable: { type: Boolean, default: false },
  /**
   * If true an X button will be displayed
   */
  closeable: { type: Boolean, default: true },
  /**
   * If true focus will not be trapped in modal
   */
  disableFocusTrap: Boolean,
  /**
   * If true modal can be closed by hititng the X button
   */
  keyboard: { type: Boolean, default: true },
  /**
   * @values medium, small, large
   */
  size: { type: String as PropType<ModalSizes>, default: 'base' },
  mode: { type: String as PropType<ModalTypes>, default: 'default' },
  onBeforeClose: { type: Function as PropType<() => Promise<boolean>> },
})

const emit = defineEmits<{ close: [id: string, value: any] }>()
defineOptions({ inheritAttrs: false })

const $modals = useModals()
const element = ref(null)

const close = async (value?: any) => {
  if (props.onBeforeClose) {
    const allowClose = await props.onBeforeClose()
    if (!allowClose) {
      return
    }
  }
  emit('close', props.id, value)
}
provide('closeModal', close)
if (props.keyboard) {
  useEscapeKey(() => $modals.isUpperMost?.(props.id) && (close(), true))
}
onClickOutside(element as any, () => props.maskCloseable && close())

const ready = ref(false)
onUnmounted(() => nextTick(() => (ready.value = false)))
onMounted(() => nextTick(() => (ready.value = true)))
</script>
<style lang="stylus">
@import '../styles/variables.styl'

:root
  --modal-padding-top: 20px
  --modal-padding-bottom: 25px
  --modal-padding-right: 15px
  --modal-padding-left: 15px
  --modal-min-height: 150px
  --modal-width: calc(100% - 10px)
  --modal-offset-top: 0px
  --modal-border-radius: 14px

.modal
  --bleed-left: var(--modal-padding-left)
  --bleed-right: var(--modal-padding-right)
  box-sizing: border-box
  margin: 5px 5px 50px 5px
  padding: 0
  position: relative
  background: $color-white
  width: var(--modal-width)
  max-width: 100%
  pointer-events: all
  min-height: var(--modal-min-height)
  top: var(--modal-offset-top)
  border-radius: var(--modal-border-radius)
  display: flex
  flex-flow: column
  &__headInner
    flex: 1
  &__close
    z-index: 2
  @media (max-width: $breakpoint-xs-max)
    max-height: calc(100% - var(--modal-offset-top))
    overflow: auto
  @media (min-width: $breakpoint-xs)
    margin: 0 auto 30px
    --modal-offset-top: 10px
  @media (min-width: $breakpoint-sm)
    --modal-offset-top: 30px
    --modal-padding-right: 36px
    --modal-padding-left: 36px
  &:focus
    outline: 0
  &--drawer
    --modal-border-radius: 0
    top: 0
    margin: 0 0 0 auto!important
    overflow: hidden
    overflow-y: auto
    height: 100%
    @media (min-width: $breakpoint-sm)
      max-width: calc(100vw - 30px )!important

  &[admin]
    &:before
      border-top: 4px solid var(--color-nuucon)!important
      content: ''
      display: block
      height: 1px
      position: absolute
      top: 0px
      left: var( --modal-padding-right)
      width: calc(100% - var( --modal-padding-right) * 2 )
  &--xs
    --modal-min-height: 120px
    --modal-width: 360px
    --modal-padding-right: 28px
    --modal-padding-left: 28px
  &--sm
    --modal-padding-right: 30px
    --modal-padding-left: 30px
    --modal-width: 520px
  &--base
    --modal-min-height: 220px
    --modal-width: 720px
  &--lg
    --modal-min-height: 350px
    --modal-width: 870px
  &--xl
    --modal-min-height: 450px
    --modal-width: 1200px
  &--2xl
    --modal-min-height: 550px
    --modal-width: 1400px
  &--3xl
    --modal-min-height: 550px
    --modal-width: calc(100vw - 100px)
  &--fullwidth
    --modal-width: 100%
    background: transparent
    top: var(--modal-padding-top)
    --modal-padding-right: 0
    --modal-padding-left: 0
  &__content
    padding: var(--modal-padding-top) var(--modal-padding-right) var(--modal-padding-bottom) var(--modal-padding-left)
    display: flex
    flex-flow: column
    flex: 1
    ~/--tiny &, ~/--small &
      min-height: 40px
</style>
