import * as focusTrap from 'focus-trap'
import { type Directive, type DirectiveBinding } from 'vue'

const trapMap: Map<HTMLElement, focusTrap.FocusTrap> = new Map()
/**
 * @TODO: check safari behavior (https://github.com/focus-trap/focus-trap/issues/783)
 */
const FocusTrap: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding): void {
    trapMap.set(el, focusTrap.createFocusTrap(el, { allowOutsideClick: true, escapeDeactivates: false }))
    if (binding.value) {
      try {
        trapMap.get(el)?.activate()
      } catch (e) {
        console.warn('ERROR:', e)
      }
    }
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    if (trapMap.get(el)) {
      if (binding.value) {
        try {
          trapMap.get(el)?.activate()
        } catch (e) {
          console.warn('ERROR:', e)
        }
      } else {
        try {
          trapMap.get(el)?.deactivate()
        } catch (e) {
          console.warn('ERROR:', e)
        }
      }
    }
  },
  beforeUnmount(el: HTMLElement) {
    if (trapMap.get(el)) {
      try {
        trapMap.get(el)?.deactivate()
        trapMap.delete(el)
      } catch (e) {}
    }
  },
}

export default FocusTrap
