<template lang="pug">
text-auto-expand(v-if='description' v-model='expanded' :text='text' class='whitespace-pre-wrap text-sm text-text-light')
</template>
<script setup lang="ts">
import { useTranslation } from '@/plugins/translation'
import { computed } from 'vue'

const props = defineProps<{
  description: string
  productNumber: string
}>()
const { $t } = useTranslation()
const text = computed(() =>
  !props.description && props.productNumber
    ? `${$t('Article Number')}: ${props.productNumber}`
    : props.description && props.productNumber
      ? `${props.description}\n(${$t('Article Number')}: ${props.productNumber})`
      : props.description || '',
)
const expanded = defineModel<boolean | undefined>()
</script>
