<template lang="pug">
div(class='collapse-container')
  slot
</template>

<script lang="ts">
/**
 * Wrapper component for CollapseItem components-
 * @displayName Collapse
 */
export default {}
</script>
<style lang="stylus">
@import '../../styles/variables.styl'

.collapse-container
  margin: 20px 0

  p
    font-size: 15px
</style>
