<template lang="pug">
modal(size='2xl' mode='drawer' @close='$emit("close")')
  modal-header
    span(class='mr-2') {{ $t('PDF Preview') }}:
    span(v-if='inquiry' class='font-light') {{ inquiry.inqTitle || inquiry.id }}
    template(#below)
      collapse-content(:when='showFilters')
        div(class='flex items-center gap-2 pt-4')
          label(class='text-text-light') {{ $t('Show or hide fields') }}:
          control-switch(v-for='(_, key) in hideFields' :key='key' v-model='hideFields[key]' size='tiny')
            span {{ $t(key) }}
    template(#actions)
      btn(:active='showFilters' icon='sliders' secondary='' @click='() => (showFilters = !showFilters)') {{ $t('Settings') }}
      btn(icon='download' secondary='' @click='download(inquiry.inqTitle || inquiry.id)') {{ $t('download') }}
      btn(icon='printer' secondary='' @click='print') {{ $t('print') }}
  div(class='bleed relative flex-1')
    overlay(:visible='loading')
    iframe(ref='iframe' data-test='iframe-inquiry-pdf-preview' class='h-full w-full flex-1')
</template>

<script setup lang="ts">
import { useInquiriesStore } from '@/pages/inquiries/store'
import { stringArrayToBooleanMap } from '@/utilities'
import { usePdfGenerator } from '@/utilities/Pdf'
import { createInquiryPdfDefinition } from '@/utilities/PdfInquiry'
import { useSessionStorage, watchDebounced } from '@vueuse/core'
import { computed, ref, type Ref, onMounted } from 'vue'

const DEFAULT_INQUIRY_PDF_SETTINGS = [
  'quantity',
  'retailPriceNetString',
  'retailPriceTotalNetString',
  'tableFooter',
  'addressContent',
  'headInfoContent',
  'belowTableContent',
]

const props = defineProps<{
  inquiryId: string
}>()

const iframe = ref<HTMLIFrameElement | null>(null)
const { init, download, print } = usePdfGenerator(iframe as Ref<HTMLIFrameElement>)
const loading = ref(true)
const store = useInquiriesStore()
const inquiry = computed(() => store.allInquiriesAsMap[props.inquiryId])

const hideFields = useSessionStorage<Record<string, boolean>>(
  'pdf-settings-inquiry-2',
  stringArrayToBooleanMap(DEFAULT_INQUIRY_PDF_SETTINGS, true),
)

const hideFieldsMapped = computed(() =>
  Object.entries(hideFields.value)
    .filter(([, value]) => !value)
    .map(([key]) => key),
)

const update = async () => {
  loading.value = true
  if (!inquiry.value) {
    await store.loadInquiry(props.inquiryId)
  }
  const definitions = await createInquiryPdfDefinition(inquiry.value, hideFieldsMapped.value)
  init(definitions)
  loading.value = false
}
onMounted(async () => {
  update()
  watchDebounced(() => [inquiry.value, hideFields.value], update, {
    debounce: 100,
    deep: true,
  })
})

const showFilters = ref(false)
</script>
