<template lang="pug">
teleport(v-if='visible' :to='teleportTarget')
  div(ref='content' :style='floatingStyles' class='popover-image-zoom-provider')
    btn(icon='x' plain @click='close')
    spinner(v-if='loading')
    img(v-if='imageSrc' v-image-lazy='true' :data-src='imageSrc' :data-cloudinary='currentCloudinaryTransformation' @load='loading = false')
</template>

<script setup lang="ts">
import { useDelegatedClickEventListener } from '@/composables/'
import { useEscapeKey } from '@/plugins/EscapeKey'
import { autoUpdate, useFloating, offset } from '@floating-ui/vue'
import { onClickOutside, useParentElement } from '@vueuse/core'
import { ref, onMounted, type ComponentPublicInstance, computed, type Ref } from 'vue'

const props = defineProps({
  teleportTarget: { type: String, default: '#content' },
  cloudinaryTransformation: { type: String, default: 't_lg' },
})
const content = ref<null | HTMLElement>(null)
const target = ref<null | HTMLElement>(null)
const visible = ref(false) as Ref<boolean>
const parentEl = useParentElement()
const imageSrc = ref('')
const currentOffset = ref(-60)
const currentCloudinaryTransformation = ref<string | boolean>(props.cloudinaryTransformation)
const middleware = computed(() => [offset(currentOffset.value)])
const { floatingStyles } = useFloating(target, content, {
  whileElementsMounted: autoUpdate,
  placement: 'bottom-start',
  middleware,
})

useEscapeKey(() => (visible.value ? ((visible.value = false), true) : false))
onMounted(() =>
  onClickOutside(target, () => (visible.value = false), {
    detectIframe: true,
    ignore: [content],
  }),
)
const setRef = (ref: ComponentPublicInstance | null | Element) => {
  target.value = ref ? ((ref as ComponentPublicInstance)?.$el as HTMLElement) || (ref as HTMLElement) : null
}

const loading = ref(false)
onMounted(() => {
  useDelegatedClickEventListener(
    parentEl.value as HTMLElement,
    '[data-src-zoom]',
    (targetElement, event) => {
      loading.value = true
      event.stopPropagation()
      if (targetElement === target.value && visible.value) {
        visible.value = false
      } else {
        setRef(targetElement! as Element)
        imageSrc.value = targetElement.getAttribute('data-src-zoom')!
        currentOffset.value = targetElement.getBoundingClientRect().height * -1
        currentCloudinaryTransformation.value =
          targetElement.getAttribute('data-cloudinary') !== 'false' ? props.cloudinaryTransformation : false
        visible.value = true
      }
    },
    true,
  )
})

const close = () => (visible.value = false)
</script>

<style lang="stylus">
@import '../styles/variables.styl'
[data-src-zoom-parent]
  position: relative
  cursor: pointer!important
  &:hover
    &:after, &:before
      z-index: 1
      content: ''
      display: block
      pointer-events: none
      background: transparent url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'><path stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m21 21-4.35-4.35M19 11a8 8 0 1 1-16 0 8 8 0 0 1 16 0Z'/></svg>") no-repeat center
      border-radius: 4px
      position: absolute
      left: 0
      background-size: 20px 20px
      opacity: 0.5
      top: 0
      width: 100%
      height: 100%
    &:before
      opacity: 0.075
      background: var(--grey-900)

.popover-image-zoom-provider
  padding: 20px
  position: absolute
  top: 0
  left: 0
  background: $color-white
  box-shadow: $shadow-intense
  border-radius: 8px
  z-index: 9999
  border: 1px solid #eee
  overflow: hidden
  width: 300px
  min-height: 220px
  > .spinner
    position: absolute!important
    left: 50%
    top: 50%
    z-index: -1
    font-size: 30px
  .btn
    z-index: 99
    position: absolute
    top: 8px
    left: 8px
  img
    width: 100%
    border-radius: 6px
</style>
