<template lang="pug">
modal(class='modal-product-detail' size='2xl' mode='drawer' @close='$emit("close")')
  modal-header(class='z-40')
  div(class='-mt-12')
    product-detail-container(:id='productId' hideSimilarProducts :hideActions='hideActions' style='--product-detail-info-grid-area: 2/1/3/3')
</template>

<script setup lang="ts">
import { useModals } from '@/plugins/Modals'
import { provide } from 'vue'

defineOptions({ inheritAttrs: false })
defineProps({
  productId: { required: true, type: String },
})
const $modals = useModals()

provide('productVariantLinkFn', (productId: string) => $modals.getLocation('modal-product-detail', { productId }))
const hideActions = window.location.href.includes('manufacturer')
</script>
