<template lang="pug">
dropdown(ref='dropdown' v-model='isOpen' fitTriggerWidth ignoreContentClick :focusTrap='false' :trigger='container' :offset='6' :maxWidth='width' :minWidth='width' style='display: block; width: 100%')
  div(
    v-bind='{ ...attributes, ...$attrs }'
    :id='id'
    ref='container'
    :class='{ "multiselect--multiline": allowMultiline, "multiselect--open": isOpen, "multiselect--multiple": multiple }'
    class='multiselect'
    @keydown.prevent.up='!disabled && onKeydownUp()'
    @keydown.prevent.down='!disabled && onKeydownDown()'
    @keydown.prevent.enter='!disabled && onKeydownEnter()'
    @keydown.esc='onKeyEsc'
  )
    div(:tabindex='!searchable ? 0 : -1' role='button' class='multiselect__inner' @focus='() => !disabled && !searchable && onFocus()' @blur='!disabled && onBlur()') 
      div(class='multiselect__innerWrapper' @click.stop.prevent='!disabled && onClick()')
        template(v-if='multiple')
          div(v-for='item in selectedOptions' :key='item.label' :disabled='disabled' class='multiselect-bubble' @click.prevent.stop='onClickSelectedOption(item)')
            span {{ item.label }}
            icon(name='x')
        div(v-if='searchable' class='multiselect__inputContainer')
          input(
            ref='el'
            v-model='inputValue'
            type='text'
            :style='inputStyle'
            :disabled='disabled'
            class='multiselect__input'
            @click='open'
            @blur='onBlur'
            @focus='onFocusInput'
            @keydown.backspace='onKeydownBackspace'
            @input='onInput'
          )
        div(v-if='isValueVisible' class='multiselect__single')
          span(v-html='selectedOptionsAsString')
        div(v-else-if='isPlaceholderVisible' class='multiselect__placeholder')
          span {{ !multiple && selectedOptions.length > 0 ? selectedOptionsAsString : placeholder }}
      div(class='multiselect__actions' @click='open')
        slot(name='clear' :clear='clear')
          btn(v-if='showClear || loading' :icon='loading ? "loading" : "x"' tabIndex='-1' faded small class='multiselect__clear' @click.stop='clear')
          icon(:name='isOpen ? "chevron-up" : "chevron-down"' class='multiselect__arrow')
  template(#content) 
    div(:data-test-multiselect-dropdown='id' class='multiselect__dropdown' @mousedown.prevent='focus')
      div(class='multiselect__dropdownInner')
        div(class='multiselect__items')
          div(v-for='({ item }, index) in visibleOptions' :key='item.value' ref='itemsRef')
            slot(
              name='item'
              :item='item'
              :label='item.label'
              :data='item.data'
              :focus='focus'
              :value='item.value'
              :focused='index === focused'
              :selected='selected[item.value]'
              :toggle='() => onClickItem(index)'
            )
              div(
                :class='{ "multiselect-item--hasChildren": item.children && item.children.length > 0, "multiselect-item--focus": index === focused, "multiselect-item--selected": selected[item.value] }'
                :data-level='item.level'
                class='multiselect-item'
                @click.stop.prevent='onClickItem(index)'
              ) 
                div(v-html='item.label')
        div(v-if='loading && hasNoVisibleOptions' class='multiselect__dropdownPlaceholder')
          span(class='faded' v-html='$t("Loading..")')
        div(v-else-if='hasNoVisibleOptions' class='multiselect__dropdownPlaceholder')
          slot(name='empty' :inputValue='inputValue')
            span(v-if='inputValue' class='faded' v-html='$t("No results for {0}", undefined, [inputValue])')
            span(v-else class='faded' v-html='$t("No results")')
        div(v-if='$slots.footer' class='multiselect__footer')
          slot(name='footer')
      div(v-if='$slots.aside' class='multiselect__dropdownInnerRight')
        slot(name='aside' :selectedOptions='selectedOptions')
</template>

<script lang="ts">
import { flattenTreeArray, type IFlatTreeItem } from '../../utilities/TreeUtils'
import { Input, useControl } from './composables'
import Btn from '@/components/Btn.vue'
import Dropdown from '@/components/Dropdown.vue'
import Icon from '@/components/Icon.vue'
import { type IOptionNested } from '@/types'
import { syncRef, useElementSize } from '@vueuse/core'
import { useFuse } from '@vueuse/integrations/useFuse'
import { isArray, shake } from 'radash'
import {
  computed,
  type ComputedRef,
  defineComponent,
  nextTick,
  type PropType,
  ref,
  type Ref,
  type SetupContext,
  watch,
  type WritableComputedRef,
} from 'vue'

export const useDropdown = (context: SetupContext) => {
  const isOpen: Ref<boolean> = ref(false)
  const hasBeenOpened: Ref<boolean> = ref(false)
  watch(
    () => isOpen.value,
    (v: boolean) => context.emit('update:open', v),
    { immediate: true },
  )
  return {
    isOpen,
    hasBeenOpened,
    open: () => ((isOpen.value = true), (hasBeenOpened.value = true)),
    close: () => (isOpen.value = false),
    position: computed(() => ['top', 'left']),
  }
}

const ControlMultiSelect = defineComponent({
  components: { Btn, Icon, Dropdown },
  props: {
    ...Input.props,
    modelValue: { required: true, type: [Array, String] as PropType<string | string[]> },
    nested: Boolean,
    searchable: { type: Boolean, default: true },
    multiple: Boolean,
    loading: Boolean,
    // @TODO: check styling
    allowMultiline: Boolean,
    creatable: Boolean,
    filter: { type: Boolean, default: true },
    closeOnSelect: { type: Boolean, default: true },
    placeholder: { type: String, default: 'Select..' },
    placeholderCreatable: { type: Function, default: (value: string) => `Create <b>${value}</b>` },
    options: { type: Array as PropType<IOptionNested[]>, required: true },
    cachedOptions: { type: Array as PropType<IOptionNested[]>, default: () => [] },
    query: { type: String, default: '' },
  },
  setup(props, context: SetupContext) {
    const container = ref<HTMLElement | null>(null)
    const { multiple, disabled, searchable, nested, creatable, closeOnSelect } = props
    const { value, focus, onFocus, hasFocus, provideState, onSubmit, onBlur, isEmpty, el } = useControl<string[]>(
      props,
      context,
      [],
    )
    const { isOpen, hasBeenOpened, close: closeDropdown, open: openDropdown } = useDropdown(context)
    const onChange = () => context.emit('update:modelValue', multiple ? value.value : value.value?.[0] || '')
    const externalInputValue: WritableComputedRef<string> = computed({
      set: (value: string) => context.emit('update:query', value),
      get: () => props.query!,
    })

    const inputValue = ref<string>('')
    provideState?.(
      'isEmpty',
      computed(() => isEmpty.value && inputValue.value.length === 0),
    )

    syncRef(inputValue, externalInputValue)
    const dropdown = ref<null | InstanceType<typeof Dropdown>>(null)
    watch(
      () => value.value,
      () => nextTick(() => dropdown.value?.update()),
    )

    const focused: Ref<number> = ref(-1)
    const selectedOptions: Ref<IOptionNested[]> = ref([])

    const computedOptions: ComputedRef<IOptionNested[]> = computed(() =>
      nested
        ? flattenTreeArray<IOptionNested>(props.options).map(
            ({ parents, option }: IFlatTreeItem<IOptionNested>) =>
              ({ ...option, level: parents.length }) as IOptionNested,
          )
        : props.options,
    )
    const optionsAsMap: ComputedRef<{ [value: string]: IOptionNested }> = computed(() =>
      [...selectedOptions.value, ...computedOptions.value, ...props.cachedOptions].reduce(
        (m: { [value: string]: IOptionNested }, o: IOptionNested) => ({ ...m, [o.value as string]: o }),
        {},
      ),
    )
    const selectedOptionsAsString: ComputedRef<string> = computed(() =>
      selectedOptions.value.map((o) => o.label).join(', '),
    )
    const isFilterEmpty: ComputedRef<boolean> = computed(() => inputValue.value.length === 0)
    const hasSelectedOptions: ComputedRef<boolean> = computed(() => selectedOptions.value.length > 0)
    const focusedOption: ComputedRef<IOptionNested> = computed(() => visibleOptions.value[focused.value])
    const selected: ComputedRef<Record<string, boolean>> = computed(
      () =>
        ((isArray(value.value) ? value.value : [value.value]) as string[]).reduce?.(
          (s: Record<string, boolean>, v: string) => ({ ...s, [v]: true }),
          {},
        ) || {},
    )

    const hasInputValue = computed(() => inputValue.value.length > 0)
    const hasPerfectMatch = computed(
      () =>
        hasInputValue.value &&
        computedVisibleOptions.value.some(({ item }) => item.label.toLowerCase() === inputValue.value.toLowerCase()),
    )

    const visibleOptions: ComputedRef<any[]> = computed(() =>
      creatable && hasInputValue.value && !hasPerfectMatch.value
        ? [
            ...computedVisibleOptions.value,
            { item: { value: '#new', label: props.placeholderCreatable(inputValue.value) } },
          ]
        : computedVisibleOptions.value,
    )

    const { results: computedVisibleOptions } = useFuse(inputValue, computedOptions, {
      fuseOptions: {
        keys: ['label'],
        includeMatches: true,
        minMatchCharLength: 1,
        ignoreFieldNorm: true,
        ignoreLocation: true,
        threshold: 0.2,
      },
      matchAllWhenSearchEmpty: true,
    })

    const toggle = (o: IOptionNested) =>
      o && (!selected.value[o.value as string] ? select(o) : deselect(o)) && updateFocus()
    const select = (v: IOptionNested, deselectOthers?: boolean) => {
      const opt = v.value === '#new' ? { label: inputValue.value, value: inputValue.value } : { ...v }
      if (deselectOthers) {
        selectedOptions.value = [opt]
      } else {
        selectedOptions.value.push(opt)
      }
    }

    const deselect = (v: IOptionNested) =>
      (selectedOptions.value = selectedOptions.value.filter((vl: IOptionNested) => vl.value !== v.value))
    const choose = (index: number) =>
      multiple
        ? toggle(visibleOptions.value[index].item)
        : visibleOptions.value[index] && select(visibleOptions.value[index].item, true)
    const updateFocus = () =>
      focused.value >= visibleOptions.value.length && (focused.value = visibleOptions.value.length - 1)
    const itemsRef = ref<HTMLElement[]>([])
    const scrollFocusedOptionIntoView = () => scrollOptionIntoView(focused.value)
    const scrollOptionIntoView = (index: number) =>
      itemsRef.value?.[index]?.scrollIntoView({ block: 'nearest', inline: 'start' })
    const closeAndClearInput = () => ((inputValue.value = ''), closeDropdown())

    const onKeyEsc = (e: KeyboardEvent) => isOpen.value && (e.stopPropagation(), closeAndClearInput())
    const open = () => (
      !isOpen.value &&
        (focused.value = hasSelectedOptions.value ? visibleOptions.value.indexOf(selectedOptions.value[0]) : 0),
      openDropdown()
    )

    // maps options onto passed values
    const updateOptions = (v: string[] | string) =>
      (selectedOptions.value = multiple
        ? (v as string[]).map((v2: string) => optionsAsMap.value[v2] || { value: v2, label: v2 }) // the latter case ensures that if options not provided yet, the selected options at least gets the provided value as label
        : [optionsAsMap.value[v as string] || { value: v, label: v }])

    const delayedFocus = () => nextTick(() => focus())

    if (props.modelValue) {
      updateOptions(props.modelValue)
    }

    watch(
      () => props.options,
      () => updateOptions(props.modelValue),
    )

    watch(
      () => props.modelValue,
      (v: string | string[]) =>
        (v as string[])?.join?.('') !== selectedOptions.value.map((o: IOptionNested) => o.value).join('') &&
        updateOptions(v),
    )

    watch(
      () => selectedOptions.value,
      (o: IOptionNested[]) => ((value.value = o.map((o2: IOptionNested) => o2.value as string)), onChange()),
      { deep: true },
    )

    // used to not call blur function when clicking on arrow
    const mouseDownFlag: boolean = false
    const { width } = useElementSize(container)

    return {
      mouseDownFlag,
      width: computed(() => `max(200px, ${width.value}px)`),
      onFocusInput: () => (onFocus(), (focused.value = 0)),
      onBlur: () => !mouseDownFlag && (onBlur(), closeAndClearInput()),
      itemsRef,
      el,
      container,
      close,
      isEmpty,
      dropdown,
      hasFocus,
      onFocus,
      focus,
      focused,
      toggle,
      onChange,
      val: value,
      selected,
      isOpen,
      computedOptions,
      hasBeenOpened,
      onSubmit,
      inputValue,
      open,
      focusedOption,
      visibleOptions,
      hasNoVisibleOptions: computed(() => visibleOptions.value.length === 0),
      selectedOptions,
      selectedOptionsAsString,
      attributes: computed(() => shake({ disabled: props.disabled }, (v) => !v)),
      clear: () => ((selectedOptions.value = []), closeAndClearInput(), focus()),
      onClick: () =>
        searchable
          ? (onFocus(), focus(), open())
          : isOpen.value
            ? closeAndClearInput()
            : (console.log('open'), onFocus(), open()),
      onClickItem: (index: number) => (
        choose(index), focus(), (focused.value = index), !multiple && closeOnSelect && closeAndClearInput()
      ),
      onClickSelectedOption: (o: IOptionNested) =>
        (selectedOptions.value = selectedOptions.value.filter((o2: IOptionNested) => o.value !== o2.value)),
      onKeyEsc,
      onKeydownUp: () => (isOpen.value ? focused.value > 0 && focused.value-- : open(), scrollFocusedOptionIntoView()),
      onKeydownBackspace: () => isFilterEmpty.value && (selectedOptions.value.pop(), delayedFocus()),
      onInput: () => (isOpen.value ? (focused.value = 0) : open()),
      onKeydownDown: () => (
        isOpen.value ? focused.value < visibleOptions.value.length - 1 && focused.value++ : open(),
        scrollFocusedOptionIntoView()
      ),
      onKeydownEnter: () => {
        if (isOpen.value && focused.value > -1) {
          choose(focused.value)
          if (multiple) {
            inputValue.value = ''
          } else {
            inputValue.value = focusedOption.value?.label || ''
          }
          if (!multiple && closeOnSelect) {
            closeAndClearInput()
          }
          delayedFocus()
        }
      },
      isValueVisible: computed(
        () => !multiple && isFilterEmpty.value && hasSelectedOptions.value && (!searchable || !isOpen.value),
      ),
      isPlaceholderVisible: computed(
        () =>
          isFilterEmpty.value &&
          (multiple
            ? !hasSelectedOptions.value || (!hasSelectedOptions.value && hasFocus.value)
            : !hasSelectedOptions.value || (searchable && isOpen.value)),
      ),
      inputStyle: computed(() =>
        multiple && selectedOptions.value.length > 0 ? { width: `${inputValue.value.length * 12 + 14}px` } : {},
      ),
      showClear: computed(() => !disabled && searchable && (inputValue.value !== '' || value.value.length > 0)),
    }
  },
})

export default ControlMultiSelect
</script>

<style lang="stylus">
@import '../../styles/variables.styl'

:root
 --bg-item: #fff
 --color-item: $color-outer-space
 --bubble-color: $color-outer-space
 --bubble-bg-color: lighten($color-manatee,30)
 --input-height: 42px

.multiselect-bubble
  margin: 2px 2px 2px 0
  background: var(--bubble-bg-color)
  color: var(--bubble-color)
  padding: 3px 4px 3px 7px
  font-size: 14px
  border-radius: 20px
  cursor: pointer
  transition: background .1s linear
  display: flex
  align-self: center
  align-items: center
  &:first-child
    margin-left: -5px
  &[disabled="true"]
    pointer-events: none
  .icon
    margin-left: 3px
    opacity: 0.5
    ~/:hover &
      opacity: 1
  &:hover
    --bubble-bg-color: var(--grey-200)
    --bubble-color: var(--grey-500)

.multiselect-item
  font-family: $font-default
  text-align: left
  cursor: pointer
  padding: 6px 11px
  line-height: 1.5
  border-radius: 4px
  margin: 1px 4px
  color: var(--color-item)
  background: var(--bg-item)
  &--hasChildren
    font-weight: bold
  &:hover,
  &--focus
    --bg-item: var(--grey-900-5)
    --color-item: var(--blue-500)
  &--selected
    --color-item: #fff!important
    --bg-item: var(--color-accent)!important
    ~/&:active,
    ~/&:hover,
    ~/--focus&
      --bg-item: var(--blue-500)!important

.multiselect
  display: inline-block
  position: relative
  width: 100%
  outline: none!important
  &[small]
    --control-height: var(--control-height-small)
  &[large]
    --control-height: var(--control-height-large)
  &[tiny]
    --control-height: var(--control-height-tiny)
  &[medium]
    --control-height: var(--control-height-medium)
  &[disabled]
    --control-bg-color: var(--grey-75)
    --control-border-color: var(--grey-75)
    --control-text-color: var(--color-text-light)
    pointer-events: none
  &[grey]
    --control-bg-color: var(--grey-75)
    --control-border-color: var(--grey-75)
    &:hover:not([data-disabled]):not(:focus):not(:focus-within)
      --control-bg-color: var(--grey-100)
      --control-border-color: var(--grey-100)
    &:focus,
    &:focus-within
      --control-bg-color: white
  &:hover:not([disabled])
    --control-border-color: var(--grey-200)
  [error] &
    &:hover, &
      --control-border-color: var(--color-danger)
  &:focus-within:not([disabled])
    --control-border-color: var(--color-accent)
  &__dropdown
    display: flex
  &__dropdownInnerRight
    flex: 1
    display: flex
  &__items
    overflow: auto
  &__dropdownInner
    position: relative
    flex: 1
    padding: 5px 0
  &__footer
    position: sticky!important
    bottom: 0!important
    background: var(--control-bg-color)

  &__inner
    display: flex
    box-sizing: content-box
    align-items: center
    justify-content: space-between
    background: var(--control-bg-color)
    border: var(--control-border-width) solid var(--control-border-color)
    border-radius: var(--control-border-radius)
    position: relative
    outline: none!important
    color: var(--control-text-color)

  &__innerWrapper
    display: flex
    padding-top: var(--control-padding-t)
    padding-left: var(--control-padding-x)
    padding-right: var(--control-padding-x)
    flex-wrap: wrap
    position: relative
    width: 100%
    max-width: 100%
    box-sizing: border-box
    min-height: var(--control-height)

  &__actions
    min-height: var(--input-height)
    display: flex
    align-items: center

  ~/:not(.multiselect--multiline) &__single,
  &__placeholder
    height: var(--input-height)
    position: absolute
    width: calc(100% - var(--control-padding-x))
    display: flex
    align-items: center
    left: var(--control-padding-x)
    bottom: 0
    pointer-events: none
    > span
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
  &__placeholder
    pointer-events: none
    color: #777
    [floating="true"] &
      display: none
  ~/--multiline &__single
    line-height: 1.5
  &__inputContainer
    max-width: 100%
    display: inline-block
    display: flex
    align-items: center
    width: 100%
    ~/--multiple &
      width: auto!important
  &__input
    border: none
    width: 100%
    min-width: 15px
    background: transparent
    flex-grow: 1
    padding: 0
    line-height: 30px
    &:focus
      outline: 0
  &__arrow
    display: inline-flex!important
    align-items: center
    cursor: pointer
    margin: 0 5px
    pointer-events: none

  &__clear
    margin-right: -6px
  &__dropdownPlaceholder
    padding: 12px 11px 12px
</style>
