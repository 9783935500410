<template lang="pug">
modal(size='3xl' mode='drawer' admin @close='$emit("close")')
  inquiry-admin(:inquiryId='id' class='my-2' @close='$emit("close")')
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const FormInquiryAdmin = defineComponent({
  props: {
    id: { type: String, required: true },
  },
})
export default FormInquiryAdmin
</script>
