<template lang="pug">
div(ref='container' :data-test='ready ? "form-ready" : "form-loading"' class='relative flex flex-1 flex-col')
  overlay(v-if='showOverlay' :visible='loading || loadingForm')
  form(v-if='configRaw' :id='selectorId' novalidate :currentAction='currentAction' class='flex flex-1 flex-col' @submit.prevent='() => submit()')
    slot(name='header' :config='configRaw' :configMap='configMap' :formData='formData' :loading='loading' :error='error' :currentAction='currentAction' :submit='submit')
    notification-list(v-if='!hideNotifications && notifications' :items='notifications')
    slot(
      :submit='submit'
      :setValue='setValue'
      :config='configRaw'
      :configMap='configMap'
      :formData='formData'
      :disabledMap='disabledMap'
      :loading='loading'
      :error='error'
      :currentAction='currentAction'
      :notifications='notifications'
    )
  div(v-else-if='error')
    slot(name='error' :error='error')
</template>

<script setup lang="ts">
import { flattenConfig } from './composables'
import type {
  GenericFormData,
  IFormResponseInfo,
  IGenericFormConfig,
  SetFormValueFunction,
  IGenericFormConfigMap,
  IFormResponseError,
} from './types'
import { useFetchForm, useOnSuccess, useThorForm } from '@/composables/'
import { useConfirm } from '@/plugins/Confirm'
import type { IConfirmData } from '@/plugins/Confirm'
import { useTranslation } from '@/plugins/translation'
import { objectify } from 'radash'
import { computed, onMounted, type PropType, provide, ref, watch } from 'vue'
import { nextTick } from 'vue'
import { toRaw } from 'vue'

export interface IFormReadyData {
  formData: GenericFormData
  configMap: IGenericFormConfigMap
}

const emit = defineEmits<{
  submitSuccess: [responseInfo: IFormResponseInfo]
  submitError: [error: IFormResponseError]
  onReady: [obj: { configMap: IGenericFormConfigMap; formData: GenericFormData }]
  updateConfig: [configMap: IGenericFormConfigMap]
  beforeSubmit: []
}>()

const props = defineProps({
  id: String,
  endpoint: { type: String, required: true },
  updateOnSubmitSuccess: String,
  hideNotifications: Boolean,
  showOverlay: { type: Boolean, default: true },
  confirmActions: Object as PropType<Record<string, IConfirmData>>,
  localConfigRaw: Object as PropType<IGenericFormConfig>,
})

const { $t } = useTranslation()
const { confirm } = useConfirm()
const primaryAction = ref<string | null>(null)
const currentAction = ref<string | null>(null)

const setPrimaryAction = (action: string) => (primaryAction.value = action)
provide('setPrimaryAction', setPrimaryAction)

const model = defineModel<GenericFormData>({ default: () => {} })

const formData = ref<GenericFormData>(model.value || {})

watch(
  () => model.value,
  (value) => (formData.value = toRaw(value)),
)
watch(
  () => formData.value,
  (value) => (model.value = toRaw(value)),
)

provide('formData', formData)

const setValue: SetFormValueFunction = (fieldName: string, value: any) =>
  (formData.value = { ...formData.value, [fieldName]: value })
provide('setValue', setValue)
const configValidationRaw = ref<IGenericFormConfig | null>(null)
const configValidationFlat = computed<IGenericFormConfig[]>(() =>
  configValidationRaw.value?.items ? flattenConfig({ ...configValidationRaw.value!, name: '' }) : [],
)
const configValidationMap = computed<{ [id: string]: IGenericFormConfig }>(() =>
  objectify(configValidationFlat.value, (item) => item.name),
)
const ready = ref(false)
const onReady = () => {
  ready.value = true
  emit('onReady', { configMap: configMap.value, formData: formData.value })
}

provide('configValidationMap', configValidationMap)

const { fetch, loading: loadingForm, error } = useFetchForm()
const configRaw = ref<IGenericFormConfig | null>(props.localConfigRaw || null)
const fetchConfig = () =>
  fetch(props.endpoint).subscribe(
    (c: IGenericFormConfig) => (
      (configValidationRaw.value = null),
      ((configRaw.value = c), nextTick(() => onReady())),
      emit('updateConfig', configMap.value)
    ),
  )
if (!props.localConfigRaw) {
  onMounted(fetchConfig)
  if (props.updateOnSubmitSuccess) {
    useOnSuccess(props.updateOnSubmitSuccess.split(','), fetchConfig)
  }
  watch(() => props.endpoint, fetchConfig)
}

const configFlat = computed<IGenericFormConfig[]>(() =>
  configRaw.value?.items
    ? flattenConfig(configRaw.value!).map((c: IGenericFormConfig) =>
        configValidationMap.value?.[c.name] ? { ...c, ...configValidationMap.value?.[c.name] } : c,
      )
    : [],
)
const configMap = computed<IGenericFormConfigMap>(() => objectify(configFlat.value, (item) => item.name))
provide('configMap', configMap)

const { post, loading } = useThorForm()
const submit = (action?: string) => {
  emit('beforeSubmit')
  const actualAction = action || primaryAction.value

  // @TODO: loose confirm handling in GenericForm component
  const defaultConfirmAction: IConfirmData = {
    title: $t('Are you sure?'),
    okText: $t('Yes'),
    cancelText: $t('No'),
    onOk: () => submitAction(actualAction as string),
  }

  const matchingConfirmableAction: [string, IConfirmData] | undefined = actualAction
    ? Object.entries({
        Delete: {
          ...defaultConfirmAction,
        } as IConfirmData,
        ...(props.confirmActions || {}),
      }).find(([key]) => key.toLowerCase() === (actualAction as string).toLowerCase())
    : undefined

  if (matchingConfirmableAction !== undefined) {
    confirm({ ...defaultConfirmAction, ...matchingConfirmableAction[1] })
  } else {
    submitAction(actualAction as string)
  }
}

defineExpose({ submit, fetchConfig })

const submitAction = (action: string) => {
  currentAction.value = action
  const splittedEndpoint = props.endpoint.split('?')
  const endpointWithAction = `${splittedEndpoint[0]}${action ? '/' + action : ''}${
    splittedEndpoint[1] ? '?' + splittedEndpoint[1] : ''
  }`
  post(endpointWithAction, formData.value!).subscribe({
    next: (r: IFormResponseInfo) => {
      if (r.response.valid) {
        emit('submitSuccess', r)
      }
      configValidationRaw.value = r.response.form!
    },
    error: (err: IFormResponseError) => {
      emit('submitError', err)
    },
  })
}

provide('submitForm', submit)

const disabledMap = computed(() =>
  Object.fromEntries(configFlat.value.map((c: IGenericFormConfig) => [c.name, c.disabled ?? false])),
)
const selectorId = computed(() => props.id || `Form${props.endpoint}`.replace(/[^a-zA-Z0-9]/g, ''))
const notifications = computed(() => configMap.value?.['']?.notifications)
</script>
