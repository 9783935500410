<template lang="pug">
modal(size='sm' @close='$emit("close")')
  modal-header(icon='custom-claim' class='!mb-0') {{ $t('Produkte reklamieren') }}
  p(class='mb-4 text-text-light') {{ $t('Zugehöriges Angebot auswählen') }}:
  overlay(:visible='loading' class='rounded-lg')
  empty-state(v-if='!loading && items && !items.length' centered :message='$t("Keine akzeptierten Angebote gefunden.")' class='rounded-lg border border-grey-100 pb-10')
  div(class='-mx-4 mb-3 flex flex-col')
    btn-select(v-for='inquiry in items' :key='inquiry.id' data-test-btn='AddProductsToTag' :hoverText='$t("öffnen")' @click.prevent='openInquiry(inquiry.id)')
      div(class='flex items-center gap-3')
        icon(name='custom-inquiry' class='text-3xl')
        div
          div(class='flex items-center gap-2')
            span(class='font-family-lato mr-2 font-bold') {{ inquiry.inqTitle || inquiry.id }}
            inquiry-status-pill(:status='inquiry.status')
          div(class='flex gap-3')
            //- date-tooltip(v-if='inquiry.recentStateChangeDateInstant' :dateAsString='inquiry.recentStateChangeDateInstant' v-slot='{ text }' class='link-tooltip flex items-center gap-1 text-sm')
            //-   icon(name='clock')
            //-   span {{ $t('{0} aktualisiert', '', [text]) }}
            span(v-if='inquiry.projectId' class='link-tooltip -ml-[1px] flex items-center gap-1 truncate text-sm' :data-tooltip='$t("zugehöriges Projekt")') 
              icon(name='folder')
              span {{ inquiry.projectTitle }}

  action-bar
    btn(tertiary @click='$emit("close")') {{ $t('Cancel') }}
</template>

<script setup lang="ts">
import { useInquirySearch } from '@/pages/inquiries'
import { useModals } from '@/plugins/Modals'
import { type SetupContext } from 'vue'

const modals = useModals()
// @TODO: solve load emit differently
const emit = defineEmits(['load', 'close'])
const { items, loading, registerProperty } = useInquirySearch({ emit } as SetupContext)

registerProperty('status', ['accepted'])
const openInquiry = (inquiryId: string) => {
  setTimeout(() => modals.open('form-inquiry', { inquiryId }), 10)
  emit('close')
}
</script>
