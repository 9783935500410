<template lang="pug">
generic-form#FormInquiry(
  v-if='inquiry'
  ref='form'
  :confirmActions='confirmActions'
  :endpoint='`/_/Inquiry/${inquiryId}`'
  updateOnSubmitSuccess='OperatorUpdate,Offer,SendReject,PricingAttributesUpdate,Revoke,SendReset'
  class='!static flex-1'
)
  template(#default='{ loading, currentAction, formData }')
    toolbar(class='mt-2' :wrap='false')
      span(class='mr-1') {{ $t('Inquiry') }}:
      span(class='mr-3 font-thin') {{ inquiry.inqTitle || inquiry.id }}
      template(#titleAfter)
        div
          inquiry-status-pill(:status='inquiry.status' :data-test-inquiry-status='inquiry.status')
        btn(secondary class='ml-2' icon='external-link' tiny :href='`/#/inquiries/${inquiry.id}`' target='_blank' :data-tooltip='$t("Nutzeransicht")')
      template(#after)
        avatar-list
          avatar(
            v-if='inquiry.createdByUser && inquiry.createdByUser.userFullName'
            :username='inquiry.createdByUser.userFullName'
            :data-tooltip='`${$t("Created By User")}:  <b>${inquiry.createdByUser.userFullName}</b>`'
          )
          avatar(v-if='inquiry.operatorFullName' :username='inquiry.operatorFullName' icon='custom-nuucon' :data-tooltip='`${$t("Responsible Operator")}:  ${inquiry.operatorFullName}`')
        btn(v-if='inquiry.status === "accepted"' secondary @click='exportInquiry(inquiry.id)') {{ $t('Export') }}
        btn(secondary @click='openPrintPreview(inquiry.id)') {{ $t('Print Preview') }}

        btn(plain icon='x' data-target='form-header-close' class='!-mr-3' @click='$emit("close")')
    div(class='mb-8 flex flex-wrap gap-2')
      a(:data-tooltip='$t("Related project")' class='link-faded flex cursor-pointer items-center gap-1 text-sm' @click='$modals.open("form-project-admin", { id: inquiry?.projectId })')
        icon(name='folder')
        span {{ inquiry.projectTitle }}
      div(class='link-tooltip flex items-center gap-1 text-sm')
        icon(name='calendar')
        date-tooltip(v-slot='{ text }' :dateAsString='inquiry.createdAt' :prefix='$t("Creation date")') {{ $t('created {0}', '', [text]) }}
    div(class='bleed')
      inquiry-product-list(
        v-if='inquiry'
        adminMode
        :inquiryStatus='inquiry.status'
        :items='inquiry.items'
        :inquiryId='inquiry.id'
        :projectId='inquiry.projectId'
        :allowEditProducts='allowEditExternalProducts'
      )
    div(class='flex-1 sm:grid sm:grid-cols-2 sm:gap-10')
      div(class='pt-6')
        inquiry-meta-table(:inquiry='inquiry' :showEditButton='isPricingEditable' @openDeliverySetupForm='openDeliverySetupForm')
      div
        form-hidden(name='InquiryIdentifier' :modelValue='`${inquiry.id}`')
        table(class='class w-full [&_td:nth-child(3)]:w-0')
          tbody(data-target='rowGroupInquiryPrices')
            tr
              td(style='min-width: 250px' class='font-bold') {{ $t('RRP sum net') }}:
              td(class='text-right font-bold') {{ inquiry.totalRRPNetString }}
            tr(class='highlight-nuucon')
              td {{ $t('Purchasing price subtotal') }}:
              td(class='text-right') {{ inquiry.purchasingPriceTotalNetString }}
            tr(class='highlight-nuucon')
              td {{ $t('Margin (nuucon)') }}:
              td(class='text-right text-sm') ({{ inquiry.nuuconMargin }}) !{ ' ' } {{ inquiry.nuuconMarginAbsolute }}
            tr
              td {{ $t('Custom Discount') }}:
              td
                form-input(
                  v-model='customDiscount'
                  name='CustomDiscount'
                  :controlProps='{ type: "number", min: "0", max: "100", step: "0.5" }'
                  :label='false'
                  :floating='false'
                  style='--margin-bottom: 0'
                ) %
            tr
              td(v-html='$t("Project discount", "admin")')
              td(v-if='hasReachedDiscountLimit' class='text-right text-sm text-text-light') ({{ inquiry.discountPercent }}) !{ ' ' } -{{ inquiry.discountAbsolute }}
              td(v-else class='text-right') -
            tr
              td(class='font-bold') {{ $t('Subtotal') }}:
              td(class='text-right font-bold') {{ inquiry.totalDiscountedPriceNetString }}
            tr
              td {{ $t('Delivery Costs') }}:
              td
                form-input-money(
                  v-model='deliveryCosts'
                  name='DeliveryCosts'
                  :label='false'
                  :floating='false'
                  style='--margin-bottom: 0'
                  :note='inquiry.deliveryOption && inquiry.deliveryOption.name == "to be discussed" ? $t("Addtionional services ist selected. Are you sure about adding delivery costs?") : ""'
                ) {{ $t('€') }}
              td
                btn(plain class='ml-1' icon='calculator' :data-tooltip='$t("Lieferkosten berechnen")' @click='calculateDeliveryCosts')
            tr
              td(class='font-bold') {{ $t('Project price') }}:
              td(class='text-right text-lg font-bold') {{ inquiry.totalNetString }}
            tr
              td {{ $t('Total gross') }}:
              td(class='text-right text-text-light') ({{ $t('19% vat') }}: &nbsp; {{ inquiry.vatAmountString }}) !{ ' ' } {{ inquiry.totalGrossString }}
            tr
              td(colspan='2')
                div(class='mt-6')
                  form-select-native(name='DeliveryOptions')
                  form-input-date(name='DateOfExpiry')
                  form-textarea(v-model='offerTexts' name='OfferTexts')
                  btn(v-if='isPricingEditable' link icon='rotate-cw' class='-mt-6 mb-2' @click='refreshOfferText') {{ $t('refresh offer texts') }}
                  form-select-native(name='Payment')
                  div(v-if='formData.Payment === "22"')
                    div(v-for='(item, index) in 5' :key='index' class='flex items-center gap-2')
                      span(class='mb-2 mr-1 text-text-light') {{ index + 1 }}
                      form-input(:name='`PaymentGroup${index + 1}/PaymentAmount`' :label='$t("Payment Amount")' :controlProps='{ type: "number" }' class='min-w-0 flex-1') %
                      form-input-date(:name='`PaymentGroup${index + 1}/PaymentDate`' class='min-w-0 flex-1')
                      form-input(:name='`PaymentGroup${index + 1}/PaymentDescription`' class='min-w-0 flex-1')
                  form-radio(
                    name='Commission'
                    label=''
                    modelValue='false'
                    :controlProps='{ options: [ { value: "true", label: $t("with commission") }, { value: "false", label: $t("without commission") }, ], }'
                    class='pt-2'
                  )
                  div(v-if='formData.Commission === "true"')
                    form-input(name='CommissionAmount' :label='$t("Commission")' :controlProps='{ type: "number" }') %
                    form-input(name='CommissionBiller')
                  div(v-if='inquiry.declineReason' class='mb-4')
                    hr
                    label(class='mt-2 block font-bold') {{ $t('Decline Reason') }}:
                    p {{ inquiry.declineReason }}
                  div(v-if='inquiry.changeRequest' class='mb-4')
                    hr
                    label(class='mt-2 block font-bold') {{ $t('Change Request') }}:
                    p {{ inquiry.changeRequest }}

    sticky-container(position='bottom')
      action-bar(class='py-3')
        template(#left)
          form-action(name='PricingAttributesUpdate' secondary icon='save' :loading='loading && currentAction === "PricingAttributesUpdate"')
            template(#inner) {{ $t('Save') }}
          form-action(name='OperatorUpdate' secondary icon='arrow-right' :loading='loading && currentAction === "PricingAttributesUpdate"')
            template(#inner) {{ $t('Take in processing') }}
        form-action(name='Offer' icon='check' secondary :loading='loading && currentAction === "Offer"')
          template(#inner) {{ $t('Send Offer') }}
        form-action(v-if='inquiry.status == "inquired" || inquiry.status == "processing"' name='Revoke' icon='rotate-ccw' danger :loading='loading && currentAction === "Revoke"')
          template(#inner) {{ $t('Revoke Inquiry') }}
        btn(v-if='inquiry.status == "inquired" || inquiry.status == "processing"' icon='x' danger @click='$utils.routeQueryAdd({ "form-reject-inquiry": inquiry.id })')
          | {{ $t('Reject Inquiry') }}
        btn(v-if='inquiry.status == "offered"' icon='x' danger @click='$utils.routeQueryAdd({ "form-reset-offer": inquiry.id })')
          | {{ $t('Reset Offer') }}
</template>

<script setup lang="ts">
import InquiryMetaTable from './InquiryMetaTable.vue'
import InquiryProductList from './InquiryProductList.vue'
import GenericForm from '@/components/GenericForm/GenericForm.vue'
import { type IFormResponseInfo } from '@/components/GenericForm/types'
import StickyContainer from '@/components/StickyContainer.vue'
import { useOnSuccess } from '@/composables/'
import { useInquiry } from '@/pages/inquiries/composables'
import { type IConfirmData } from '@/plugins/Confirm'
import { useModals } from '@/plugins/Modals'
import { type IAddress } from '@/plugins/User'
import { useUtils } from '@/plugins/Utils'
import { useTranslation } from '@/plugins/translation'
import { watchDebounced } from '@vueuse/shared'
import Axios from 'axios-observable'
import { unique } from 'radash'
import { computed, toRef, ref } from 'vue'

const TRANSLATIONS = {
  'offerText.a':
    'Sofern angegeben, spiegeln die Lieferzeiten die Verfügbarkeit zum Zeitpunkt der Angebotserstellung wider und unterliegen ständigen Änderungen. Die angebotenen Positionen werden nicht reserviert.',
  'offerText.c':
    'Wir weisen explizit darauf hin, dass es aufgrund der aktuellen Herausforderungen in den Lieferketten, auch nach Auslösung der Bestellung, zu Verzögerungen kommen kann. Verzögerungen führen nicht zu Schadenersatzansprüchen bzw. zur Stornierung von Aufträgen. Es gelten unsere AGB (nuucon.com/content/terms).',
  'offerText.b': 'Lieferzeit: 6-8 Wochen (default)',
  'offerText.b.1.standardDelivery':
    'Die Ware wird je nach Verfügbarkeit und ohne Zwischenlagerung direkt vom Zulieferer an Dich versendet. Wenn Deine Bestellung von unterschiedlichen Lieferanten bedient wird, können die Waren ggf. auch in mehreren Teillieferungen bei Dir ankommen. Die Lieferung wird bis zur Bordsteinkante durchgeführt. Bitte beachte, dass wir in diesem Fall keinen Einfluss auf genaue Liefertermine oder Avisierungen haben. ',
  'offerText.b.1.other':
    'Die Waren werden in einer Sammellieferung durch unsere Logistikpartner an Dich zugestellt, sobald alle Produkte auf Lager sind. Dabei sind immer folgende Leistungen inkludiert:\n- Warenannahme und Kontrolle auf äußere Beschädigungen\n- Zwischenlagerung\n- Anfahrt mit LKWs',
  'offerText.b.2.combinedDeliveryFreeCurb':
    'Die Lieferung erfolgt zu einem abgestimmten Zeitpunkt und erfolgt frei Bordsteinkante.',
  'offerText.b.2.combinedDeliveryWithCarrying':
    'Die Lieferung erfolgt zu einem abgestimmten Zeitpunkt und wird bis zur Verwendestelle durchgeführt. Gewünschte Teillieferungen werden separat abgerechnet und erfolgen nach Absprache.\n\nVoraussetzung für die Zustellung \n- Komplette Baufreiheit ist zu gewährleisten während der Anlieferung\n- Alle Wege müssen frei und zugänglich für die Monteure sein. Keine Maschinen, lose Werkzeuge, Baustoffe oder andere potentielle Stolperfallen. \n- Freie Zugänglichkeit zu den gewünschten Flächen Exklusive Nutzbarkeit von mind. 1 Aufzug (sofern vorhanden)\n\n Sind Voraussetzungen nicht gegeben und entstehen daraus Verzögerungen im Ablauf, erlauben wir uns die Mehrkosten nach tatsächlichem Aufwand nachzuberechnen.',
  'offerText.b.2.combinedDeliveryWithInstallation':
    'Die Lieferung erfolgt zu einem abgestimmten Zeitpunkt und umfasst neben dem Vertragen aller Produkte auch die Montage (exkl. Elektroinstallation) und Mitnahme der Verpackung. Gewünschte Teillieferungen werden separat abgerechnet und erfolgen nach Absprache. Exklusive anfallende Spesen und Hotelkosten für Monteure.\n\nVoraussetzung für die Zustellung\n- Komplette Baufreiheit ist zu gewährleisten während der Anlieferung\n- Alle Wege müssen frei und zugänglich für die Monteure sein. Keine Maschinen, lose Werkzeuge, Baustoffe oder andere potentielle Stolperfallen.\n- Freie Zugänglichkeit zu den gewünschten Flächen Exklusive Nutzbarkeit von mind. 1 Aufzug (sofern vorhanden)\n- Adequate Lichtverhältnisse müssen gegeben sein\n\nSind Voraussetzungen nicht gegeben und entstehen daraus Verzögerungen im Ablauf, erlauben wir uns die Mehrkosten nach tatsächlichem Aufwand nachzuberechnen.',
  'offerText.b.2.pickupFromWarehouse':
    'Abholung am Lager: Sobald alle Produkte auf Lager sind, wird ein Abholtermin mit Dir vereinbart.\n\nAbholadresse:\n\nDie Waren werden bei unserem Logistikpartner für Dich zusammengestellt. Dabei sind immer folgende Leistungen inkludiert:\n- Warenannahme und Kontrolle auf äußere Beschädigungen\n- Zwischenlagerung',
}

const props = defineProps<{ inquiryId: string }>()
const { $t, add } = useTranslation()
add(TRANSLATIONS)
const $utils = useUtils()
const $modals = useModals()
const { inquiry, load, setInquiry } = useInquiry(toRef(props, 'inquiryId'))
const isPricingEditable = computed<boolean>(() => ['inquired', 'processing'].includes(inquiry.value?.status || ''))
const allowEditExternalProducts = computed<boolean>(() =>
  ['drafted', 'inquired', 'processing'].includes(inquiry.value?.status || ''),
)

useOnSuccess(
  [
    'OperatorUpdate',
    'Revoke',
    'SendReject',
    'SendReset',
    'Offer',
    'SaveDeliverySetup',
    'SetSupplierForProduct',
    'AddOrUpdateItem',
    'UpdateProductOrder',
    'UpdateInquiry',
    'PricingAttributesUpdate',
    'SetSupplierForProduct',
  ],
  (r: IFormResponseInfo) => (r.data ? setInquiry(r.data.inquiry || r.data) : load()),
)

const customDiscount = ref<number | undefined>(undefined)
const deliveryCosts = ref<number | undefined>(undefined)
const form = ref<InstanceType<typeof GenericForm> | null>(null)

const submitPricingAttributesIfValueChange = (newValue: number | undefined, prevValue: number | undefined) =>
  prevValue !== undefined && form.value?.submit('PricingAttributesUpdate')

watchDebounced(() => customDiscount.value, submitPricingAttributesIfValueChange, { debounce: 800 })
watchDebounced(() => deliveryCosts.value, submitPricingAttributesIfValueChange, { debounce: 800 })

const offerTextsMaps = {
  standardDelivery: ['offerText.a', 'offerText.b', 'offerText.b.1.standardDelivery', 'offerText.c'],
  combinedDeliveryFreeCurb: [
    'offerText.a',
    'offerText.b',
    'offerText.b.1.other',
    'offerText.b.2.combinedDeliveryFreeCurb',
    'offerText.c',
  ],
  combinedDeliveryWithCarrying: [
    'offerText.a',
    'offerText.b',
    'offerText.b.1.other',
    'offerText.b.2.combinedDeliveryWithCarrying',
    'offerText.c',
  ],
  combinedDeliveryWithInstallation: [
    'offerText.a',
    'offerText.b',
    'offerText.b.1.other',
    'offerText.b.2.combinedDeliveryWithInstallation',
    'offerText.c',
  ],
  pickupFromWarehouse: ['offerText.a', 'offerText.b.2.pickupFromWarehouse', 'offerText.c'],
}
const generateOfferText = () =>
  offerTextsMaps[inquiry.value?.deliverySetup?.serviceLevel?.name || 'standardDelivery']
    .map((text) => $t(text))
    .join('\n\n')

const offerTexts = ref('')
const refreshOfferText = () => (offerTexts.value = generateOfferText())

const addressToString = ({ address, postalCode, city, country }: IAddress) =>
  `${address}, ${postalCode ? postalCode : ''} ${city ? city : ''}, ${country} `

const calculateDeliveryCosts = async () => {
  const value = await $modals.open('modal-logistic-calculation', {
    inquiryId: inquiry.value!.id,
    totalRrpNet: inquiry.value!.totalNet,
    productsQuantity: inquiry.value?.items.reduce((sum, curr) => sum + curr.quantity, 0) || 0,
    supplierQuantity: unique(inquiry.value!.items.map((item) => item.supplierInfo.supplierId)).length,
    deliveryAddress: inquiry.value?.deliveryAddress ? addressToString(inquiry.value.deliveryAddress!) : '',
    serviceLevel: inquiry.value?.deliverySetup?.serviceLevel?.name || '',
    deliverySetupType: inquiry.value?.deliverySetup?.deliverySetupType?.name || '',
  })
  if (value) {
    deliveryCosts.value = value
  }
}

const exportInquiry = (inquiryId: string) => {
  Axios.post(`/_/Inquiry/${inquiryId}/Export`).subscribe()
}

const openPrintPreview = (inquiryId: string) => $modals.open('modal-inquiry-pdf-preview', { inquiryId })

const confirmActions = computed<Record<string, IConfirmData>>(() => ({
  Revoke: {
    content: $t('Revoking the offer will reset the inquiry state back to drafted.'),
  },
  Offer: {
    title: $t('Do you really want to send the offer?'),
  },
}))

const openDeliverySetupForm = () =>
  $utils.routeQueryAdd({
    'form-inquiry-delivery-setup': {
      inquiryId: inquiry.value!.id,
      customerId: inquiry.value!.createdByCompany.companyId,
    },
  })

const hasReachedDiscountLimit = computed(
  () => inquiry.value?.discountAbsolute !== '0,00 €' || inquiry.value?.totalNet >= 1000,
)
</script>
