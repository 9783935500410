<template lang="pug">
div(v-if='items.length > 0' class='tag-list')
  span(v-for='tag in items' :key='tag.name' class='tag-list__item') {{ tag.name }}
</template>

<script lang="ts">
import { type ITag } from '@/pages/projects/types'
import { type PropType } from 'vue'
import { defineComponent } from 'vue'

const TagList = defineComponent({
  props: {
    items: { type: Array as PropType<ITag[]>, default: () => [] },
  },
})

export default TagList
</script>
<style lang="stylus">
@import '../styles/variables.styl'

.tag-list
  padding-right: 2px
  display: flex
  flex-wrap: wrap
  align-items: center
  margin-left: -3px
  &__item
    margin: 2px 5px 2px 0
    display: inline-block
    border: 1px solid var(--grey-200)
    color: var(--grey-700)
    padding: 2px 8px
    white-space: nowrap
    font-size: 13px
    border-radius: 20px
</style>
