<template lang="pug">
div(:class='{ [`tabs--${type}`]: true }' class='tabs')
  div(class='tabs__content')
    slot(name='tabs' :selectTab='selectTab' :currentTab='currentTab')
      tab-button(v-for='tab in tabs' :key='tab.title' :active='currentTab === tab.id' :data-test-tab-button='tab.id' @click.prevent='selectTab(tab.id)')
        slot(name='tab' :tab='tab')
          span(v-if='tab.title') {{ tab.title }}
  div(class='tabs__details')
    slot
</template>

<script lang="ts">
import TabButton from './TabButton.vue'
import { computed, defineComponent, provide, type Ref, ref, type SetupContext, onMounted } from 'vue'

export type ITabRegisterFn = (tab: ITab) => void
export interface ITab {
  title: string
  id: string
}

/**
 * Wrapper component for TabPane components
 * @displayName Tabs
 */
const Tabs = defineComponent({
  components: { TabButton },
  props: {
    id: String,
    defaultActiveId: String,
    type: String,
    modelValue: String,
  },
  setup(props, context: SetupContext) {
    const localValue = ref('')
    const currentTab = computed({
      get: () => props.modelValue || localValue.value,
      set: (id: string) => ((localValue.value = id), context.emit('update:modelValue', id)),
    })
    const tabs = ref<ITab[]>([])
    provide<ITabRegisterFn>('registerTab', (tab: ITab) => tabs.value.push(tab))
    provide<Ref<string>>('currentTab', currentTab)
    onMounted(() => (localValue.value = props.modelValue || props.defaultActiveId || tabs.value[0]?.id))
    return {
      currentTab,
      selectTab: (id: string) => (currentTab.value = id),
      tabs,
    }
  },
})

export default Tabs
</script>
<style lang="stylus">
@import '../../styles/variables.styl'
.tabs
  &__details
    flex: 1
    display: flex
    flex-flow: column
  &__content
    margin-bottom: 16px
    border-bottom: 2px solid var(--grey-100)
    ~/--navigation &
      border-bottom: none
</style>
