import Env from '@/utilities/Env'
import { type Directive, type DirectiveBinding, nextTick } from 'vue'

export const applyAttributes = (el: HTMLElement, attributes: { [attribute: string]: string }) => {
  Object.keys(attributes)
    .filter((key: string) => key !== 'target')
    .forEach((key: string) => el.setAttribute(key, attributes[key]))
}

const querySelectorOrGroup = (el: HTMLElement, selector: string) =>
  el.getAttribute('data-lazy-group')
    ? document.querySelectorAll(
        selector
          .split(',')
          .map((s: string) => `[data-lazy-group="${el.getAttribute('data-lazy-group')}"] ${s}`)
          .join(', '),
      )
    : el.querySelectorAll(selector)

const isImageElement = (el: HTMLElement) => el.tagName.toLowerCase() === 'img'
const changeExt = (path: string, ext: string) => `${path.split('.')[0]}.${ext}`
const setAttributeWhenLoaded = (el: HTMLElement) =>
  [el]
    .flatMap((e) => (isImageElement(e) ? [e] : Array.from(querySelectorOrGroup(e!, 'img'))))
    .map((e) => e?.addEventListener('load', () => e?.setAttribute('lazy', 'loaded')))

export const createCloudinaryUrl = (path: string, transformations: string = '', ext?: string) =>
  Env.isLocalhost
    ? `http://localhost:8080${path}`
    : `${Env.cloudinaryPath}${transformations}/${Env.assetPathPrefix}${ext ? changeExt(path, ext) : path}`

const transformSrcOrSrcSet = (el: HTMLElement) =>
  (isImageElement(el) ? [el] : Array.from(querySelectorOrGroup(el, '[data-src], [data-srcset]')))
    .map((e: any) => [e, e.getAttribute('data-src'), e.getAttribute('data-srcset'), e.getAttribute('data-cloudinary')])
    .forEach(([e, src, srcset, transformations]) =>
      applyAttributes(e, {
        [src ? 'src' : 'srcset']:
          transformations === 'false' ? src || srcset : createCloudinaryUrl(src || srcset, transformations || 'f_auto'),
      }),
    )

const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
  entries.forEach(
    (entry: IntersectionObserverEntry) =>
      entry.isIntersecting &&
      (observer.unobserve(entry.target),
      setAttributeWhenLoaded(entry.target as HTMLElement),
      transformSrcOrSrcSet(entry.target as HTMLElement)),
  )
})

const Image: Directive = {
  mounted(el: HTMLElement): void {
    setAttributeWhenLoaded(el)
    transformSrcOrSrcSet(el)
  },
}
const ImageLazy: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding): void {
    if (binding.value === true) {
      observer.observe(el)
    } else {
      setAttributeWhenLoaded(el)
      transformSrcOrSrcSet(el)
    }
  },
  updated(el: HTMLElement) {
    setAttributeWhenLoaded(el)
    transformSrcOrSrcSet(el)
  },
  unmounted(el: HTMLElement) {
    observer.unobserve(el)
  },
}
const ImageLazyContainer: Directive = {
  mounted(el: HTMLElement): void {
    nextTick(() => observer.observe(el))
  },
  unmounted(el: HTMLElement) {
    observer.unobserve(el)
  },
}

export default { Image, ImageLazy, ImageLazyContainer }
