<template lang="pug">
div(v-bind='attributes' class='checkbox')
  input(:id='selectorId' v-model='modelValue' type='checkbox' :indeterminate='indeterminate' :disabled='disabled' :name='selectorId' class='checkbox__input')
  label(:for='selectorId' class='checkbox__label')
    checkbox-icon(:value='modelValue' :disabled='disabled' :indeterminate='indeterminate' class='checkbox__icon')
    slot
</template>

<script setup lang="ts">
import CheckboxIcon from './CheckboxIcon.vue'
import { shakeFalsy } from '@/utilities'
import { computed } from 'vue'

const props = defineProps({
  indeterminate: Boolean,
  disabled: Boolean,
  passive: Boolean,
  id: { type: String },
})

const modelValue = defineModel({ type: Boolean, default: false })
const attributes = computed(() =>
  shakeFalsy({
    'data-disabled': props.disabled,
    'data-indeterminate': props.indeterminate,
    'data-checked': modelValue.value,
  }),
)
const selectorId = computed(() => (props.id || 'Checkbox') + Math.random().toString())
</script>

<style lang="stylus">
@import '../../styles/variables.styl'
:root
  --checkbox-label-color: var(--color-text)
  --checkbox-cursor: pointer
.checkbox
  &__input
    position: absolute
    left: 0
    opacity: 0.01
    cursor: var(--checkbox-cursor)
  &__label
    display: flex
    line-height: 1.5
    font-weight: normal
    cursor: var(--checkbox-cursor)
    color: var(--checkbox-label-color)
  &__icon
    margin-right: 10px
</style>
