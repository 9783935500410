import { Comment, Text, onDeactivated, onUnmounted, type Slot, type VNode } from 'vue'
import { nextTick, onMounted, onActivated } from 'vue'

export const hasSlotContent = (slot: Slot | undefined, slotProps = {}) => {
  if (!slot) return false
  return slot(slotProps).some((vnode: VNode) => {
    if (vnode.type === Comment || (Array.isArray(vnode.children) && !vnode.children.length)) {
      return false
    }

    return vnode.type !== Text || (typeof vnode.children === 'string' && vnode.children.trim() !== '')
  })
}

export function onMountedOrActivated(hook: () => any) {
  let mounted: boolean

  onMounted(() => {
    hook()
    nextTick(() => {
      mounted = true
    })
  })

  onActivated(() => {
    if (mounted) {
      hook()
    }
  })
}
