<template lang="pug">
modal(size='base' data-test='modal-pcon-catalog' mode='drawer' @close='$emit("close")')
  modal-header(icon='custom-rotate-furniture') {{ $t('pCon Katalog') }}
  div
    div(class='mb-4 flex')
      control-input(v-if='isRootPath' v-model='rootQuery' grey rounded icon='search' :placeholder='$t("Search Brands..")')
      control-input(v-else :modelValue='query' grey rounded icon='search' :placeholder='$t("„{0}“ durchsuchen..", "", [path[0].label])' @submit='(v: string) => (query = v)' @clear='onClearQuery')
    div(class='relative')
      overlay(:visible='loading')
      div(class='mb-4 flex items-center')
        breadcrumbs(class='flex-1')
          breadcrumbs-item(:label='$t("Brands")' link @click='resetPath')
          breadcrumbs-item(v-for='(segment, index) in path' :key='segment.name' :link='index < path.length - 1' :label='segment.label || segment.name' @click='onClickBreadcrumb(segment)') 
          breadcrumbs-item(v-if='!isRootPath && query' :label='$t("Results for „{0}“", "", [query])' link)
        div(v-if='$permissions.canAccessAdminMenu && isRootPath')
          checkbox(v-model='showUnmappedBrands' data-test='switch-toggle-show-unmapped-brands' class='highlight-nuucon') {{ $t('Alle Marken') }}
      div(v-if='isRootPath' class='grid grid-cols-3 gap-2')
        div(v-for='item in filteredRootCatalogItems' :key='item.name' class='flex flex-1 flex-col')
          btn(plain no-radius block class='flex h-[120px] items-center justify-center !bg-grey-50 hover:!bg-grey-100' @click='navigateRootItem(item)')
            img(:src='item.icon' class='mix-blend-multiply')
          p(class='pt-1 text-sm leading-tight') {{ item.label }}

      div(v-else class='grid grid-cols-3 gap-2')
        div(v-for='item in catalogItems' :key='item.name' class='flex flex-1 flex-col')
          btn(plain no-radius block class='relative flex h-[120px] items-center justify-center !bg-grey-50 hover:!bg-grey-100' @click='navigateItem(item)')
            img(v-if='item.icon' :src='item.icon' class='max-h-full mix-blend-multiply')
            p(v-else class='pt-1 text-sm leading-tight') {{ item.label }}
            pill(v-if='item.type === "Container"' class='absolute left-1 top-1 rounded') {{ $t('Container') }}
          p(v-if='item.icon' class='pt-1 text-sm leading-tight') {{ item.label }}
      empty-state(v-if='!loading && isRootPath && filteredRootCatalogItems.length === 0' centered :message='$t("Keine Ergebnisse für {0}", undefined, [rootQuery])')
        template(#buttons)
          btn(secondary icon='x' @click='rootQuery = ""') {{ $t('Filter zurücksetzen') }}
      empty-state(v-if='!loading && !isRootPath && catalogItems.length === 0' centered :message='$t("Keine Ergebnisse für {0}", undefined, [query])')
        template(#buttons)
          btn(secondary icon='x' @click='query = ""') {{ $t('Filter zurücksetzen') }}
</template>

<script setup lang="ts">
import { useGlobalPconItemStore, usePconSession } from '@/composables/pcon'
import { useModals } from '@/plugins/Modals'
import type { IPaginatedResponse, IBrandMapping } from '@/types'
import {
  ArticleCatalogItem,
  CatalogItem,
  LookupOptions,
  SearchParameterSet,
} from '@easterngraphics/wcf/modules/eaiws/catalog'
import { useSessionStorage } from '@vueuse/core'
import { useFuse } from '@vueuse/integrations/useFuse'
import Axios, { type AxiosResponse } from 'axios'
import { toRaw } from 'vue'
import { onBeforeMount } from 'vue'
import { computed, ref, watch } from 'vue'

const removeDateFromString = (string: string) =>
  string
    .split(' ')
    .filter(
      (value) =>
        !value.match(
          '(^(((0[1-9]|1[0-9]|2[0-8])[\\/](0[1-9]|1[012]))|((29|30|31)[\\/](0[13578]|1[02]))|((29|30)[\\/](0[4,6,9]|11)))[\\/](19|[2-9][0-9])\\d\\d$)|(^29[\\/]02[\\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)',
        ),
    )
    .join(' ')

defineOptions({
  inheritAttrs: false,
})
const props = defineProps({
  projectId: { type: String, required: true },
  inquiryId: String,
  tag: String,
})
defineEmits(['close'])
const modals = useModals()

const globalItemState = useGlobalPconItemStore()
const loading = ref(true)
const catalogItems = ref<CatalogItem[]>([])
const rootCatalogItems = ref<CatalogItem[]>([])
const pConBrandMap = ref<Record<string, boolean>>({})
const showUnmappedBrands = useSessionStorage<boolean>('showUnmappedBrands', false)
const lookupOptions = new LookupOptions()
lookupOptions.itemTypes = ['Article', 'Folder', 'Container']
const path = useSessionStorage<CatalogItem[]>('pconCatalogPath', [])
const pathIds = computed(() => path.value.map((catalogItem) => catalogItem.name))
const customPathIds = useSessionStorage<string[]>('pconCatalogPathCustom2', [])
const updateCatalog = async (newPath: string[]) => {
  // only search when not on root level
  if (newPath.length > 0) {
    // clear current catalog items
    catalogItems.value = []
    loading.value = true
    try {
      catalogItems.value = await session.catalog.listCatalogItems(
        customPathIds.value.length ? customPathIds.value : newPath,
        lookupOptions,
      )
    } catch (e) {}
    loading.value = false
  }
}

const loadBrandMappingList = async () => {
  await Axios.get(`/_/BrandMappings`).then((response: AxiosResponse<IPaginatedResponse<IBrandMapping>>) => {
    pConBrandMap.value = Object.fromEntries(
      response.data.hits.map((data) => [data.pConBrandName, data.displayInPConCatalog]),
    )
  })
}
const { session, init } = usePconSession()
onBeforeMount(async () => {
  await init()
  rootCatalogItems.value = await session.catalog.listCatalogItems([])
  await updateCatalog(pathIds.value)
  await loadBrandMappingList()
  loading.value = false
})

watch(
  () => pathIds.value,
  (newPath) => !query.value && updateCatalog(newPath),
  { deep: true },
)

const rootQuery = ref('')
const query = ref('')

const { results } = useFuse<CatalogItem>(rootQuery, rootCatalogItems, {
  fuseOptions: {
    keys: ['label'],
    includeMatches: true,
    minMatchCharLength: 1,
    ignoreFieldNorm: true,
    ignoreLocation: true,
    threshold: 0.2,
  },
  matchAllWhenSearchEmpty: true,
})

watch(
  () => query.value,
  async () => {
    if (path.value.length === 0 || query.value === '') {
      updateCatalog(pathIds.value)
      return
    }
    path.value = [path.value[0]]
    const lookupOptions = new LookupOptions()
    lookupOptions.itemTypes = ['Article']
    lookupOptions.displayMode = 'AllVisible'
    const params = new SearchParameterSet()
    params.query = query.value + '*'
    params.catalogIds = [pathIds.value[0]]
    params.numberOfHits = 100
    params.flags = ['FolderText']
    const result = await session.catalog.searchCatalogItems(params, lookupOptions)
    catalogItems.value = result?.scoredItems.map((scoredItem) => scoredItem.item) || []
  },
)

const isRootPath = computed(() => path.value.length === 0)
const filteredRootCatalogItems = computed(() => {
  const res: CatalogItem[] = results.value.map((result) => result.item)
  return showUnmappedBrands.value ? res : res.filter((r) => !!pConBrandMap.value[removeDateFromString(r.label)])
})
const onClearQuery = () => {
  query.value = ''
  updateCatalog(pathIds.value)
}
const onClickBreadcrumb = async (item: CatalogItem) => {
  const index = path.value.findIndex((pathItem) => pathItem.catalogNodeKey === item.catalogNodeKey)
  query.value = ''
  if (index > -1 && index < path.value.length - 1) {
    path.value = path.value.slice(0, index + 1)
  }
}
const resetPath = () => {
  query.value = ''
  path.value = []
}
const navigateRootItem = (item: CatalogItem) => {
  query.value = ''
  path.value.push(item)
}
const navigateItem = async (item: CatalogItem | ArticleCatalogItem) => {
  customPathIds.value = []
  if (item.type === 'Folder') {
    if (query.value) {
      customPathIds.value = await session.catalog.getCatalogPath(item.catalogId, item.catalogNodeKey)
    } else {
      path.value.push(item)
    }
  } else if (item.type === 'Article' || item.type === 'Container') {
    globalItemState.catalogItem.value = toRaw(item)
    modals.open('form-edit-pcon-configuration', {
      projectId: props.projectId,
      inquiryId: props.inquiryId,
      addFromCatalog: true,
    })
  }
}
</script>
