<template lang="pug">
div(v-if='data.length > 1')
  div(v-if='!showAsModal' class='grid grid-cols-[repeat(auto-fill,minmax(65px,0.5fr))] gap-2')
    btn-image(v-for='variant in variants' :key='variant.id' class='bg-white' :to='variantLinkFnLocal(variant.id)' :active='variant.current' :src='variant.image' :data-tooltip='variant.titleShort')
  div(v-else)
    btn(class='flex !w-full !bg-body' secondary no-radius @click='modalVisible = true')
      img(v-image-lazy class='-m-1 mr-3 aspect-square w-[60px] mix-blend-multiply' :data-src='current.image' data-cloudinary='t_xs')
      span(class='mr-4 flex-1 !justify-start font-default font-normal') {{ current.titleShort }}
      icon(name='chevron-down' class='text-md')
    modal-container
      modal(v-if='modalVisible' :footer='null' size='lg' @close='modalVisible = false')
        modal-header {{ $t('Choose variant') }}
        div(class='grid gap-2 sm:grid-cols-2')
          router-link(
            v-for='variant in variants'
            :key='variant.id'
            class='flex items-center rounded-lg border-2 border-grey-50 bg-white p-2 hover:border-body hover:bg-body hover:no-underline'
            :to='variantLinkFnLocal(variant.id)'
            :class='{ "!border-grey-800": variant.current }'
            @click='modalVisible = false'
          )
            div(class='flex w-[60px] flex-col place-content-center')
              img(v-image-lazy :data-src='variant.image' class='aspect-square mix-blend-multiply' data-cloudinary='t_xs')
            div(class='ml-2 flex-1')
              b(class='text-md font-lato font-semibold text-text') {{ variant.titleShort }}
              div(class='flex text-sm text-text-light')
                b(class='mr-2') {{ variant.retailPriceNet }} !{ ' ' }
                span {{ $t(`excl. {0}% VAT`, '', [$env.vat]) }}
            icon(v-if='variant.current' class='mr-2 text-3xl text-text' name='check')
</template>

<script lang="ts">
import ModalContainer from './ModalContainer.vue'
import BtnImage from '@/components/BtnImage.vue'
import type { IProductSearchItem } from '@/types'
import { defineComponent, ref, computed, type PropType } from 'vue'
import { inject } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

export interface IProductVariant extends IProductSearchItem {
  current?: boolean
  titleShort?: string
}

export const getCommonPrefix: (strings: string[]) => string = (strings: string[]) => {
  const splittedStrings: string[][] = strings.map((s: string) => s.split(' '))
  return splittedStrings[0].reduce(
    ([prefix, hasPotentialMatches]: [string, boolean], segment: string, index: number) => {
      return (
        hasPotentialMatches && splittedStrings.every((t: string[]) => t[index] === segment)
          ? [`${prefix}${segment} `, true]
          : [prefix, false]
      ) as [string, boolean]
    },
    ['', true],
  )[0] as string
}

export type IVariantLinkFn = (id: string) => RouteLocationRaw
const ProductVariants = defineComponent({
  components: { BtnImage, ModalContainer },
  props: {
    data: { type: Array as PropType<IProductVariant[]>, default: () => [] },
    limit: { type: Number, default: 8 },
  },
  setup(props) {
    const modalVisible = ref(false)
    const prefix = computed(() => getCommonPrefix(props.data.map((d: IProductVariant) => d.title)))
    const variants = computed(() =>
      props.data
        .map((d: IProductVariant) => ({ ...d, titleShort: d.title.replace(prefix.value, '') }))
        .sort((a: IProductVariant, b: IProductVariant) => a.id.localeCompare(b.id)),
    )

    return {
      variantLinkFnLocal: inject<IVariantLinkFn>('productVariantLinkFn', (productId: string) => ({
        name: '/products/[id]',
        params: { id: productId },
      })),
      modalVisible,
      variants,
      current: computed(() => variants.value.find((v: IProductVariant) => v.current)!),
      showAsModal: computed(() => props.data.length > props.limit),
    }
  },
})
export default ProductVariants
</script>
