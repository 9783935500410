<template lang="pug">
action-bar
  btn(v-if='close' link-dark data-test-btn='form-footer-cancel' data-test='btn-form-footer-cancel' @click='() => close && close()') {{ $t('Cancel') }}
  slot
  template(v-if='$slots.secondary' #left)
    slot(name='secondary')
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'

const FormFooter = defineComponent({
  setup() {
    return {
      close: inject<undefined | (() => void)>('closeModal', undefined),
    }
  },
})

export default FormFooter
</script>
