<template lang="pug">
div(class='mb-2 rounded-lg border border-grey-100')
  div(class='group sticky top-0 z-10 flex cursor-pointer rounded-lg bg-white px-3 py-4 hover:bg-grey-50 active:bg-grey-75' tabindex='0' role='button' @click='!force && (expanded = !expanded)')
    div(class='flex items-start gap-2')
      icon(:name='!force && !expanded ? "chevron-right" : "chevron-down"' class='mt-1 text-text-light group-hover:text-text')
      div(class='flex flex-1 flex-wrap items-center gap-2')
        slot
  div(v-if='force || expanded' class='pl-8 pr-3')
    slot(name='content')
</template>

<script setup lang="ts">
const expanded = defineModel<boolean>()
defineProps<{ force?: boolean }>()
</script>
