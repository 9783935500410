<template lang="pug">
div(ref='el' :class='classes' class='sticky-container')
  slot(:sticky='sticky' :enabled='actuallyEnabled')
</template>

<script lang="ts">
import { logReturn } from '@/utilities/log'
import { debouncedWatch } from '@vueuse/shared'
import { defineComponent, ref, onMounted, type PropType, onUnmounted, computed, nextTick } from 'vue'

const StickyContainer = defineComponent({
  props: {
    position: { type: String as PropType<'top' | 'bottom'>, default: 'top' },
    enabled: { type: Boolean, default: true },
  },
  setup(props) {
    const sticky = ref(false)
    const el = ref(null)
    let observer: IntersectionObserver | null = null
    const isSticky = {
      bottom: (intersectionRatio: number) => intersectionRatio < 1,
      top: (intersectionRatio: number) => intersectionRatio < 1,
    }
    onMounted(() =>
      nextTick(() => {
        observer = new IntersectionObserver(
          ([e]) => (sticky.value = isSticky[props.position](logReturn(e.intersectionRatio))),
          { threshold: [1] },
        )
        observer.observe(el.value!)
      }),
    )

    const enabledDebounced = ref(props.enabled)
    const actuallyEnabled = computed(() => props.enabled || enabledDebounced.value)
    debouncedWatch(
      () => props.enabled,
      (value: boolean) => (enabledDebounced.value = value),
      { debounce: 200 },
    ),
      onUnmounted(() => observer && observer.disconnect())

    return {
      el,
      actuallyEnabled,
      sticky,
      classes: computed(() => ({
        [`sticky-container--${props.position}`]: true,
        'sticky-container--enabled': actuallyEnabled.value,
        'sticky-container--visible': props.enabled,
        'sticky-container--overlapping': actuallyEnabled.value && sticky.value,
      })),
    }
  },
})
export default StickyContainer
</script>

<style lang="stylus">
@import '../styles/variables.styl'
:root
  --sticky-bg: #fff

.sticky-container
  --sticky-shadow: none
  &--top
    transition: top .2s ease, box-shadow .3s ease
    top: -60px
    --sticky-shadow: 0 12px 14px -4px rgba(68, 69, 84, 0.13)
    ~/--visible&
      top: -1px
  &--bottom
    transition: bottom .2s ease, box-shadow .4s ease
    bottom: -60px
    --sticky-shadow: 0 -10px 12px -8px rgba(68, 69, 84, 0.1)
    ~/--visible&
      bottom: -1px
  &--enabled
    position: sticky
  &--overlapping
    background: var(--sticky-bg)
    z-index: 999
    box-shadow: var(--sticky-shadow)
</style>
