import { gtmEventTracking } from './Gtm'

export const bookDemo = (trackEvent?: boolean) => {
  ;(window as any).Calendly.initPopupWidget({ url: 'https://calendly.com/nuucon-team/kennenlernen-mit-nuucon' })
  if (trackEvent) {
    gtmEventTracking('event', 'click', 'makeAppointment', 'bookDemo')
  }
}

export const makeAppointment = (
  url: string = 'https://calendly.com/nuucon-team/kennenlernen-mit-nuucon',
  trackEvent?: boolean,
) => {
  ;(window as any).Calendly.initPopupWidget({ url })
  if (trackEvent) {
    gtmEventTracking('event', 'click', 'makeAppointment', 'default')
  }
}

export const openIntercomChat = (message: string) => (window as any).Intercom('showNewMessage', message)

export const useThirdPartyTools = () => {
  return {
    bookDemo,
    makeAppointment,
    openIntercomChat,
  }
}
