<template lang="pug">
div(:class='classes' class='control-radio-item')
  input(:id='selectorId' v-model='val' :value='value' type='radio' :disabled='isDisabled' class='control-radio-item__input' @input='onChange')
  label(:for='selectorId' class='control-radio-item__label')
    div(class='control-radio-item__text')
      slot
    div(v-if='$slots.actions' class='control-radio-item__actions')
      slot(name='actions')
</template>

<script lang="ts">
import { computed, type ComputedRef, defineComponent, inject, type Ref } from 'vue'

const ControlRadioItem = defineComponent({
  props: {
    value: String,
    centered: Boolean,
    disabled: Boolean,
    id: String,
    size: {
      type: String,
      default: 'default',
    },
    bordered: Boolean,
  },
  setup(props) {
    const val = inject<Ref<string>>('value')
    const groupDisabled = inject<ComputedRef<boolean>>('disabled')
    const onChange = inject('onChange')
    const isDisabled = computed(() => props.disabled || groupDisabled!.value)
    const checked = computed(() => val!.value === props.value)
    return {
      val,
      selectorId: computed(() => props.id || `radio-item_${props.value}`),
      onChange: () => !isDisabled.value && (onChange as any)(props.value),
      isDisabled,
      classes: computed(() => ({
        [`control-radio-item--${props.size}`]: true,
        'control-radio-item--disabled': isDisabled.value,
        'control-radio-item--bordered': props.bordered,
        'control-radio-item--checked': checked.value,
        'control-radio-item--centered': props.centered,
      })),
    }
  },
})

export default ControlRadioItem
</script>

<style lang="stylus">
@import '../../styles/variables.styl'
:root
  --radio-size: 18px
  --radio-padding: 4px
  --radio-gap: 10px
  --radio-offset-y: 0px
  --radio-offset-x: 0px
  --radio-color-highlight: $color-accent
  --radio-color-hover: rgba($color-accent, 0.1)
  --radio-color-active: rgba($color-accent, 0.1)
  --radio-color-highlight-faded: rgba($color-accent, 0.1)
  --radio-color-input-border: $color-input-border

.control-radio-item
  position: relative
  margin-bottom: 8px
  display: inline-flex
  padding-right: 20px
  &__label
    display: !important
    align-items: center
    flex: 1
    b
      font-weight: 600
  &__text
    flex-grow: 1
  &--large
    --radio-size: 22px
    --radio-padding: 4px
  &--bordered
    label
      border-radius: 8px!important
      border: 2px solid var(--grey-100)
    & [type="radio"]:focus + label,
    & [type="radio"]:active + label,
    & [type="radio"]:checked + label
      border-color $color-accent!important

  &--disabled
    --radio-color-highlight: var(--grey-300)!important
    pointer-events: none

  &--centered
    --radio-gap: 18px
    --radio-offset-y: calc(50% - 11px)
    --radio-offset-x: 16px
    padding-right: 0px
    display: flex
    label
      padding: 15px 10px
      border-radius: 12px
      flex-grow: 1
  &:not(&--disabled) label:hover
    border-color: var(--radio-color-hover)
    & [type="radio"]:active + label,
    & [type="radio"]:checked + label
      border-color: var(--radio-color-active)
    & [type="radio"]:focus + label
      border-color: var(--radio-color-active)

  &[block], [block] &
    display: flex

  &--disabled label
    color: var(--grey-500)

  &:not(&--disabled):not(&--centered):hover label:before,
  [type="radio"]:active + label:before,
  [type="radio"]:focus + label:before
      box-shadow: 0 0 0 8px var(--radio-color-highlight-faded)

  input[type="radio"]
    position absolute
    left -9999px
    & + label
      position relative
      padding-left calc(var(--radio-size) + var(--radio-gap) + var(--radio-offset-x))
      cursor pointer
      line-height 1.35
      display block
      &:hover
        --radio-color-input-border: $color-accent
      &:before
        content ''
        position absolute
        left var(--radio-offset-x)
        top var(--radio-offset-y)
        width var(--radio-size)
        height var(--radio-size)
        border 1.5px solid var(--radio-color-input-border)
        border-radius 100%
        background transparent

      &:after
        content ''
        width calc(var(--radio-size) - 2 * var(--radio-padding))
        height calc(var(--radio-size) - 2 * var(--radio-padding))
        background var(--radio-color-highlight)
        position absolute
        top calc(var(--radio-offset-y) + var(--radio-padding))
        left calc(var(--radio-padding) + var(--radio-offset-x))
        border-radius 100%
        transition all 0.2s ease

    &:not(:checked) + label:after
      opacity 0
      transform scale(0)

    &:checked + label
      &:before
        border-color: var(--radio-color-highlight)!important
      &:after
        opacity 1
        transform scale(1)
</style>
