<template lang="pug">
div(:id='id' :class='classes' class='control-select-list')
  select-list-item(
    v-for='({ data, value, label, disabled }, index) in options'
    :key='value'
    :disabled='disabled'
    :modelValue='selected[index]'
    :data-id='value'
    class='control-select-list__item'
    @click.prevent='toggle(index)'
  )
    slot(name='item' :data='data' :label='label' :value='value' :disabled='disabled')
      b {{ label }}
    template(#actions)
      slot(name='actions' :item='{ data, value, label, disabled }')
  empty(v-if='options.length === 0' :centered='true' class='control-select-list__empty')
</template>

<script lang="ts">
import { Input, useControl } from './composables'
import SelectListItem from '@/components/SelectListItem.vue'
import { type IOption } from '@/types'
import { computed, defineComponent, type PropType, type SetupContext, watch } from 'vue'

const ControlSelectList = defineComponent({
  components: { SelectListItem },
  props: {
    ...Input.props,
    modelValue: Array as PropType<string[]>,
    options: { type: Array as PropType<IOption[]>, default: () => [] },
  },
  setup(props, context: SetupContext) {
    const { value: val, classes, onChange } = useControl(props, context, props.modelValue)
    watch(() => val.value, onChange)
    const selected = computed(() => props.options.map((o: IOption) => val.value?.includes(o.value as string)))
    return {
      selected,
      toggle: (index: number) =>
        !selected.value[index]
          ? (val.value = [...val.value!, props.options[index].value as string])
          : (val.value = val.value!.filter((v) => v !== props.options[index].value)),
      val,
      classes,
    }
  },
})

export default ControlSelectList
</script>

<style lang="stylus">
@import '../../styles/variables.styl'

.control-select-list
  &__item
    padding-left: var(--bleed-left)!important
    padding-right: var(--bleed-right)!important
    width: 100%
  &__empty
    margin: 0 15px
</style>
