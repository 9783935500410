import Message from './Message.vue'
import MessageList from './MessageList.vue'
import { type App, type Plugin, ref, type Ref } from 'vue'

export interface IComponentConfig {
  component: string
  props: Record<string, any>
}
export type IMessageOption = Pick<IMessageItem, 'delay' | 'size' | 'closable' | 'icon'>

export type MessageSize = 'default' | 'large'
export type MessageType = 'success' | 'warning' | 'info' | 'error'
export interface IMessageItem {
  type: MessageType
  content: string | IComponentConfig
  icon?: string | boolean
  key?: any
  size?: MessageSize
  delay?: number
  closable?: boolean
}

export type IMessageFunction = (content: string | IComponentConfig, options?: IMessageOption) => void

export interface IMessage {
  success: IMessageFunction
  warning: IMessageFunction
  info: IMessageFunction
  error: IMessageFunction
  close: (key: string | number) => void
}

let maxMessageNum = 2

const add = (item: IMessageItem) => {
  if (!item.content) {
    return
  }
  if (messages.value.length >= maxMessageNum) {
    removeLatest()
  }
  item.key = Math.random()
  messages.value.push(item)
  setTimeout(
    () => (messages.value = messages.value.filter((msg: IMessageItem) => msg.key !== item.key)),
    item.delay || 3500,
  )
}
const remove = (key: string | number) => {
  messages.value = messages.value.filter((msg: IMessageItem) => msg.key !== key)
}

const createMessageFn = (type: MessageType) => (content: string | IComponentConfig, options?: IMessageOption) =>
  add({ content, type, ...(options || {}) })

const removeLatest = () => messages.value.length > 0 && messages.value.pop()

export type IMessageItemsRef = Ref<IMessageItem[]>
export const messages: IMessageItemsRef = ref<IMessageItem[]>([])
export const msg: IMessage = {
  success: createMessageFn('success'),
  warning: createMessageFn('warning'),
  error: createMessageFn('error'),
  info: createMessageFn('info'),
  close: (key: string | number) => remove(key),
}
export const MessagePlugin: Plugin = {
  install(app: App, max: number = 2) {
    maxMessageNum = max
    app.config.globalProperties.$messages = messages
    app.config.globalProperties.$msg = msg
    app.provide('$messages', messages)
    app.provide('$msg', msg)
  },
}

export const Components = {
  Message,
  MessageList,
}

export const useMessages = () => {
  return {
    $msg: msg,
    $nessages: messages,
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $msg: IMessage
    $messages: IMessageItemsRef
  }
}
