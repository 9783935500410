<template lang="pug">
tr(v-if='(value !== "" && value !== undefined) || $slots.default' :data-test-meta-row='id' class='table-meta-row')
  td(:colspan='singleColumn ? 2 : 1')
    div(class='table-meta-row__label')
      icon(v-if='icon' :name='icon')
      span(v-html='label || $t(id)')
    div(v-if='singleColumn' class='table-meta-row__value')
      slot
        span(v-html='transformValue ? transformValue(value) : value')
  td(v-if='!singleColumn')
    slot
      div(class='table-meta-row__value' v-html='transformValue ? transformValue(value) : value')
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const MetaTableRow = defineComponent({
  props: {
    label: String,
    singleColumn: Boolean,
    id: { required: true, type: String },
    icon: String,
    value: [String, Boolean],
    transformValue: Function,
  },
})

export default MetaTableRow
</script>

<style lang="stylus">
.table-meta-row
  &__value
    white-space: pre-wrap
  &__label
    padding-left: 0
    color: var(--grey-500)
    display: flex
    + div
      margin-top: 5px
      font-size: 14px
    .icon
      margin-right: 6px
      margin-left: -3px
      top: 2px
      position: relative
  td
    font-size: 15px
    vertical-align: top
    line-height: 1.25
    padding: 6px 12px
    &:first-child
      padding-left: 0
      width: 45%
    &:last-child
      padding-right: 0
  &:not(:first-child) td
    border-top: 1px solid var(--table-td-border)
</style>
