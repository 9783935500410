import { emitter } from '@/common'
import { shakeFalsy } from '@/utilities'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { isObject } from 'radash'
import { type App, inject, type Plugin, ref, type Ref } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

export interface IModalsPlugin {
  modals: Ref<string[]>
  register: (id: string, setDisableBodyScroll?: boolean) => void
  unregister: (id: string) => void
  isUpperMost: (id: string) => boolean
  open: (componentName: string, props?: any, replace?: boolean) => Promise<any>
  close: (componentName: string, value?: any) => void
  getLocation: (componentName: string, props?: any) => RouteLocationRaw
}

// @TODO: simplify ModalConfirmManager and FormModalManager?
const ModalsPlugin: Plugin = {
  install(app: App) {
    const utils = app.config.globalProperties.$utils
    const modals = ref<string[]>([])
    const register = (id: string, setDisableBodyScroll: boolean = true) => {
      if (setDisableBodyScroll && modals.value.length === 0) {
        disableBodyScroll(document.querySelector('#modal,body')!, {
          reserveScrollBarGap: true,
          allowTouchMove: () => true,
        })
      }
      modals.value.push(id)
    }
    const unregister = (id: string) => {
      if (modals.value.indexOf(id) > -1) {
        modals.value.splice(modals.value.indexOf(id), 1)
        emitter.emit('modalClosed', id)
        if (modals.value.length === 0) {
          clearAllBodyScrollLocks()
          emitter.emit('modalClosed', 'all')
        }
      }
    }

    const resolvers: Map<string, (value: any) => any> = new Map()

    const open = async (componentName: string, props: any = {}, replace: boolean = true) => {
      const promise = new Promise((resolve) => resolvers.set(componentName, resolve))
      utils.routeQueryAdd({ [componentName]: isObject(props) ? shakeFalsy(props) : props }, false, replace)
      const value = await promise
      return value
    }

    const getLocation = (componentName: string, props: any = {}) =>
      utils.routeQueryAdd({ [componentName]: shakeFalsy(props) }, true)

    const close = (componentName: string, value?: any) => {
      if (resolvers.get(componentName) && value !== undefined) {
        resolvers.get(componentName)!(value)
      }
      utils.routeQueryRemove(componentName, undefined, true)
    }

    const isUpperMost = (id: string) => modals.value.indexOf(id) + 1 === modals.value.length

    const $modals: IModalsPlugin = {
      register,
      modals,
      unregister,
      isUpperMost,
      open,
      close,
      getLocation,
    }

    app.config.globalProperties.$modals = $modals
    app.provide<IModalsPlugin>('$modals', $modals)
  },
}

export const useModals = () => {
  return inject<IModalsPlugin>('$modals')!
}

export default ModalsPlugin

declare module 'vue' {
  interface ComponentCustomProperties {
    $modals: IModalsPlugin
  }
}
