<template lang="pug">
dropdown(:ignoreContentClick='ignoreContentClick' :maxWidth='maxWidth' :minWidth='minWidth')
  template(#default='{ toggle, visible }')
    slot(name='button' :toggle='toggle' :active='visible')
      btn(:loading='loading' :icon='icon' v-bind='$attrs' :active='visible' @click.stop='toggle')

  template(#content)
    slot
      component(:is='item.children ? "custom-menu-group" : "dropdown-item"' v-for='item in options' v-bind='item' :key='item.id' v-on='item.onClick ? { click: item.onClick } : {}')
        div(v-if='item.children')
          dropdown-item(v-for='child in item.children' :key='child.id' v-bind='child' v-on='child.onClick ? { click: child.onClick } : {}')
</template>

<script lang="ts">
import Dropdown from './Dropdown.vue'
import { defineComponent, type PropType } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

export interface IDropdownOption {
  text: string
  description?: string
  icon?: string
  id?: string
  href?: string
  onClick?: () => void
  buttonProps?: any
  to?: RouteLocationRaw
  children?: IDropdownOption[]
}

const DropdownOptions = defineComponent({
  components: { Dropdown },
  inheritAttrs: false,
  props: {
    minWidth: { type: String, default: '225px' },
    maxWidth: { type: String, default: '300px' },
    icon: { type: String, default: 'more-horizontal' },
    loading: Boolean,
    options: { type: Array as PropType<IDropdownOption[]>, required: false },
    ignoreContentClick: Boolean,
  },
  setup() {},
})

export default DropdownOptions
</script>
