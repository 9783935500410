<template lang="pug">
component(:is='compiledData')
</template>

<script lang="ts">
import snarkdown from 'snarkdown'
import { defineComponent, computed, compile } from 'vue'

export interface IHtmlJsonItem {
  id: string | undefined
  tag: string
  content: string | undefined
  title: string | undefined
  children: IHtmlJsonItem[]
}

export const snarkdownEnhanced = (markdown: string, wrap: boolean = false) =>
  markdown
    .split(/(?:\r?\n){2,}/)
    .map((l) =>
      !wrap || [' ', '\t', '#', '- ', '* ', '> '].some((char) => l.startsWith(char))
        ? snarkdown(l)
        : `<p>${snarkdown(l)}</p>`,
    )
    .join('\n')

const Markdown = defineComponent({
  props: { data: { type: String, required: true } },
  setup(props) {
    return {
      compiledData: computed(() => compile(snarkdownEnhanced(props.data))),
    }
  },
})
export default Markdown
</script>
