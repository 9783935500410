import { logReturn } from './log'
import Cookies from 'js-cookie'

export const COOKIE_VALUE: string = 'on'
export const COOKIE_NAME_TECHNICAL = 'complianceCookieTechnical'
export const COOKIE_NAME_MARKETING = 'complianceCookieMarketing'
export const COOKIE_NAME_ANALYTICS = 'complianceCookieAnalytics'

/**
 * Version 1:
 * view_item_list --> Impression in der Suchergebnisliste (Product View)
 * select_content --> Product Click (In Router)
 * view_item --> Product Detail View
 * add_to_project --> aus dem Form
 * add_to_inquiry (draft) --> aus dem Form / aus dem Projekt
 * remove_from_project --> element aus projekt löschen / projekt löschen
 * remove_from_inquiry (draft) --> element aus anfrage löschen / anfrage löschen / projekt löschen
 *
 * begin_checkout --> Angebot anfordern
 * checkout_progress --> processing
 * checkout_progress --> Angebot übermittelt
 *
 *
 * Version 2:
 * view_item_list --> Impression in der Suchergebnisliste (Erst wenn in view bereich on scroll)
 *
 * @param pageTitle
 * @param path
 */

export interface IProductTrackingItem {
  name: string
  id: string
  price: string
  brand: string
  category?: string
  variant?: string
  list?: string
  position?: number
  quantity?: number
}

export const gtmVirtualPageView = (pageTitle: string, path: string) =>
  gtmEvent('virtualPageView', pageTitle, path, {
    allowVirtualPageViewCookie: Cookies.get(COOKIE_NAME_ANALYTICS) === COOKIE_VALUE,
  })

export const gtmEvent = (
  event: string,
  pageTitle: string,
  path: string,
  more: { [key: string]: string | boolean } = {},
) => (window as any).dataLayer?.push({ event, pageTitle, path, ...more })

export const gtmEventTracking = (
  event: string,
  eventCategory: string,
  eventAction: string,
  eventLabel?: string,
  eventValue?: number,
  fieldsObject?: object,
) => (window as any).dataLayer?.push({ event, eventCategory, eventAction, eventLabel, eventValue, fieldsObject })

export const gtmEcommerceTracking = (event: string | null, ecommerce: any) =>
  (window as any).dataLayer?.push(logReturn({ ...(event ? { event } : {}), ecommerce }, 'gtmEcommerceTracking'))
