import { useMessages } from '../Message'
import api from './api'
import { useTranslation } from '@/plugins/translation'
import type { IClipboardItem } from '@/types'
import { defineStore } from 'pinia'
import { sleep } from 'radash'
import { lastValueFrom } from 'rxjs'
import { ref } from 'vue'

export interface IClipboardState {
  items: IClipboardItem[]
  loading: Record<string, boolean>
}
export const useClipboardStore = defineStore('clipboardStore', () => {
  const loading = ref<Record<string, boolean>>({})
  const items = ref<IClipboardItem[]>([])
  const { $msg } = useMessages()
  const { $t } = useTranslation()

  return {
    items,
    loading,
    isInClipboard: (productId: string) => items.value.findIndex((item) => item.productId === productId) > -1,
    isLoading: (productId: string) => !!loading.value[productId],
    setItems(newItems: IClipboardItem[]) {
      console.log('setItemst', newItems)
      items.value = newItems
    },
    async loadItems() {
      loading.value['all'] = true
      items.value = await lastValueFrom(api.loadItems())
      loading.value['all'] = false
    },
    async toggle(productId: string) {
      if (items.value.findIndex((item) => item.productId === productId) === -1) {
        await this.addItem(productId)
      } else {
        await this.removeItem(productId)
      }
    },
    async addItem(productId: string) {
      loading.value[productId] = true
      const { data } = await lastValueFrom(api.addProduct(productId))
      loading.value[productId] = false
      $msg.success($t('Zu Merkzettel hinzugefügt'))
      return this.setItems(data.items)
    },
    async removeItem(productId: string) {
      loading.value[productId] = true
      const { data } = await lastValueFrom(api.removeProduct(productId))
      loading.value[productId] = false
      return this.setItems(data.items)
    },
    async removeItems(productIds: string[]) {
      loading.value['all'] = true
      let items: IClipboardItem[] = []
      for (const id of productIds) {
        items = (await lastValueFrom(api.removeProduct(id))).data.items
        await sleep(300)
      }
      loading.value['all'] = false
      return this.setItems(items)
    },
  }
})
