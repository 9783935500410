import FormFooter from './FormFooter.vue'
import FormActionSwitch from './FormActionSwitch.vue'
import FormHeader from './FormHeader.vue'
import GenericForm from './GenericForm.vue'

import ControlInput from '@/components/Control/ControlInput.vue'
import ControlInputMoney from '@/components/Control/ControlInputMoney.vue'
import ControlSelect from '@/components/Control/ControlSelect.vue'
import ControlTextarea from '@/components/Control/ControlTextarea.vue'
import ControlRadioGroup from '@/components/Control/ControlRadioGroup.vue'
import ControlFileUpload from '@/components/Control/ControlFileUpload.vue'
import ControlCheckbox from '@/components/Control/ControlCheckbox.vue'
import ControlMultiSelect from '@/components/Control/ControlMultiSelect.vue'
import ControlMultiSelectAsync from '@/components/Control/ControlMultiSelectAsync.vue'
import ControlSelectList from '@/components/Control/ControlSelectList.vue'
import ControlRadio from '@/components/Control/ControlRadio.vue'

export const FormInput = createFormComponent({ component: ControlInput, controlProps: { type: 'text' } })
export const FormInputMoney = createFormComponent({ component: ControlInputMoney })
export const FormInputDate = createFormComponent({
  component: ControlInput,
  controlProps: { type: 'date' },
  floatOnFocus: true,
})
export const FormInputHidden = createFormComponent({
  component: 'input',
  hiddenLabel: true,
  controlProps: { type: 'hidden' },
})
export const FormSelect = createFormComponent({ component: ControlSelect, controlProps: { placeholder: 'choose' } })
export const FormRadio = createFormComponent({ component: ControlRadio, floating: false })
export const FormSelectList = createFormComponent({ component: ControlSelectList, hiddenLabel: true })
export const FormMultiselect = createFormComponent({ component: ControlMultiSelect })
export const FormMultiselectAsync = createFormComponent({ component: ControlMultiSelectAsync })
export const FormTextarea = createFormComponent({ component: ControlTextarea })
export const FormRadioGroup = createFormComponent({ component: ControlRadioGroup, hiddenLabel: true, floating: false })
export const FormFileUpload = createFormComponent({
  component: ControlFileUpload,
  hiddenLabel: true,
  initialValue: [],
})
export const FormCheckbox = createFormComponent({
  component: ControlCheckbox,
  hiddenLabel: true,
  transform: {
    get: (value: string) => (value === '' ? false : JSON.parse(value)),
    set: (value: boolean) => `${value}`,
  },
})

// @TODO: needed?
export const FormSingleSelect = createFormComponent({
  component: ControlMultiSelect,
  controlProps: { multiple: false },
  transform: {
    get: (value: string) => (value === '' ? [] : [value]),
    set: (value: string[]) => (value.length > 0 ? value[0] : ''),
  },
})

import FormActionItem from './FormActionItem.vue'
import FormGroupItem from './FormGroupItem.vue'
import FormTabGroupItem from './FormTabGroupItem.vue'
import FormFieldPlainText from './FormFieldPlainText.vue'
import { createFormComponent } from './createFormComponent'

export const Components = {
  GenericForm,
  FormFooter,
  FormActionSwitch,
  FormAction: FormActionItem,
  FormHeader,
  FormInput,
  FormInputMoney,
  FormInputDate,
  FormHidden: FormInputHidden,
  FormTextarea,
  FormSelectNative: FormSelect,
  FormSelect: FormMultiselect,
  FormSelectAsync: FormMultiselectAsync,
  FormSingleSelect,
  FormRadioGroup,
  FormRadio,
  FormFileUpload,
  FormCheckbox,
  FormSelectList,
  FormGroup: FormGroupItem,
  FormTabGroup: FormTabGroupItem,
  FormPlainText: FormFieldPlainText,
}
