<template lang="pug">
div(v-show='active' class='tabs-pane')
  slot
</template>

<script lang="ts">
import { type ITabRegisterFn } from './Tabs.vue'
import { computed, defineComponent, inject, type Ref } from 'vue'

const TabsPane = defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { id, title } = props
    inject<ITabRegisterFn>('registerTab')?.({ id, title })
    const currentTab = inject<Ref<string>>('currentTab')
    return {
      active: computed(() => currentTab?.value === props.id),
    }
  },
})

export default TabsPane
</script>
