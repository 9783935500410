import { type IGenericFormConfig } from './types'
import { type GenericFormData } from '@/components/GenericForm/types'
import { shallowEqual } from '@/utilities'
import { useSessionStorage } from '@vueuse/core'
import { isEmpty, omit } from 'radash'
import { ref, computed, onBeforeUnmount, nextTick, unref, inject, type ComputedRef, provide, type Ref } from 'vue'

/**
 * Transforms nested config items into flat array
 * @param config
 */
export const flattenConfig = (config: IGenericFormConfig, group: string = ''): IGenericFormConfig[] =>
  !config.items
    ? []
    : [
        config,
        ...Object.entries(config.items).reduce(
          (array: IGenericFormConfig[], [name, conf]) => [
            ...array,
            ...(conf.items
              ? flattenConfig(conf, group ? `${group}/${name}` : name)
              : [{ ...conf, name: group ? `${group}/${name}` : name }]),
          ],
          [],
        ),
      ]

export const useFormDataPersist = (id: string, debug: boolean = false, ignoreFields: string[] = []) => {
  const initialFormData = ref<GenericFormData>({})
  const storedFormData = useSessionStorage<GenericFormData>(
    `EditDeliverySetup_${id}`,
    {},
    { writeDefaults: false, flush: 'sync' },
  )
  const formDataModel = ref<GenericFormData>({})
  // make sure only initial onReady call will be used
  let onFormReadyCalled = false
  const onFormReady = ({ formData }: { formData: GenericFormData }) =>
    !onFormReadyCalled &&
    ((onFormReadyCalled = true),
    (initialFormData.value = { ...formData }),
    nextTick(() => !isEmpty(storedFormData.value) && (formDataModel.value = storedFormData.value)))
  onBeforeUnmount(() => (storedFormData.value = { ...formDataModel.value }))
  const hasFormChanges = computed(
    () =>
      !isEmpty(storedFormData.value) &&
      (debug && console.log('useFormDataPersist', initialFormData.value, storedFormData.value),
      !shallowEqual(omit(initialFormData.value, ignoreFields), omit(storedFormData.value, ignoreFields))),
  )
  const resetChanges = () => ((formDataModel.value = unref(initialFormData)), (storedFormData.value = {}))

  return {
    initialFormData,
    storedFormData,
    formDataModel,
    onFormReady,
    hasFormChanges,
    resetChanges,
  }
}

export const useFormItem = (name: string | Ref<string>) => {
  const n = ((name as Ref<string>).value ? name : ref(name)) as Ref<string>
  const parents = inject<ComputedRef<string[]>>(
    'name',
    computed(() => []),
  )
  const configMap = inject<ComputedRef<{ [id: string]: IGenericFormConfig }>>('configMap')
  const path = computed<string[]>(() => (parents ? [...parents.value, n.value] : [n.value]))
  const completeName = computed(() => path.value.join('/'))
  const config = computed<IGenericFormConfig | null>(() => configMap?.value?.[completeName!.value] || null)
  provide<ComputedRef<string[]>>('name', path)
  return { path, parents, configMap, completeName, config }
}
