const env = import.meta.env

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $env: {
      assetPathPrefix: string
      cloudinaryPath: string
      useCloudinary: boolean
      environment: string
      isLocalhost: boolean
      isTestBuild: boolean
      isProductionBuild: boolean
      buildEnv: string
      commitHash: string
      vat: number
    }
  }
}

export default {
  environment: (env as any).NODE_ENV,
  useCloudinary: (env as any).VITE_USE_CLOUDINARY,
  assetPathPrefix: (env as any).VITE_THOR_ASSET_PATH_PREFIX,
  cloudinaryPath: (env as any).VITE_CLOUDINARY_PATH,
  commitHash: (env as any).VITE_COMMIT_HASH,
  isLocalhost: window.location.hostname === 'localhost',
  buildEnv: (env as any).VITE_BUILD_ENV,
  isProductionBuild: (env as any).VITE_BUILD_ENV === 'prod',
  isTestBuild: (env as any).VITE_BUILD_ENV === 'test',
  vat: 19,
}
