<template lang="pug">
modal(size='2xl' mode='drawer' admin @close='$emit("close")')
  div(v-if='project')
    form-header(class='!mb-0')
      h4
        span(class='mr-2') {{ $t('Project') }}:
        span(class='quotes font-light') {{ project.title || project.id }}
        btn(secondary class='ml-2' icon='external-link' tiny :href='`/#/projects/${project.id}`' target='_blank' :data-tooltip='$t("Nutzeransicht")')
      template(#actions)
        div(@click='$modals.open("form-project-members", { id })')
          project-members-avatar-list(:members='project.members')
        project-attachments-dropdown(:projectId='id' :attachments='project.attachments' @update='onUpdateAttachments')
        btn(secondary icon='printer' @click='actions.showPrintPreview(project.id, "")') {{ $t('Print Preview') }}
        btn(secondary admin icon='user' @click='actions.upadeProjectOwner(project.id)') {{ $t('Projekt übertragen') }}
    div(class='mb-12 flex w-full flex-wrap gap-2 text-sm text-text-light')
      div(v-if='project.description')
        span(class='font-normal') {{ $t('Description') }}: !{ ' ' }
        span(class='font-bold') {{ project.description }}
      div(v-if='project.budget')
        span(class='font-normal') {{ $t('Budget') }}: !{ ' ' }
        span(class='font-bold') {{ project.budget }}
      div(v-if='project.deadline')
        span {{ $t('Deadline') }}: !{ ' ' }
        span(class='font-bold') {{ project.deadline }}
    div(class='bleed')
      project-product-list(:id='id' :editable='false' :loadOnMount='true' :admin-mode='true' class='-mt-6' style='--bleed-left: 50px')
</template>

<script setup lang="ts">
import { useProjectActions } from '../composables'
import { type IAttachment } from '../types'
import { useProjectsStore } from '@/pages/projects/store'
import { computed } from 'vue'

const props = defineProps<{ id: string }>()
const actions = useProjectActions()
const store = useProjectsStore()
store.loadProject(props.id)
const project = computed(() => store.project(props.id))

const onUpdateAttachments = (attachments: IAttachment[]) => store.setProject({ ...project.value, attachments })
</script>
