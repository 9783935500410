<template lang="pug">
div(:class='classes' class='empty-state')
  div(class='empty-state__image')
    slot(name='image')
      icon(:name='icon' class='empty-state__icon')
  div(class='empty-state__content')
    div(class='empty-state__title')
      slot(name='title') {{ title }}
    p(class='empty-state__text')
      slot(name='message')
        span(v-if='message') {{ message }}
    div(class='empty-state__buttons')
      slot(name='buttons')
</template>

<script setup lang="ts">
import Icon from './Icon.vue'
import { computed } from 'vue'
import { type PropType } from 'vue'

const props = defineProps({
  message: { type: String, default: 'No items found' },
  title: { type: String },
  icon: { type: String, default: 'custom-sofa-not-found' },
  size: { type: String as PropType<'default' | 'small'>, default: 'default' },
  type: { type: String as PropType<'default' | 'warning' | 'info' | 'danger'>, default: 'default' },
  centered: Boolean,
  bordered: Boolean,
  boxed: Boolean,
})
const classes = computed(() => ({
  [`empty-state--${props.type}`]: true,
  [`empty-state--${props.size}`]: true,
  'empty-state--centered': props.centered,
  'empty-state--boxed': props.boxed,
  'empty-state--bordered': props.bordered,
}))
</script>

<style lang="stylus">
@import '../styles/variables.styl'

:root
  --empty-state-font-size: 18px
  --empty-state-gap: 0 20px 0 0
  --empty-state-icon-margin: 20px
  --empty-state-icon-bg: var(--grey-75)
  --empty-state-icon-color: var(--grey-300)
  --empty-state-icon-size: 80px
  --empty-state-icon-font-size: 44px
  --empty-state-font-size-title: 18px
  --empty-state-content-alignment: flex-start
.empty-state
  padding: 20px 20px
  border-radius: 8px
  display: flex
  justify-content: var(--empty-state-content-alignment)
  &--small
    --empty-state-font-size-title: 16px
    --empty-state-font-size: 16px
    --empty-state-icon-size: 50px
    --empty-state-icon-font-size: 26px
    --empty-state-icon-stroke-width: 1.5px
    --empty-state-icon-margin: 15px
  &--danger
    --empty-state-icon-bg: var(--red-50)
    --empty-state-icon-color: var(--red-400)
  &--warning
    --empty-state-icon-bg: var(--yellow-100)
    --empty-state-icon-color: var(--yellow-500)
  &--info
    --empty-state-icon-bg: var(--blue-50)
    --empty-state-icon-color: var(--blue-400)
  &--bordered
    padding: 30px
    border: 1px solid var(--grey-200)
  &--boxed
    background: var(--grey-50)
  &--centered
    --empty-state-gap: 0 20px 0
    flex-direction: column
    text-align: center
    justify-content: center
    align-items: center
  &__text
    font-size: var(--empty-state-font-size)
    color: var(--grey-500)
    padding: 0!important
    margin: 0!important
    line-height: 1.5
  &__title
    font-family: $font-lato
    color: var(--grey-500)
    font-weight: 900
    font-size: var(--empty-state-font-size-title)
    margin-bottom: 6px
  &__buttons
    margin-top: 20px
    &:empty
      display: none
  &__icon
    border-radius: 100%
    display: inline-flex
    align-items: center
    justify-content: center
    margin: var(--empty-state-icon-margin)
    position: relative
    font-size: var(--empty-state-icon-font-size)
    width: var(--empty-state-icon-size)
    min-width: var(--empty-state-icon-size)
    height: var(--empty-state-icon-size)
    stroke-width: var(--empty-state-icon-stroke-width, 1px)
    mix-blend-mode: multiply
    background: var(--empty-state-icon-bg)
    color: var(--empty-state-icon-color)
</style>
