import { parseValues } from '../composables'
import { featureFlags } from '@/plugins/FeatureFlags'
import { type AxiosResponse } from 'axios'
import { Axios } from 'axios-observable'
import Cookies from 'js-cookie'
import { isEmpty } from 'radash'
import { timer } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { type Plugin, type App, reactive, type UnwrapNestedRefs, inject } from 'vue'

export interface IMember {
  userId: string
  salutationString: string
  email: string
  roles: string[]
}
export interface IAddress {
  id?: any
  addressId?: { id: string }
  name: string
  city: string
  country: string
  postalCode: string
  address: string
  contactPerson?: string
  phone?: string
}
export interface ICustomerData {
  id: string
  name: string
  defaultInvoiceAddress?: IAddress
  defaultDeliveryAddress?: IAddress
  responsibleOperator: string
  addresses: IAddress[]
  members: IMember[] | []
  active: boolean
}

export interface IUserData {
  id: string
  fullName: string
  intercomHash?: string
  username: string
  email: string
  givenName: string
  familyName: string
  isAnonymous: boolean
  customers?: string[]
  myCustomer?: string | undefined
  supplierId?: string | undefined
  customerId: string
  settings?: any
  impersonator?: string | undefined
}

export interface IUserDataExtended extends IUserData {
  isLoggedIn: boolean
  ready: boolean
}
export const getSessionPublicCookie: () => string | undefined = () => Cookies.get('P')
export const isLoggedIn: () => boolean = () => !!getSessionPublicCookie()

export const userDataReactive: UserDataRef = reactive<IUserDataExtended>({
  id: '',
  fullName: '',
  intercomHash: '',
  username: '',
  email: '',
  givenName: '',
  familyName: '',
  customers: [],
  myCustomer: undefined,
  customerId: '',
  supplierId: '',
  settings: {},
  impersonator: undefined,
  isAnonymous: false,
  ready: false,
  isLoggedIn: isLoggedIn(),
})

export type UserDataRef = UnwrapNestedRefs<IUserDataExtended>

// @TODO: rename userId to id in backend
const mapUserCookieData = (userDataFromCookie: any) => ({ ...userDataFromCookie, id: userDataFromCookie.userId })

export const getUserDataFromCookie: () => IUserData | undefined = () =>
  (getSessionPublicCookie() && mapUserCookieData(JSON.parse(decodeUtf8(atob(getSessionPublicCookie()!))))) || undefined

export const isNuuconUser: () => boolean = () => {
  const userData: IUserData | undefined = getUserDataFromCookie()
  return !!userData && (userData.email!.includes('@nuucon.com') || !!userData.impersonator)
}

export const decodeUtf8 = (s: string): string => decodeURIComponent(escape(s))

export const logout = () => Axios.get('/_/Logout')

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $user: UserDataRef
  }
}

const initIntercom = (userData: IUserData) => {
  if ((window as any).intercomSettings) {
    // tslint:disable-next-line
    ;(window as any).intercomSettings = {
      app_id: (window as any).intercomSettings.app_id,
      username: userData.username,
      email: userData.email,
      given_name: userData.givenName,
      name: `${userData.givenName} ${userData.familyName}`,
      family_name: userData.familyName,
      user_id: userData.id,
      user_hash: userData.intercomHash,
    }
    if ((window as any).Intercom) {
      // tslint:disable-next-line
      ;(window as any).Intercom('update', {
        hide_default_launcher: !featureFlags.ENABLE_INTERCOM_FOR_NUUCON_USERS && isNuuconUser(),
      })
    }
  }
}

const update = () => {
  const userData: IUserData | undefined = getUserDataFromCookie()
  if (userData && !isEmpty(userData)) {
    userDataReactive.isLoggedIn = true
    userDataReactive.myCustomer = userData.myCustomer
    userDataReactive.impersonator = userData.impersonator
    Axios.get('/_/User').subscribe((response: AxiosResponse<IUserData>) => {
      const { givenName, familyName, username } = response.data
      userDataReactive.fullName = givenName ? `${givenName} ${familyName}` : username
      Object.entries(response.data).forEach(
        ([key, value]) =>
          (userDataReactive[key as keyof IUserData] =
            key !== 'settings' ? (value as never) : (parseValues(value) as never)),
      )
      initIntercom(userDataReactive)
      userDataReactive.ready = true
    })
  } else {
    // force logout when userData not available to avoid errorenous application state
    logout().subscribe(() => {
      userDataReactive.isLoggedIn = false
    })
  }
}

export const useUser = () => inject<UserDataRef>('$user')!

export const UserPlugin: Plugin = {
  install(app: App) {
    const observable = timer(0, 1000).pipe(
      map(() => getSessionPublicCookie()),
      distinctUntilChanged(),
    )
    observable.subscribe(update)
    app.config.globalProperties.$user = userDataReactive
    app.provide('$user', app.config.globalProperties.$user)
  },
}

export default UserPlugin
