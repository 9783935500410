<template lang="pug">
div
  control-multi-select(
    v-bind='props'
    v-model:query='inputValue'
    :searchable='true'
    :filter='false'
    :options='asyncOptions'
    :cachedOptions='options'
    :loading='loading'
    :creatable='creatable'
    :placeholder='placeholder'
    :placeholderCreatable='placeholderCreatable'
    :modelValue='modelValue'
    @update:modelValue='(v: string) => $emit("update:modelValue", v)'
    @update:open='onUpdateOpen')

</template>

<script lang="ts">
import ControlMultiSelect from './ControlMultiSelect.vue'
import { Input } from './composables'
import type { IOption } from '@/types'
import { watchThrottled } from '@vueuse/shared'
import { ref, defineComponent, type PropType, watch } from 'vue'

export type UpdateFn = (opts: IOption[]) => void
export type FetchOptionsFn = (query: string, update: UpdateFn) => void

const ControlMultiSelectAsync = defineComponent({
  components: { ControlMultiSelect },
  props: {
    ...Input.props,
    modelValue: { required: true, type: [Array, String] as PropType<string | string[]> },
    nested: Boolean,
    searchable: { type: Boolean, default: true },
    creatable: Boolean,
    multiple: Boolean,
    allowMultiline: Boolean,
    options: Array as PropType<IOption[]>,
    filter: { type: Boolean, default: true },
    placeholder: { type: String, default: 'Auswählen..' },
    placeholderCreatable: { type: Function },
    fetchOptions: { type: Function as PropType<FetchOptionsFn>, required: true },
  },
  setup(props) {
    const asyncOptions = ref<IOption[]>(props.options || [])
    const inputValue = ref('')
    const loading = ref(false)
    let hasChanged = true

    const update = (opts: IOption[]) => ((asyncOptions.value = opts), setTimeout(() => (loading.value = false), 0))
    const callUpdate = (value: string = '') => (
      (loading.value = true), props.fetchOptions(value, update), (hasChanged = false)
    )
    watch(
      () => inputValue.value,
      () => (hasChanged = true),
    )
    watchThrottled(
      () => inputValue.value,
      (v) => v && callUpdate(v),
      { throttle: 300 },
    )
    return {
      props,
      asyncOptions,
      loading,
      onUpdateOpen: (isOpen: boolean) => isOpen && hasChanged && callUpdate(inputValue.value),
      inputValue,
    }
  },
})

export default ControlMultiSelectAsync
</script>
