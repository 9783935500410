<template lang="pug">
div(:disabled='loading' :title='filename' :class='{ "file-item--centered": centered }' class='file-item')
  div(class='file-item__icon')
    icon(:name='icon')
  div(v-if='loading && progress !== undefined' class='file-item__progress')
    progress-bar(:progress='progress')
  div(v-else class='file-item__content')
    slot
      div(class='file-item__filename') {{ filename }}
      div(v-if='info' class='file-item__info') {{ info }}
  div(class='file-item__actions')
    slot(name='actions')
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import { defineComponent } from 'vue'

const FileItem = defineComponent({
  components: { Icon, ProgressBar },
  props: {
    loading: Boolean,
    filename: String,
    progress: Number,
    centered: Boolean,
    icon: { type: String, default: 'custom-file-any' },
    info: String,
  },
})
export default FileItem
</script>
<style lang="stylus">
@import '../styles/variables.styl'

:root
  --file-item-icon-color: var(--color-accent)

.file-item
  display: flex
  align-items: center
  width: 100%
  padding: 2px 0
  &--centered
    flex-flow: column
  &[disabled='true']
    pointer-events: none
    --file-item-icon-color: var(--grey-100)
  &__icon
    margin-right: 15px
    .icon
      font-size: 30px
      color: var(--color-purple)
      ~/--centered &
        font-size: 60px

  &__progress,
  &__content
    position: relative
    flex-grow: 1
    line-height: 1.2
    min-width: 0
    ~/--centered &
      flex-flow: column
      display: flex
      align-items: center

  &__filename
    ~/--centered &
      font-size: 16px

    .dropdown-item:focus &,
    .dropdown-item:hover &
      text-decoration: underline
  &__info,
  &__filename
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
  &__info
    color: var(--color-text-light)
    text-decoration: none!important
    font-size: 14px
  &__actions
    display: flex
    @media (min-width: $breakpoint-sm)
      position: absolute
      opacity: 0
    .dropdown-item:active &,
    .dropdown-item:focus &,
    .dropdown-item:hover &,
    .dropdown-item:focus-within &,
    ~/:hover &
      opacity: 1
      position: relative
      display: flex
</style>
