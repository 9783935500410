import { thorFormPostPromise } from '@/composables/'
import { type IAddress } from '@/plugins/User'
import { flat, isArray, isNumber, isObject, isString, shake } from 'radash'

export const areObjectsDifferent = (o1: any, o2: any) => JSON.stringify(o1) !== JSON.stringify(o2)

export const shakeFalsy = <T>(o: T) => shake(o, (v) => !v)

export const shallowEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }
  return true
}

export const lightOrDark = (color: string) => {
  const c = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))
  const r = c >> 16
  const g = (c >> 8) & 255
  const b = c & 255
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
  // Using the HSP value, determine whether the color is light or dark
  return hsp > 127.5 ? 'light' : 'dark'
}
/**
 * Moves elements in array to a new index.
 */
export const arrayMoveMultiple = <T = any>(array: T[], elements: T[], targetIndex: number) => {
  const a: any[] = [...array]
  a.splice(targetIndex, 0, elements)
  return flat(a.filter((e) => elements.indexOf(e) === -1).map((e) => (!isArray(e) ? [e] : e))) as T[]
}

export const paramsToURIComponent = (params: Record<string, any>) =>
  Object.entries(params).reduce(
    (query: string, [key, value], index: number) =>
      `${query}${index > 0 ? '&' : ''}${key}=${encodeURIComponent(isString(value) ? value : JSON.stringify(value))}`,
    '?',
  )

export type Types = 'string' | 'boolean' | 'object' | 'array' | 'number'
export const getType: (v: any) => Types = (value: any) =>
  isString(value)
    ? 'string'
    : isNumber(value)
      ? 'number'
      : isArray(value)
        ? 'array'
        : isObject(value)
          ? 'object'
          : 'boolean'

export const createUrlWithQueryParams = (baseUrl: string, query: Record<string, string | undefined>) =>
  `${baseUrl}?${new URLSearchParams(shakeFalsy(query)).toString()}`

export const stringArrayToBooleanMap = (values: string[], defaultValue: boolean = false) =>
  Object.fromEntries(values.map((value) => [value, defaultValue]))

export const parseNumber = (strg: string, defaultTo: number = 0) => {
  let decimal = '.'
  strg = strg.replace(/[^0-9$.,]/g, '')
  if (strg.indexOf(',') > strg.indexOf('.')) decimal = ','
  if ((strg.match(new RegExp('\\' + decimal, 'g')) || []).length > 1) decimal = ''
  if (decimal != '' && strg.length - strg.indexOf(decimal) - 1 == 3 && strg.indexOf('0' + decimal) !== 0) decimal = ''
  strg = strg.replace(new RegExp('[^0-9$' + decimal + ']', 'g'), '')
  strg = strg.replace(',', '.')
  return parseFloat(strg) || defaultTo
}
export const parsePrice = (value: string) => {
  const commaIndex = value.lastIndexOf(',')
  const dotIndex = value.lastIndexOf('.')
  // use dot as decimal when
  // - dot but no comma in value
  // - dot and comma in value but comma index before dot inde
  const decimal = dotIndex > -1 && (commaIndex === -1 || commaIndex < dotIndex) ? '.' : ','
  // Build regex to strip out everything except digits, decimal point and minus sign:
  const regex = new RegExp('[^0-9-' + decimal + ']', 'g'),
    unformatted = parseFloat(
      ('' + value)
        .replace(/\((?=\d+)(.*)\)/, '-$1') // replace bracketed values with negatives
        .replace(regex, '') // strip out any cruft
        .replace(decimal, '.'), // make sure decimal point is standard
    )

  // This will fail silently which may cause trouble, let's wait and see:
  return !isNaN(unformatted) ? unformatted : 0
}

export const formatPrice = (price: number, currency = 'EUR') =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency,
  }).format(price)

/**
 * formats a number as percent string
 * @param percent as fraction (e.g. 0.5 for 50%)
 */
export const formatPercents = (percent: number) =>
  new Intl.NumberFormat('de-DE', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(percent)

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes == 0) return '0 Bytes'
  const k = 1024,
    dm = decimals,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const isValidUrl = (string: string) => {
  try {
    return Boolean(new URL(string))
  } catch (e) {
    return false
  }
}

export const parseJSONWithFallback = <T>(json: string, fallback: T) => {
  let result = fallback
  try {
    result = JSON.parse(json)
  } catch (e) {
    console.warn('could not parse', json)
  }
  return result
}

export const toDataURL = (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise<string>((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result as string)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        }),
    )

export const addressToString = ({ name, city, country, postalCode, address, contactPerson }: IAddress) =>
  `${name}, ${address}, ${postalCode ? postalCode : ''} ${city ? city : ''},  ${country} ${contactPerson ? '(' + contactPerson + ')' : ''}`

export const addImageFromUrl = async (newImageUrl: string) => {
  if (isValidUrl(newImageUrl)) {
    const { data } = await thorFormPostPromise<string>(`/_/UploadAsset/UploadAssetFromUrl`, {
      ImageUrl: decodeURI(newImageUrl),
    })
    return data as string
  } else {
    return ''
  }
}
export const addImageFromB64 = async (imageName: string, newImageUrl: string) => {
  if (isValidUrl(newImageUrl)) {
    const { data } = await thorFormPostPromise<string>(`/_/UploadAsset/UploadAssetFromB64`, {
      ImageUrl: newImageUrl,
      ImageName: imageName,
    })
    return data as string
  } else {
    return ''
  }
}
