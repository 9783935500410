<template lang="pug">
div(v-if='$permissions.canAccessAdminMenu')
  dropdown
    template(#default='{ toggle, visible }')
      btn(:active='visible' secondary admin icon='more-horizontal' @click='toggle')
    template(#content)
      dropdown-item(v-if='productId' @click='$utils.routeQueryAdd({ "form-products-edit": productId })')
        span {{ $t('Edit Product') }}
      dropdown-item(v-if='productId' @click='$utils.routeQueryAdd({ "form-product-images": productId })')
        span {{ $t('Edit Image') }}
      dropdown-item(v-if='modelId' @click='$utils.routeQueryAdd({ "form-models-edit": modelId })')
        span {{ $t('Edit Model') }}
      dropdown-item(v-if='brandId' @click='$utils.routeQueryAdd({ "form-brands-edit": brandId })')
        span {{ $t('Edit Brand') }}
</template>

<script lang="ts">
import Dropdown from './Dropdown.vue'

export default {
  components: { Dropdown },
  props: {
    productId: String,
    modelId: String,
    brandId: String,
  },
}
</script>
