<template lang="pug">
div(ref='container' style='--td-padding-t: 4px; --td-padding-b: 4px')
  popover-image-zoom-provider
  draggable-container(:active='allowEditProducts' type='multiselect' handleSelector='.handle' targetSelector='tr[data-id].group' @dragDropEnd='onDragDropEnd')
    custom-table(v-if='true')
      thead(class='whitespace-nowrap')
        th(style='width: 60px' class='font-mono relative text-xs text-grey-400') #
        th(style='width: 1%')
        th {{ $t('Product') }}
        th {{ $t('Comment') }}
        th(v-if='showVerifiedState') {{ $t('Status') }}
        th(style='width: 1%') {{ $t('Quantity') }}
        th(v-if='$permissions.canAccessAdminMenu && adminMode' class='text-nuucon') {{ $t('SupplierInfo') }}
        th(class='text-right' style='width: 1%') {{ $t('retailPriceNetString') }}
        th(style='width: 1%' class='text-right') {{ $t('retailPriceTotalNetString') }}
        th(style='width: 30px')

      tr(
        v-for='(item, index) in items'
        :key='item.productId'
        :data-id='item.productId'
        tabindex='0'
        :data-index='index'
        data-level='0'
        class='group [&>td>div]:inline-flex [&>td>div]:min-h-[58px] [&>td>div]:items-center'
      )
        td
          div(class='relative')
            div(class='font-mono relative text-xs text-grey-400' :class='{ "group-hover:opacity-0": allowEditProducts }') {{ index + 1 }}
            div(v-if='allowEditProducts' class='handle absolute -left-4 mr-2')
              icon(name='custom-handle')
        td(class='image sticky')
          project-product-image(:url='item.image' data-src-zoom-parent class='mr-2')
        td
          div(class='!flex flex-col !items-start')
            div(class='align-left text-sm text-text-light') {{ item.brand }}
            div(class='flex items-start gap-2')
              project-product-title(
                class='text-[15px] font-bold'
                :isPcon='!!item.pConInformation'
                :title='item.title'
                :productId='item.productId'
                :baseProductId='item.baseProductId'
                :externalProductUrl='item.externalProductUrl'
              )
              div(class='-mt-1 flex flex-wrap items-start gap-2')
                btn(v-if='item.isExternal' icon='custom-edit' tiny faded :data-tooltip='$t("Edit external product")' @click.stop='editExternalInquiryItem(item.productId)')
                template(v-else-if='item.pConInformation')
                  btn(icon='custom-edit' tiny faded :data-tooltip='$t("Configure product")' @click.stop='editPconProduct(item, projectId, inquiryId, undefined, !allowEditProducts)')
                  btn(
                    v-if='adminMode'
                    icon='custom-edit'
                    tiny
                    secondary
                    admin
                    :data-tooltip='$t("Name und Preis anpassen (pCon Produkt)")'
                    @click.stop='$modals.open("form-edit-pcon-properties-admin", { projectId, inquiryId, productId: item.productId })'
                  ) 
            project-product-description(v-if='item.isExternal || item.pConInformation' v-model='expanded[item.productId]' :description='item.description' :productNumber='item.productNumber')

        td(v-if='showVerifiedState')
          div(v-if='item.isExternal')
            pill(v-if='item.isVerified' type='success' icon='check' outlined) {{ $t('verified') }}
            pill(v-else type='faded' icon='info' outlined) {{ $t('not verified') }}
        td
          span(class='whitespace-pre-wrap text-sm italic text-text-light') {{ item.comment || '-' }}
        td
          div
            control-number(
              v-if='allowEditProducts'
              :id='item.productId'
              :debounce='0'
              :modelValue='quantityMap[item.productId] ?? item.quantity'
              size='small'
              class='max-w-[70px]'
              @update:modelValue='(quantity) => updateQuantity(quantity, item.productId)'
            )
            span(v-else) {{ item.quantity }}
        td(v-if='$permissions.canAccessAdminMenu && adminMode' class='text-nuucon' data-col-id='supplierInfo' tabindex='0' :class='{ editable: true }')
          div
            div(class='text-xs')
              b(class='whitespace-no-wrap') {{ item.supplierInfo.title }},
              span {{ $t('PP') }}: {{ item.supplierInfo.purchasingPrice }} ({{ $t('Discount') }}: {{ item.supplierInfo.discount }})
        td
          span(class='whitespace-no-wrap') {{ item.retailPriceNetString }}
        td
          div(class='whitespace-no-wrap font-bold' :class='{ "text-text-light": loadingQuantityMap[item.productId] }') {{ item.retailPriceTotalNetString }}
        td
          div
            btn(v-if='!adminMode && inquiryStatus === "accepted"' secondary small icon='custom-claim' @click='openClaimsForm(inquiryId, item.title, item.productId, 1)') {{ $t('Reklamieren') }}
            dropdown-options(
              tabindex='1'
              small
              faded
              :loading='loadingQuantityMap[item.productId]'
              data-test='btn-row-options'
              :data-test-btn-row-action='item?.productId'
              :data-tooltip='$t("Options")'
            )
              dropdown-item#configure-product(v-if='item.pConInformation' :disabled='!allowEditProducts' icon='edit' @click='editPconProduct(item, projectId, inquiryId)')
                span {{ $t('Configure product') }}
              dropdown-item#edit-external-products(v-if='item.isExternal' :disabled='!allowEditProducts' icon='custom-edit' @click='editExternalInquiryItem(item.productId)')
                span {{ $t('Edit external product') }}
              dropdown-item#choose-supplier(v-if='$permissions.canAccessAdminMenu && adminMode' class='!text-nuucon' icon='truck' @click='editProductSupplier(item.productId, item.retailPriceNet)')
                span {{ $t('Choose supplier') }}
              dropdown-item#remove-product(:disabled='!allowEditProducts' icon='trash' danger @click='removeProduct(item.productId)')
                span {{ $t('Remove from inquiry') }}
</template>
<script lang="ts" setup>
import { type IInquiryProduct, type InquiryStatus } from '../types'
import ControlNumber from '@/components/Control/ControlNumber.vue'
import { useDelegatedClickEventListener } from '@/composables/'
import { useInquiriesApi, useInquiryActions } from '@/pages/inquiries/composables'
import { useEditPconProduct, useQuantityHandling } from '@/pages/projects/composables'
import { useConfirm } from '@/plugins/Confirm'
import { useModals } from '@/plugins/Modals'
import { type PermissionsData } from '@/plugins/Permissions'
import { useTranslation } from '@/plugins/translation'
import { useLocalStorage } from '@vueuse/core'
import { type DragDropPayload } from 'dndrxjs'
import { computed, inject, onMounted, type PropType } from 'vue'
import { ref } from 'vue'

const props = defineProps({
  inquiryId: { type: String, required: true },
  projectId: { type: String, required: true },
  items: { type: Array as PropType<IInquiryProduct[]>, required: true },
  showVerifiedState: { type: Boolean, default: true },
  allowEditProducts: Boolean,
  adminMode: Boolean,
  inquiryStatus: { type: String as PropType<InquiryStatus>, required: true },
})
const { $t } = useTranslation()
const $permissions = inject<PermissionsData>('$permissions')!
const $modals = useModals()
const api = useInquiriesApi()
const { loadingQuantityMap, updateQuantity, quantityMap } = useQuantityHandling(
  computed(() => props.inquiryId),
  api.updateProductQuantity,
)
const { editPconProduct } = useEditPconProduct()

const expanded = useLocalStorage<Record<string, boolean>>(`text_expanded_${props.inquiryId}`, {})
const { openClaimsForm } = useInquiryActions()
const editExternalInquiryItem = (productId: string) =>
  $modals.open('form-edit-external-inquiry-item', {
    contextId: props.inquiryId,
    productId,
    inquiryStatus: props.inquiryStatus,
  })
const editProductSupplier = (productId: string, retailPriceNet?: number) =>
  $modals.open('form-select-product-supplier', {
    inquiryId: props.inquiryId,
    productId,
    priceNet: retailPriceNet || props.items?.find((inquiryItem) => inquiryItem.productId === productId)?.retailPriceNet,
    preSelectedSupplierId: props.items?.find((inquiryItem) => inquiryItem.productId === productId)?.supplierInfo
      .supplierId,
    inquiryStatus: props.inquiryStatus,
  })

const { confirm } = useConfirm()
const container = ref<HTMLElement | null>(null)
onMounted(() => {
  useDelegatedClickEventListener(
    container.value!,
    'td[data-col-id="supplierInfo"]',
    (target) => editProductSupplier(target.closest('tr')?.getAttribute('data-id') || ''),
    true,
  )
})

const removeProduct = (productId: string) =>
  confirm({
    title: $t('Are you sure you want to remove this product?'),
    onOk: () => updateQuantity(0, productId),
  })
const onDragDropEnd = (t: DragDropPayload) => {
  const currentId = t.dragElements[0]?.getAttribute?.('data-id')
  const targetId = t.dropElement?.getAttribute?.('data-id')
  const targetIndex = props.items?.map((i: IInquiryProduct) => i.productId).indexOf(targetId!)
  const currentIndex = props.items?.map((i: IInquiryProduct) => i.productId).indexOf(currentId!)
  const newIndex = targetIndex! + (t.position === 'before' ? -1 : 0) + (currentIndex! > targetIndex! ? 1 : 0)
  api.updateProductOrder(props.inquiryId, currentId!, newIndex).subscribe()
}
</script>
