<template lang="pug">
div(ref='container' class='project-list my-5')
  popover-image-zoom-provider
  slot(v-if='project' name='before' :project='project')
  template(v-if='project && $user.settings.tour')
    //- tour-wrapper#/projects/[id]/
  custom-table-editable-cell-text-delegated(selector='[data-col-id=\'comment\']' :getIdAndContentFn='getIdAndCommentFn' :loading='loadingComment' @submit='submitComment')
  dropdown-delegated(selector='[data-product-options-btn]' :getValue='getOptionsStateFromElement')
    template(#default='{ value }')
      template(v-if='value?.alternative')
        dropdown-item#configure-product(v-if='value.alternative.pConInformation' icon='edit' @click='editPconProduct(value?.alternative, id, undefined, value.product.productId)')
          | {{ $t('Configure product') }}
        dropdown-item#edit-external-products(v-if='value.alternative.isExternal' icon='custom-edit' @click='actions.editExternalProduct(id, value.alternative.productId, value.product.productId)')
          span {{ $t('Edit external product') }}
        dropdown-item#copy-product(icon='copy' @click='actions.copyProducts(id, value.alternative.productId)')
          span {{ $t('Add to project or inquiry') }}
        dropdown-item#set-favorite(icon='star' @click='store.setAlternativeAsFavorite(id, value.alternative.productId, value.product.productId)')
          span {{ $t('Set as favorite') }}
        dropdown-item#move-to-toplevel(icon='corner-up-left' @click='store.moveAlternativeToToplevel(id, value.alternative.productId, value.product.productId, value.index)')
          span {{ $t('Move to toplevel') }}
        dropdown-item#duplicate-product(v-if='value.alternative.isExternal' icon='copy' @click='store.copyExternalProduct(id, value.alternative.productId, value.product.productId)')
          span {{ $t('Duplicate product') }}
        dropdown-item#remove-product(icon='trash' danger @click='actions.removeAlternative(id, value.alternative.productId, value.product.productId)')
          span {{ $t('Remove from project') }}

      template(v-else-if='value?.product')
        dropdown-item#configure-product(v-if='value.product.pConInformation' icon='edit' @click='editPconProduct(value.product, id)')
          | {{ $t('Configure product') }}
        dropdown-item#edit-external-products(v-if='value.product.isExternal' icon='custom-edit' @click='actions.editExternalProduct(id, value.product.productId)')
          | {{ $t('Edit external product') }}
        dropdown-item#copy-products(icon='copy' @click='actions.copyProducts(id, value.product.productId)')
          | {{ $t('Add to project or inquiry') }}
        dropdown-item#edit-tags-products(icon='custom-list-edit' @click='actions.editTags(id, value.product.productId)')
          | {{ $t('Add/Remove Tags') }}
        dropdown-item#set-as-alternative(
          v-if='!value.product.alternatives || value.product.alternatives.length === 0'
          icon='corner-down-right'
          @click='actions.setAsAlternative(id, value.product.productId)'
        )
          | {{ $t('Set as alternative') }}
        dropdown-item#duplicate-product(v-if='value.product.isExternal' icon='copy' @click='store.copyExternalProduct(id, value.product.productId)')
          | {{ $t('Duplicate product') }}
        dropdown-item#remove-product(icon='trash' danger @click='actions.removeProducts(id, value.product.productId)')
          | {{ $t('Remove from project') }}

  project-items-empty-state(v-if='!project || filteredProducts.length === 0' :hasTag='!!tag' :projectId='id')
  draggable-container(v-else :dropPositionFn='dropPositionFn' :active='editable' type='multiselect' handleSelector='.handle' targetSelector='tr[data-id]' @dragDropEnd='onDragDropEnd')
    custom-table(v-if='true')
      thead(class='whitespace-nowrap')
        th(style='width: 10px')
          div(class='relative')
            div(class='font-mono relative text-xs text-grey-400' :class='{ "!absolute  mr-5 right-full ": editable }') #
            div(v-if='editable' class='flex w-full items-center justify-end')
              checkbox(class='mr-3' :modelValue='allSelected' :indeterminate='!allSelected && selected.length > 0' @update:modelValue='toggleSelectAll')
        th
        th {{ $t('Product') }}
        th {{ $t('Comment') }}
        th {{ $t('Tags') }}
        th(style='width: 1%') {{ $t('Quantity') }}
        th(class='text-right' style='width: 1%') {{ $t('retailPriceNetString') }}
        th(style='width: 1%' class='text-right') {{ $t('retailPriceTotalNetString') }}
        th(style='width: 30px')
      template(v-for='(item, index) in filteredProducts' :key='item.productId')
        tbody
          tr(
            :data-id='item.productId'
            tabindex='0'
            :data-selected='!!selectedMap[item.productId]'
            :data-index='index'
            data-level='0'
            :data-has-children='!!item.alternatives?.length'
            :data-expanded='item.alternatives?.length && !itemCollapsed[item.productId]'
            class='group'
          )
            td(style='width: 10px' class='relative')
              div(class='relative flex items-center')
                div(class='font-mono text-xs text-grey-400' :class='{ " absolute right-full mr-5  group-hover:opacity-0": editable }') 
                  span {{ index + 1 }}
                template(v-if='editable')
                  div(class='handle absolute right-full mr-2 flex h-full cursor-move items-center rounded p-2 opacity-0 mix-blend-multiply hover:bg-grey-50 group-hover:opacity-100')
                    icon(name='custom-handle' class='pointer-events-none')
                  checkbox-icon(:value='selectedMap[item.productId]' class='cursor-pointer')
            td(class='image sticky')
              project-product-image(:url='item.image' class='mr-3' data-src-zoom-parent)
            td(data-col-id='title' class='min-w-[30%] md:max-w-[20vmax]')
              //- not abstracted for perfomance reasons
              div(class='!flex flex-col !items-start justify-center')
                div(class='align-left text-sm text-text-light') {{ item.brand }}
                div(class='flex items-start gap-2')
                  project-product-title(
                    class='text-[14px] font-bold'
                    :isPcon='!!item.pConInformation'
                    :title='item.title'
                    :productId='item.productId'
                    :baseProductId='item.baseProductId'
                    :externalProductUrl='item.externalProductUrl'
                  )
                  div(class='-mt-[2px] flex flex-wrap items-start gap-2')
                    toggle-pill(
                      v-if='item?.alternatives?.length'
                      class='mr-2'
                      :modelValue='!itemCollapsed[item.productId]'
                      :data-tooltip='$t("Produktalternativen einblenden/ausblenden")'
                      @click='() => toggleCollapse(item.productId)'
                    )
                      span {{ item.alternatives.length }}
                    btn(
                      v-if='item.isExternal'
                      class='btn-edit-product'
                      icon='custom-edit'
                      tiny
                      faded
                      :data-tooltip='$t("Edit external product")'
                      @click.stop='actions.editExternalProduct(id, item.productId)'
                    )
                    btn(v-else-if='item.pConInformation' class='btn-edit-product' icon='custom-edit' tiny faded :data-tooltip='$t("Configure product")' @click.stop='editPconProduct(item, id)')
                    btn(
                      v-if='adminMode && item.pConInformation'
                      icon='custom-edit'
                      tiny
                      secondary
                      admin
                      :data-tooltip='$t("Name und Preis anpassen (pCon Produkt)")'
                      @click.stop='$modals.open("form-edit-pcon-properties-admin", { projectId: id, productId: item.productId })'
                    )
                    pill(v-if='!item.isAvailable || item.isActive === false' type='faded' icon='alert-circle' outlined ellipsis class='max-w-full') {{ $t('Product no longer available') }}
                div(class='whitespace-pre-wrap text-sm text-text-light')
                project-product-description(v-if='item.isExternal || item.pConInformation' v-model='expanded[item.productId]' :description='item.description' :productNumber='item.productNumber')

            td(data-col-id='comment' tabindex='0' :class='{ editable: editable }' class='md:max-w-[15vmax]' :data-col-edit-empty-message='$t("Add comment")')
              div(v-if='item.comment' class='whitespace-pre-wrap break-all text-sm italic text-text-light') {{ item.comment }}
            td(data-col-id='tags' tabindex='0' :class='{ editable: editable }' :data-col-edit-empty-message='$t("Add/Remove Tags")')
              span(v-if='item.tags.length')
                tag-list(:items='item.tags')
            td(data-col-id='quantity')
              div
                control-number(
                  :id='item.productId'
                  :modelValue='quantityMap[item.productId] ?? item.quantity'
                  :disabled='!item.isAvailable || !item.isActive'
                  :min='0'
                  size='small'
                  @update:modelValue='(quantity) => updateQuantity(quantity, item.productId)'
                )
            td(class='!pl-3 text-right' data-col-id='retailPriceNetString')
              span {{ item.retailPriceNetString }}
            td(data-col-id='retailPriceTotalNetString' class='text-right')
              span(v-if='item.isActive && item.isAvailable' class='font-bold') {{ item.retailPriceTotalNetString }}
              span(v-else class='font-bold text-text-light') {{ '0,00 €' }}
            td(class='')
              div
                btn(:loading='loadingQuantityMap[item.productId]' icon='more-horizontal' small faded :data-product-options-btn='item.productId' data-test='btn-row-options')
        tbody(v-if='item.alternatives.length && !itemCollapsed[item.productId]' :data-parent='item.productId' class='nested-tbody')
          tr
            td
          tr
            td
            td(colspan='9' class='nested-tbody__td-top')
          tr(
            v-for='(alternative, aIndex) in item.alternatives'
            :key='alternative.productId'
            :data-index='aIndex'
            :data-parent-id='item.productId'
            :data-parent-index='index'
            :data-id='alternative.productId'
            data-level='1'
            class='group'
          )
            td(class='relative')
              div(v-if='editable' class='handle r-full absolute mr-1 flex cursor-move rounded p-2 opacity-0 hover:bg-grey-50 group-hover:opacity-100')
                icon(name='custom-handle' class='pointer-events-none')
            td(class='image')
              project-product-image(:url='alternative.image' data-src-zoom-parent style='--project-product-image-size: 40px' class='mix-blend-multiply')
            td(class='min-w-[30%] md:max-w-[20vmax]')
              div(class='!flex flex-col !items-start justify-center')
                div(class='align-left text-sm text-text-light') {{ alternative.brand }}
                div(class='flex items-start gap-2')
                  project-product-title(
                    class='text-[14px] font-bold text-text-light'
                    :isPcon='!!alternative.pConInformation'
                    :title='alternative.title'
                    :productId='alternative.productId'
                    :baseProductId='alternative.baseProductId'
                    :externalProductUrl='alternative.externalProductUrl'
                  )
                  btn(
                    v-if='alternative.isExternal'
                    class='btn-edit-product'
                    icon='custom-edit'
                    tiny
                    faded
                    :data-tooltip='$t("Edit external product")'
                    @click.stop='actions.editExternalProduct(id, alternative.productId, item.productId)'
                  )
                  btn(
                    v-else-if='alternative.pConInformation'
                    class='btn-edit-product'
                    icon='custom-edit'
                    tiny
                    faded
                    :data-tooltip='$t("Configure product")'
                    @click.stop='editPconProduct(alternative, id, undefined, item.productId)'
                  )
                  pill(v-if='!alternative.isAvailable || alternative.isActive === false' type='faded' icon='alert-circle' outlined ellipsis class='max-w-full') {{ $t('Product no longer available') }}
                project-product-description(
                  v-if='alternative.isExternal || alternative.pConInformation'
                  v-model='expanded[alternative.productId]'
                  :description='alternative.description'
                  :productNumber='alternative.productNumber'
                )

            td(data-col-id='comment' tabindex='0' class='editable' :data-col-edit-empty-message='$t("Add comment")')
              div(v-if='alternative.comment') {{ alternative.comment }}
            td
            td
            td(class='text-right text-sm' data-col-id='retailPriceNetString')
              span {{ alternative.retailPriceNetString }}
            td(data-col-id='retailPriceTotalNetString' class='text-right')
              span(v-if='alternative.isAvailable' class='text-sm font-bold') {{ alternative.retailPriceTotalNetString }}
              span(v-else class='text-sm font-bold text-text-light') {{ '0,00 €' }}
            td(class='nested-tbody__td-last')
              btn(:loading='loadingQuantityMap[alternative.productId]' icon='more-horizontal' small faded :data-product-options-btn='item.productId' data-test='btn-row-options')
          tr
            td
            td(colspan='9' class='nested-tbody__td-bottom')
          tr
            td
  slot(name='after' :project='project')
</template>

<script setup lang="ts">
import { useEditPconProduct, useProject, useProjectProductDragDrop } from '../composables'
import { type IProjectProductItem } from '../types'
import { useDelegatedClickEventListener } from '@/composables/'
import { useSelectItems } from '@/composables/'
import { useQuantityHandling } from '@/pages/projects/composables'
import { useLocalStorage } from '@vueuse/core'
import { computed, ref, onMounted, watch } from 'vue'

// FOR DEBUGGING
// import { watchEffect } from 'vue'
// watchEffect(() => console.log('filteredProducts', filteredProducts.value))

const props = defineProps({
  id: { required: true, type: String },
  editable: { type: Boolean, default: true },
  tag: String,
  loadOnMount: Boolean,
  adminMode: { type: Boolean, default: false },
})
const selected = defineModel<string[]>({ default: () => [] })

const container = ref<HTMLElement | null>(null)

const loadingComment = computed(() => store.loading['addComment'])
const submitEditComment = (id: string, value: string, parentProductId: string = '') =>
  store.addComment(props.id, id, value, parentProductId)
const getIdAndCommentFn = (el: HTMLElement) => {
  const row = el.closest('[data-id]')
  const parentId = row?.getAttribute('data-parent-id')
  const id = row?.getAttribute('data-id')
  const product = parentId
    ? productsAsMap.value[parentId!].alternatives.find((item) => item.productId === id)
    : productsAsMap.value[id!]
  return { id: parentId ? `${id}__${parentId}` : (id as string), content: product?.comment || '' }
}

const submitComment = (concatenatedId: string, content: string) => {
  const [id, parentId] = concatenatedId.split('__')
  submitEditComment(id, content, parentId)
}

const { productsAsMap, filteredProducts, project, store, actions } = useProject(
  computed(() => props.id),
  selected,
  computed(() => props.tag!),
)

const getOptionsStateFromElement = (el: HTMLElement) => {
  const row = el.closest('[data-id]')
  const parentId = row?.getAttribute('data-parent-id')
  const id = row?.getAttribute('data-id')
  return {
    product: productsAsMap.value[parentId || id!],
    alternative: parentId ? productsAsMap.value[parentId!].alternatives.find((item) => item.productId === id) : null,
    index: parseInt(row?.getAttribute(parentId ? 'data-parent-index' : 'data-index') || '0'),
  } as { product: IProjectProductItem; alternative: IProjectProductItem | null; index: number }
}

onMounted(() => {
  if (props.editable) {
    useDelegatedClickEventListener(
      container.value!,
      'td[data-col-id="tags"]',
      (target) => actions.editTags(props.id, target.closest('tr[data-id]')!.getAttribute('data-id')!),
      true,
    )
  }
})

const itemCollapsed = useLocalStorage<Record<string, boolean>>(`project_collapsed_${props.id}`, {})
const collapse = (id: string) => (itemCollapsed.value[id] = true)
const expand = (id: string) => itemCollapsed.value[id] === true && (itemCollapsed.value[id] = false)
const toggleCollapse = (id: string) => (!itemCollapsed.value[id] ? collapse(id) : expand(id))

const expanded = useLocalStorage(`text_expanded_${props.id}`, {})
const { selectedMap, toggleSelectAll, allSelected } = useSelectItems(
  selected,
  container,
  '[data-id]:not([data-parent-id])',
)

const { loadingQuantityMap, updateQuantity, quantityMap } = useQuantityHandling(
  computed(() => props.id),
  store.updateProductQuantitySubscription,
)
// remove ids from selected when removed from products
watch(
  () => filteredProducts.value,
  () => (selected.value = selected.value.filter((id) => productsAsMap.value[id])),
)

// reset selected after ID change
watch(
  () => props.id,
  () => (selected.value = []),
)

if (props.loadOnMount) {
  store.loadProject(props.id)
}

const ready = ref(false)
onMounted(() => (ready.value = true))

const { editPconProduct } = useEditPconProduct()

const { dropPositionFn, onDragDropEnd } = useProjectProductDragDrop(
  computed(() => props.id),
  selected,
  computed(() => props.tag!),
  expand,
)
</script>
<style lang="stylus">

.project-list
  --td-padding-t: 4px
  --td-padding-b: 4px
  td
    position: relative
    vertical-align: top
    &.image
      img
        border-radius: 8px
    > span,
    > div
      min-height: 58px
      display: inline-flex
      align-items: center
  .list-filter-item
    & .icon
      display: none
    &:hover .icon
      display: inline-block

.btn-edit-product
  transition: none
  opacity: 0
  margin: -3px 0 -3px
  td:hover &
    opacity: 1

.nested-tbody
  position: relative
  --nest-tbody-bg: var(--grey-50)
  td
    border: 0!important
    &, a
      color: var(--grey-500)!important
    > span,
    > div
      min-height: 40px
  // removes border of row after nested body
  & + tbody > tr > td
    border: 0!important
  tr:not(:first-child):not(:last-child)
    td:not(:first-child):not(:last-child)
      background: var(--nest-tbody-bg)
  &__td-top,
  &__td-bottom
    padding: 0
    height: 10px
    position: relative
    background: transparent
    &:after
      display: block
      content: ''
      background: var(--nest-tbody-bg)
      position: absolute
      left: 0
      top: 0
      width: calc(100% - 15px)
      height: 10px
  &__td-top:after
    border-radius: 15px 15px 0px 0px
    border-top: 1px solid transparent
  &__td-bottom:after
    border-radius: 0 0 15px 15px
  &__td-last
    position: relative
    &:before
      display: block
      content: ''
      background: var(--nest-tbody-bg)
      position: absolute
      left: 0
      top: 0
      width: calc(100% - 15px)
      height: 100%
      z-index: 0
</style>
