<template lang="pug">
form-action(:name='disabledName' :hideWhenDisabled='true' danger)
form-action(:name='enabledName' :hideWhenDisabled='true' success)
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const FormActionSwitch = defineComponent({
  props: {
    enabledName: { type: String, required: true },
    disabledName: { type: String, required: true },
  },
})
export default FormActionSwitch
</script>
