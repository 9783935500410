<template lang="pug">
div(:data-disabled='disabled' class='control-input')
  div(class='control-input__inner')
    icon(v-if='icon' :name='icon' class='control-input__icon')
    slot
    btn(v-if='!disabled && showClear' icon='x' small tabIndex='-1' faded class='control-input__clear' @click='$emit("clear")')
  div(class='control-input__after')
    slot(name='after')
</template>

<script lang="ts">
import Btn from '@/components/Btn.vue'
import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

const ControlInputWrapper = defineComponent({
  components: { Btn, Icon },
  props: {
    icon: String,
    disabled: Boolean,
    showClear: Boolean,
  },
  setup() {
    return {
      focus,
    }
  },
})

export default ControlInputWrapper
</script>

<style lang="stylus">
@import '../../styles/variables.styl'
:root
  --control-input-icon-offset: 6px
  --control-input-icon-size: 20px

.control-input
  display: inline-flex
  width: 100%
  align-items: center
  position: relative
  background: var(--control-bg-color)
  border: var(--control-border-width) solid var(--control-border-color)
  border-radius: var(--control-border-radius)
  &[small], &[size="small"]
    --control-height: var(--control-height-small)
  &[large], &[size="large"]
    --control-height: var(--control-height-large)
  &[tiny]
    --control-height: var(--control-height-tiny)
  &[medium]
    --control-height: var(--control-height-medium)
  &[rounded]
   --control-border-radius: 50px
   --control-input-icon-offset: 8px
  &[grey]
    mix-blend-mode: multiply
    --control-bg-color: var(--grey-75)
    --control-border-color: var(--grey-75)
    &:hover
      --control-bg-color: var(--grey-100)
      --control-border-color: var(--grey-100)
    &:focus,
    &:focus-within
      --control-bg-color: white
  &[data-disabled="true"]
    pointer-events: none
    --control-bg-color: var(--grey-75)
    --control-text-color: var(--color-text-light)
    --control-border-color: var(--grey-75)
  &__after
    padding: 0 8px
    background: var(--grey-75)
    min-width: 30px
    align-self: stretch
    color: var(--color-text-light)
    display: flex
    justify-content: center
    align-items: center
    border-radius: 0 var(--control-border-radius) var(--control-border-radius) 0
    &:empty
      display: none
  &__inner
    position: relative
    display: flex
    align-items: center
    flex: 1
    min-width: 0
  &__clear
    position: absolute
    right: 2px
  &__icon
    position: absolute
    left: var(--control-input-icon-offset)
    color: var(--color-text-light)
    font-size: var(--control-input-icon-size)
    ~/:focus-within &
      color: var(--color-text)
  &:hover
    --control-border-color: var(--grey-200)
  [error] &
    --control-bg-color: white
    &, &:hover
      --control-border-color: var(--color-danger)
  &:focus,
  &:focus-within
    --control-border-color: var(--color-accent)!important
  input
    min-width: 0
    background: transparent
    height: var(--control-height)
    border: none
    border-radius: var(--control-border-radius)
    color: var(--control-text-color)
    padding-top: var(--control-padding-t)
    padding-left: var(--control-padding-x)
    padding-right: var(--control-padding-x)
    flex: 1
    font-size: var(--control-font-size)
    outline: 0!important
    [floating] &[type="date"]
      &[data-empty="true"]:not(:focus)
        opacity: 0!important
        &::placeholder, &::-webkit-datetime-edit
          color: transparent
    &:not(:first-child)
      padding-left: calc(var(--control-padding-x) + 24px)
    &:not(:last-child)
      padding-right: calc(var(--control-padding-x) + 30px)
</style>
