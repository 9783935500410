import { useFormItem } from './composables'
import { type INotification, type SetFormValueFunction } from './types'
import ControlLabel from '@/components/Control/ControlLabel.vue'
import type { IOption } from '@/types'
import { shakeFalsy } from '@/utilities'
import {
  type Component,
  computed,
  type ComputedRef,
  defineComponent,
  inject,
  nextTick,
  onMounted,
  type PropType,
  ref,
  type SetupContext,
  watch,
} from 'vue'
import { onUnmounted } from 'vue'

export interface TransformFunction {
  get: (v: any) => any
  set: (v: any) => any
}

export type FormControlValue = string | number | string[] | boolean | undefined

interface IFormControlComponentOptions {
  hiddenLabel?: boolean
  floating?: boolean
  floatOnFocus?: boolean
  initialValue?: any
  transform?: TransformFunction
  controlProps?: Record<string, any>
  component?: Component | string
}

export const createFormComponent = ({
  component,
  transform,
  initialValue,
  hiddenLabel,
  controlProps = {},
  floating = true,
  floatOnFocus = false,
}: IFormControlComponentOptions) =>
  defineComponent({
    components: { ControlLabel },
    props: {
      name: {
        type: String,
        required: true,
      },
      modelValue: { type: [String, Array, Boolean, Number] as PropType<FormControlValue>, default: undefined },
      option: Object as PropType<IOption>,
      markOptional: Boolean,
      forceValue: Boolean, // if true modelValue (if provided) will override config value initially
      autofocus: Boolean,
      noSubmit: Boolean,
      controlProps: Object,
      note: String,
      floating: { type: Boolean, default: undefined },
      label: { type: [String, Boolean], default: undefined },
      disabled: { type: Boolean, default: undefined },
    },
    emits: ['update:modelValue', 'change:modelValue', 'update:option'],
    setup(props, { emit }: SetupContext) {
      const { name } = props
      const { completeName, path, config } = useFormItem(name!)
      const setValue = inject<SetFormValueFunction>('setValue')
      const formData = inject<ComputedRef<Record<string, string>>>('formData')
      const transformGet = (value: FormControlValue) => (transform ? transform.get(value) : value)
      const formDataValue = computed(() => formData?.value?.[completeName!.value])
      const value = computed({
        get: () => transformGet(formDataValue.value ?? config.value?.value ?? (initialValue as FormControlValue)),
        set: (v: FormControlValue) => {
          setValue!(completeName.value, transform ? transform.set(v) : v)
          hasChanged.value = true
        },
      })

      const hasChanged = ref(false)
      const options = computed(() => config.value?.options || [])

      const change = (v: FormControlValue) => ((value.value = v), emit('change:modelValue', v))
      const update = (v: FormControlValue) => (
        (value.value = v),
        emit('update:modelValue', v),
        options.value.length &&
          emit(
            'update:option',
            options.value.find((o) => o.value === v),
          )
      )
      onMounted(() => {
        const configValueProvided = !!config.value?.value && config.value?.value.length !== 0
        if (configValueProvided && !props.forceValue) {
          emit('update:modelValue', config.value?.value)
          emit('change:modelValue', config.value?.value)
          value.value = config.value?.value
        } else if (props.modelValue !== undefined) {
          update(props.modelValue)
        }
      })

      onMounted(() =>
        watch(
          () => props.modelValue,
          (v: FormControlValue) => update(v),
        ),
      )
      onUnmounted(() => update(undefined))

      watch(
        () => config.value?.notifications,
        () => nextTick(() => (hasChanged.value = false)),
        { deep: true },
      )

      watch(
        () => config.value?.value,
        (v: FormControlValue) => v !== undefined && change(v),
      )

      const notifications = computed<INotification[]>(() => config?.value?.notifications || [])
      const hasError = computed(() => notifications.value.find(({ type }) => type === 'error')?.message)

      const extendedNotifications = computed<INotification[]>(() =>
        filteredNotifications.value.length
          ? filteredNotifications.value
          : props.note
            ? [{ type: 'info', message: props.note }]
            : [],
      )

      // custom behavior: remove error notifications when value has changed
      const filteredNotifications = computed<INotification[]>(() =>
        hasError.value && hasChanged.value
          ? notifications.value.filter(({ type }) => type !== 'error')
          : notifications.value,
      )

      return {
        component,
        value,
        config,
        completeName,
        hasChanged,
        controlPropsComputed: computed(() => ({ ...controlProps, ...props.controlProps })),
        options,
        change,
        update,
        id: computed(() => completeName.value?.replace(/\//g, '')),
        path,
        isDisabled: computed(() => props.disabled ?? config.value?.disabled),
        labelOptions: computed(() =>
          shakeFalsy({
            note: props.note,
            hiddenLabel,
            label: props.label ?? config?.value?.label,
            floating: props.floating ?? floating,
            floatOnFocus,
            optional: props.markOptional,
            disabled: props.disabled ?? config?.value?.disabled,
            error: !hasChanged?.value && hasError.value,
            notifications: extendedNotifications.value,
          }),
        ),
      }
    },
    template: `
      <div v-if='config'>
        <control-label v-bind='labelOptions'>
          <component :is='component' :autofocus='autofocus' :error='labelOptions.error' :noSubmit='noSubmit' :modelValue='value' @update:modelValue='update' @change:modelValue='change' :id='id' :disabled='isDisabled' :options='options' v-bind='controlPropsComputed'>
            <slot></slot>
            <span v-if='labelOptions.hiddenLabel'> {{labelOptions.label}}</span>
            <template v-for="(slot, index) of Object.keys($slots)" :key="index" v-slot:[slot]='data'>
              <slot :name='slot' v-bind='data' ></slot>
            </template>
          </component>
        </control-label>
      </div>
      `,
  })
