<template lang="pug">
div(:disabled='disabled' class='control-number')
  btn(faded tiny :disabled='disabled' icon='minus' tabindex='-1' data-test-btn='number-decrease' data-test='btn-number-decrease' @click='decrease')
  div(class='control-number__placeholder') {{ model }}
  input(ref='el' v-model.lazy='model' :min='min' :step='step' type='number' data-test='input-number' :disabled='disabled' class='control-number__input' @blur='submit' @keydown.enter='submit')
  btn(faded tiny :disabled='disabled' icon='plus' tabindex='-1' data-test-btn='number-increase' data-test='btn-number-increase' @click='increase')
</template>

<script setup lang="ts">
import Btn from '@/components/Btn.vue'

const props = defineProps({
  min: { type: Number, default: 1 },
  step: { type: Number, default: 1 },
  disabled: Boolean,
})

const model = defineModel<number>({ default: 0 })

const emit = defineEmits<{ 'change:modelValue': [value: number] }>()
const onChange = () => emit('change:modelValue', model.value)
const submit = () => ((!model.value || model.value <= props.min) && (model.value = props.min), onChange())
const increase = () => ((model.value = model.value + props.step), onChange())
const decrease = () => (
  (model.value = model.value > props.min + props.step ? model.value - props.step : props.min), onChange()
)
</script>
<style lang="stylus">
:root
  --control-number-font-size: 15px
  --control-number-icon-size: 14px
  --control-number-min-height: 34px
  --control-number-min-width: 60px
  --control-number-btn-width: 24px

.control-number
  position: relative
  width: auto
  display: inline-block
  text-align: center!important
  &[small]
    --control-number-icon-size: 12px
    --control-number-font-size: 14px
    --control-number-min-height: 22px
    --control-number-min-width: 42px
    --control-number-btn-width: 18px
    --control-bg-color: var(--grey-100)
    --control-border-color: var(--grey-100)
  [data-selected="true"] &
    mix-blend-mode: multiply
  &[disabled="true"]
    pointer-events: none
    --control-bg-color: var(--grey-75)
    --control-text-color: var(--color-text-light)
    --control-border-color: var(--grey-75)
  &:hover
    --control-border-color: var(--grey-200)
  &:focus-within, &:focus
    --control-border-color: var(--color-accent)
  &__placeholder
    padding-left: var(--control-number-btn-width)
    padding-right: var(--control-number-btn-width)
    min-width: var(--control-number-min-width)
    min-height: var(--control-number-min-height)
    opacity: 0
    font-size: var(--control-number-font-size)
  &__input
    font-size: var(--control-number-font-size)
    color: var(--control-text-color)
    background: var(--control-bg-color)
    border: var(--control-border-width) solid var(--control-border-color)
    border-radius: var(--control-border-radius)
    padding-left: 5px!important
    padding-right: 5px!important
    position: absolute!important
    top: 0
    left: 0
    width: 100%
    height: 100%
    text-align: center
    outline: none!important
    appearance: none
    -webkit-appearance: none
    -moz-appearance: textfield
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button
      opacity: 0
      display: none
  .btn
    position: absolute
    left: 0
    top: 2px
    height: calc(100% - 4px)
    z-index: 1
    border-radius: var(--control-border-radius)
    font-size: var(--control-number-icon-size)
    --btn-min-size: var(--control-number-btn-width)

    &:last-child
      left: unset
      right: 0
</style>
