<template lang="pug">
button(:class='{ "toggle-pill--expanded": modelValue }' class='toggle-pill' @click='$emit("update:modelValue", !modelValue)')
  div(class='toggle-pill__content')
    slot
  icon(:name='!modelValue ? "chevron-down" : "chevron-up"' class='toggle-pill__icon')
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

const TogglePill = defineComponent({
  components: { Icon },
  props: {
    modelValue: { type: Boolean, required: true },
  },
})
export default TogglePill
</script>
<style lang="stylus">
@import '../styles/variables.styl'

.toggle-pill
  border-radius: 20px
  padding: 4px 8px
  font-size: 12px
  line-height: 1
  letter-spacing: -0.005em
  display: inline-flex
  align-items: center
  justify-content: center
  mix-blend-mode: multiply
  background: var(--grey-700)
  color: var(--color-white)
  &:hover
    background: var(--grey-900)
    color: var(--color-white)
  &__icon
    font-size: 12px
    stroke-width: 2.75px
  &__content
    font-weight: 900
    padding-right: 2px
    padding-left: 2px
</style>
