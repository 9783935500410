import { type Placement } from '@floating-ui/core'
import { computePosition, offset, flip, shift, arrow } from '@floating-ui/dom'
import { useDebounceFn } from '@vueuse/core'
import { type Directive } from 'vue'

const createElements = (content: string) => {
  const tooltipElement = document.createElement('div')
  const tooltipInner = document.createElement('div')
  const tooltipArrow = document.createElement('div')
  tooltipElement.appendChild(tooltipInner)
  tooltipElement.appendChild(tooltipArrow)
  tooltipArrow.classList.add('tooltip-arrow')
  tooltipElement.classList.add('tooltip')
  tooltipInner.innerHTML = content
  return { tooltipArrow, tooltipElement, tooltipInner }
}

const show = (reference: HTMLElement, tooltipEl: HTMLElement, arrowEl: HTMLElement, placement: Placement = 'top') => {
  document.body.appendChild(tooltipEl)
  computePosition(reference, tooltipEl, {
    placement: placement,
    middleware: [offset(10), shift({ padding: 10 }), flip(), arrow({ element: arrowEl, padding: 5 })],
  }).then(({ x, y, middlewareData, placement }) => {
    Object.assign(tooltipEl.style, {
      top: `${y}px`,
      left: `${x}px`,
    })

    if (middlewareData.arrow && arrowEl) {
      const { x: arrowX, y: arrowY } = middlewareData.arrow
      const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
      }[placement.split('-')[0]] as string

      Object.assign(arrowEl.style, {
        left: arrowX != null ? `${arrowX}px` : '',
        top: arrowY != null ? `${arrowY}px` : '',
        right: '',
        bottom: '',
        [staticSide]: '-5px',
      })
    }
  })
}

const getPlacement = (element: HTMLElement) => {
  return (
    element.hasAttribute('data-tooltip-position') ? element.getAttribute('data-tooltip-position') : 'top'
  ) as Placement
}

const TooltipContainer: Directive = {
  mounted(el: HTMLElement): void {
    const tooltips: Map<HTMLElement, HTMLElement> = new Map()
    const removeTooltip = useDebounceFn(
      () => tooltips.forEach((tooltip, target) => !document.body.contains(target) && tooltip.remove()),
      20,
    )
    const observer = new MutationObserver(
      (mutationList) => mutationList.some((m) => m.removedNodes.length > 0) && removeTooltip(),
    )

    observer.observe(el, { childList: true, subtree: true })

    const handlerShow = (e: Event) => {
      const eventTarget = e.target as HTMLElement
      let target: HTMLElement | null = null

      if (eventTarget.hasAttribute('data-tooltip')) {
        target = eventTarget
      } else if (eventTarget.matches('[data-tooltip] *')) {
        target = eventTarget.closest('[data-tooltip]') as HTMLElement
      }
      if (target && !tooltips.get(target)) {
        const value = target.getAttribute('data-tooltip')!
        if (!value || value === 'false') {
          return
        }
        const { tooltipArrow, tooltipElement } = createElements(value)
        show(target, tooltipElement, tooltipArrow, getPlacement(target))
        tooltips.set(target, tooltipElement)
        const hide = () => {
          tooltips.delete(target!)
          tooltipElement.remove()
          target?.removeEventListener('blur', hide)
          target?.removeEventListener('mouseleave', hide)
        }
        target.addEventListener('mouseleave', hide, { once: true })
        target.addEventListener('blur', hide, { once: true, capture: true })
      }
    }
    el.addEventListener('mouseover', handlerShow)
    el.addEventListener('focus', handlerShow, true)
  },
}

export default TooltipContainer
