<template lang="pug">
div(class='relative')
  overlay(:visible='loading' class='rounded-xl')
  empty-state(v-if='error' type='danger' centered :message='$t("Das Produkt wurde leider nicht gefunden.")')
    template(#buttons)
      btn(secondary icon='message-square' @click='() => openIntercomChat($t("Es gibt ein Problem mit einem konfigurierbaren Produkt: {0}", "", [productId || pconInfoAsString]))') {{ $t('Kontaktiere uns') }}

  div(class='w-full md:flex' :class='{ "!hidden": error }')
    div(class='mb-3 min-w-0 flex-1 md:mb-0 md:pr-8')
      div(class='overflow-hidden rounded-xl bg-white')
        div(v-show='!selectedImage' class='relative')
          div#viewer(class='!children:w-full' style='height: var(--pcon-config-max-height, 70vh)')
          action-bar(class='!absolute left-0 top-0 w-full p-4')
            template(#left)
              control-select(v-if='articleOptions.length > 1' plain :options='articleOptions' :modelValue='currentArticleId' inline @update:modelValue='(id: any) => updateArticleId(id)')
            btn(icon='custom-dimensions' faded :active='showDimensions' :data-tooltip='$t(!showDimensions ? "Show Dimensions" : "Hide Dimensions")' @click='toggleDimensions') 
            btn(icon='rotate-cw' faded :data-tooltip='$t("reset perspective")' @click='resetCamera') 
            dropdown-options(faded icon='upload' ignoreContentClick :data-tooltip='$t("Exportieren")')
              dropdown-item(icon='custom-file-image' @click='downloadScreenshot') {{ $t('Screenshot (.png)') }}
              dropdown-item(icon='custom-file-obx' @click='downloadObx') {{ $t('pCon (.obx)') }}
              dropdown-item(icon='custom-file-dwg' @click='downloadFormat("DWG")') {{ $t('AutoCAD (.dwg)') }}
              dropdown-item(icon='custom-file-3ds' @click='downloadFormat("3DS")') {{ $t('Autodesk 3D Studio (.3ds)') }}
              dropdown-item(icon='custom-file-skp' @click='downloadFormat("SKP")') {{ $t('SketchUp (.skp)') }}
            dropdown-options(faded ignoreContentClick)
              dropdown-item(icon='upload' @click='open') {{ $t('pCon-Datei (.obx) importieren') }}
            btn(
              v-if='$permissions.canAccessAdminMenu && showCreateAndUploadAssetImageButton'
              icon='custom-camera'
              admin
              secondary
              :data-tooltip='$t("take picture")'
              @click='createAndUploadAssetImage'
            ) 
            slot(name='options')
        picture(v-if='selectedImage' v-image-lazy class='')
          source(:data-srcset='selectedImage' data-cloudinary='f_auto/t_md' media='(max-width: 600px)')
          img(:data-src='selectedImage' data-cloudinary='f_auto/t_xl' class='w-full object-contain sm:h-[70vh] xl:max-h-[950px]')
      div(v-if='images.length > 1' class='mt-2 grid grid-cols-[repeat(auto-fill,minmax(60px,0.5fr))] gap-2')
        btn-image(:active='selectedImage === ""' :data-tooltip='$t("Konfigurator Ansicht")' @click='() => (selectedImage = "")')
          icon(name='custom-rotate-3d' class='text-2xl')
        btn-image(v-for='(srcOrId, i) in images' :key='i' :src='srcOrId' :active='selectedImage === srcOrId' @click='() => (selectedImage = srcOrId)')
      slot(name='belowViewer' :articleProperties='articleProperties')

    div(class='relative flex flex-col rounded-lg md:w-[30%]' style='height: var(--pcon-config-max-height, unset); background: var(--properties-col-bg)')
      slot(name='title' :article='mainArticleProperties')
        template(v-if='catalogItem')
          h4 {{ catalogItem.label }}
        template(v-else-if='mainArticleProperties')
          h4 {{ mainArticleProperties.seriesName }} {{ mainArticleProperties.shortText }}
          p {{ mainArticleProperties.longText }}
          p {{ mainArticleProperties.featureText }}
      div#container(class='relative mt-2' :class='{ "flex-1 overflow-auto": stretchConfigColumn }')
        div(v-for='(propertiesGroup, key) in propertiesGrouped' :key='key')
          template(v-if='hasMultipleProperties')
            label(v-if='propertyClassesAsMap[key]' class='mb-2 ml-1 mt-4 block text-sm font-bold') {{ propertyClassesAsMap[key].getName() }}
            label(v-else class='mb-2 ml-1 mt-4 block text-sm font-bold') {{ $t('Settings') }}
          btn-group(vertical)
            template(v-for='property in propertiesGroup' :key='property')
              dropdown(teleportTarget='body' fitTriggerWidth maxWidth='100%')
                template(#default='{ toggle }')
                  btn(secondary :disabled='!property.editable || disabled' @click='property.choiceList && toggle($event), onClickProperty(property)')
                    div(class='flex w-full')
                      div(class='flex flex-1 flex-col')
                        div(class='mb-1 text-text-light') {{ property.name }}
                        div {{ property.getValue()?.text }}
                      div(v-if='property && property.getValue() && property.getValue().largeIcon' class='flex flex-col')
                        img(:src='property.getValue().largeIcon' class='w-[40px] rounded mix-blend-multiply')
                        //- img(:src='checkImage(property.getValue().largeIcon)' class='w-[40px] rounded mix-blend-multiply')
                template(#content)
                  div(v-if='property.choiceList && choices[property.key] && choices[property.key].length > 0')
                    dropdown-item(v-for='choice in choices[property.key]' :key='choice.value' :active='isChoiceSelected(choice, property)' @click='selectChoice(choice, property)')
                      div(class='flex-1' :class='{ "font-bold": isChoiceSelected(choice, property) }') {{ choice.text }}
                      div
                        img(v-if='choice.largeIcon' :src='choice.largeIcon' class='w-[40px] rounded mix-blend-multiply')
                        img(v-else-if='choice.smallIcon' :src='choice.smallIcon' class='w-[40px] rounded mix-blend-multiply')
      sticky-container(position='bottom' :enabled='breakpoints.md' style='--sticky-bg: var(--color-body)')
        div(class='my-2')
          div(v-if='!loading')
            div(class='font-bold') {{ priceNetFormatted }}

            span(class='text-sm') {{ $t('UVP net') }} ({{ $t(`excl. {0}% VAT`, '', [$env.vat]) }})
          slot(:loading='loading' :articleProperties='articleProperties')
</template>

<script setup lang="ts">
import { useCustomBreakpoints } from './composables'
import { usePconApplication } from './composables/pcon'
import { useConfirm } from './plugins/Confirm'
import type { IOption, IPconInformation } from './types'
import { useThirdPartyTools } from './utilities/ThirdParty'
import { formatPrice, toDataURL } from '@/utilities'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import * as cf from '@easterngraphics/wcf/modules/cf'
import { importPecFromCatalog } from '@easterngraphics/wcf/modules/cf/io'
import { SceneElement } from '@easterngraphics/wcf/modules/core/mdl'
import {
  PropertyChangedResult,
  Property,
  PropertyClass,
  PropertyValue,
  NumberProperty,
} from '@easterngraphics/wcf/modules/core/prop'
import { ShadowMapping, ShadowPlane } from '@easterngraphics/wcf/modules/core/rendering'
import * as tool from '@easterngraphics/wcf/modules/core/tool'
import * as basket from '@easterngraphics/wcf/modules/eaiws/basket'
import { type CatalogItem, type ArticleCatalogItem } from '@easterngraphics/wcf/modules/eaiws/catalog'
import { useFileDialog } from '@vueuse/core'
import Axios from 'axios'
import { saveAs } from 'file-saver'
import { objectify, group } from 'radash'
import { onBeforeMount, toRaw, computed, ref, watch, type PropType } from 'vue'

type PartialArticleProperties = Pick<
  basket.ArticleProperties,
  | 'shortText'
  | 'longText'
  | 'salesCurrency'
  | 'seriesName'
  | 'catalogId'
  | 'ofmlVariantCode'
  | 'featureText'
  | 'salesPrice'
  | 'manufacturerId'
  | 'manufacturerName'
  | 'finalArticleNumber'
  | 'baseArticleNumber'
  | 'seriesId'
>

/**
 * Property names that should be hidden
 */
const PROPERTY_HIDE_LIST = [
  'Lieferung', // used by König + Neurath
]

const props = defineProps({
  productId: String,
  disabled: Boolean,
  stretchConfigColumn: Boolean,
  showCreateAndUploadAssetImageButton: Boolean,
  disableCameraPanning: Boolean,
  autoCaptureImage: Boolean,
  limitCameraDistanceByElementRadius: { type: Boolean, default: true },
  showMetaProperties: { type: Boolean, default: true },
  images: { type: Array as PropType<string[]>, default: () => [] },
  catalogItem: {
    type: Object as PropType<CatalogItem | ArticleCatalogItem | null>,
  },
  pconInfo: {
    type: Object as PropType<IPconInformation>,
  },
  name: String,
})

const { openIntercomChat } = useThirdPartyTools()
const breakpoints = useCustomBreakpoints()
const selectedImage = ref('')
const loading = ref(true)
const error = ref(false)
const confirm = useConfirm()
const subArticleElements = ref<cf.ArticleElement[]>([])
const basketItems = ref<basket.BasketItem[]>([])
const subArticleElementsMap = computed<Record<string, cf.ArticleElement>>(() =>
  objectify(subArticleElements.value, (item) => item.basketId!),
)
const currentArticleId = ref('')
const updateArticleId = (basketId: string) =>
  subArticleElementsMap.value[basketId]
    ? coreApp.model.setSubElementSelection(coreApp.model.selection[0] as any, subArticleElementsMap.value[basketId])
    : coreApp.model.setSelection(coreApp.model.selection)

const articleOptions = computed<IOption[]>(() =>
  basketItems.value
    .filter((item) => item.itemType !== 'Folder')
    .map((item) => ({ label: item.label, value: item.itemId })),
)

const {
  coreApp,
  addArticle,
  articleManager,
  priceNet,
  removeAllElements,
  updatePriceNet,
  resetCameraPosition,
  clearBasket,
  session,
  importFromObx,
  getPconDataForCurrentBasketItem,
  init,
} = usePconApplication()

// Handle single obx upload
const { open, onChange } = useFileDialog({ accept: '.obx' })
onChange((files) => {
  const reader = new FileReader()
  reader.onload = async () => importFromObx(reader.result as ArrayBuffer)
  if (files) {
    reader.readAsArrayBuffer(files[0])
  }
})

onBeforeMount(async () => {
  await init()

  // init eventhandler for changed properties
  const propertyProvider = coreApp.model.selectionProperties
  propertyProvider.eventPropertiesChanged.addListener(async (result?: PropertyChangedResult) => {
    if (result === PropertyChangedResult.Nothing) {
      return
    }
    loading.value = true
    try {
      propertyClasses.value = await propertyProvider.getPropertyClasses()
      properties.value = await propertyProvider.getProperties()
      const mainArticles = articleManager.value!.getAllMainArticles().map((a) => toRaw(a))
      const itemProperties = await Promise.all(mainArticles.map((article) => article.getItemProperties()))

      articleProperties.value = itemProperties.map((p) => p.article!)
      await updatePriceNet()

      subArticleElements.value = coreApp.model.elements.flatMap(
        (e) => ((e as cf.MainArticleElement).getSubArticles(false) || []) as cf.ArticleElement[],
      )

      basketItems.value = await session.basket.getAllItems()
      const selectedSubArticleElement = coreApp.model.getSubElementSelection(
        coreApp.model.selection[0] as any,
      ) as cf.ArticleElement

      // @TODO: check typing
      currentArticleId.value = selectedSubArticleElement?.basketId || (coreApp.model.selection[0] as any)?.basketId

      selectedImage.value = ''
    } catch (e) {
      console.warn(e)
    }
    loading.value = false
  })

  // init tools
  const defaultTool: tool.SelectionDefault = new tool.SelectionDefault(coreApp)
  defaultTool.deselectionEnabled = false
  defaultTool.showMainElementSelection = false
  defaultTool.showElementInteractors = true
  coreApp.tools.defaultTool = defaultTool
  coreApp.tools.startDefaultTool()

  // add shadows
  coreApp.rendering.addShadowGenerator(new ShadowPlane(coreApp)) // shadows on the floor
  coreApp.rendering.addShadowGenerator(new ShadowMapping(coreApp)) // (directional) shadows on the objects

  await clearBasket()
  // initialize pcon info from props
  if (props.pconInfo) {
    await addArticleAndUpdate(props.pconInfo)
  } else if (props.catalogItem) {
    await addCatalogItemAndUpdate(props.catalogItem!)
  }

  // @TODO: implement actual solution in the ui to handle updateStates ('Migratable',  'Updatatable')
  //
  // const updateStates = await Promise.all(
  //   articleManager.value!.getAllMainArticles().map((article) => article.getOfmlUpdateState()),
  // )
  // await Promise.all(
  //   mainArticles.map(async (article) => {
  //     const updateState = await article.getOfmlUpdateState()
  //     if (updateState === 'Updatable' || updateState === 'Migratable') {
  //       await article.updateOfmlArticle(true, true)
  //     }
  //     await sleep(200)
  //   }),
  // )

  watch(
    () => props.pconInfo,
    async (pconInfo) => {
      await removeAllElements()
      addArticleAndUpdate(pconInfo!)
    },
  )

  if (props.autoCaptureImage) {
    createAndUploadAssetImage()
  }

  coreApp.viewer.eventBeforeRender.addListener(() => (hasChanged.value = true))
})

defineExpose({ getPconDataForCurrentBasketItem })

const properties = ref<Property[]>([])
const propertyClasses = ref<PropertyClass[]>([])
const articleProperties = ref<PartialArticleProperties[]>([])
const mainArticleProperties = computed(() => articleProperties.value[0] || undefined)
const propertiesFiltered = computed(() =>
  props.showMetaProperties
    ? properties.value.filter((p) => p.visible && !PROPERTY_HIDE_LIST.includes(p.name!))
    : properties.value.filter(
        (p) => p.visible && !PROPERTY_HIDE_LIST.includes(p.name!) && !p.class?.toLocaleLowerCase().includes('meta'),
      ),
)
const propertiesAsMap = computed(() => objectify(properties.value, (property) => property.key))
const propertiesGrouped = computed(() => group(propertiesFiltered.value, (property) => property.class || ''))
const propertyClassesAsMap = computed(() => objectify(propertyClasses.value, (propertyClass) => propertyClass.key))
const choices = ref<Record<string, PropertyValue[]>>({})
const hasChanged = ref(false)
const showDimensions = ref(false)

if (props.disableCameraPanning) {
  const cameraControl = coreApp.viewer.view.cameraControl
  cameraControl.panningEnabled = false
  cameraControl.setFixedTarget(Vector3.Zero())
  cameraControl.dblClickZoomToFitOptions.adjustFixedTarget = false
  cameraControl.orbitInertia = 0.95 // uncomment if you want some movement after the user releases mouse button
}

/**
 * Get all choices for property key
 * @param propertyKey
 */
const getChoices = async (propertyKey: string) =>
  // test if any properties changed and if so, we need to update the choices
  // @TODO: hasChanged is not working correctly, because we need to check if one of the properties has changed
  hasChanged.value || !choices.value[propertyKey]
    ? (choices.value[propertyKey] = (await propertiesAsMap.value[propertyKey]?.getChoices()) || ([] as PropertyValue[]))
    : choices.value[propertyKey]

const resetCamera = () => {
  resetCameraPosition()
  setTimeout(() => (hasChanged.value = false), 100)
}
const loadingCreateAndUploadAssetImage = ref(false)

/**
 * Create asset image and upload to assets
 */
const createAndUploadAssetImage = async () => {
  loadingCreateAndUploadAssetImage.value = true
  const blob: Blob = await coreApp.viewer.createScreenshot({ width: 2000, height: 1600 }, true)
  const formData = new FormData()
  formData.append('Asset', blob!)
  Axios.post(`/Administration/Products/${props.productId}/UploadAsset`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(() => (loadingCreateAndUploadAssetImage.value = false))
}
let element: cf.MainArticleElement | null = null

const downloadScreenshot = async () => {
  const blob: Blob = await coreApp.viewer.createScreenshot({ width: 1000, height: 800 }, true)
  saveAs(blob, props.pconInfo?.baseArticleNumber || props.productId)
}

const downloadObx = async () => {
  const maybeObxUrl = await articleManager.value!.exportObx(articleManager.value!.getAllMainArticles())
  saveAs(maybeObxUrl, (props.pconInfo?.baseArticleNumber || props.productId) + '.obx')
}

const downloadFormat = async (format: '3DS' | 'DWG' | 'DXF' | 'SKP') => {
  const url = await getExportUrl(format)
  if (url) {
    window.location.href = url
  }
}

/**
 * Returns export URL for current configuration.
 */
const getExportUrl = async (format: '3DS' | 'DWG' | 'DXF' | 'SKP'): Promise<string | undefined> => {
  loading.value = true
  let exportOptions: Array<string> | null = null
  switch (format) {
    case 'DWG':
      exportOptions = ['format=DWG', 'hideSubArticles=false', 'no2D=true', 'textures=true', 'materials=true']
      break

    case 'DXF':
      exportOptions = [
        'format=DWG',
        'dxf=true',
        'hideSubArticles=false',
        'no2D=true',
        'textures=true',
        'materials=true',
      ]
      break

    case '3DS':
      exportOptions = ['format=3DS', 'hideSubArticles=false', 'textures=true']
      break

    case 'SKP':
      exportOptions = ['format=SKP', 'hideSubArticles=false', 'no2D=true', 'textures=false', 'textureToColor=true']
      break
  }
  try {
    const articles: Array<cf.MainArticleElement> = articleManager.value!.getAllMainArticles()
    // export obx
    const obxUrl: string = await articleManager.value!.exportObx(articles)

    // create temporary set article
    const folderId: string = await articleManager.value!.session.basket.insertFolder(null, null, 'Set')
    const setId: string = await articleManager.value!.session.basket.convertToSetArticle(folderId)

    // insert obx into set article
    const pastedIds: Array<string> = await articleManager.value!.session.basket.paste(setId, null, obxUrl)

    // add to set article
    if (pastedIds != null) {
      await articleManager.value!.session.basket.addToSetArticle(pastedIds)

      // export geometry
      const exportUrl: string = await articleManager.value!.session.basket.getExportedGeometry(setId, exportOptions)

      // delete temporary set
      const options: basket.DeleteItemsOptions = new basket.DeleteItemsOptions()
      options.subItems = true
      await articleManager.value!.session.basket.deleteItems([setId], options)

      // delete temporary obx
      await articleManager.value!.session.deleteFile(obxUrl)
      return exportUrl
    }
  } catch (error) {
    console.error('Failed to export geometry. Error: ', error)
  } finally {
    loading.value = false
  }
  return undefined
}

/**
 * Add article from PconInformation
 * @param data - IPconInformation
 */
const addArticleAndUpdate = async (data: IPconInformation) => {
  loading.value = true
  error.value = false
  try {
    const elements = await addArticle(data)
    elements.forEach((e) => (e.showDimensions = showDimensions.value))
    resetCamera()
    const itemProperties = await Promise.all(
      articleManager.value!.getAllMainArticles().map((article) => article.getItemProperties()),
    )
    articleProperties.value = itemProperties.map((p) => p.article!)
    await updatePriceNet()
  } catch (e) {
    const errorAsString = JSON.stringify(e)
    if (errorAsString.includes('ArticleDataNotFoundException')) {
      error.value = true
    }
    console.error(e)
  }
  loading.value = false
}

/**
 * Add article or container from CatalogItem
 */
const addCatalogItemAndUpdate = async (item: CatalogItem | ArticleCatalogItem) => {
  loading.value = true
  if (item.type === 'Article') {
    element = await articleManager.value!.insertArticle(item as ArticleCatalogItem)
    coreApp.model.addElement(element)
    element.showDimensions = showDimensions.value
    coreApp.model.setSelection([element])
  } else if (item.type === 'Container') {
    const elements: Array<SceneElement> = await importPecFromCatalog(articleManager.value! as cf.ArticleManager, item)
    elements.forEach((element) => coreApp.model.addElement(element))
    coreApp.model.setSelection([elements[0]])
    if (coreApp.tools.defaultTool instanceof tool.SelectionDefault) {
      coreApp.tools.defaultTool.showMainElementSelection = true
      coreApp.tools.defaultTool.deselectionEnabled = true
    }
  }

  resetCamera()
  await updatePriceNet()
  loading.value = false
}

const onClickProperty = async (property: Property) => {
  if (!property.choiceList && property.type === 2) {
    const numberProperty = property as NumberProperty
    const promptLabel = `min: ${numberProperty.minValue} – max: ${numberProperty.maxValue}`
    const userInput: string | number | undefined = await confirm.prompt(
      property.getName(),
      promptLabel,
      parseFloat(property.getValueAsString() || '') || '',
      { min: numberProperty.minValue, max: numberProperty.maxValue },
    )
    if (!!userInput) {
      await property.setValue(userInput)
    }
  } else {
    getChoices(property.key)
  }
}
const priceNetFormatted = computed(() => formatPrice(priceNet.value, 'EUR'))
const toggleDimensions = () => {
  showDimensions.value = !showDimensions.value
  try {
    element!.showDimensions = showDimensions.value
  } catch (e) {}
}
const hasMultipleProperties = computed(() => Object.values(propertiesGrouped.value).length > 1)

const selectChoice = async (choice: PropertyValue, property: Property) => {
  if (choice.value !== property.getValue()?.value) {
    try {
      await property.setValue(choice.value)
    } catch (e) {
      console.error(e)
    }
  }
}
const isChoiceSelected = (choice: PropertyValue, property: Property) => choice.value === property.getValue()?.value

const pconInfoAsString = computed(() => (props.pconInfo ? Object.values(props.pconInfo).join(', ') : ''))
</script>
