<template lang="pug">
slot(name='default' :setRef='setRef' :toggle='toggle' :visible='visible')
teleport(v-if='visible' :to='teleportTarget')
  div(ref='content' v-focus-trap='focusTrap && visible' :style='styles' class='dropdown-content')
    div(class='dropdown-content__inner')
      slot(name='content' :toggle='toggle' :close='close' :visible='visible')
    div(class='dropdown-content__footer')
      slot(name='footer' :toggle='toggle' :close='close' :visible='visible')
</template>

<script setup lang="ts">
import { useDelegatedClickEventListener } from '@/composables/'
import { useEscapeKey } from '@/plugins/EscapeKey'
import { type Placement } from '@floating-ui/core'
import { useFloating, autoPlacement, shift, offset as floatingOffset, autoUpdate, hide, size } from '@floating-ui/vue'
import { onClickOutside } from '@vueuse/core'
import { ref, computed, onMounted, type PropType } from 'vue'
import { type ComponentPublicInstance } from 'vue'
import { watch } from 'vue'

const props = defineProps({
  teleportTarget: { type: String, default: '#content, body' },
  position: { type: String as PropType<Placement> },
  offset: { type: Number, default: 4 },
  padding: { type: Number, default: 0 },
  canFlip: { type: Boolean, default: true },
  focusTrap: { type: Boolean, default: true },
  maxWidth: { type: String, default: '400px' },
  minWidth: { type: String, default: 'initial' },
  selector: String,
  fitTriggerWidth: Boolean,
  ignoreContentClick: Boolean,
  container: String,
  trigger: Element,
})

const emit = defineEmits<{ clickOutside: [e: PointerEvent | FocusEvent, target: null | HTMLElement] }>()
const content = ref<null | HTMLElement>(null)
const target = ref<null | HTMLElement>(null)
const visible = defineModel<boolean>({ default: false })
const actualTarget = computed(() => props.trigger || target.value)

let maxHeight = ''
const { floatingStyles, update } = useFloating(actualTarget, content, {
  whileElementsMounted: autoUpdate,
  ...(props.position ? { placement: props.position as Placement } : {}),
  middleware: [
    ...(!props.position
      ? [autoPlacement({ allowedPlacements: ['bottom-start', 'bottom-end', 'top-end', 'top-start'] })]
      : []),
    shift(),
    floatingOffset(props.offset),
    size({
      apply({ availableHeight }) {
        maxHeight = `${availableHeight}px`
      },
    }),
  ],
})

useEscapeKey(() => (visible.value ? ((visible.value = false), true) : false))

watch(
  () => target.value,
  (t) => !t && hide(),
)

onMounted(() =>
  onClickOutside(
    target,
    (e: PointerEvent | FocusEvent) => ((visible.value = false), emit('clickOutside', e, target.value)),
    {
      detectIframe: true,
      ignore: props.ignoreContentClick ? [content] : [],
    },
  ),
)

// add/remove active class to target
watch(
  () => visible.value,
  (v) => target.value?.classList[v ? 'add' : 'remove']('active'),
)

const toggle = (event: PointerEvent) => {
  if (event?.target) {
    setRef((event.target as Element).closest('a, button, [role="button"]'))
  }
  if (!visible.value && props.fitTriggerWidth && target.value) {
    width.value = `${target.value.getBoundingClientRect().width}px`
  }
  visible.value = !visible.value
}

if (props.selector) {
  useDelegatedClickEventListener(
    document.body as HTMLElement,
    props.selector,
    (_el, event) => toggle(event as PointerEvent),
    true,
  )
}

const setRef = (ref: ComponentPublicInstance | null | Element) => {
  target.value = ref ? ((ref as ComponentPublicInstance)?.$el as HTMLElement) || (ref as HTMLElement) : null
}

const width = ref('auto')
const close = () => (visible.value = false)
const styles = computed(() => ({
  ...floatingStyles.value,
  maxHeight,
  '--dropdown-max-width': props.maxWidth,
  '--dropdown-width': width.value,
  '--dropdown-min-width': props.minWidth,
}))

defineExpose({ toggle, setRef, close, update })
</script>

<style lang="stylus">
@import '../styles/variables.styl'
:root
  --dropdown-padding-x: 15px
  --dropdown-max-height: 60vh
  --dropdown-menu-border-radius: 8px
.dropdown-content
  position: absolute
  top: 0
  left: 0
  background: $color-white
  box-shadow: $shadow-intense
  border-radius: var(--dropdown-menu-border-radius)
  min-width:  var(--dropdown-min-width)
  max-width: var(--dropdown-max-width)
  width: var(--dropdown-width)
  z-index: 9999
  border: 1px solid #eee
  display: flex
  flex-flow: column
  &__inner
    max-height: var(--dropdown-max-height)
    overflow: auto
    flex-grow: 1
    display: flex
    flex-flow: column
</style>
