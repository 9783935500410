import { mapEntries } from 'radash'
import { type App, type Component, type Directive } from 'vue'

export const registerComponents = (app: App, componentsMap: Record<string, Component>) => {
  Object.entries(componentsMap)
    .filter(([a, b]) => a && a !== 'default' && typeof b === 'object')
    .forEach(([key, component]) => app.component(key, component))
}

export const registerDirectives = (app: App, directivesMap: Record<string, Directive>) => {
  Object.entries(directivesMap)
    .filter(([a, b]) => a && a !== 'default' && typeof b === 'object')
    .forEach(([key, value]) => app.directive(key, value))
}

export const registerComponentsFromModules = (app: App, modules: Record<string, any>) =>
  registerComponents(
    app,
    mapEntries(modules, (path: string) => [path.split('/').pop()!.split('.')[0], modules[path].default]),
  )
