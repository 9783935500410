<template lang="pug">
div(v-if='config && config.value' class='form-plain-text')
  div(class='form-plain-text__label') {{ config.label }}:
  div(class='form-plain-text__value') {{ config.value }}
</template>

<script lang="ts">
import { useFormItem } from './composables'
import { defineComponent } from 'vue'

const FormFieldPlainText = defineComponent({
  props: { name: { type: String, required: true } },
  setup(props) {
    const { config } = useFormItem(props.name)
    return {
      config,
    }
  },
})
export default FormFieldPlainText
</script>
<style lang="stylus">
@import '../../styles/variables.styl'
.form-plain-text
  display: inline-flex
  flex-wrap: wrap
  &__label,
  &__value
    line-height: 16px
  &__value
    font-size: 15px
  &__label
    font-size: 12px
    font-weight: normal
    color: var(--grey-400)
    text-transform: uppercase
    letter-spacing: 0.1em
    font-weight: 300
    margin-right: 5px
</style>
