<template lang="pug">
span(:data-tooltip='tooltipContent')
  slot(:text='timeAgo') {{ timeAgo }}
</template>
<script lang="ts">
import { useTranslatedTimeAgo } from '@/composables/'
import { useDateFormat } from '@vueuse/core'
import { defineComponent, computed } from 'vue'

const DateTooltip = defineComponent({
  props: {
    dateAsString: String,
    prefix: { type: String, default: '' },
    formatStr: { type: String, default: 'dddd, DD. MMMM, YYYY' },
    pattern: { type: RegExp, default: /(\d{2})\.(\d{2})\.(\d{4})\,\s(\d{2})\:(\d{2})/ },
  },
  setup(props) {
    const date = new Date(props.dateAsString!.replace(props.pattern, '$3-$2-$1T$4:$5').trim())
    const timeAgo = useTranslatedTimeAgo(date)
    const formattedDate = useDateFormat(date, props.formatStr)
    return {
      tooltipContent: computed(() =>
        !props.prefix ? formattedDate.value : `<small>${props.prefix}:</small> ${formattedDate.value}`,
      ),
      timeAgo,
    }
  },
})
export default DateTooltip
</script>
