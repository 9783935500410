<template lang="pug">
dropdown(ref='dropdown' ignoreContentClick) 
  template(#default='{ toggle, visible }')
    btn-dropdown(no-radius secondary small :active='visible' :value='value' :disabled='disabled' data-test='btn-dropdown-filter' @click='toggle') {{ label }}
  template(#content)
    slot
  template(#footer)
    slot(name='footer')
</template>

<script lang="ts">
import BtnDropdown from './BtnDropdown.vue'
import Dropdown from './Dropdown.vue'
import { defineComponent } from 'vue'

const DropdownFilter = defineComponent({
  components: { Dropdown, BtnDropdown },
  props: {
    label: String,
    id: String,
    value: [String, Number, Boolean],
    disabled: Boolean,
  },
  setup() {},
})
export default DropdownFilter
</script>
