import { onKeyStroke } from '@vueuse/core'
import { type App, type Plugin, inject, onUnmounted, ref } from 'vue'

export type EscapeKeyCallback = () => boolean
export interface IEscapeKeyFunctions {
  add: (callback: EscapeKeyCallback) => any
  remove: (callback: EscapeKeyCallback) => any
  invokeLatest: (index?: number) => any
}

export const useEscapeKey = (cb: EscapeKeyCallback) => {
  const $escapeStack = inject<IEscapeKeyFunctions | null>('$escapeKey', null)
  if ($escapeStack) {
    $escapeStack?.add(cb)
    onUnmounted(() => $escapeStack?.remove(cb))
  } else {
    console.warn('$escapeKey not provided; EscapeKey plugin most likely not imported')
  }
}

const EscapeKeyPlugin: Plugin = {
  install(app: App) {
    const escapeStack = ref<EscapeKeyCallback[]>([])
    const escapeKeyFunctions: IEscapeKeyFunctions = {
      add: (callback: EscapeKeyCallback) => escapeStack.value.push(callback),
      remove: (callback: EscapeKeyCallback) => (escapeStack.value = escapeStack.value.filter((cb) => cb !== callback)),
      invokeLatest: (index?: number) => {
        const actualIndex = index !== undefined ? index : escapeStack.value.length - 1
        return actualIndex >= 0 && !escapeStack.value[actualIndex]() && escapeKeyFunctions.invokeLatest(actualIndex - 1)
      },
    }
    app.provide<IEscapeKeyFunctions>('$escapeKey', escapeKeyFunctions)

    onKeyStroke('Escape', () => escapeKeyFunctions.invokeLatest())
  },
}
export default EscapeKeyPlugin
