import { createAndOpenPdf } from './PdfInvoice'
import { useScriptTag } from '@vueuse/core'
import saveAs from 'file-saver'
// import pdfMake, { TCreatedPdf } from 'pdfmake/build/pdfmake'
import type { TCreatedPdf } from 'pdfmake/build/pdfmake'
import type { TDocumentDefinitions, CustomTableLayout, Content } from 'pdfmake/interfaces'
import { type MaybeRef, ref, unref } from 'vue'

export interface IPdfConfig<T> {
  title: string
  titleContent?: Content
  addressContent?: Content
  infoContent?: Content
  tableFooterContent?: Content
  tableConfigItems: IPdfTableConfigItem<T>[]
  notesContent?: Content
}

export interface IPdfTableConfigItem<T> {
  width: any
  content: (item: T, configItem: IPdfTableConfigItem<T>, index: number) => Content
  title: string
  id?: string
  alignment?: string
}

export const customTableLayout: CustomTableLayout = {
  hLineWidth: (i, node) => (i === 0 ? 0 : i === node.table.headerRows ? 0.25 : 0.25),
  vLineWidth: () => 0,
  paddingTop: () => 1,
  paddingBottom: () => 1,
  hLineColor: '#ccc',
  paddingLeft: (i) => (i === 0 ? 0 : 4),
  paddingRight: (i, node) => (i === node.table.widths!.length - 1 ? 0 : 4),
}

export const checkImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => resolve(true)
    image.onerror = () => reject(false)
    image.src = url
  })

export const usePdfGenerator = (iframe: MaybeRef<HTMLIFrameElement>) => {
  const doc = ref<TCreatedPdf | null>(null)
  const { load } = useScriptTag('https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.10/pdfmake.min.js', undefined, {
    manual: true,
  })
  const init = async (definitions: TDocumentDefinitions) => {
    await load()
    doc.value = createAndOpenPdf(definitions, unref(iframe))
  }
  return {
    init,
    download: (filename: string) => {
      doc.value!.getBlob((result) => saveAs(result, `${filename}.pdf`))
    },
    print: () => doc.value!.print(),
  }
}

export const isValidUrl = (urlString: string) => {
  try {
    return urlString.length < 150 && urlString !== '/Products/Assets/' && Boolean(new URL(urlString))
  } catch (e) {
    console.warn('url not valid', urlString)
    return false
  }
}

export const createCloudinaryFetchImageUrl = (absoluteImageUrl: string | undefined) =>
  `https://res.cloudinary.com/nuucon-io/image/fetch/t_md_square,f_jpg,d_placeholder_s3ntb8.jpg/${
    absoluteImageUrl && isValidUrl(absoluteImageUrl) ? encodeURIComponent(absoluteImageUrl) : 'http://undefined'
  }`

export const createCloudinaryProductImageUrl = (image: string, prefix: string = '') =>
  `https://res.cloudinary.com/nuucon-io/image/upload/t_md_square,f_jpg,d_placeholder_s3ntb8.jpg/test${prefix}${image}`
