<template lang="pug">
popover-image-zoom-provider

empty-state(v-if='results && results.length === 0' centered :message='$t("Keine Produkte und Anfragen gefunden.")' class='rounded-lg border border-grey-100 pb-10')
  template(#buttons)
    btn(v-if='query && query.length > 0' icon='x' secondary @click='query = ""') {{ $t('Filter zurücksetzen') }}

div(v-if='results.length' class='-mt-2 mb-5 flex items-center gap-2')
  btn(v-if='!areAllItemsExpanded' link link-inline @click='expandAll') {{ $t('alle einklappen') }}
  btn(v-if='!areAllItemsCollapsed' link link-inline @click='collapseAll') {{ $t('alle ausklappen') }}
SupplierInquiryItem(v-for='{ item, matches } in results' :key='item.inquiryId' v-model='collapsed[item.inquiryId]' :item='item' :matches='matches' :force='!!query && query.length > 0')
</template>

<script setup lang="ts">
import SupplierInquiryItem from './SupplierInquiryItem.vue'
import type { IInquirySupplier } from './types'
import { useCollapsePersisted, useFuzzySearch } from '@/composables/'
import { computed, toRef } from 'vue'

const props = defineProps<{ showProjects?: boolean; items: IInquirySupplier[]; id?: string }>()

const query = defineModel<string>()

const { results } = useFuzzySearch<IInquirySupplier>(query, toRef(props.items), ['listItems.title'])

const { collapsed, expandAll, areAllItemsExpanded, areAllItemsCollapsed, collapseAll } = useCollapsePersisted(
  'manufacturer_' + props.id,
  computed(() => results.value.map((r) => r.item.inquiryId)),
)
</script>
