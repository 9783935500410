<template lang="pug">
modal(size='base' data-test='modal-platform-updates' style='--modal-width: 620px' @close='close' )
  modal-header(icon='bell-01')
    span(class='flex gap-3 items-center mb-0 min-h-[42px]')
      //- icon(name='bell-01' class="-ml-1 text-xl" style='stroke-width: 2.8125px;')
      span {{ $t("What's new?") }}
      pill(v-if='store.unseenEntryDates.length > 0' type='purple') {{ store.unseenEntryDates.length }}

  overlay(:visible='store.loading' class='roundex-xl')
  div(ref='scrollArea' class='max-h-[min(80vh,800px)] overflow-auto relative platform-updates' style='padding-right: var(--modal-padding-right); margin-right: calc(var(--modal-padding-right) * -1)'  @click='onClickMarkdownContent')
    div(v-if='store.data' ref='target' class='pt-1 pb-5 prose')
      markdown(:data='store.data'  )
      btn(v-if='!archivedLoaded' link class='-ml-4 mt-2' @click='loadMore') {{$t("Load more")}}
</template>

<script lang="ts">
import { usePlatformUpdates } from '@/stores'
import { useIntersectionObserver } from '@vueuse/core'
import { watch } from 'vue'
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'

const ModalPlatformUpdates = defineComponent({
  inheritAttrs: false,
  setup(props, { emit }) {
    const $router = useRouter()
    const store = usePlatformUpdates()
    const target = ref<HTMLElement | null>(null)
    const scrollArea = ref<HTMLElement | null>(null)
    const timeElements = ref<HTMLElement[]>([])

    watch(
      () => target.value,
      async () => {
        timeElements.value = Array.from(target.value!.querySelectorAll('time'))
        if(timeElements.value.length > 0){
          store.setEntriesStateToSeen([timeElements.value[0].getAttribute('datetime')!])
        }
      },
    )
    const { stop } = useIntersectionObserver(
      timeElements,
      (entries, observerElement) =>
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            observerElement.unobserve(entry.target)
            store.setEntriesStateToSeen([entry.target.getAttribute('datetime')!])
          }
        }),
      { root: scrollArea },
    )

    const archivedLoaded = ref(false)
    return {
      archivedLoaded,
      loadMore: () => ((archivedLoaded.value = true), store.loadArchive()),
      target,
      scrollArea,
      store,
      close: () => {
        stop()
        store.setAllCurrentEntriesStateToSeen()
        emit('close')
      },
      onClickMarkdownContent: (e: MouseEvent) => {
        const href = (e.target as HTMLElement).getAttribute?.('href')
        if (href && href.includes('#')) {
          e.preventDefault()
          const a = href.slice(1, href.length).split(':')
          switch (a[0]) {
            case 'app':
              $router.push(a[1])
              break
          }
        }
      },
    }
  },
})

export default ModalPlatformUpdates
</script>
<style lang="stylus">
.platform-updates
  hr
    border-top: 1px solid var(--grey-200)
    margin: 40px 0
  img
    border-radius: 6px
  h5
    margin-top: -15px
    margin-bottom: 0
  .video-player
    margin-bottom: 2em
  time
    color: var(--color-text-light)
    padding-top: 3px
    text-emphasis none
    display: flex
    margin-bottom: 5px
    font-size: 14px
    & + *
      padding-top: 0!important
</style>
