<template lang="pug">
div(class='control-radio-group')
  slot
</template>

<script lang="ts">
import { useControl, Input } from './composables'
import { defineComponent, computed, type SetupContext, provide } from 'vue'

const ControlRadioGroup = defineComponent({
  props: {
    ...Input.props,
  },
  setup(props, context: SetupContext) {
    const { value: val, classes, onChange } = useControl<string>(props, context, '')
    provide('value', val)
    provide('onChange', (value: string) => ((val.value = value), onChange()))
    provide(
      'disabled',
      computed(() => props.disabled),
    )
    return {
      val,
      classes,
    }
  },
})

export default ControlRadioGroup
</script>
