<template lang="pug">
div(v-if='visible')
  slot(:submit='submit' :label='actualLabel')
    btn(v-bind='$attrs' :id='name' :data-test='name' :loading='loading' :htmlType='htmlType' :disabled='disabled || (config && config.disabled)' :formaction='formActionName || name' @click='submit')
      slot(name='inner' :label='actualLabel')
        | {{ actualLabel }}
</template>

<script lang="ts">
import { useFormItem } from './composables'
import { computed, defineComponent, inject, onMounted } from 'vue'

const FormActionItem = defineComponent({
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    formActionName: String,
    loading: Boolean,
    disabled: Boolean,
    label: String,
    primary: Boolean,
    hideWhenDisabled: Boolean,
  },
  setup(props) {
    const submitForm = inject('submitForm') as (actionName: string) => Promise<any>
    const { completeName, path, config } = useFormItem(props.name)
    onMounted(
      () =>
        props.primary && inject<(actionName: string) => void>('setPrimaryAction')!(props.formActionName || props.name),
    )
    return {
      submit: () => !props.primary && submitForm(props.formActionName || props.name),
      visible: computed(() => config.value && (!props.hideWhenDisabled || !config.value?.disabled)),
      actualLabel: computed(() => props.label || config.value?.label || ''),
      htmlType: computed(() => (props.primary ? 'submit' : 'button')),
      completeName,
      config,
      path,
      isDisabled: computed(() => false),
    }
  },
})

export default FormActionItem
</script>
