<template lang="pug">
custom-table(class='')
  thead(class='whitespace-nowrap')
    th(colspan='2') {{ $t('Product') }}
    th(style='width: 4%') {{ $t('Quantity') }}
    th(style='width: 8%' class='text-right') {{ $t('retailPriceNetString') }}
    th(style='width: 8%' class='!pr-2 text-right') {{ $t('retailPriceTotalNetString') }}
  template(v-for='(item, index) in items' :key='item.productId')
    tbody(v-if='!matches || matchesAsIndexMap[index]')
      tr
        td(class='image')
          project-product-image(:url='item.image' data-src-zoom-parent grey class='mr-1')
        td
          div(class='!flex flex-col !items-start')
            div(class='align-left text-sm text-text-light') {{ item.brand }}
            div(class='flex items-start gap-2')
              project-product-title(
                class='font-bold'
                :isPcon='!!item.pConInformation'
                :productId='item.productId'
                :title='matches ? highlight(item.title, matchesAsIndexMap[index].indices) : item.title'
                :baseProductId='item.baseProductId'
                :externalProductUrl='item.externalProductUrl'
              )

            text-auto-expand(
              v-if='(item.isExternal || item.pConInformation) && item.description'
              v-model='expanded[item.productId]'
              :text='item.description'
              class='whitespace-pre-wrap text-sm text-text-light'
            )

        td {{ item.quantity }}
        td(class='text-right') {{ item.retailPriceNetString }}
        td(class='!pr-2 text-right')
          b {{ item.retailPriceTotalNetString }}
</template>

<script setup lang="ts">
import { highlight } from '@/composables/'
import { type IProjectProductItem } from '@/pages/projects/types'
import { type ResultMatch } from '@/types'
import { useLocalStorage } from '@vueuse/core'
import { objectify } from 'radash'
import { computed } from 'vue'

const props = defineProps<{
  items: IProjectProductItem[]
  matches?: ReadonlyArray<ResultMatch>
}>()

const matchesAsIndexMap = computed(() => (props.matches ? objectify(props.matches, (m) => m.refIndex!) : {}))
const expanded = useLocalStorage(`text_expanded_manufacturer`, {})
</script>
