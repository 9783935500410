export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/index.vue'),
    /* no children */
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/[...all].vue'),
    /* no children */
  },
  {
    path: '/administration',
    name: '/administration',
    component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration.vue'),
    children: [
      {
        path: 'advanced',
        name: '/administration/advanced',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/advanced.vue'),
        children: [
          {
            path: '',
            name: '/administration/advanced/',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/advanced/index.vue'),
            /* no children */
          },
          {
            path: 'backup-restore',
            name: '/administration/advanced/backup-restore',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/advanced/backup-restore.vue'),
            /* no children */
          },
          {
            path: 'change-log-level',
            name: '/administration/advanced/change-log-level',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/advanced/change-log-level.vue'),
            /* no children */
          },
          {
            path: 'maintenance',
            name: '/administration/advanced/maintenance',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/advanced/maintenance.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'customers',
        name: '/administration/customers',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/customers.vue'),
        /* no children */
      },
      {
        path: 'dashboard',
        name: '/administration/dashboard',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/dashboard.vue'),
        /* no children */
      },
      {
        path: 'inquiries',
        name: '/administration/inquiries',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/inquiries.vue'),
        /* no children */
      },
      {
        path: 'products-import',
        name: '/administration/products-import',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/products-import.vue'),
        children: [
          {
            path: '',
            name: '/administration/products-import/',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/products-import/index.vue'),
            /* no children */
          },
          {
            path: 'brand-mappings',
            name: '/administration/products-import/brand-mappings',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/products-import/brand-mappings.vue'),
            /* no children */
          },
          {
            path: 'brands',
            name: '/administration/products-import/brands',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/products-import/brands.vue'),
            /* no children */
          },
          {
            path: 'categories',
            name: '/administration/products-import/categories',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/products-import/categories.vue'),
            /* no children */
          },
          {
            path: 'category-mappings',
            name: '/administration/products-import/category-mappings',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/products-import/category-mappings.vue'),
            /* no children */
          },
          {
            path: 'color-mappings',
            name: '/administration/products-import/color-mappings',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/products-import/color-mappings.vue'),
            /* no children */
          },
          {
            path: 'colors',
            name: '/administration/products-import/colors',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/products-import/colors.vue'),
            /* no children */
          },
          {
            path: 'import-logs',
            name: '/administration/products-import/import-logs',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/products-import/import-logs.vue'),
            /* no children */
          },
          {
            path: 'material-mappings',
            name: '/administration/products-import/material-mappings',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/products-import/material-mappings.vue'),
            /* no children */
          },
          {
            path: 'materials',
            name: '/administration/products-import/materials',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/products-import/materials.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'projects',
        name: '/administration/projects',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/projects.vue'),
        /* no children */
      },
      {
        path: 'registrations',
        name: '/administration/registrations',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/registrations.vue'),
        /* no children */
      },
      {
        path: 'suppliers',
        name: '/administration/suppliers',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/suppliers.vue'),
        /* no children */
      },
      {
        path: 'tools',
        name: '/administration/tools',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/tools.vue'),
        children: [
          {
            path: '',
            name: '/administration/tools/',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/tools/index.vue'),
            /* no children */
          },
          {
            path: 'pcon-csv-transform',
            name: '/administration/tools/pcon-csv-transform',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/tools/pcon-csv-transform.vue'),
            /* no children */
          },
          {
            path: 'price-calculator',
            name: '/administration/tools/price-calculator',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/tools/price-calculator.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'users',
        name: '/administration/users',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/administration/users.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/brands',
    /* internal name: '/brands' */
    /* no component */
    children: [
      {
        path: '',
        name: '/brands/',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/brands/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/clipboard',
    name: '/clipboard',
    component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/clipboard.vue'),
    /* no children */
  },
  {
    path: '/customer',
    name: '/customer',
    component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/customer.vue'),
    children: [
      {
        path: '',
        name: '/customer/',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/customer/index.vue'),
        /* no children */
      },
      {
        path: 'addresses',
        name: '/customer/addresses',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/customer/addresses.vue'),
        /* no children */
      },
      {
        path: 'members',
        name: '/customer/members',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/customer/members.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/inquiries',
    /* internal name: '/inquiries' */
    /* no component */
    children: [
      {
        path: '',
        name: '/inquiries/',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/inquiries/[id]',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/[id].vue'),
        /* no children */
      },
      {
        path: 'forms',
        /* internal name: '/inquiries/forms' */
        /* no component */
        children: [
          {
            path: 'FormDeclineOffer',
            name: '/inquiries/forms/FormDeclineOffer',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormDeclineOffer.vue'),
            /* no children */
          },
          {
            path: 'FormEditExternalInquiryItem',
            name: '/inquiries/forms/FormEditExternalInquiryItem',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormEditExternalInquiryItem.vue'),
            /* no children */
          },
          {
            path: 'FormInquiry',
            name: '/inquiries/forms/FormInquiry',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormInquiry.vue'),
            /* no children */
          },
          {
            path: 'FormInquiryAddProductsFromProjects',
            name: '/inquiries/forms/FormInquiryAddProductsFromProjects',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormInquiryAddProductsFromProjects.vue'),
            /* no children */
          },
          {
            path: 'FormInquiryAddressAdd',
            name: '/inquiries/forms/FormInquiryAddressAdd',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormInquiryAddressAdd.vue'),
            /* no children */
          },
          {
            path: 'FormInquiryAddressChange',
            name: '/inquiries/forms/FormInquiryAddressChange',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormInquiryAddressChange.vue'),
            /* no children */
          },
          {
            path: 'FormInquiryAddressUpdate',
            name: '/inquiries/forms/FormInquiryAddressUpdate',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormInquiryAddressUpdate.vue'),
            /* no children */
          },
          {
            path: 'FormInquiryAdmin',
            name: '/inquiries/forms/FormInquiryAdmin',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormInquiryAdmin.vue'),
            /* no children */
          },
          {
            path: 'FormInquiryDeliverySetup',
            name: '/inquiries/forms/FormInquiryDeliverySetup',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormInquiryDeliverySetup.vue'),
            /* no children */
          },
          {
            path: 'FormInquiryRequestChanges',
            name: '/inquiries/forms/FormInquiryRequestChanges',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormInquiryRequestChanges.vue'),
            /* no children */
          },
          {
            path: 'FormInquiryRequestWhenExpired',
            name: '/inquiries/forms/FormInquiryRequestWhenExpired',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormInquiryRequestWhenExpired.vue'),
            /* no children */
          },
          {
            path: 'FormRejectInquiry',
            name: '/inquiries/forms/FormRejectInquiry',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormRejectInquiry.vue'),
            /* no children */
          },
          {
            path: 'FormResetOffer',
            name: '/inquiries/forms/FormResetOffer',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/inquiries/forms/FormResetOffer.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/manufacturer',
    /* internal name: '/manufacturer' */
    /* no component */
    children: [
      {
        path: 'archive',
        name: '/manufacturer/archive',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/manufacturer/archive.vue'),
        /* no children */
      },
      {
        path: 'offers',
        name: '/manufacturer/offers',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/manufacturer/offers.vue'),
        /* no children */
      },
      {
        path: 'orders',
        name: '/manufacturer/orders',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/manufacturer/orders.vue'),
        /* no children */
      },
      {
        path: 'overview',
        /* internal name: '/manufacturer/overview' */
        /* no component */
        children: [
          {
            path: '',
            name: '/manufacturer/overview/',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/manufacturer/overview/index.vue'),
            /* no children */
          },
          {
            path: 'accepted',
            name: '/manufacturer/overview/accepted',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/manufacturer/overview/accepted.vue'),
            /* no children */
          },
          {
            path: 'archived',
            name: '/manufacturer/overview/archived',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/manufacturer/overview/archived.vue'),
            /* no children */
          },
          {
            path: 'offered',
            name: '/manufacturer/overview/offered',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/manufacturer/overview/offered.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'projects',
        name: '/manufacturer/projects',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/manufacturer/projects.vue'),
        /* no children */
      },
      {
        path: 'user',
        name: '/manufacturer/user',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/manufacturer/user.vue'),
        children: [
          {
            path: '',
            name: '/manufacturer/user/',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/manufacturer/user/index.vue'),
            /* no children */
          },
          {
            path: 'change-email',
            name: '/manufacturer/user/change-email',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/manufacturer/user/change-email.vue'),
            /* no children */
          },
          {
            path: 'change-password',
            name: '/manufacturer/user/change-password',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/manufacturer/user/change-password.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/products',
    /* internal name: '/products' */
    /* no component */
    children: [
      {
        path: '',
        name: '/products/',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/products/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/products/[id]',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/products/[id].vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/projects',
    /* internal name: '/projects' */
    /* no component */
    children: [
      {
        path: '',
        name: '/projects/',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/projects/[id]',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/[id].vue'),
        children: [
          {
            path: '',
            name: '/projects/[id]/',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'inquiries',
            name: '/projects/[id]/inquiries',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/[id]/inquiries.vue'),
            /* no children */
          },
          {
            path: 'settings',
            name: '/projects/[id]/settings',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/[id]/settings.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'forms',
        /* internal name: '/projects/forms' */
        /* no component */
        children: [
          {
            path: 'ExternalProjectItemForm',
            name: '/projects/forms/ExternalProjectItemForm',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/ExternalProjectItemForm.vue'),
            /* no children */
          },
          {
            path: 'FormAddOrEditExternalProjectItem',
            name: '/projects/forms/FormAddOrEditExternalProjectItem',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormAddOrEditExternalProjectItem.vue'),
            /* no children */
          },
          {
            path: 'FormAddProductAlternative',
            name: '/projects/forms/FormAddProductAlternative',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormAddProductAlternative.vue'),
            /* no children */
          },
          {
            path: 'FormEditPconConfiguration',
            name: '/projects/forms/FormEditPconConfiguration',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormEditPconConfiguration.vue'),
            /* no children */
          },
          {
            path: 'FormExport',
            name: '/projects/forms/FormExport',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormExport.vue'),
            /* no children */
          },
          {
            path: 'FormImportProducts',
            name: '/projects/forms/FormImportProducts',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormImportProducts.vue'),
            /* no children */
          },
          {
            path: 'FormProjectAddProduct',
            name: '/projects/forms/FormProjectAddProduct',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectAddProduct.vue'),
            /* no children */
          },
          {
            path: 'FormProjectAddProducts',
            name: '/projects/forms/FormProjectAddProducts',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectAddProducts.vue'),
            /* no children */
          },
          {
            path: 'FormProjectAdmin',
            name: '/projects/forms/FormProjectAdmin',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectAdmin.vue'),
            /* no children */
          },
          {
            path: 'FormProjectCreate',
            name: '/projects/forms/FormProjectCreate',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectCreate.vue'),
            /* no children */
          },
          {
            path: 'FormProjectEdit',
            name: '/projects/forms/FormProjectEdit',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectEdit.vue'),
            /* no children */
          },
          {
            path: 'FormProjectMemberAdd',
            name: '/projects/forms/FormProjectMemberAdd',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectMemberAdd.vue'),
            /* no children */
          },
          {
            path: 'FormProjectMembers',
            name: '/projects/forms/FormProjectMembers',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectMembers.vue'),
            /* no children */
          },
          {
            path: 'FormProjectTagCreate',
            name: '/projects/forms/FormProjectTagCreate',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectTagCreate.vue'),
            /* no children */
          },
          {
            path: 'FormProjectTagEdit',
            name: '/projects/forms/FormProjectTagEdit',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectTagEdit.vue'),
            /* no children */
          },
          {
            path: 'FormProjectTagsAdd',
            name: '/projects/forms/FormProjectTagsAdd',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectTagsAdd.vue'),
            /* no children */
          },
          {
            path: 'FormProjectTagsAddRemove',
            name: '/projects/forms/FormProjectTagsAddRemove',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectTagsAddRemove.vue'),
            /* no children */
          },
          {
            path: 'FormProjectTagsEdit',
            name: '/projects/forms/FormProjectTagsEdit',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectTagsEdit.vue'),
            /* no children */
          },
          {
            path: 'FormProjectUpdateOwnerAdmin',
            name: '/projects/forms/FormProjectUpdateOwnerAdmin',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormProjectUpdateOwnerAdmin.vue'),
            /* no children */
          },
          {
            path: 'FormSelectProductSupplier',
            name: '/projects/forms/FormSelectProductSupplier',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/FormSelectProductSupplier.vue'),
            /* no children */
          },
          {
            path: 'ProductsImportFromCsvOrXlsPane',
            name: '/projects/forms/ProductsImportFromCsvOrXlsPane',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/ProductsImportFromCsvOrXlsPane.vue'),
            /* no children */
          },
          {
            path: 'ProductsImportFromObxPane',
            name: '/projects/forms/ProductsImportFromObxPane',
            component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/projects/forms/ProductsImportFromObxPane.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/user',
    name: '/user',
    component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/user.vue'),
    children: [
      {
        path: '',
        name: '/user/',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/user/index.vue'),
        /* no children */
      },
      {
        path: 'change-email',
        name: '/user/change-email',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/user/change-email.vue'),
        /* no children */
      },
      {
        path: 'change-password',
        name: '/user/change-password',
        component: () => import('/opt/atlassian/pipelines/agent/build/frontend/src/pages/user/change-password.vue'),
        /* no children */
      }
    ],
  }
]
