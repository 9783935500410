import { gtmEcommerceTracking, gtmEventTracking } from '@/utilities/Gtm'
import { type Directive, type DirectiveBinding } from 'vue'

const GtmEventClick: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding): void {
    el.addEventListener('click', () => {
      const b = binding.value
      gtmEventTracking(b.event, b.eventCategory, b.eventAction, b.eventLabel)
    })
  },
}
const GtmProductDetail: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding): void {
    /*
    TODO: List attribute based on referal
    1) /products --> "Search Results"
    2) /products-detail/<same-id> --> "Direct"
    3) /products-detail/<different-id> --> "Similar Products"
    4) /clipboard --> "Clipboard"
    5) /project/<id> --> "Project List"
    6) /project/<id>/inquiries-create --> "Inquiry"
    7) everything else --> "unkown?"

    vnode.context.$route?
     */

    gtmEcommerceTracking(null, {
      detail: {
        actionField: { list: 'Product Overview' },
        products: [binding.value],
      },
    })
  },
}

export default { GtmProductDetail, GtmEventClick }
