<script lang="ts">
import { defineComponent } from 'vue'

const TransitionExpand = defineComponent({
  props: {
    disabled: { type: Boolean, default: false },
  },
  setup(props) {
    return {
      transition: {
        onAfterEnter(element: HTMLElement) {
          element.style.height = `auto`
        },
        onEnter(element: HTMLElement) {
          if (props.disabled) {
            return
          }
          const { width } = getComputedStyle(element)

          element.style.width = width
          element.style.position = `absolute`
          element.style.visibility = `hidden`
          element.style.height = `auto`

          const { height } = getComputedStyle(element)

          element.style.width = ''
          element.style.position = ''
          element.style.visibility = ''
          element.style.height = ''

          // Force repaint to make sure the
          // animation is triggered correctly.

          const {} = getComputedStyle(element).height

          setTimeout(() => {
            element.style.height = height
          })
        },
        onLeave(element: HTMLElement) {
          if (props.disabled) {
            return
          }

          const { height } = getComputedStyle(element)

          element.style.height = height

          // Force repaint to make sure the
          // animation is triggered correctly.

          const {} = getComputedStyle(element).height

          setTimeout(() => {
            element.style.height = '0'
          })
        },
      },
    }
  },
  template:
    '<transition class="transition-expand" v-bind="transition" name="transition-expand"><slot></slot></transition>',
})
export default TransitionExpand
</script>

<style lang="stylus">
:root
  --transition-expand-animation-duration: .2s

.DISABLE_TRANSITIONS
  --transition-expand-animation-duration: 0

.transition-expand
  will-change: height
  backface-visibility: hidden

.transition-expand-enter-active,
.transition-expand-leave-active
  transition: height var(--transition-expand-animation-duration) ease-in-out
  overflow: hidden

.transition-expand-enter-from,
.transition-expand-leave-to
  height: 0
</style>
