<template lang="pug">
thead(class='table-header')
  slot(name='before')
  th(v-for='item in itemsWithProperties' :key='item.key' :class='item.classes')
    span(v-if='!item.sortable' class='table-header__span') {{ item.label }}
    button(v-else :class='{ "table-header__button--active": item.state !== "default" }' class='table-header__button' @click='toggleSort(item.key)')
      span {{ item.label }}
      icon(:name='iconMap[item.state]')
  th(v-if='additionalColumn')
</template>

<script lang="ts">
import { type ITableConfigItemWithKey } from './CustomDataTable.vue'
import { useListFilter } from '@/components/List'
import { computed, defineComponent, type PropType } from 'vue'

export interface ISortState {
  key: string
  state: 'asc' | 'desc' | ''
}

const CustomTableHeader = defineComponent({
  props: {
    selectable: Boolean,
    items: { required: true, type: Array as PropType<ITableConfigItemWithKey[]> },
    additionalColumn: Boolean,
    selectState: String as PropType<'none' | 'all' | 'partial'>,
    iconMap: {
      type: Object,
      default: () => ({ asc: 'chevron-up', desc: 'chevron-down', default: 'sort' }),
    },
  },
  setup(props) {
    const keySortKeyMap = computed(() =>
      Object.fromEntries(props.items?.map((i: ITableConfigItemWithKey) => [i.key, i.sortKey || i.key]) || []),
    )
    const sortKeyKeyMap = computed(() =>
      Object.fromEntries(props.items?.map((i: ITableConfigItemWithKey) => [i.sortKey || i.key, i.key]) || []),
    )
    const { value, setProperty } = useListFilter('sort', '')
    const sortState = computed<ISortState>({
      get: () => {
        const partials = (value.value as string)?.split('_')
        return partials?.length
          ? ({ key: sortKeyKeyMap.value[partials[0]], state: partials[1] } as ISortState)
          : ({ key: '', state: '' } as ISortState)
      },
      set: ({ key, state }: ISortState) => setProperty!('sort', state && `${key}_${state}`),
    })

    return {
      itemsWithProperties: computed(() =>
        props.items
          ? props.items.map((item: ITableConfigItemWithKey) => ({
              ...item,
              state: sortState.value?.key === item.key ? sortState.value?.state : 'default',
            }))
          : [],
      ),
      toggleSort: (key: string) => {
        const newState =
          sortState!.value?.key === key ? (sortState!.value?.state === 'asc' ? 'desc' : undefined) : 'asc'
        sortState.value = { key: keySortKeyMap.value[key], state: newState } as ISortState
      },
    }
  },
})

export default CustomTableHeader
</script>

<style lang="stylus">
@import '../../styles/variables.styl'
:root
  --table-th-bg: #fff
.table-header
  &__button,  &__span
    background: transparent
    border: none
    padding: 0
    text-align: left
    font-size: 13.5px
    font-weight: 500
    font-family: system-ui, sans-serif
    color: var(--color-text-light)
  &__button
    cursor: pointer
    display: flex
    align-items: center
    justify-content: stretch
    width: 100%
    .icon
      display: flex
    &:hover,
    &--active
      color: var(--color-text)

  > th
    z-index: 4
    top: 0
    background: var(--table-th-bg)
    line-height: 1
    padding: 14px 6px 14px 6px
    position: sticky
    .table--small &
      border: 0
      padding: 5px 7px 7px 7px
</style>
