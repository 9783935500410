<template lang="pug">
div(ref='el' class='backdrop')
  slot
</template>

<script setup lang="ts">
import { onUnmounted, ref } from 'vue'

defineProps({
  visible: Boolean,
})
const el = ref<HTMLElement | null>(null)
onUnmounted(() => el.value?.classList.remove('backdrop--visible'))
</script>
<style lang="stylus">
@import '../styles/variables.styl'

:root
  --backdrop-bg: rgba($color-outer-space, 0.22)
  --backdrop-animation-duration: .15s
.backdrop
  :is(.DISABLE_TRANSITIONS) &
    --backdrop-animation-duration: 0
  position: fixed!important
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1002
  overflow: auto
  outline: 0
  &:after
    display: block
    content: ''
    animation-duration: var(--backdrop-animation-duration)
    animation-name: backdrop-fade
    animation-fill-mode: backward
    z-index: -1
    background: var(--backdrop-bg)
    transition: background .15s linear
    position: fixed
    pointer-events: none
    top: 0
    left: 0
    width: 100%
    height: 100%
  body.is-dragging &
    --backdrop-bg: rgba($color-outer-space, 0.075)

@keyframes backdrop-fade
  0%
    background: rgba($color-outer-space, 0)
  100%
    background: var(--backdrop-bg)
</style>
