export const objectToFormData = (obj: { [key: string]: any }) => {
  const submitData: FormData = new FormData()
  Object.keys(obj).forEach((field: string) =>
    obj[field] !== undefined && Array.isArray(obj[field])
      ? obj[field].length > 0
        ? obj[field].forEach((item: string) => submitData.append(field, item))
        : submitData.set(field, '')
      : submitData.set(field, obj[field] ?? ''),
  )

  return submitData
}

export const preferredCountry = () => {
  const lang = (
    window.navigator.language.includes('-') ? window.navigator.language.split('-')[1] : window.navigator.language
  ).toUpperCase()
  return ['DE', 'CH', 'AT'].includes(lang) ? lang : undefined
}
