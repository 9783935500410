<template lang="pug">
div(:class='classes' class='message' @click='() => !closable && close()')
  icon(v-if='icon !== false' :name='iconName' class='message__icon')
  div(v-if='content || $slots.default' class='message__content')
    slot
      component(:is='content.component' v-if='isCustomComponent' v-bind='content.props' @close='close()')
      span(v-else)
        | {{ content }}
  div(v-if='closable' class='message__close')
    btn(icon='x' small plain @click='$emit("close")')
</template>

<script setup lang="ts">
import { type IComponentConfig, type MessageSize } from '.'
import Btn from '@/components/Btn.vue'
import Icon from '@/components/Icon.vue'
import { useEscapeKey } from '@/plugins/EscapeKey'
import { isString } from 'radash'
import { type PropType, computed } from 'vue'

const ICON_MAP: { [key: string]: string } = {
  info: 'info',
  success: 'custom-check-circle',
  error: 'alert-triangle',
  warning: 'alert-circle',
}
const props = defineProps({
  type: { default: 'info', type: String },
  content: [String, Object] as PropType<string | IComponentConfig>,
  icon: { type: [String, Boolean], default: undefined },
  closable: Boolean,
  size: { default: 'default', type: String as PropType<MessageSize> },
  messageKey: [String, Number],
})

const emit = defineEmits(['close'])
const close = () => emit('close')
if (props.closable) {
  useEscapeKey(() => (close(), true))
}
const iconName = computed(() => (isString(props.icon) ? props.icon : ICON_MAP[props.type]))
const classes = computed(() => ({ [`message--${props.type}`]: true, [`message--${props.size}`]: true }))
const isCustomComponent = computed(() => !isString(props.content))
</script>

<style lang="stylus">
@import '../../styles/variables.styl'
:root
  --message-bg-color: var(--color-text)
  --message-bg-color-faded: var(--grey-75)
  --message-icon-size: 30px
  --message-icon-margin: 12px
  --message-padding: 10px

.message
  color:  var(--color-text)
  position: relative
  background:  #fff
  display: inline-flex
  align-items: center
  font-size: 16px
  position: relative
  line-height: 1.4
  padding: var(--message-padding)
  margin: 5px auto
  border-radius: 6px
  pointer-events: all
  border: 1px solid #eee
  &__icon.icon
    align-self: top
    font-size: var(--message-icon-size)
    margin-right: var(--message-icon-margin)
    color: var(--message-bg-color)
    background-color: var(--message-bg-color-faded)
    padding: 5px
    border-radius: 5px
    display: inline-block
    position: relative
    top: 1px
  &__close
    padding-left: 5px
    position: relative
    &:before
      position: absolute
      top: 5px
      left: 0
      height: calc(100% - 10px)
      width: 1px
      content: ''
      background: var(--grey-200)
  &__content
    flex-grow: 1
    display: flex
    align-items: center
    padding-right: 10px
  &--large
    font-size: 17px
    line-height: 1.5
    padding: var(--message-padding) calc(var(--message-padding) - 5px) var(--message-padding) var(--message-padding)
    border-radius: 12px
    --message-icon-size: 36px
    --message-icon-margin: 22px
  &--info
    border: 1px solid #eee
  &--success
    --message-bg-color: var(--green-500)
    --message-bg-color-faded: var(--green-100)
  &--error
    --message-bg-color: var(--red-500)
    --message-bg-color-faded: var(--red-100)
  &--warning
    --message-bg-color: var(--yellow-500)
    --message-bg-color-faded: var(--yellow-50)
</style>
