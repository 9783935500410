<template lang="pug">
modal(size='lg' data-test='modal-brand-reporting' @close='$emit("close", false)')
  modal-header Volumen berechnen
  popover-image-zoom-provider
  div(class='grid sm:grid-cols-2 gap-4')
    custom-table(v-if='inquiry' )
      tr(v-for='item in inquiry.items' :key='item.title')
        td 
          project-product-image(:url='item.image' data-src-zoom-parent  style='--project-product-image-size: 40px' class='mr-2')
        td {{ item.title }} 
        td(class='whitespace-nowrap font-bold text-sm') {{ item.quantity }} ×
        td
          control-select(:modelValue='volumes[item.productId] || "a"' :options='volumeOptions' @update:modelValue='(v: string) => volumes[item.productId] = v')
      tr()
        td(class='font-bold' colspan='3') Gesamtvolumen 
        td(class='font-bold text-right !text-2xl' ) {{ volumeSum }} m<sup>3</sup>
    div(class='max-h-[calc(100vh-200px)] overflow-auto bg-grey-50 px-4 py-4 pr-4 mb-2 rounded-lg -mt-3')
      h5(class='mb-1') Produktvolumen Referenzblatt
      table(class='w-full text-sm')
        tr(v-for='(row, index) in sheet' :key='index' )
          td(class='text-text-light') {{ row['OrderNumber'] }}
          td {{ row.Kategorie }}
          td {{ row['Volumen in cbm'] }}
  action-bar(class='mt-2')
    btn(tertiary @click='$emit("close", false)') {{ $t('close') }}
    btn(@click='$emit("close", volumeSum)') Übernehmen
</template>

<script setup lang="ts">
import { useInquiriesStore } from '@/pages/inquiries/store'
import { type IOption } from '@/types'
import axios from 'axios'
// @ts-expect-error
import * as csvjson from 'csvjson'
import { isEmpty, objectify } from 'radash'
import { computed, ref, watch } from 'vue'

const CATEGORY_VOLUME_MAP = {
  a: 0.1,
  b: 0.2,
  c: 0.4,
  d: 0.6,
  e: 0.8,
  f: 1,
  g: 1.4,
  h: 1.6,
  i: 2,
}
const props = defineProps({
  inquiryId: { type: String, required: true },
})
const store = useInquiriesStore()
const inquiry = computed(() => (props.inquiryId ? store.allInquiriesAsMap[props.inquiryId] : undefined))

const volumeOptions: IOption[] = Object.entries(CATEGORY_VOLUME_MAP).map(([key, value]) => ({
  label: `${key.toUpperCase()} (${value} m3)`,
  value: key,
}))

const volumes = ref<Record<string, string>>({})
const initVolumes = () =>
  inquiry.value &&
  isEmpty(volumes.value) &&
  (volumes.value = Object.fromEntries(inquiry.value.items.map((item) => [item.productId, 'a'])))
initVolumes()
const inquiryItemsMap = computed(() => objectify(inquiry.value?.items || [], (item) => item.productId))

watch(() => inquiry.value, initVolumes)

const sheet = ref({})
const loadCSVSheet = async () => {
  const { data } = await axios.get<string>('/logistik-und-montage-sheet.csv')
  sheet.value = csvjson.toObject(data, {
    delimiter: ',', // optional
    quote: '"',
  })
  console.log('data', data, sheet.value)
}
loadCSVSheet()

const volumeSum = computed(() =>
  !inquiry.value
    ? 0
    : Math.round(
        Object.entries(volumes.value).reduce(
          (sum: number, [productId, volumeKey]) =>
            sum + inquiryItemsMap.value[productId].quantity * CATEGORY_VOLUME_MAP[volumeKey || 'c'],
          0,
        ) * 100,
      ) / 100,
)
</script>
