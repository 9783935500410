export const logReturn = (value: any, ...args: string[]) => {
  console.log(...args, value)
  return value
}

export const logReturnTime = (cb: any, name: string = 'test') => {
  console.time(name)
  const e: any = cb()
  console.log(e)
  console.timeEnd(name)
  return e
}
