<template lang="pug">
div(:class='classes' class='collapse-item')
  button(tabindex='0' class='collapse-item__header' @click='toggle')
    span(class='collapse-item__title')
      slot(name='head')
        | {{ title }}
    icon(name='chevron-down' class='collapse-item__arrow')
  transition-expand
    div(v-if='active')
      div(class='collapse-item__content')
        slot
</template>

<script lang="ts">
import TransitionExpand from '../TransitionExpand.vue'
import Icon from '@/components/Icon.vue'
import { useLocalStorage } from '@vueuse/core'
import { computed, defineComponent } from 'vue'

const CollapseItem = defineComponent({
  components: { TransitionExpand, Icon },
  props: {
    id: { type: String, required: true },
    title: String,
    noBorder: Boolean,
    isOpenByDefault: Boolean,
    disabled: Boolean,
    size: { type: String, default: 'default' },
  },
  setup(props) {
    const active = useLocalStorage(`collapse_${props.id}`, props.isOpenByDefault ?? false)
    return {
      active,
      toggle: () => (active.value = !active.value),
      classes: computed<{ [className: string]: boolean }>(() => ({
        'collapse-item--active': active.value,
        'collapse-item--disabled': props.disabled,
        'collapse-item--noBorder': props.noBorder,
        [`collapse-item--${props.size}`]: true,
      })),
    }
  },
})
export default CollapseItem
</script>
<style lang="stylus">
@import '../../styles/variables.styl'
$padding = 10px

.collapse-item
  margin: 0 ($padding * -1)
  &__title
    flex-grow: 1
    display: flex
    align-items: center
  &__arrow
    position: relative
    flex-grow: 0
    ~/--active &
      transform: rotate(180deg)
    ~/--disabled &
      opacity: 0.5
  &__content
    padding: 10px 0 20px
  &__content,
  &__header
    width: "calc(100% - 2 * %s)" % ($padding)
    margin: 0 $padding
  &__header
    border: none
    transition: all 0.2s ease
    border-top: 1px solid rgba(0, 0, 0, 0.1)
    padding: 20px 0
    font-size: 19px
    background: none
    align-items: center
    text-align: left
    font-family: $font-lato
    cursor: pointer
    display: flex
    ~/--disabled &
      color: var(--grey-500)
    ~/--noBorder &
      border-top: 0
    &:focus, &:hover
      border-color: rgba(0, 0, 0, 0.3)
      body.using-mouse &
        outline: 0
    ~/--small &
      padding: 15px 0
      font-size: 15px
      font-weight: 800
</style>
