<template lang="pug">
div(class='filter-group') 
  slot
  btn(v-if='showClearButton' link icon='rotate-ccw' small @click='$emit("clear")') {{ $t('Clear all') }}
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const FilterGroup = defineComponent({ props: { showClearButton: Boolean } })

export default FilterGroup
</script>

<style lang="stylus">
.filter-group
  display: flex
  min-height: 34px
  gap: 8px
</style>
