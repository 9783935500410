<template lang="pug">
button(:class='classesLocal' type='button' class='control-switch' @click.stop.prevent='onClick')
  control-switch-icon(:id='selectorId' type='none' :value='val' :disabled='disabled' :formaction='formaction' :name='selectorId' :size='size' class='control-switch__icon')
  label(:for='selectorId' class='control-switch__label')
    slot
</template>

<script lang="ts">
import ControlSwitchIcon from './ControlSwitchIcon.vue'
import { Input, useControl } from './composables'
import { computed, defineComponent, type SetupContext, type PropType } from 'vue'

const ControlSwitch = defineComponent({
  components: { ControlSwitchIcon },
  props: {
    ...Input.props,
    modelValue: Boolean,
    size: String as PropType<'small' | 'default' | 'tiny'>,
    align: {
      type: String,
      default: 'right',
    },
    formaction: String,
  },
  setup(props, context: SetupContext) {
    const {
      value: val,
      classes,
      focus,
      onFocus,
      onChange,
      onBlur,
      isEmpty,
    } = useControl(props, context, props.modelValue)

    return {
      val,
      isEmpty,
      onFocus,
      classes,
      classesLocal: computed(() => ({
        [`control-switch--${props.size}`]: true,
        [`control-switch--${props.align}`]: true,
        'control-switch--checked': !!val.value,
        'control-switch--disabled': !!props.disabled,
      })),
      onBlur,
      onClick: () => {
        val.value = !val.value
        onChange()
      },
      selectorId: computed(() => props.id || `ControlCheckbox${Math.random()}`),
      onChange,
      focus,
    }
  },
})

export default ControlSwitch
</script>

<style lang="stylus">
@import '../../styles/variables.styl'

:root
  --control-switch-font-size: 16px
  --control-switch-gap: 12px
.control-switch
  display: flex
  padding: 3px
  border-radius: 10px
  align-items: center
  color: var(--color-text-semi-light)
  cursor: pointer
  appearance: none
  background: none
  &--tiny
    --control-switch-font-size: 14.5px
    --control-switch-gap: 9px
  &--small
    --control-switch-gap: 10px
    --control-switch-font-size: 15px
  &--left
    flex-direction: row-reverse
  &__label
    font-family: var(--font-default)
    font-size: var(--control-switch-font-size)
    cursor: pointer
    line-height: 1
  &__icon
    margin: 0 var(--control-switch-gap) 0 0
    ~/--left &
      margin: 0 0 0 var(--control-switch-gap)
    &:focus
      body.using-mouse &
        outline: 0
</style>
