import { makeAppointment, bookDemo, openIntercomChat } from '@/utilities/ThirdParty'
import mitt, { type Emitter } from 'mitt'

/**
 *  Accesibility Features
 */
document.body.addEventListener('mousedown', () => {
  document.body.classList.add('using-mouse')
})

document.body.addEventListener('keydown', () => {
  document.body.classList.remove('using-mouse')
})

/**
 * Third Party
 */
export const ThirdParty = { makeAppointment, bookDemo, openIntercomChat }
;(window as any).ThirdParty = ThirdParty

/**
 * Event Emitter
 */
export const emitter: Emitter<any> = mitt()
emitter.on('*', (args: any, args2: any) => console.log('__EMIT:', args, args2))
