<template lang="pug">
button(role='button' tab-index='1' :class='{ "select-list-item--selected": modelValue }' class='select-list-item' @click='update(!modelValue)')
  div(class='select-list-item__checkbox')
    checkbox-icon(:value='modelValue')
  div(class='select-list-item__content') 
    slot
  div(class='select-list-item__actions' @click.stop='false')
    slot(name='actions')
</template>

<script lang="ts">
import CheckboxIcon from '@/components/Control/CheckboxIcon.vue'
import { defineComponent } from 'vue'

const SelectListItem = defineComponent({
  components: { CheckboxIcon },
  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    return {
      update: (value: boolean) => emit('update:modelValue', value),
    }
  },
})
export default SelectListItem
</script>
<style lang="stylus">
:root
  --select-list-item-bg-color: transparent

.select-list-item
  display: flex
  align-items: center
  flex-shrink: 0
  padding: 0 5px
  background-color: var(--select-list-item-bg-color)
  min-height: 42px
  &:active
    --select-list-item-bg-color: var(--blue-400-20)!important
  &:hover
    --select-list-item-bg-color: var(--blue-400-10)
  &--selected
    --select-list-item-bg-color: transparent
  &__checkbox
    margin-right: 12px
  &__content
    padding: 5px 5px
    flex-grow: 1
    min-width: 0
    display: flex
  &__actions
    display: none
    align-items: center
    ~/:hover &
      display: flex
</style>
