import { useEventListener } from '@vueuse/core'
import { isString, sleep } from 'radash'
import { computed, inject, onMounted, nextTick, type Ref, ref, type SetupContext, watch } from 'vue'

// @TODO: props type
export const useControl = <T>(props: any, context: SetupContext, initialValue: T) => {
  const hasFocus: Ref<boolean> = ref(false)
  const isDirty: Ref<boolean> = ref(false)
  const value: Ref<T> = ref(props.modelValue ?? initialValue)
  const cachedInitialValue = initialValue
  const isEmpty: Ref<boolean> = computed(
    () =>
      !value.value || (value!.value as any) === '' || (value.value as any)?.length === 0 || value.value === undefined,
  )
  const el = ref<HTMLElement | null>(null)
  // @TODO: move string specific functionality somewehere elese
  const normalize = () => isString(value.value) && (value.value = (value.value as string).normalize() as any)
  useEventListener(el, 'paste', async () => {
    await sleep(10)
    normalize()
  })
  const focus = () => el.value?.focus?.()
  const provideState = inject<((key: string, value: Ref<any>) => void) | null>('setState', null)
  provideState?.('isEmpty', isEmpty)
  provideState?.('hasFocus', hasFocus)
  provideState?.('isDirty', isDirty)

  if (props.autofocus) {
    onMounted(() => (focus(), setTimeout(() => focus(), 20))) // delay makes sure focus-trap does not steal the focus
  }
  const classes: Ref<{ [key: string]: boolean }> = computed(() => ({
    'control--focus': hasFocus.value,
    'control--empty': isEmpty.value,
    'control--disabled': props.disabled,
  }))

  // sync local value with modelValue
  watch(
    () => props.modelValue,
    (valueNew) => ((value.value = valueNew), (isDirty.value = true)),
  )
  return {
    cachedInitialValue,
    value,
    hasFocus,
    classes,
    el,
    isEmpty,
    normalize,
    focus,
    provideState,
    onFocus: () => (hasFocus.value = true),
    onBlur: () => (hasFocus.value = false),
    clear: () => {
      value.value = initialValue
      context.emit('clear')
      context.emit('update:modelValue', initialValue)
      if (focus) {
        focus()
      }
      nextTick(() => (isDirty.value = false))
    },
    showClear: computed(() => !isEmpty.value),
    // @TODO: rename to onUpdate
    onChange: () => context.emit('update:modelValue', value.value),
    onSubmit: () => context.emit('submit', value.value),
  }
}

export const Input = {
  props: {
    id: String,
    modelValue: String,
    disabled: { type: Boolean, default: undefined },
    autofocus: Boolean,
    placeholder: String,
    required: Boolean,
  },
}
