<template lang="pug">
span(ref='element' :class='{ clamped: !ready || (!expanded && isOverflowing) }' v-bind='$attrs' v-html='text')
div(v-if='isOverflowing' class='-ml-1 block')
  btn(class='bold !text-xs' tiny no-radius faded @click.stop='toggle') {{ expanded ? $t('collapse text') : $t('expand text') }}
</template>

<script lang="ts">
import { useVModel } from '@vueuse/core'
import { watch } from 'vue'
import { nextTick } from 'vue'
import { defineComponent, onMounted, ref } from 'vue'

const TextAutoExpand = defineComponent({
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      required: true,
    },
    maxNewLines: {
      type: Number,
      default: 2,
    },
    modelValue: { type: Boolean, default: undefined },
  },
  setup(props) {
    const element = ref<HTMLElement | null>(null)
    const ready = ref(false)
    const expanded = props.modelValue == undefined ? ref(false) : useVModel(props, 'modelValue')
    const isOverflowing = ref(false)
    const isOverflowingCheck = () =>
      !!element.value &&
      (element.value!.scrollHeight > element.value!.clientHeight ||
        element.value!.clientHeight > props.maxNewLines * 20)
    const update = () => (
      (ready.value = false), nextTick(() => ((isOverflowing.value = isOverflowingCheck()), (ready.value = true)))
    )
    onMounted(update)
    watch(() => props.text, update)
    return {
      isOverflowing,
      ready,
      element,
      toggle: () => (expanded.value = !expanded.value),
      expanded,
    }
  },
})
export default TextAutoExpand
</script>
<style>
.clamped {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: v-bind(maxNewLines);
}
</style>
