<template lang="pug">
div
  tabs#formGroup(v-model='currentTab' size='small' :defaultActiveKey='groups[0]')
    tab-pane(v-for='group in groups' :id='group' :key='group' :title='group')
    slot(:config='config' :completeName='completeName')
</template>

<script lang="ts">
import { useFormItem } from './composables'
import { defineComponent, ref, type PropType } from 'vue'

const FormTabGroupItem = defineComponent({
  props: {
    groups: {
      type: Array as PropType<string[]>,
      default: () => ['de', 'en'],
    },
  },
  setup(props) {
    const currentTab = ref(props.groups[0])
    const { config, completeName } = useFormItem(currentTab)
    return {
      config,
      completeName,
      notifications: config?.value?.notifications || [],
      currentTab,
    }
  },
})

export default FormTabGroupItem
</script>
