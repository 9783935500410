<template lang="pug">
transition(name='fade')
  div(v-if='isVisible' class='overlay')
    div(class='overlay__inner')
      spinner
</template>

<script lang="ts">
import Spinner from './Spinner.vue'
import { debouncedWatch } from '@vueuse/shared'
import { defineComponent, ref } from 'vue'

const Overlay = defineComponent({
  components: { Spinner },
  props: {
    visible: { required: true, type: Boolean },
    debounce: { type: Number, default: 0 },
  },
  setup(props) {
    const isVisible = ref(props.visible)
    debouncedWatch(
      () => props.visible,
      (value: boolean) => (isVisible.value = value),
      { debounce: props.debounce },
    )
    return {
      isVisible,
    }
  },
})

export default Overlay
</script>
<style lang="stylus">
@import '../styles/variables.styl'
:root
  --border-radius: 0
  --overlay-bg: $color-white
  --overlay-min-height: 50px
  --overlay-opacity: 0.9
  --overlay-spinner-size: 32px

.overlay
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  pointer-events: none
  z-index: 100
  min-height: var(--overlay-min-height)
  border-radius: var(--border-radius)
  opacity: 1
  overflow: hidden
  &__inner
    position: absolute
    width: 100%
    height: 100%
    opacity: var(--overlay-opacity)
    background: var(--overlay-bg)
    svg
      position: absolute
      font-size: var(--overlay-spinner-size)
      opacity: 0.7
      transform: translate(-50%, -50%)
      top: min(150px, 50%)
      left: 50%
      .modal &
        top: 50%
</style>
