import api from './api'
import type { IAttachment, IProjectData, IProjectProductItem, ITagList, ProjectSettingsRaw } from './types'
import { userDataReactive } from '@/plugins/User'
import { type IPconDTO } from '@/types'
import { defineStore } from 'pinia'
import { mapValues, objectify } from 'radash'
import { lastValueFrom, tap } from 'rxjs'
import { computed, ref } from 'vue'

export const useProjectsStore = defineStore('projects-store', () => {
  const projects = ref<IProjectData[]>([])
  const loading = ref<Record<string, boolean>>({})
  const allProjects = computed(() =>
    projects.value.filter((p) => p.members.some((m) => m.userId === userDataReactive.id)),
  )
  const activeProjects = computed(() => allProjects.value.filter((p) => p.isActive))

  const allProjectsAsMap = computed(() => objectify(projects.value, (project) => project.id))
  const allProjectsIndexMap = computed(() =>
    Object.fromEntries(projects.value.map((project, index) => [project.id, index])),
  )

  const projectProductsAsMap = computed(() =>
    mapValues(allProjectsAsMap.value, (project) =>
      objectify<IProjectProductItem, string>(project.items, (item) => item.productId),
    ),
  )
  return {
    allProjects,
    allProjectsAsMap,
    loading,
    sharedProjects: computed(() => activeProjects.value.filter((p) => p.createdByUser.userId !== userDataReactive.id)),
    ownProjects: computed(() => activeProjects.value.filter((p) => p.createdByUser.userId === userDataReactive.id)),
    activeProjects: computed(() => allProjects.value.filter((p) => p.isActive)),
    deletedProjects: computed(() => allProjects.value.filter((p) => !p.isActive)),
    project: computed(() => (projectId: string) => allProjectsAsMap.value[projectId]),
    projectProductsAsMap,
    setProject(data: IProjectData) {
      const projectIndex = allProjectsIndexMap.value[data.id]
      if (projectIndex !== undefined) {
        projects.value[projectIndex] = { ...data }
      } else {
        projects.value.unshift(data)
      }
      return data
    },
    removeProject(projectId: string) {
      projects.value = projects.value.filter((project) => project.id !== projectId)
    },
    addOrUpdateProjectItem(projectId: string, item: IProjectProductItem) {
      const projectIndex = allProjectsIndexMap.value[projectId]
      if (projectIndex !== undefined) {
        const itemIndex = projects.value[projectIndex].items.findIndex(
          (oldItem) => item.productId === oldItem.productId,
        )
        if (itemIndex > -1) {
          projects.value[projectIndex].items[itemIndex] = item
        } else {
          projects.value[projectIndex].items.push(item)
        }
      }
    },
    setProjectItems(projectId: string, items: IProjectProductItem[]) {
      const projectIndex = allProjectsIndexMap.value[projectId]
      if (projectIndex !== undefined) {
        projects.value[projectIndex].items = items
      }
    },
    setProjectTagList(projectId: string, tagList: ITagList) {
      const projectIndex = allProjectsIndexMap.value[projectId]
      if (projectIndex !== undefined) {
        projects.value[projectIndex].tagList = tagList
      }
    },
    setProjectSettings(projectId: string, settings: ProjectSettingsRaw) {
      const projectIndex = allProjectsIndexMap.value[projectId]
      projects.value[projectIndex].settings = settings
    },
    setProjectAttachments(projectId: string, attachments: IAttachment[]) {
      const projectIndex = allProjectsIndexMap.value[projectId]
      projects.value[projectIndex].attachments = attachments
    },
    async loadProject(id: string) {
      loading.value[id] = true
      const project = await lastValueFrom(api.loadProject(id))
      loading.value[id] = false
      return this.setProject(project!)
    },
    async createInquiry(projectId: string, selectedProductIds: string[]) {
      loading.value['createInquiry'] = true
      const inquiry = lastValueFrom(api.createInquiry(projectId, selectedProductIds))
      loading.value['createInquiry'] = false
      return inquiry
    },
    async loadProjects(callback?: () => any) {
      loading.value['all'] = true
      const data = await lastValueFrom(api.loadProjects())
      projects.value = data
      loading.value['all'] = false
      if (callback) {
        callback()
      }
      return data
    },
    async restoreProject(projectId: string) {
      const { data } = await lastValueFrom(api.restoreProject(projectId))
      return this.setProject(data)
    },
    async deleteProject(projectId: string) {
      loading.value[projectId] = true
      try {
        const { data } = await lastValueFrom(api.deleteProject(projectId))
        this.setProject(data)
        loading.value[projectId] = false
        return projectId
      } catch (e) {
        loading.value[projectId] = false
        return false
      }
    },
    async updateSettings(projectId: string, settingKey: string, settingValue: string) {
      const { data } = await lastValueFrom(api.updateSettings(projectId, settingKey, settingValue))
      this.setProjectSettings(projectId, data)
      return data
    },
    async addAlternatives(projectId: string, alternativeIds: string[], parentProductId: string) {
      const { data } = await lastValueFrom(api.addAlternatives(projectId, alternativeIds, parentProductId))
      return this.setProject(data)
    },
    async removeAlternative(projectId: string, alternativeId: string, parentProductId: string) {
      const { data } = await lastValueFrom(api.removeAlternative(projectId, alternativeId, parentProductId))
      return this.setProject(data)
    },
    async setAlternativeAsFavorite(projectId: string, alternativeId: string, parentProductId: string) {
      const { data } = await lastValueFrom(api.setAlternativeAsFavorite(projectId, alternativeId, parentProductId))
      return this.setProject(data)
    },
    async moveAlternativeToToplevel(projectId: string, alternativeId: string, parentProductId: string, index: number) {
      const { data } = await lastValueFrom(
        api.moveAlternativeToToplevel(projectId, alternativeId, parentProductId, index),
      )
      return this.setProject(data)
    },
    async copyExternalProduct(projectId: string, productId: string, parentProductId?: string) {
      const { data } = await lastValueFrom(api.copyExternalProduct(projectId, productId, parentProductId))
      return this.setProject(data)
    },
    async updateProductOrder(projectId: string, productItems: string[]) {
      const originalProductItems = [...allProjectsAsMap.value[projectId].items]
      try {
        // set items directly for better performance
        this.setProjectItems(
          projectId,
          productItems.map((id: string) => projectProductsAsMap.value[projectId][id]),
        )
        const { data } = await lastValueFrom(api.updateProductOrder(projectId, productItems))
        return this.setProject(data)
      } catch (e) {
        return this.setProjectItems(projectId, originalProductItems)
      }
    },

    async updateAlternativeProductOrder(
      projectId: string,
      alternativeId: string,
      parentProductId: string,
      index: number,
      targetParentId?: string,
    ) {
      const { data } = await lastValueFrom(
        api.updateAlternativeProductOrder(projectId, alternativeId, parentProductId, index, targetParentId),
      )
      return this.setProject(data)
    },
    async removeProducts(projectId: string, productItems: string[] | string) {
      const { data } = await lastValueFrom(api.removeProducts(projectId, productItems))
      return this.setProject(data)
    },
    async removeMember(projectId: string, userId: string) {
      const { data } = await lastValueFrom(api.removeMember(projectId, userId))
      return this.setProject(data)
    },
    async removeAttachment(projectId: string, assetKey: string) {
      const { data } = await lastValueFrom(api.removeAttachment(projectId, assetKey))
      this.setProjectAttachments(projectId, data)
      return data
    },
    async updateTagOrder(projectId: string, tags: string[]) {
      const { data } = await lastValueFrom(api.updateTagOrder(projectId, tags))
      this.setProjectTagList(projectId, data)
      return data
    },
    async updateProductQuantity(projectId: string, productId: string, quantity: number) {
      const { data } = await lastValueFrom(api.updateProductQuantity(projectId, productId, quantity))
      return this.setProject(data)
    },
    updateProductQuantitySubscription(projectId: string, productId: string, quantity: number) {
      return api.updateProductQuantity(projectId, productId, quantity).pipe(tap(({ data }) => this.setProject(data)))
    },
    async removeTags(projectId: string, tags: string[]) {
      const { data } = await lastValueFrom(api.removeTags(projectId, tags))
      return this.setProject(data)
    },
    async addProducts(
      projectId: string,
      sourceProjectId: string,
      productIds: string[],
      tag?: string,
      index?: number,
      parentProductId?: string,
    ) {
      loading.value['addProducts'] = true
      const { data } = await lastValueFrom(
        api.addProducts(projectId, sourceProjectId, productIds, tag || '', index, parentProductId),
      )
      loading.value['addProducts'] = false
      return this.setProject(data)
    },
    async addComment(projectId: string, productId: string, comment: string, maybeParentProductId: string) {
      const { data } = await lastValueFrom(api.addComment(projectId, productId, comment, maybeParentProductId))
      return this.setProject(data)
    },

    async addPConProducts(projectId: string, pConData: IPconDTO[]) {
      const response = await api.addPConProducts(projectId, pConData)
      this.setProject(response.data.project)
      return response.data
    },
    async addOrEditPconProduct(
      projectId: string,
      inquiryId: string = '',
      baseProductId: string = '',
      productId: string = '*',
      tag: string = '',
      pconDTO: IPconDTO,
      name?: string,
      parentProductId?: string,
    ) {
      loading.value['addOrEditPconProduct'] = true
      const { data, response } = await lastValueFrom(
        api.addOrEditPconProduct(
          projectId,
          baseProductId,
          inquiryId,
          productId,
          tag,
          name || pconDTO.name!,
          pconDTO,
          parentProductId,
        ),
      ).finally(() => {
        loading.value['addOrEditPconProduct'] = false
      })
      if (response.valid) {
        if (data?.project) {
          this.setProject(data.project!)
        }
        return data
      } else {
        return false
      }
    },
  }
})
