<template lang="pug">
div(ref='tableContainer' class='table-container w-full')
  div(v-if='items && items.length > 0' ref='tableInner')
    custom-table(:size='size' :linked='linked')
      custom-table-header(v-if='!hideTableHeader' :items='configItemsForHeader' :additionalColumn='hasSlotAfter')
        template(#before)
          th(v-if='hasSlotBefore || selectable' class='td-before')
            div(class='flex justify-end' :data-tooltip='$t("Select all")')
              div
                checkbox(v-if='selectable' :id='`checkbox-${Math.random()}`' :modelValue='selectState === "all"' :indeterminate='selectState === "partial"' @update:modelValue='toggleSelectAll')
      tbody(
        v-if='computedItems && computedItems.length > 0'
        ref='tableBody'
        @click='onClickTableBody'
        @dblclick='onDblClickTableBody'
        @keydown.enter='onClickTableBody'
        @keydown.space='onClickTableBody'
      )
        template(v-for='({ id: rowId, item }, index) in computedItems' :key='rowId')
          tr(
            :data-id='rowId'
            :data-index='index'
            :data-parent-id='parentId'
            :data-level='level'
            :data-has-children='childrenKey && !!item[childrenKey]?.length'
            :data-selected='!!selectedMap[rowId]'
            :tabindex='linked ? 0 : -1'
            @dblclick='$emit("edit", item)'
          )
            td(v-for='{ key, tdProps } in configItemsWithProps' v-bind='tdProps' :key='key')
              slot(:value='item[key]' :name='key' :item='item' :index='index' :selected='selectedMap[rowId]')
            td(v-if='hasSlotAfter' class='td-after')
              slot(name='after' :item='item' :index='index' :edit='() => $emit("edit", item)')
          slot(name='nested' :item='item' :index='index')
      tfoot
        slot(name='footer')
  div(v-else class='table__emptyState')
    slot(name='emptyState')
      empty-state(icon='custom-sofa-not-found' :centered='true' :message='$t("There are no items yet..")')
</template>

<script lang="ts">
import CustomTable from './CustomTable.vue'
import Checkbox from '@/components/Control/Checkbox.vue'
import CustomTableHeader from '@/components/Table/CustomTableHeader.vue'
import { useSelectItems } from '@/composables/'
import { useDragDrop } from '@/utilities/DragDrop'
import { hasSlotContent } from '@/utilities/Vue'
import { type MaybeElement, useIntersectionObserver, useVModel } from '@vueuse/core'
import { type DragDropPayload, type DropPositionFn } from 'dndrxjs'
import { computed, defineComponent, onMounted, type PropType, provide, ref, type SetupContext } from 'vue'

export interface ITableConfigItem {
  label: string
  classes?: string
  style?: string
  sortable?: boolean
  editable?: boolean
  editEmptyMessage?: string
  onEdit?: (id: string) => void
  sortKey?: string
}

export interface ITableConfigItemWithKey extends ITableConfigItem {
  key: string
}

export interface ITableConfigItemWithProps extends ITableConfigItemWithKey {
  tdProps: {
    'data-col-id'?: string
    'data-col-edit-empty-message'?: string
    title?: string
    class?: string
    style?: string
    tabindex?: number
  }
}

export type ITableConfig<T = Record<string, any>> = {
  [Prop in keyof Partial<T>]: ITableConfigItem
}

export const enhanceConfigItem = (key: string, item: ITableConfigItem) => {
  const { editable, editEmptyMessage } = item
  return {
    ...item,
    key,
    tdProps: {
      'data-col-id': key,
      ...(editEmptyMessage ? { 'data-col-edit-empty-message': editEmptyMessage } : {}),
      ...(editable ? { class: 'editable ' + item.classes } : { class: item.classes }),
      ...(editable ? { tabindex: 0 } : {}),
    },
  } as ITableConfigItemWithProps
}

export const getIdsFromEventTarget = (element: EventTarget) => ({
  rowId: (element as HTMLElement).closest('tr')?.getAttribute('data-id'),
  colId: (element as HTMLElement).closest('td')?.getAttribute('data-col-id'),
})

export type TableSelectValue = string[]

const CustomDataTable = defineComponent({
  components: {
    CustomTableHeader,
    CustomTable,
    Checkbox,
  },
  props: {
    items: { required: true, type: Array as PropType<any[]> },
    type: String as PropType<'multiselect' | 'select'>,
    size: String as PropType<'default' | 'small'>,
    id: String,
    parentId: String,
    draggable: Boolean,
    linked: Boolean,
    level: { type: Number, default: 0 },
    dropPositionFn: { type: Function as PropType<DropPositionFn>, default: undefined },
    hideTableHeader: Boolean,
    itemKey: { type: String, default: 'id' },
    childrenKey: { type: String },
    config: { required: true, type: Object as PropType<ITableConfig> },
    modelValue: {
      type: Array as PropType<TableSelectValue>,
      default: () => [],
    },
  },
  emit: ['close', 'edit', 'dragDropEnd', 'clickRow'],
  setup(props, context: SetupContext) {
    const tableContainer = ref<HTMLElement | null>(null)
    const tableBody = ref<HTMLElement | null>(null)
    const tableInner = ref<HTMLElement | null>(null)
    provide('actionsContent', context.slots.actionsContent)
    const selected = useVModel(props, 'modelValue', context.emit)

    if (props.draggable) {
      useDragDrop(
        tableContainer,
        {
          dragElementSelector: ' tr[data-id]',
          dropPositionFn: props.dropPositionFn,
        },
        (t: DragDropPayload) => context.emit('dragDropEnd', t),
      )
    }
    /**
     * sticky test
     */
    const intersecting = ref(false)
    onMounted(() => {
      const el = document.querySelector('th.sticky, td.sticky')
      const offset = tableContainer.value?.getBoundingClientRect()?.x || 0
      useIntersectionObserver(
        el as MaybeElement,
        ([e]) => (intersecting.value = e.intersectionRatio < 1 && e.boundingClientRect.left < offset),
        { threshold: [1], root: tableInner.value },
      )
    })

    const getItemById = (id: string) => props.items.find((item: any) => item[props.itemKey] === id)

    const selectable = ['select', 'multiselect'].includes(props.type!)

    const hasSlotBefore = computed(() => hasSlotContent(context.slots.before))
    const hasSlotAfter = computed(() => hasSlotContent(context.slots.after))

    const computedItems = computed(() =>
      props.items!.map((item) => ({
        id: item[props.itemKey],
        item,
      })),
    )
    const configItemsWithProps = computed<ITableConfigItemWithProps[]>(() => [
      ...(hasSlotBefore.value ? [{ key: 'before', label: '', tdProps: { class: 'td-before' } }] : []),
      ...Object.entries(props.config).map(([key, item]) => enhanceConfigItem(key, item)),
    ])
    const configItemsForHeader = computed<ITableConfigItemWithKey[]>(() => [
      ...Object.entries(props.config).map(([key, item]) => ({ ...item, key })),
    ])

    return {
      ...useSelectItems(selected, tableContainer),
      hasSlotAfter,
      hasSlotBefore,
      tableContainer,
      tableInner,
      idAttribute: `CustomTableHeader${Math.random()}`,
      tableBody,
      onClickTableBody: (e: Event) => {
        if ((e.target! as Element).closest('.table-container') !== tableContainer.value) {
          return
        }
        const { rowId, colId } = getIdsFromEventTarget(e.target!)
        if (rowId) {
          if (colId) {
            if (props.config[colId!]?.editable && props.config[colId!]?.onEdit) {
              props.config[colId!].onEdit!(rowId!)
            }
          }

          context.emit('clickRow', rowId, getItemById(rowId!))
          if (
            (e.target as Element).closest('td')?.classList.contains('editable') ||
            (e.target as Element).closest('button')
          ) {
            return false
          }
        }
      },
      onDblClickTableBody: (e: Event) => {
        const { rowId } = getIdsFromEventTarget(e.target!)
        if (rowId) {
          context.emit('edit', getItemById(rowId))
        }
      },
      configItemsForHeader,
      selected,
      selectable,
      allSelected: computed(() =>
        props!.items!.length === 0 ? undefined : props.modelValue.length === props.items!.length,
      ),
      computedItems,
      configItemsWithProps,
      configKeys: computed(() => Object.keys(props.config)),
    }
  },
})

export default CustomDataTable
</script>
