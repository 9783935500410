import { useFeatureFlags } from './plugins/FeatureFlags'
import { useRequest, useUserSettingsValue } from '@/composables/'
import { defineStore } from 'pinia'
import { unique } from 'radash'
import { computed, ref } from 'vue'

export const daysBetweenDates = (date_1: Date, date_2: Date) =>
  Math.ceil((date_1.getTime() - date_2.getTime()) / (1000 * 3600 * 24))

export const usePlatformUpdates = defineStore('platform-updates', (newDateLimit: number = 90) => {
  const { get, loading } = useRequest()
  const seenEntryDates = useUserSettingsValue<string[]>('platformUpdates', [])
  const data = ref<string>('')

  const $flags = useFeatureFlags()
  if ($flags.SHOW_ALL_PLATFORM_UPDATES) {
    newDateLimit = 999999
  }
  const loadCurrent = () => get<string>('/platform-updates.md').subscribe((r) => (data.value = r.data))
  const loadArchive = () =>
    get<string>('/platform-updates-archive.md').subscribe((r) => (data.value = data.value + '\n' + r.data))
  const currentEntryDates = computed(() =>
    data.value ? [...data.value!.matchAll(/<[^>]*?datetime=([""])?((?:.(?!\1|>))*.?)\1?/g)].map((item) => item[2]) : [],
  )
  const setEntriesStateToSeen = (entryDates: string[]) =>
    (seenEntryDates.value = unique([...seenEntryDates.value, ...entryDates]))

  loadCurrent()
  return {
    loadArchive,
    loadCurrent,
    reset: () => (seenEntryDates.value = []),
    setEntriesStateToSeen,
    seenEntryDates,
    setAllCurrentEntriesStateToSeen: () => setEntriesStateToSeen(currentEntryDates.value),
    unseenEntryDates: computed(() =>
      currentEntryDates.value.filter(
        (date) => !seenEntryDates.value.includes(date) && daysBetweenDates(new Date(), new Date(date)) < newDateLimit,
      ),
    ),
    currentEntryDates,
    loading,
    data,
  }
})
