<template lang="pug">
div(:class='classes' style='appearance: none' class='control-switch-icon')
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

const ControlSwitchIcon = defineComponent({
  props: {
    value: Boolean,
    size: String,
    disabled: Boolean,
  },
  setup(props) {
    return {
      classes: computed(() => ({
        [`control-switch-icon--${props.size}`]: true,
        'control-switch-icon--checked': props.value,
        'control-switch-icon--disabled': !!props.disabled,
      })),
    }
  },
})

export default ControlSwitchIcon
</script>

<style lang="stylus">
:root
  --switch-size: 18px
  --switch-padding: 4px
  --switch-ratio: 2
  --switch-bg: var(--grey-300)
  --switch-position: 0%

.control-switch-icon
  text-indent: -9999px
  width: calc( var(--switch-size) * var(--switch-ratio))
  height: var(--switch-size)
  border-radius:  var(--switch-size)
  background: var(--switch-bg)
  border: var(--switch-padding) solid var(--switch-bg)
  display: block
  transition: all 0.3s ease
  position: relative
  appearance: none
  &,&:after
    box-sizing: content-box
  &--checked
    --switch-bg: var(--green-400)
    --switch-position: calc(100% - var(--switch-size))
  &--disabled
    --switch-bg: var(--grey-200)!important
    pointer-events: none
  &--tiny
    --switch-size: 12px
    --switch-padding: 3px
    --switch-ratio: 2
  &--small
    --switch-size: 16px
    --switch-padding: 3px
  &:after
    content: ''
    display: block
    position: absolute
    top: 0
    left: var(--switch-position)
    background: #fff
    border-radius:  var(--switch-size)
    transition: .25s
    height: var(--switch-size)
    width: var(--switch-size)
</style>
